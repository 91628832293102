import * as questionsActions from "./QuestionsActionTypes";
import * as repeatingCardsHelper from "./QuestionsForm/QuestionsTypes/RepeatingCards/RepeatingCardsHelper";
import * as appHelper from "../App/AppHelper";
import * as helper from "./QuestionHelpers/QuestionsHelper";
import * as validationHelper from "./QuestionHelpers/ValidationHelper";
import * as psExecute from "./PageScript/Execute";
import * as psInit from "./PageScript/Initialize";
import * as psUpdate from "./PageScript/Update";
import { cloneDeep, filter, forEach, uniqBy } from "lodash";
import util from "../Utilities/Functions/Functions";
import enums from "../Utilities/Enums/Enums";

export const INITIAL_STATE = {
  //questions state (e.g. cards, expandingCards, question, etc....)
  additionalInsuredsBasic: [],
  allData: {},
  applyDataDefaults: {},
  applySimpleValidation: validationHelper.applySimpleValidation,
  companyAddressCity: "",
  companyAddressState: "",
  companyAddressStreet: "",
  companyAddressZip: "",
  data: {},
  dataDefaults: {},
  dateConflictAlert: "",
  dateConflictEffDate: "",
  downPayment: "",
  dynamicModal: "",
  dynamicModalQuestions: [],
  entityID: "",
  exitWithoutSaveModalMsg: "",
  facilityRefCode: "",
  fieldDataDisplay: false,
  hasNegativeQuote: false,
  hasNoPremium: false,
  hasSurplusLineCoverage: false,
  hasWorkersCompCoverage: false,
  hideQRForm: false,
  hideAllInputs: false,
  invalidNavigationPages: [],
  isCompleteSubmission: true,
  isEndorsement: false,
  isInvalidForm: false,
  isMailInPurchase: false,
  isReviewDependentPurchase: false,
  isValidPurchase: false,
  isWorkersCompOnly: false,
  loading: false,
  loadingQuote: false,
  logSetupErrors: [],
  navigateTo: {},
  navigationData: [],
  navigationPage: "",
  navigationsPageName: "",
  navigationsUrl: "",
  nextPage: {},
  notifyOnly: false,
  offersSurplusLine: false,
  offersWorkersComp: false,
  openExitWithoutSaveModal: false,
  openSaveAndExitModal: false,
  openSaveAndExitAIModal: false,
  pageHasBanner: false,
  pageName: "",
  pageUrl: "",
  pageScript: {},
  pageScriptPristine: {},
  pageScriptReportLog: [],
  pageScriptReporting: false,
  policyEffectiveDate: "",
  policyExpirationDate: "",
  premQuote: "",
  premQuoteMinusCommission: "",
  previousPage: {},
  programCode: "",
  programGroupCode: "",
  programName: "",
  promoCode: "",
  psExecutedFields: [],
  qrHardRequired: [],
  questionIDLookups: {},
  questions: [],
  quote: "",
  rCardsWithError: [],
  requiresOutOfStateUnemploymentNumber: false,
  resetFormikToInitialValues: false,
  storedInvalidFields: [],
  storedValidFields: [],
  styles: {},
  submissionID: "",
  submissionLoaded: false,
  submissionValidationMsg: [],
  subpage: "",
  subpageIsSelected: false,
  subpageQuestions: [],
  toastMsg: "",
  toastType: "",
  toggleEmailQuoteModal: false,
  useDebugMode: false,
  useNoQuoteQR: false,
  useQuickQuoteLink: false,
  userEntityType: "",
  validationMsg: "",
  validationSchema: {},
  validNavigationPages: [],
};

export default function questionsReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case questionsActions.clearAppliedDataDefaults:
      state = update(state, {
        applyDataDefaults: [],
      });
      break;
    case questionsActions.clearLoadingFlags:
      state = update(state, {
        loading: false,
        loadingQuote: false,
      });
      break;
    case questionsActions.clearPropertyValueFromPSField:
      let executedFieldScripts = cloneDeep(state.psExecutedFields);
      let clonedPageScripts = cloneDeep(state.pageScript);

      // we need to update both the unexecuted and executed fields
      let clearedPS = {};
      clearedPS["scripts"] = psUpdate.clearPropertyValueFromPSField(
        action.fieldID,
        action.propertyToClear,
        clonedPageScripts.scripts
      );
      clearedPS["contingentValuesPrevPages"] = clonedPageScripts.contingentValuesPrevPages;

      let clearedPSExecuted = psUpdate.clearPropertyValueFromPSField(
        action.fieldID,
        action.propertyToClear,
        executedFieldScripts
      );

      state = update(state, {
        pageScript: clearedPS,
        psExecutedFields: clearedPSExecuted,
      });
      break;
    case questionsActions.clearPSReportLog:
      state = update(state, {
        pageScriptReportLog: [],
      });
      break;
    case questionsActions.clearQuestionsState:
      state = INITIAL_STATE;
      break;
    case questionsActions.clearSubpage:
      state = update(state, {
        subpage: "",
        subpageIsSelected: false,
        subpageQuestions: [],
      });
      break;
    case questionsActions.clearQuestionsToast:
      state = update(state, {
        toastMsg: "",
        toastType: "",
      });
      break;
    case questionsActions.executePageScript:
      let clonedExecutedPSFields = cloneDeep(state.psExecutedFields);
      let applyDataDefaults = {};
      let clonedPS = cloneDeep(state.pageScript);
      let formValuesCurrentAndPrevPages = action.values;
      if (clonedPS.contingentValuesPrevPages.length > 0) {
        if (formValuesCurrentAndPrevPages && state.pageUrl.indexOf("QuickRate") < 0) {
          for (let field in clonedPS.contingentValuesPrevPages) {
            for (let appliesToLookupCode in clonedPS.contingentValuesPrevPages[field].appliesToLookupCodes) {
              formValuesCurrentAndPrevPages[
                clonedPS.contingentValuesPrevPages[field].appliesToLookupCodes[appliesToLookupCode]
              ] = clonedPS.contingentValuesPrevPages[field].value;
            }
          }
        }
      }
      //can't rely on formik to update formValue - so we always pass field value here if it's available
      //and update the formValues located in action.values so we have one point of reference inside psHelper
      if (action.value && formValuesCurrentAndPrevPages && action.issuingInputLookup) {
        for (let lookupCode in formValuesCurrentAndPrevPages) {
          if (lookupCode === action.issuingInputLookup) {
            formValuesCurrentAndPrevPages[lookupCode] = action.value;
          }
        }
      }

      //if issuingInputLookup contains an -OP, we need to know about it in our execution since our PS db fields will be generic, non-OP
      let issuingInput_SetID_OP;

      if (
        action.issuingInputLookup &&
        action.issuingInputLookup.split("-SetID-")[1] &&
        action.issuingInputLookup.split("-SetID-")[1].includes("OP")
      ) {
        issuingInput_SetID_OP = action.issuingInputLookup.split("-SetID-")[1];
      }

      let executedFieldsObj;
      let updatedPSFields;
      const pageScriptReports = [];

      //note: incoming action.field may include mult appliesToLookupCodes
      executedFieldsObj = psExecute.executePageScript(
        action.field,
        formValuesCurrentAndPrevPages,
        clonedExecutedPSFields,
        clonedPS.scripts,
        action.executionTime,
        action.userEntityType,
        issuingInput_SetID_OP,
        state,
        false,
        pageScriptReports
      );

      let uniquePageScriptReportIds = [];
      let uniquePageScriptReports = [];
      forEach(pageScriptReports, (pageScriptReports) => {
        if (!uniquePageScriptReportIds.includes(pageScriptReports.stagedField.id)) {
          uniquePageScriptReportIds.push(pageScriptReports.stagedField.id);
          uniquePageScriptReports.push(pageScriptReports);
        }
      });

      updatedPSFields = psUpdate.updatePSFieldsWithExecutedFieldsObj(executedFieldsObj, clonedPS);

      state = update(state, {
        applyDataDefaults: applyDataDefaults ? applyDataDefaults : {},
        pageScript: updatedPSFields,
        pageScriptReportLog: uniquePageScriptReports,
        psExecutedFields: executedFieldsObj,
      });
      break;
    case questionsActions.getQuestionsAndDataSuccess:
      const questionObjects = helper.setQuestionValues(
        action.questionData,
        action.questionData.programGroupCode,
        action.activeCardSet
      );

      let configSettings;
      let approvedDebugger = true;
      if (action.config && action.config.length > 0) {
        configSettings = appHelper.getConfigItems(action.questionData.programGroupCode, action.config);
      }

      // if (!util.isObjEmpty(action.configEntity)) {
      //   //use mga role check here instead of configEntity since it's just this one entry
      //   //if the entityConfig gets commented back in appActions, we can reemploy this code
      //   //use the mga role to permit debugging
      //   approvedDebugger =
      //     has(action.configEntity, enums.configEntityItems.useDebugMode) &&
      //     action.configEntity.UseDebugMode === enums.bool.true;
      // }

      approvedDebugger = action.appLevelRole === enums.role.mga;

      const pageScriptPremountReports = [];

      const pageScript = psInit.buildPSObj(
        action.questionData.pageScripts,
        action.questionData.pageScriptPrevPages,
        questionObjects.questions,
        action.questionData.programGroupCode,
        questionObjects.initialValues,
        state,
        pageScriptPremountReports
      );

      let uniquePremountReportIds = [];
      let uniquePremountReports = [];
      forEach(pageScriptPremountReports, (premountReport) => {
        if (!uniquePremountReportIds.includes(premountReport.stagedField.id)) {
          uniquePremountReportIds.push(premountReport.stagedField.id);
          uniquePremountReports.push(premountReport);
        }
      });

      const companyAddressItems = { ...action.questionData.companyAddress };

      state = update(state, {
        additionalInsuredsBasic: action.questionData.additionalInsuredsBasic,
        allData: action.questionData,
        companyAddressCity: companyAddressItems.city,
        companyAddressState: companyAddressItems.state,
        companyAddressStreet: companyAddressItems.street,
        companyAddressZip: companyAddressItems.zip,
        data: questionObjects.initialValues,
        dataDefaults: questionObjects.defaultValues,
        entityID: action.entityID ? action.entityID : state.entityID,
        isEndorsement: action.questionData.submissionStatus === enums.statusInternal.endorse,
        listeningValidationFields: questionObjects.listeningValidationFields,
        logSetupErrors: questionObjects.logErrors,
        navigationPage: action.pageName,
        offersSurplusLine:
          configSettings && configSettings.OffersSurplusLine === "TRUE" ? true : state.offersSurplusLine,
        //note: "offersWorkersComp" doesn't update in time for PS questions state test on inital load of QR
        offersWorkersComp:
          configSettings && configSettings.OffersWorkersComp === "TRUE" ? true : state.offersWorkersComp,
        pageName: action.questionData.pageName,
        pageScript: { scripts: pageScript.scripts, contingentValuesPrevPages: pageScript.contingentValuesPrevPages },
        pageScriptPristine: {
          scripts: pageScript.scripts,
          contingentValuesPrevPages: pageScript.contingentValuesPrevPages,
        },
        pageScriptReportLog: uniquePremountReports,
        pageUrl: action.pageUrl,
        //need effective date info on each nav, once inside of a policy currentSubmission data is not updated
        policyEffectiveDate: action.questionData.effectiveDate,
        policyExpirationDate: action.questionData.expirationDate,
        programCode: action.programCode,
        programGroupCode: action.questionData.programGroupCode,
        programName: action.questionData.programName,
        psExecutedFields: pageScript.executedPremountPersistFields, //load in any premountPersist fields, which convert to executred postmount objs
        qrHardRequired: questionObjects.qrHardRequired,
        storedInvalidFields: questionObjects.storedInvalidFields,
        storedValidFields: questionObjects.storedValidFields,
        questionIDLookups: questionObjects.questionIDLookups,
        questions: questionObjects.questions,
        quote: "", //unset each time so that they have the option to run QR quote again if they nav back
        submissionID: action.submissionID,
        useDebugMode: approvedDebugger,
        useNoQuoteQR: configSettings && configSettings.UseNoQuoteQR === "TRUE" ? true : state.useNoQuoteQR,
        useQuickQuoteLink:
          configSettings && configSettings.UseQuickQuoteLink === "TRUE" ? true : state.useQuickQuoteLink,
        userEntityType: action.userEntityType,
        validationMsg: "",
      });
      break;
    case questionsActions.getNavigationDataSuccess:
      let navPages = helper.setNavPages(action.navigationData, action.pageUrl);
      let validationLists = helper.setValidationList(action.navigationData);
      state = update(state, {
        dateConflictAlert: "", //clear out date alerts from prev pages, too unpredictable
        dateConflictEffDate: "", //we can always get prev page data and reset with PS
        invalidNavigationPages: validationLists.invalidList,
        navigationData: action.navigationData,
        nextPage: navPages.nextPage,
        pageUrl: action.pageUrl,
        previousPage: navPages.previousPage,
        validNavigationPages: validationLists.validList,
      });
      break;
    case questionsActions.getFacilityConfigSuccess:
      state = update(state, {
        //we need this duplicate value for pageScript, which can't read from appState
        facilityRefCode: action.facilityRefCode,
      });
      break;
    case questionsActions.issueExitWithoutSaveModal:
      state = update(state, {
        openExitWithoutSaveModal: !state.openExitWithoutSaveModal,
        exitWithoutSaveModalMsg: action.message,
      });
      break;
    case questionsActions.issueSaveAndExitModal:
      state = update(state, {
        openSaveAndExitModal: !state.openSaveAndExitModal,
      });
      break;
    case questionsActions.toggleSaveAndExitAIModal:
      state = update(state, {
        openSaveAndExitAIModal: !state.openSaveAndExitAIModal,
      });
      break;
    case questionsActions.clearSaveAndExitAIModal:
      state = update(state, {
        openSaveAndExitAIModal: false
      });
      break;
    case questionsActions.hideAllInputs:
      state = update(state, {
        hideAllInputs: action.bool,
      });
      break;
    case questionsActions.reportQuestionsToast:
      state = update(state, { toastMsg: action.toastMsg, toastType: action.toastType });
      break;
    case questionsActions.resetRCardsWithError:
      state = update(state, {
        rCardsWithError: []
      });
      break;
    case questionsActions.setCardAndValuesToPristine:
      state = update(state, {
        data: Object.assign({}, state.data, action.cleanedValues),
        questions: action.questions,
      });
      break;

    case questionsActions.setDateConflictAlert:
      let newEffectiveDate = "";
      if (action.alert) {
        newEffectiveDate = action.newEffectiveDate;
      }
      state = update(state, {
        dateConflictAlert: action.alert && !action.isAIAlert ? action.alert : state.dateConflictAlert,
        dateConflictAlertAI: action.isAIAlert ? action.alert : state.dateConflictAlertAI,
        dateConflictEffDate: newEffectiveDate,
      });
      break;
    case questionsActions.setLoadingFlag:
      state = update(state, {
        loading: true,
      });
      break;
    case questionsActions.setLoadingQuoteFlag:
      state = update(state, {
        loadingQuote: true,
      });
      break;
    case questionsActions.setDynamicModal:
      let stateQuestionsCopy = cloneDeep(state.questions);
      let modalQuestions = filter(stateQuestionsCopy, function (p) {
        return p.cardLookupCode === action.modalName;
      });

      state = update(state, {
        dynamicModal: action.modalName,
        dynamicModalQuestions: modalQuestions,
      });
      break;
    case questionsActions.setNavigateTo:
      state = update(state, {
        navigateTo: { pageName: action.pageName, pageUrl: action.pageUrl },
      });
      break;
    case questionsActions.setNavigationPage:
      let newNavPages = helper.setNavPages(state.navigationData, action.pageUrl);
      state = update(state, {
        nextPage: newNavPages.nextPage,
        previousPage: newNavPages.previousPage,
        isInvalidForm: false,
        validationMsg: "",
        subpage: "",
        subpageIsSelected: false,
        subpageQuestions: [],
      });
      break;
    case questionsActions.setProgramCode:
      state = update(state, {
        submissionID: action.programCode,
      });
      break;
    case questionsActions.setPromoCode:
      state = update(state, {
        promoCode: action.promoCode,
      });
      break;
    case questionsActions.setQuestionsQueryParam:
      const stateProperty = action.stateProperty;
      state = update(state, {
        [stateProperty]: action.queryParam,
      });
      break;
    case questionsActions.setSubmissionID:
      state = update(state, {
        submissionID: action.submissionID,
      });
      break;
    case questionsActions.setSubpageQuestions:
      state = update(state, {
        subpage: action.subpage,
        subpageIsSelected: true,
        subpageQuestions: action.subpageQuestions,
      });
      break;
    case questionsActions.submissionValidationResult:
      state = update(state, {
        submissionValidationMsg: action.validationResult.validationMsg,
      });
      break;
    case questionsActions.setValidPurchase:
      state = update(state, {
        isValidPurchase: true,
        isMailInPurchase: action.isMailInPurchase,
        subpage: "",
        hasNoPremium: false, //return to default to hide pagination options
        hasNegativeQuote: false
      });
      break;
    case questionsActions.toggleEmailQuoteModal:
      state = update(state, {
        toggleEmailQuoteModal: !state.toggleEmailQuoteModal,
      });
      break;
    case questionsActions.toggleFieldDataDisplay:
      state = update(state, {
        fieldDataDisplay: !state.fieldDataDisplay,
      });
      break;
    case questionsActions.togglePageHasBanner:
      state = update(state, {
        pageHasBanner: !state.pageHasBanner,
      });
      break;
    case questionsActions.toggleModal: //Only close
      state = update(state, {
        isInvalidForm: false,
        loading: false,
        loadingQuote: false,
        validationMsg: "",
      });
      break;
    case questionsActions.toggleQRFormHide:
      //if we are editing QR coverages and revealing form again, we need to clear the quote
      let clearQuote = state.hideQRForm ? true : false;

      state = update(state, {
        hideQRForm: !state.hideQRForm,
        quote: clearQuote ? "" : state.quote,
      });
      break;
    case questionsActions.toggleResetFormikToInitial:
      state = update(state, {
        resetFormikToInitialValues: !state.resetFormikToInitialValues,
      });
      break;
    case questionsActions.togglePageScriptReporting:
      state = update(state, {
        pageScriptReporting: !state.pageScriptReporting,
      });
      break;
    case questionsActions.toggleSubmissionLoaded:
      state = update(state, {
        submissionLoaded: !state.submissionLoaded,
      });
      break;

    case questionsActions.validationResultAll:
      let hasNoPremiums = false;
      let invalidPagesAll = [];
      let isWorkersCompOnly = false;
      let validPagesAll = cloneDeep(state.validNavigationPages);

      //grab invalid page details from error messages reported
      forEach(action.pageValidations, (msg) => {
        invalidPagesAll.push({ pageDisplayName: msg.pageName, pageUrl: msg.pageUrl });
      });

      if (invalidPagesAll && invalidPagesAll.length > 0) {
        invalidPagesAll = uniqBy(invalidPagesAll, "pageUrl");
      }

      forEach(state.validNavigationPages, (validPage, index) => {
        forEach(invalidPagesAll, (invalidPage) => {
          if (invalidPage.pageUrl === validPage.pageUrl) {
            validPagesAll.splice(index, 1);
          }
        });
      });

      //interpret existence of quote premium
      if (action.isValid && state.isEndorsement && action.quote && parseFloat(action.quote) === 0) {
        hasNoPremiums = true;
        if (action.hasWorkersCompCoverage) {
          isWorkersCompOnly = true;
        }
      }

      //update these again, for programs that may not have qr rating endpoint hit
      let workCompSelection =
        typeof action.hasWorkersCompCoverage === "boolean"
          ? action.hasWorkersCompCoverage
          : cloneDeep(state.hasWorkersCompCoverage);
      let surplusLineCovSelection =
        typeof action.hasSurplusLineCoverage === "boolean"
          ? action.hasSurplusLineCoverage
          : cloneDeep(state.hasSurplusLineCoverage);
      let requiresOutOfStateUnemploymentNumber =
        typeof action.requiresOutOfStateUnemploymentNumber === "boolean"
          ? action.requiresOutOfStateUnemploymentNumber
          : cloneDeep(state.requiresOutOfStateUnemploymentNumber);
      let hasNegativeQuote =
        typeof action.hasNegativeQuote === "boolean"
          ? action.hasNegativeQuote
          : cloneDeep(state.hasNegativeQuote);

      if (action.appLevelRole && (action.appLevelRole === enums.role.mga || action.appLevelRole === enums.role.admin || action.appLevelRole === enums.role.callrep)) {
        hasNegativeQuote = false;
      }

      //return meta validation state items
      state = update(state, {
        isInvalidForm: !action.isValid,
        isCompleteSubmission: invalidPagesAll.length > 0 ? false : true,
        premQuote: action.quote,
        premQuoteMinusCommission: action.quoteMinusCommission,
        downPayment: action.downPayment,
        hasNoPremium: hasNoPremiums,
        validNavigationPages: validPagesAll,
        hasSurplusLineCoverage: surplusLineCovSelection,
        hasWorkersCompCoverage: workCompSelection,
        invalidNavigationPages: invalidPagesAll,
        isReviewDependentPurchase: action.requiresReview,
        isWorkersCompOnly: isWorkersCompOnly,
        requiresOutOfStateUnemploymentNumber: requiresOutOfStateUnemploymentNumber,
        hasNegativeQuote: hasNegativeQuote
      });
      break;

    case questionsActions.validationResultSingle:
      let isQRPage = action.pageUrl.indexOf("QuickRate") > -1;
      let hasNoPremium = false;
      let workersCompOnly = false;

      //get the page's state prior to this update
      let pageCurrentState = helper.getPageState(
        state.validNavigationPages,
        state.invalidNavigationPages,
        state.navigationPage
      );

      //page/form is valid
      if (action.isValid) {
        //valid qr quote, no page state to update
        if (isQRPage) {
          state = update(state, {
            quote: action.quote,
            loadingQuote: false,
          });
        }

        //if page is currently invalid, but form is valid, toggle it back
        else if (pageCurrentState.invalid) {
          //page is now valid, add to valid and remove from invalid list if it exists
          state = update(state, {
            validNavigationPages: [
              ...state.validNavigationPages,
              { pageDisplayName: state.navigationPage, pageUrl: action.pageUrl },
            ],
            invalidNavigationPages: filter(state.invalidNavigationPages, function (p) {
              return p.pageDisplayName !== state.navigationPage;
            }),
          });
        }
      }

      //page/form is invalid
      else if (!action.isValid) {
        let updatedFieldValidations = [];
        let clonedInvalidFields;
        let clonedValidFields;
        let rCardsWithError = [];

        //update page validation markup from valid to invalid
        if (action.pageValidations) {
          let newFields = [];
          clonedInvalidFields = cloneDeep(state.storedInvalidFields);
          clonedValidFields = cloneDeep(state.storedValidFields);

          forEach(action.pageValidations, (item) => {
            forEach(item.validationMsgs, (msg) => {
              if (msg.lookupCode) {
                const normalizedLookup = util.normalizeLookupCode(msg.lookupCode, state.programCode);
                const eligibleField = state.questions.find((q) => q.lookupCode === normalizedLookup);
                if (eligibleField) {
                  const strappedWithValidation = validationHelper.applySimpleValidation(eligibleField, msg.answer);
                  if (strappedWithValidation) {
                    newFields.push(strappedWithValidation);
                  }
                }
                if (state.questions[0].lookupCode.includes("CardRepeatingSet")) {
                  forEach(state.questions[0].cardCollection, (card) => {
                    if (card.ordinalPosition === msg.ordinalPosition) {
                      if (!rCardsWithError.includes(card.ordinalPosition)) {
                        rCardsWithError.push(card.ordinalPosition);
                      }
                    }
                  });
                }
              }
            });
          });

          updatedFieldValidations = validationHelper.updateFieldValidations(
            newFields,
            action.isValid,
            clonedInvalidFields,
            clonedValidFields
          );
        }

        //if page is currently valid, but has errors, move page's current state to invalid
        //and remove from valid state
        if (pageCurrentState.valid) {
          state = update(state, {
            invalidNavigationPages: [
              ...state.invalidNavigationPages,
              { pageDisplayName: state.navigationPage, pageUrl: action.pageUrl },
            ],
            //add it back to invalid
            validNavigationPages: filter(state.validNavigationPages, function (p) {
              return p.pageDisplayName !== state.navigationPage;
            }),
          });
        }

        state = update(state, {
          rCardsWithError: rCardsWithError,
          validationMsg: helper.buildValidationModalMessage(action.pageValidations),
          storedInvalidFields:
            updatedFieldValidations.invalidFields.length > 0
              ? updatedFieldValidations.invalidFields
              : state.storedInvalidFields,
          storedValidFields:
            updatedFieldValidations.validFields.length > 0
              ? updatedFieldValidations.validFields
              : state.storedValidFields,
        });
      }

      //interpret existence of quote premium
      if (!pageCurrentState.invalid && state.isEndorsement && action.quote && parseFloat(action.quote) === 0) {
        hasNoPremium = true;
        if (action.hasWorkersCompCoverage) {
          workersCompOnly = true;
        }
      }

      //update the quote price for premind
      let newQuote = action.quote;
      if (!isQRPage) {
        if (action.quote) {
          newQuote = action.quote;
        }
      }

      //update the quote minus commission price for premind
      let newQuoteMinusCommission = action.quoteMinusCommission;
      if (!isQRPage) {
        if (action.quoteMinusCommission) {
          newQuoteMinusCommission = action.quoteMinusCommission;
        }
      }

      //update the downPayment price for premind
      let newDownPayment = action.downPayment;
      if (!isQRPage) {
        if (action.downPayment) {
          newQuote = action.downPayment;
        }
      }

      //update hasNegativeQuote bool
      let isNegativeQuote = action.hasNegativeQuote
        ? action.hasNegativeQuote
        : cloneDeep(state.hasNegativeQuote);

      if (action.appLevelRole && (action.appLevelRole === enums.role.mga || action.appLevelRole === enums.role.admin || action.appLevelRole === enums.role.callrep)) {
        isNegativeQuote = false;
      }

      //update hasWokersCompCoverage bool
      let workersCompSelection = state.hasWorkersCompCoverage;
      if (isQRPage) {
        workersCompSelection = action.hasWorkersCompCoverage ? action.hasWorkersCompCoverage : false;
      }

      //update hasSurplusLine bool
      let surplusLineSelection = action.hasSurplusLineCoverage
        ? action.hasSurplusLineCoverage
        : cloneDeep(state.hasSurplusLineCoverage);
      let requireOutOfStateUnemploymentNumber = action.requiresOutOfStateUnemploymentNumber
        ? action.requiresOutOfStateUnemploymentNumber
        : cloneDeep(state.requiresOutOfStateUnemploymentNumber);

      //return meta validation state items
      state = update(state, {
        isInvalidForm: !action.isValid,
        isCompleteSubmission: helper.completeSubmissionCheck(state.validNavigationPages, state.navigationData.length),
        premQuote: newQuote,
        premQuoteMinusCommission: newQuoteMinusCommission,
        downPayment: newDownPayment,
        hasSurplusLineCoverage: surplusLineSelection,
        hasWorkersCompCoverage: workersCompSelection,
        requiresOutOfStateUnemploymentNumber: requireOutOfStateUnemploymentNumber,
        hasNoPremium: hasNoPremium,
        hasNegativeQuote: isNegativeQuote,
        isWorkersCompOnly: workersCompOnly
      });
      break;

    case questionsActions.updateFieldValidations:
      let newFields = [];
      let cloneValidFields = cloneDeep(state.storedValidFields);
      let cloneInvalidFields = cloneDeep(state.storedInvalidFields);
      newFields.push(action.fields); //need to form this into array to be consistent with usage in validationResult
      let validationObjs = validationHelper.updateFieldValidations(
        newFields,
        action.valid,
        cloneInvalidFields,
        cloneValidFields
      );
      state = update(state, {
        storedValidFields: validationObjs.validFields,
        storedInvalidFields: validationObjs.invalidFields,
      });
      break;

    case questionsActions.updateInitialValues:
      let formValues = Object.assign({}, state.data, action.values);
      let questionLookups = Object.assign({}, state.questionIDLookups, action.newIDs);

      state = update(state, {
        data: formValues,
        questionIDLookups: action.newIDs ? questionLookups : state.questionIDLookups,
      });
      break;

    case questionsActions.updateProgramCode:
      state = update(state, {
        programCode: action.programCode,
      });
      break;

    //******** expanding cards
    case questionsActions.toggleCardSelection:
      state = update(state, {
        questions: action.updatedQuestions,
      });
      break;

    //********* repeating cards
    case questionsActions.closeRepeatingCard:
      let copiedQuestions = cloneDeep(state.questions);

      if (action.cardSet) {
        for (let i = 0; i < copiedQuestions.length; i++) {
          if (copiedQuestions[i].lookupCode === action.cardSet.lookupCode) {
            let copiedCardSet = copiedQuestions[i];

            copiedCardSet.dateConflictAlertAI = "";
            copiedCardSet.emailCardModalBody = "";
            copiedCardSet.hiddenCardPageCount = repeatingCardsHelper.rCardHideOrShowCount(
              action.cardSet.cardCollection,
              true
            );
            copiedCardSet.isEditingCardAtOP = null;
            copiedCardSet.modalCancelButtonText = "";
            // copiedCardSet.repeatingCardsActive = repeatingCardsActiveReset;
            copiedCardSet.showCardPageCount = repeatingCardsHelper.rCardHideOrShowCount(
              action.cardSet.cardCollection,
              false
            );
            copiedCardSet.showHiddenCards = action.cardSet.showHiddenCards;
            copiedCardSet.unsavedDeleteCardWarning = "";

            break;
          }
        }
      }

      state = update(state, {
        pageScript: state.pageScriptPristine,
        psExecutedFields: state.pageScriptPristine, //in future, there may be scenario where we can't bulk reset like this
        questions: copiedQuestions,
        resetFormikToInitialValues: true,
      });
      break;

    case questionsActions.editRepeatingCard:
      let updatedQuestionsCopy = cloneDeep(state.questions);
      let cardSetRep;

      if (action.selectedCardSet) {
        for (let i = 0; i < updatedQuestionsCopy.length; i++) {
          if (updatedQuestionsCopy[i].lookupCode === action.selectedCardSet.lookupCode) {
            cardSetRep = updatedQuestionsCopy[i];

            for (let j = 0; j < cardSetRep.cardCollection.length; j++) {
              let card = cardSetRep.cardCollection[j];
              if (card.ordinalPosition === action.selectedCard.ordinalPosition) {
                cardSetRep.modalCancelButtonText = "Remove";
                cardSetRep.isEditingCardAtOP = card.ordinalPosition;
                break;
              }
            }

            break;
          }
        }
      }
      state = update(state, {
        questions: updatedQuestionsCopy,
      });

      break;

    case questionsActions.setRepeatingCardHiddenFlag:
      //run through all questions
      let qClone = cloneDeep(state.questions);
      let set = qClone.find((q) => q.lookupCode === action.cardSet.lookupCode);
      let setIndex = qClone.findIndex((q) => q.lookupCode === action.cardSet.lookupCode);
      let cardIndex = set.cardCollection.findIndex((card) => card.ordinalPosition === action.card.ordinalPosition);

      set.cardCollection[cardIndex].isHidden = !set.cardCollection[cardIndex].isHidden;
      set.hiddenCardPageCount = repeatingCardsHelper.rCardHideOrShowCount(set.cardCollection, true);
      set.showCardPageCount = repeatingCardsHelper.rCardHideOrShowCount(set.cardCollection, false);

      set.repeatingCardsActiveRangeEnd = util.getRepeatingCardActiveRangeEnd(set);
      set.repeatingCardsActive = helper.extractActiveRCards(
        set.cardCollection,
        set.repeatingCardsActiveRangeEnd,
        action.showHiddenCards
      );

      set.activeCardRangeStart = set.repeatingCardsActiveRangeEnd - 5;
      set.activeCardRangeIndex = Math.ceil(set.repeatingCardsActiveRangeEnd / 6);

      qClone[setIndex] = set;

      state = update(state, {
        questions: qClone,
      });
      break;
    case questionsActions.toggleShowHideAllRepeatingCards:
      //run through all questions
      let questionsCloned = cloneDeep(state.questions);
      let isCardSet = questionsCloned.find((q) => q.lookupCode === action.cardSet.lookupCode);
      let isCardSetIndex = questionsCloned.findIndex((q) => q.lookupCode === action.cardSet.lookupCode);
      let isActiveRCards = helper.extractActiveRCards(isCardSet.cardCollection, 6, action.showHiddenCards);
      isCardSet.repeatingCardsActive = isActiveRCards;
      isCardSet.activeCardRangeStart = 1;
      isCardSet.activeCardRangeIndex = 0;
      isCardSet.repeatingCardsActiveRangeEnd = 6;
      isCardSet.showHiddenCards = action.showHiddenCards;
      questionsCloned[isCardSetIndex] = isCardSet;

      state = update(state, {
        questions: questionsCloned,
      });
      break;

    case questionsActions.updateActiveRepeatingCards:
      //run through all questions
      let questionsClone = cloneDeep(state.questions);
      let cardSet = questionsClone.find((q) => q.lookupCode === action.cardSet.lookupCode);
      let cardSetIndex = questionsClone.findIndex((q) => q.lookupCode === action.cardSet.lookupCode);
      cardSet.repeatingCardsActive = helper.extractActiveRCards(
        cardSet.cardCollection,
        action.activeRangeEnd,
        action.showHiddenCards
      );
      cardSet.activeCardRangeStart = action.activeRangeStart;
      cardSet.activeCardRangeIndex = action.activeRangeIndex;
      cardSet.repeatingCardsActiveRangeEnd = action.activeRangeEnd;
      questionsClone[cardSetIndex] = cardSet;

      state = update(state, {
        questions: questionsClone,
      });
      break;

    case questionsActions.updateQuestions:
      state = update(state, {
        questions: action.questions,
      });
      break;

    default:
      break;
  }
  return state;
}
