import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import PrivacyModal from "../Utilities/Modals/PrivacyModal/PrivacyModal";
import TermsModal from "../Utilities/Modals/TermsModal/TermsModal";
import * as footerActions from "./FooterActions";


const Footer = () => {

  //dispatch actions
  const dispatch = useDispatch();

  //outbound
  const togglePrivacyModal = useCallback(() => dispatch(footerActions.togglePrivacyModal()), [dispatch]);
  const toggleTermsOfUseModal = useCallback(() => dispatch(footerActions.toggleTermsOfUseModal()), [dispatch]);

  const getYear = () => {
    var d = new Date();
    var n = d.getFullYear();
    return n;
  };

  return (
    <div id="footer_main_div_1">
      <Row id="footer_row_1">
        <Col id="footer_col_1" xs={"6 d-flex justify-content-end"}>
          <Link id="footer_link_1" to="#" className={"footerLink"} onClick={() => togglePrivacyModal()}>
            Privacy
          </Link>
        </Col>
        <Col id="footer_col_2" xs={"6 d-flex justify-content-start"}>
          <Link id="footer_link_2" to="#" className={"footerLink"} onClick={() => toggleTermsOfUseModal()}>
            Terms of Use
          </Link>
        </Col>
      </Row>
      <Row id="footer_row_2" className={"d-flex justify-content-center"}>
        <Col id="footer_col_3" xs={"12"}>
          R.V. Nuccio {"&"} Associates Insurance Brokers{" "}  &copy; 2004-{getYear()}
        </Col>
      </Row>
      <Row id="footer_row_3" className={"d-flex justify-content-center"}>
        <Col id="footer_col_4" xs={"12"}>
          The information presented on this website is only a summary; it is not all inclusive, nor does it alter or waive any of the actual policy language. Read your policy.
        </Col>
      </Row>

      <PrivacyModal />
      <TermsModal />
    </div>
  );
};

export default Footer;
