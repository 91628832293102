import axios from "axios";
import { logout } from "./App/AppActions";
import reduxStore from "./Store/ConfigureStore";
import enums from "./Utilities/Enums/Enums";
import { API_ROOT } from "./api-config";
import util from "../src/Utilities/Functions/Functions";

const { dispatch } = reduxStore;

const axiosApi = axios.create({
  baseURL: `${API_ROOT}/`, // 'http://localhost:5000/'
});

//TODO: try increasing tineoout later, but for now this is too disruptive on big requests
// axiosApi.defaults.timeout = 10000; //broad rule, override with timeout config on specific requests if needed

let isRefreshingToken = false;
let apiQueue = [];

//let valid requests flow through, only intercept 401 errors
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorResponse = error.response;
    //if the response or status prop doesn't exist, we're just in an odd state, skip and reject
    if (errorResponse && errorResponse.status) {
      if (isTokenExpiredError(errorResponse)) {
        return resetTokenAndReattemptRequest(error);
      }

      if (errorResponse.status === 304 && window.location.pathname === "/login") {
        //fire this to reset login features and allow user to try again
        //to prevent state where error returns and login() drops the thread
        //leaving the user logged out and the state stuck on isLoggingIn
        dispatch(logout());
      }
    }

    //if the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error);
  }
);

function isTokenExpiredError(errorResponse) {
  //our Token-Expired header doesn't seem to be coming in the errorResponse.headers object...? figure this out and add to the bool test...
  return errorResponse.status === 401;
}

export async function get(url, headers, responseType, setTimeout) {
  return await getPostDelete("get", url, null, headers, responseType, setTimeout);
}

export async function post(url, vm, headers, responseType, setTimeout) {
  return await getPostDelete("post", url, vm, headers, responseType, setTimeout);
}

export async function deleteApi(url, vm, headers, responseType, setTimeout) {
  return await getPostDelete("delete", url, vm, headers, responseType, setTimeout);
}

async function getPostDelete(method, url, vm, headers = {}, responseType = "", setTimeout = "") {
  let apiData = null;
  let jwtToken = getJwtToken();

  //make sure the request always has the latest token in the header
  setAuthHeader(jwtToken);

  if (method !== "get") {
    apiData = JSON.stringify(vm);
  }

  var defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  var defaultResponseType = responseType ? responseType : "json";

  var defaultSetTimeout = setTimeout ? setTimeout : 0;

  if (!util.isObjEmpty(headers)) {
    defaultHeaders = headers;
  }

  let response = await axiosApi({
    method: method,
    url: url,
    data: apiData,
    headers: defaultHeaders,
    responseType: defaultResponseType,
    timeout: defaultSetTimeout
  });

  //all is good, return the response, the interceptor would've kicked in for any 401's
  return response;
}

function setAuthHeader(token) {
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

function getJwtToken() {
  return sessionStorage.getItem("token");
}

function getRefreshToken() {
  return sessionStorage.getItem("refreshToken");
}

function saveJwtToken(token) {
  sessionStorage.setItem("token", token);
}

function saveRefreshToken(refreshToken) {
  sessionStorage.setItem("refreshToken", refreshToken);
}

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    }
    //Proceed to the token refresh procedure We create a new Promise that will retry the request,
    //clone all the request configuration from the failed request in the error object.
    const retryOriginalRequest = new Promise((resolve) => {
      //We need to add the request retry to the queue since there another request that already attempt to refresh the token
      addToQueue((access_token) => {
        errorResponse.config.headers.Authorization = "Bearer " + access_token;
        resolve(axiosApi(errorResponse.config));
      });
    });
    if (!isRefreshingToken) {
      isRefreshingToken = true;
      const jwtToken = getJwtToken();

      let vm = {
        Token: jwtToken,
        RefreshToken: refreshToken,
      };

      const refreshResponse = await axiosApi({
        method: "post",
        url: "api/token/refresh",
        data: vm,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!refreshResponse.data || refreshResponse.data.response === enums.serverResponse.error) {
        return Promise.reject(error);
      }
      const newToken = refreshResponse.data.data.token;

      saveJwtToken(newToken);
      saveRefreshToken(refreshResponse.data.data.refreshToken);
      isRefreshingToken = false;
      onNewTokenFetched(newToken);
    }
    return retryOriginalRequest;
  }
  catch (err) {
    //set this back just in case
    isRefreshingToken = false;
    return Promise.reject(err);
  }
}

function onNewTokenFetched(access_token) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  apiQueue.forEach((callback) => callback(access_token));
  apiQueue = [];
}

function addToQueue(callback) {
  apiQueue.push(callback);
}

export default axiosApi;
