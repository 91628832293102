import Pagination from "react-bootstrap/Pagination";
import RepeatingCardSingle from "./RepeatingCardSingle/RepeatingCardSingle";
import * as displayHelper from "./RepeatingCardDisplays/RepeatingCardDisplays";
import Question from "../Question/Question.js";
import { clone, cloneDeep, countBy, filter, forEach } from "lodash";
import util from "../../../../Utilities/Functions/Functions";
import enums from "../../../../Utilities/Enums/Enums";

//mark card border red if new effective date set on Coverages has invalidated the
//card's date range
export const hasEffDateViolation = (cardStartDateValue, policyEffDateValue) => {
  let cardDate;
  let policyDate;

  try {
    cardDate = new Date(cardStartDateValue);
  }
  catch (e) { } //do nothing
  try {
    policyDate = new Date(policyEffDateValue);
  }
  catch (e) { }

  if (cardDate && policyDate) {
    const cardDateTime = cardDate.getTime();
    const policyDateTime = policyDate.getTime();

    if (cardDateTime < policyDateTime) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
};

export const incompleteDataViolationClass = (value) => {
  if (!value) {
    return "border-danger rCard-errors";
  }
  return "";
};

export const buildRepeatingCards = (
  app,
  activeShowHideLists,
  questions,
  cardSet,
  addNewRepeatingCardToSet,
  deleteCardBundle,
  editRepeatingCardBundle,
  formValues,
  isEndorsed,
  setSimplePromptOpen,
  toggleHideCard,
  user
) => {
  const strapCard = (card) => {
    let displayItems = displayHelper.getCardDisplayTemplate(app, card, questions);

    return (
      <div className={"inline-block"} key={card.ordinalPosition}>
        <RepeatingCardSingle
          card={card}
          cardDates={displayItems.cardDates}
          cardDatesIndex={displayItems.cardDatesIndex}
          cardSet={cardSet}
          addNewRepeatingCardToSet={addNewRepeatingCardToSet}
          dataViolationMarkup={displayItems.dataViolationMarkup}
          dateViolationMarkup={displayItems.dateViolationMarkup}
          deleteCardBundle={deleteCardBundle}
          display={displayItems.all}
          // displayIncompleteItems={displayItems.incompleteItems}
          displayTemplate={displayItems.templateType}
          dynamicPageNameForUserFeedback={questions.navigationPage}
          editRepeatingCardBundle={editRepeatingCardBundle}
          endDate={displayItems.endDate}
          formValues={formValues}
          invalidItemCount={displayItems.invalidItemCount}
          insuredName={displayItems.insuredName}
          isEndorsed={isEndorsed}
          openCardEmailModal={setSimplePromptOpen}
          ordinalPos={card.ordinalPosition}
          pageUrl={questions.pageUrl}
          policyEffectiveDate={questions.policyEffectiveDate}
          startDate={displayItems.startDate}
          toggleHideCard={toggleHideCard}
          user={user}
        />
      </div>
    );
  };

  let allCards = [];

  if (
    !util.isObjEmpty(questions) &&
    ((activeShowHideLists && activeShowHideLists.show.length > 0) ||
      (activeShowHideLists && activeShowHideLists.hide.length > 0))
  ) {
    let questionRepHideOrShow = cardSet.showHiddenCards ? activeShowHideLists.hide : activeShowHideLists.show;

    allCards = questionRepHideOrShow.reduce(function (result, card) {
      result.push(strapCard(card));
      return result;
    }, []);
  }

  return allCards;
};

export const cardPagination = (
  cardSet,
  activeCardRangeStart,
  activeCardRangeEnd,
  activeCardRangeIndex,
  showHidden,
  updateActiveRCards
) => {
  if (cardSet && (cardSet.hiddenCardPageCount > 0 || cardSet.showCardPageCount > 0)) {
    let totalCardItemsCount = showHidden ? cardSet.hiddenCardPageCount : cardSet.showCardPageCount;
    let numberOfPaginationButtons = Math.ceil(totalCardItemsCount / 6);
    let lastButtonIndex = numberOfPaginationButtons - 1;

    let prevRangeEnd = 0;

    let pageRangeButtons = () => {
      let renderedPageItems = [];

      for (let i = 0; i < numberOfPaginationButtons; i++) {
        let rangeStart = i === 0 ? 1 : prevRangeEnd + 1;
        let rangeEnd = rangeStart + 5; //1-based, so add 5 to get end
        prevRangeEnd = rangeEnd;
        let btnDisplay = rangeStart + " - " + rangeEnd;

        renderedPageItems.push(
          <Pagination.Item
            key={i}
            onClick={() => paginate(rangeStart, i, rangeEnd, updateActiveRCards, cardSet)}
            className={activeCardRangeIndex === i ? " active" : ""}
          >
            {btnDisplay}
          </Pagination.Item>
        );
      }

      return renderedPageItems;
    };

    return (
      <span>
        <Pagination>
          <Pagination.First onClick={() => paginate(1, 0, 6, updateActiveRCards, cardSet)} />
          {activeCardRangeStart >= 7 && (
            <Pagination.Prev
              onClick={() =>
                paginate(
                  activeCardRangeStart - 6,
                  activeCardRangeIndex - 1,
                  activeCardRangeEnd - 6,
                  updateActiveRCards,
                  cardSet
                )
              }
            />
          )}
          {pageRangeButtons().length < 3 && pageRangeButtons()}
          {pageRangeButtons().length >= 3 &&
            showButtonSubsetWithEllipsis(pageRangeButtons(), 0, lastButtonIndex, activeCardRangeIndex)}
          {totalCardItemsCount >= activeCardRangeStart + 6 && (
            <Pagination.Next
              onClick={() =>
                paginate(
                  activeCardRangeStart + 6,
                  activeCardRangeIndex + 1,
                  activeCardRangeEnd + 6,
                  updateActiveRCards,
                  cardSet
                )
              }
            />
          )}
          <Pagination.Last
            onClick={() =>
              paginate(
                (numberOfPaginationButtons - 1) * 6 + 1,
                lastButtonIndex,
                (numberOfPaginationButtons - 1) * 6 + 6,
                updateActiveRCards,
                cardSet
              )
            }
          />
        </Pagination>
      </span>
    );
  }
};

export function deleteCardFromCardSet(cardSet, ordinalPositionToDelete) {
  cardSet.cardCollection = filter(cardSet.cardCollection, (card) => {
    if (card.ordinalPosition !== ordinalPositionToDelete) {
      if (card.ordinalPosition > ordinalPositionToDelete) {
        let newOrdinalPosition = card.ordinalPosition - 1;
        card.ordinalPosition = newOrdinalPosition;
        //also need to decrement Q&D lookup codes with new OP
        if (card.questionsAndData && card.questionsAndData.length > 0) {
          let questions = card.questionsAndData;
          questions.map((question) => {
            question.lookupCode = util.setRepeatingLookup(question.lookupCode, cardSet.questionID, newOrdinalPosition);
            question.isVisibleLookupCode = util.setRepeatingLookup(
              question.isVisibleLookupCode,
              cardSet.questionID,
              newOrdinalPosition
            );
          });
        }
      }
      return card;
    }
  });

  return cardSet;
}

export function removeRepeatingCardItemsAndDecrementGreaterOPs(parentCardQID, ordinalPositionToDelete, objectToClean) {
  let cleanedObject = {};

  forEach(objectToClean, (value, key) => {
    const keyCopy = clone(key);
    const normalizedParentCardQID = parentCardQID.toUpperCase();

    if (!key.includes(normalizedParentCardQID + "-OP" + ordinalPositionToDelete)) {
      //decrement OP # if it's greater than the removed OP and in same cardCollection
      if (key.includes(normalizedParentCardQID) && parseInt(keyCopy.split("-OP")[1]) > parseInt(ordinalPositionToDelete)) {
        let newOrdinalPosition = parseInt(keyCopy.split("-OP")[1]) - 1;
        let newKey = keyCopy.split("-OP")[0] + "-OP" + newOrdinalPosition;

        cleanedObject[newKey] = objectToClean[key];
      }
      else {
        cleanedObject[key] = value;
      }
    }
  });

  return cleanedObject;
}

export function copyRepeatingCardItemsAndIncrementOPs(
  parentCardQID,
  ordinalPositionToCopy,
  ordinalPositionToApply,
  objectToUpdate,
  currentSubmissionID
) {
  let updatedObject = {};

  forEach(objectToUpdate, (value, key) => {
    const keyCopy = clone(key);
    const normalizedParentCardQID = parentCardQID.toUpperCase();

    if (key.includes(normalizedParentCardQID + "-OP" + ordinalPositionToCopy)) {
      // add back the copy source key value first
      updatedObject[key] = value;

      // add the new/updated copy source key with copied value
      const newKey = keyCopy.split("-OP")[0] + "-OP" + ordinalPositionToApply;

      if (newKey?.toLowerCase().includes("viewonlysubmissionid") && currentSubmissionID) {
        //always update the ViewOnlySubmissionID for new items in case we are copying data
        //since we may be entering an endorsement and we need to differentiate repeating items
        updatedObject[newKey] = currentSubmissionID;
      }
      else if (newKey?.toLowerCase().includes("startdate") || newKey?.toLowerCase().includes("enddate")) {
        updatedObject[newKey] = "";
      }
      else if (newKey?.toLowerCase().includes("waspreviouslyvalid") && currentSubmissionID) {
        updatedObject[newKey] = "";
      }
      else {
        updatedObject[newKey] = objectToUpdate[key];
      }
    }
    else {
      updatedObject[key] = value;
    }
  });

  return updatedObject;
}

const paginate = (rangeStart, rangeIndex, activeCardRangeEnd, updateActiveRCards, cardSet) => {
  //generate new 6 card set at reducer
  updateActiveRCards(cardSet, rangeStart, rangeIndex, activeCardRangeEnd, cardSet.showHiddenCards);
};

export function setRepeatingCardQuestionsAndData(parentCardQID, cardObject) {
  let cardQuestions = [];

  const strapQuestion = (questionAndData) => {
    let ordinalPos = cardObject.ordinalPosition;
    let qKey = questionAndData.questionID + "-" + ordinalPos;
    let value = questionAndData.datavalue;
    const normalizedParentCardQID = parentCardQID.toUpperCase();

    let lookupToAdd = questionAndData.lookupCode;
    let isVisibleToAdd = questionAndData.isVisibleLookupCode;

    questionAndData.questionID = questionAndData.questionID.toUpperCase();
    lookupToAdd = util.setRepeatingLookup(lookupToAdd, normalizedParentCardQID, ordinalPos);
    questionAndData.lookupCode = lookupToAdd;
    if (questionAndData.isVisibleLookupCode) {
      isVisibleToAdd = util.setRepeatingLookup(isVisibleToAdd, normalizedParentCardQID, ordinalPos);
      questionAndData.isVisibleLookupCode = isVisibleToAdd;
    }

    return {
      key: qKey,
      defaultValue: value,
      onModal: true,
      ordinalPos: ordinalPos,
      questionData: questionAndData,
      value: value,
    };
  };

  if (cardObject.questionsAndData) {
    let updatedCardQs = cardObject.questionsAndData.reduce((result, questionAndData) => {
      result.push(strapQuestion(questionAndData));
      return result;
    }, []);

    cardQuestions = updatedCardQs;
  }

  return cardQuestions;
}

export function addNewCardToSet(cardSet, newCard) {
  let newCardCopy = cloneDeep(newCard);
  newCardCopy.isHidden = false;
  let cardCollection = cardSet.cardCollection ? cardSet.cardCollection : [];

  //Can't be sure that a middle object wasn't removed. Be safe and check most recent and add on to that
  let lastSet = cardCollection[cardCollection.length - 1];
  if (lastSet) {
    newCardCopy.ordinalPosition = lastSet.ordinalPosition + 1;
  }
  else {
    newCardCopy.ordinalPosition = 1;
  }
  newCardCopy.questionType = enums.setTypes.cardRepeating;

  setRepeatingCardQuestionsAndData(cardSet.questionID, newCardCopy);

  cardCollection.push(newCardCopy);
  cardSet.cardCollection = cardCollection;

  return cardSet;
}

export function filterShowHideCardsForPagination(
  activeRepeatingCards,
  activeShowHideList,
  submissionID,
  userEntityType,
  isEndorsement,
  policyEffectiveDate
) {
  //filter active show/hide cards for pagination population
  if (activeRepeatingCards && activeRepeatingCards.length > 0) {
    let activeRepeatingCardsClone = cloneDeep(activeRepeatingCards);
    let hidePaginationPosition = 1;
    let showPaginationPosition = 1;

    for (let i = 0; i < activeRepeatingCardsClone.length; i++) {
      activeRepeatingCardsClone[i].allowEditRemove = setEditAndRemovePermissions(
        activeRepeatingCardsClone[i],
        submissionID,
        userEntityType,
        isEndorsement,
        policyEffectiveDate
      );

      activeRepeatingCardsClone[i].allowHideUnhideCards = setHideUnhidePermissions(
        activeRepeatingCardsClone[i],
        submissionID,
        isEndorsement,
        false,
        userEntityType
      );

      activeRepeatingCardsClone[i].allowHideUnhideCardsPermissioned = setHideUnhidePermissions(
        activeRepeatingCardsClone[i],
        submissionID,
        isEndorsement,
        true,
        userEntityType
      );

      activeRepeatingCardsClone[i].allowViewPrintCertificate = setViewPrintCertificatePermission(
        activeRepeatingCardsClone[i],
        submissionID,
        isEndorsement
      );

      if (activeRepeatingCardsClone[i].isHidden) {
        activeRepeatingCardsClone[i].paginationPosition = hidePaginationPosition;
        hidePaginationPosition++;
        activeShowHideList.hide.push(activeRepeatingCardsClone[i]);
      }
      else {
        activeRepeatingCardsClone[i].paginationPosition = showPaginationPosition;
        showPaginationPosition++;
        activeShowHideList.show.push(activeRepeatingCardsClone[i]);
      }
    }

    return activeShowHideList;
  }
}

export function rCardHideOrShowCount(rCardsAll, getHiddenCards) {
  const cardCount = countBy(rCardsAll, (card) => {
    return card.isHidden;
  });
  if (getHiddenCards) {
    return cardCount.true;
  }
  return cardCount.false;
}

export function allowEditToParentOwnedSubmission(role) {
  return role === enums.role.admin || role === enums.role.mga || role === enums.role.underwriter;
}

export function setHideUnhidePermissions(question, currentSubmissionID, isEndorsement, permissionedOnly, role) {

  if (!isEndorsement) {
    return false;
  }

  let result = false;

  //set this proetection so only saved/validated cards can be hidden
  //to avoid scenario where user has errors in ai and cant find them
  if (question && question.questionsAndData && currentSubmissionID) {
    question.questionsAndData.forEach((q) => {
      if (
        q.lookupCode.includes("ViewOnlySubmissionID") &&
        q.datavalue &&
        q.datavalue !== currentSubmissionID &&
        //permissioned roles will be able to both edit and hide in this case
        //creating a spatial conflict with the HDIE and DELETE buttons
        //so we can reuse the allowEditToParentOwnedSubmission func to direct the HIDE
        //button to the footer
        (
          (!permissionedOnly && role && !allowEditToParentOwnedSubmission(role)) ||
          (permissionedOnly && role && allowEditToParentOwnedSubmission(role))
        )
      ) {
        result = true;
        return;
      }
    });
  }

  return result;
}

export function setEditAndRemovePermissions(question, currentSubmissionID, role, isEndorsement, policyEffectiveDate) {

  if (!isEndorsement) {
    return true;
  }

  let result = false;
  let hasDateViolation = false;

  if (question && question.questionsAndData && currentSubmissionID) {

    //first check for date violation
    question.questionsAndData.forEach((q) => {
      if (q.lookupCode.indexOf("StartDate") > 0 && q.datavalue) {
        hasDateViolation = hasEffDateViolation(q.datavalue, policyEffectiveDate);
      }
    });

    //if viewonlysubid matches current sub id, then it hasn't been processed yet
    question.questionsAndData.forEach((q) => {
      //either there is no parent submission and we are operating on the original unowned submission item/AI
      //and anyone can edit
      if (
        q.lookupCode.includes("ViewOnlySubmissionID") &&
        (!q.datavalue || (q.datavalue && q.datavalue === currentSubmissionID))
      ) {
        result = true;
        return;
      }

      //or it is owned by the parent submission and there are permitted roles for editing
      else if (
        q.lookupCode.includes("ViewOnlySubmissionID") &&
        q.datavalue &&
        q.datavalue !== currentSubmissionID &&
        !hasDateViolation &&
        allowEditToParentOwnedSubmission(role)
      ) {
        result = true;
        return;
      }
    })
  }

  return result;
}

export function setViewPrintCertificatePermission(question, submissionID, isEndorsement) {
  let result = false;

  if (question && question.questionsAndData) {
    //if viewonlysubid doesn not match the current sub id, then it has been processed
    question.questionsAndData.forEach((q) => {
      if (
        q.lookupCode.includes("ViewOnlySubmissionID-OP") &&
        q.datavalue !== submissionID && isEndorsement) {
        result = true;
        return;
      }
    });
  }

  return result;
}

export const showButtonSubsetWithEllipsis = (
  pageRangeButtons,
  firstButtonIndex,
  lastButtonIndex,
  activeCardRangeIndex
) => {
  let ellipsisButtonRange = [];
  let leadingEllipsisExists = false;
  let trailingEllipsisExists = false;

  for (let i = 0; i < pageRangeButtons.length; i++) {
    //always push first button
    if (i === firstButtonIndex) {
      ellipsisButtonRange.push(pageRangeButtons[i]);
      continue;
    }

    if (i > firstButtonIndex && i < activeCardRangeIndex) {
      if (!leadingEllipsisExists) {
        ellipsisButtonRange.push(<Pagination.Ellipsis key={i} />);
      }
      leadingEllipsisExists = true;
    }

    if (
      i === activeCardRangeIndex &&
      activeCardRangeIndex !== firstButtonIndex &&
      activeCardRangeIndex !== lastButtonIndex
    ) {
      ellipsisButtonRange.push(pageRangeButtons[i]);
    }

    if (i < lastButtonIndex && i > activeCardRangeIndex) {
      if (!trailingEllipsisExists) {
        ellipsisButtonRange.push(<Pagination.Ellipsis key={i} />);
      }
      trailingEllipsisExists = true;
    }

    //always push last button
    if (i === lastButtonIndex) {
      ellipsisButtonRange.push(pageRangeButtons[i]);
      break;
    }
  }

  return ellipsisButtonRange;
};

export const strapQuestion = (question, ordinalPosition, formValues, setQuestionsFormValue) => {
  return (
    <Question
      key={question.questionID + ordinalPosition}
      defaultValue={formValues[question.lookupCode] ? formValues[question.lookupCode] : null}
      columnOffset={question.columnOffset}
      formValues={formValues}
      onModal={true}
      ordinalPos={ordinalPosition}
      questionData={question}
      parentCardSetType={"repeatingCardSet"}
      setQuestionsFormValue={setQuestionsFormValue}
      value={question.datavalue}
    />
  );
};

export function updateDefaultCardSetObjWithNewOP(parentCardQID, defaultObj, newOrdinalPosition) {
  let updatedDefaultPropertyObj = {};

  if ((parentCardQID || parentCardQID === 0) && defaultObj && newOrdinalPosition) {
    forEach(defaultObj, (value, key) => {
      let lookupCodeWithNewOP = util.setRepeatingLookup(key.split("-OP")[0], parentCardQID, newOrdinalPosition);
      updatedDefaultPropertyObj[lookupCodeWithNewOP] = value;
    });
  }

  return updatedDefaultPropertyObj;
}
