import { createContext, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import * as policyActions from "../PolicyList/PolicyActions";

export const SearchContext = createContext(null);

export default ({ children }) => {
  //children are all the passed in components wrapped within App
  const [isSearching, setIsSearching] = useState(false);
  const [searchFormIsOpen, setSearchFormOpen] = useState(false);
  const [searchResults, setSearchResults] = useState(false);

  //outbound
  const clearPolicyState = useCallback(() => dispatch(policyActions.clearPolicyState()), [dispatch]);

  //dispatch actions
  const dispatch = useDispatch();

  const clearSearchStore = () => {
    setIsSearching(false);
    setSearchFormOpen(false);
    setSearchResults(false);
    clearPolicyState();
  }

  const searchStore = {
    clearSearchStore: clearSearchStore,
    searching: [isSearching, setIsSearching],
    searchForm: [searchFormIsOpen, setSearchFormOpen],
    searchResult: [searchResults, setSearchResults],
  }

  return <SearchContext.Provider value={searchStore}>{children}</SearchContext.Provider>
}