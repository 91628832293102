import FormGroups from "./FormGroups";
import util from "../Functions/Functions";
import enums from "../Enums/Enums";

const FormGroupsWrapper = (props) => {

  //Check if the input is read only and set to disabled
  let disabled = props.disabled;
  if ((!disabled && props.type === "readonly") || (props.input_css && props.input_css.includes("disabled"))) {
    disabled = true;
  }
  const strapPassedOption = (passedOption) => {
    if (props.type === enums.fieldTypes.list) {
      return (
        <li key={passedOption} value={passedOption}>
          {passedOption}
        </li>
      );
    }
    else if (passedOption.hasOwnProperty("lookupCode")) {
      return { value: passedOption.lookupCode, label: passedOption.lookupText };
    }
    else if (passedOption.hasOwnProperty("value") && passedOption.hasOwnProperty("text")) {
      return { value: passedOption.value, label: passedOption.text };
    }
    else if (passedOption.hasOwnProperty("value") && passedOption.hasOwnProperty("label")) {
      return { value: passedOption.value, label: passedOption.label };
    }
    else {
      return { value: passedOption, label: passedOption };
    }
  };
  //Build our dropdown values if we're given them
  let passedOptions;
  if (!util.isObjEmpty(props.options)) {
    passedOptions = props.options.reduce(function (result, passedOption) {
      result.push(strapPassedOption(passedOption));
      return result;
    }, []);
  }

  //Build a unique identifier if we're within a parent (repeating), otherwise just use the name (lookupCode)
  return (
    <FormGroups
      {...props}
      color={props.color}
      clearForm={props.clearForm}
      defaultValue={props.defaultValue}
      disabled={disabled}
      fontSize={props.fontSize}
      formValues={props.formValues}
      helptext={props.helptext}
      isUl={props.isUl}
      name={props.name}
      notEligibleIf={props.notEligibleIf}
      options={passedOptions}
      placeholder={props.placeholder}
      requiresReviewIf={props.requiresReviewIf}
      setFormValueCallback={props.setFormValueCallback}
      source={props.source}
      tooltipImg={props.tooltipImg}
      type={props.type}
      validationOff={props.validationOff}
      value={props.value} //need this for eligibility test in formGroup - dont remove
    />
  );
};

export default FormGroupsWrapper;
