import { cloneDeep } from "lodash";
import enums from "../../Utilities/Enums/Enums";
import * as psExecute from "../PageScript/Execute";
import * as psUpdate from "../PageScript/Update";
import * as helper from "../QuestionHelpers/QuestionsHelper";
import psUtil from "../../Utilities/Functions/PageScript";
import util from "../../Utilities/Functions/Functions";


export function buildPSObj(ps, prevPageValues, questions, programGroupCode, initialFormValues, questionsState, pageScriptPremountReports) {
  let pageScriptObj = {
    scripts: [],
    contingentValuesPrevPages: [],
    executedPremountPersistFields: []
  };

  if (ps && ps.length > 0) {
    //pages may have mult rows coming in due to ps table's logic constraints
    for (let i = 0; i < ps.length; i++) {
      if (ps[i].executionTime) {
        let lookupCodes = psUtil.convertToArray(ps[i].appliesToLookupCodes, programGroupCode);

        //a) premount: push each appliesToLookupCode to its own row so we don't have to loop downstream

        //b) postmount: unlike premount, postmount would require filtering through all ps to check for mult appliesToLookupCodes
        //which gets super expensive on pages like ProdInfo with percents - so leave appliesToLookupCodes in array
        //for postmounted items for cheap detection
        for (let j = 0; j < lookupCodes.length; j++) {
          pageScriptObj.scripts.push({
            //premount id: have to tack on the iterator to make shared id unique
            id:
              ps[i].executionTime === enums.psExecute.premount ||
                ps[i].executionTime === enums.psExecute.premountPersist
                ? ps[i].pageScriptId + j
                : ps[i].pageScriptId,
            pageName: ps[i].pageName,
            action: ps[i].action,
            executionTime: ps[i].executionTime,
            //premount appliesToLookupCodes: cast sinlge value to array for consistency downstream
            appliesToLookupCodes:
              ps[i].executionTime === enums.psExecute.premount ||
                ps[i].executionTime === enums.psExecute.premountPersist
                ? [util.normalizeLookupCode(lookupCodes[j], programGroupCode)]
                : lookupCodes,
            contingentLookupCodes: psUtil.convertToArray(ps[i].contingentLookupCodes, programGroupCode),
            contingentResult: util.normalizeLookupCode(ps[i].contingentResult, programGroupCode),
            contingentReturnLocation: ps[i].contingentReturnLocation
              ? ps[i].contingentReturnLocation.toLowerCase()
              : null,
            logReturnValue: ps[i].logReturnValue,
            preventActionOnPristine: ps[i].preventActionOnPristine
          });
        }
      }
    }
  }

  const repeatingCards = helper.getRepeatingCards(questions);

  if (prevPageValues && prevPageValues.length > 0) {
    pageScriptObj.contingentValuesPrevPages = prevPageValues;
  }

  if (repeatingCards && repeatingCards.length > 0) {
    pageScriptObj = buildPSRCards(pageScriptObj, repeatingCards);
  }

  //preprocess premount fields so we only need to grab finished product
  const premountExecutables = psUtil.getAllPreOrPostExecutables(pageScriptObj.scripts, enums.psExecute.premount);
  let formValuesCurrentAndPrevPages = cloneDeep(initialFormValues);
  let clonedPSObj = cloneDeep(pageScriptObj);

  //pull the form values from previous pages and add the {lookupcode: value} pairs to our cloned pageScriptObj
  if (clonedPSObj.contingentValuesPrevPages.length > 0) {
    if (formValuesCurrentAndPrevPages) {
      for (let field in clonedPSObj.contingentValuesPrevPages) {
        for (let appliesToLookupCode in clonedPSObj.contingentValuesPrevPages[field].appliesToLookupCodes) {
          let normalAppliesToLookup = clonedPSObj.contingentValuesPrevPages[field].appliesToLookupCodes[
            appliesToLookupCode
          ].split("-@")[0];
          formValuesCurrentAndPrevPages[normalAppliesToLookup] = clonedPSObj.contingentValuesPrevPages[field].value;
        }
      }
    }
  }

  let executedPremountFields = {};
  let processingExecutables = [];

  for (let r = 0; r < premountExecutables.length; r++) {

    executedPremountFields = psExecute.executePageScript(
      premountExecutables[r],
      formValuesCurrentAndPrevPages,
      processingExecutables,
      premountExecutables,
      premountExecutables[r].executionTime,
      null,
      null,
      questionsState,
      true,
      pageScriptPremountReports
    );

    processingExecutables = executedPremountFields.updatedPSFields;
  }

  if (!util.isObjEmpty(executedPremountFields) && executedPremountFields.updatedPSFields.length > 0) {
    const updatedScripts = psUpdate.updatePSFieldsWithExecutedFieldsObj(executedPremountFields.updatedPSFields, pageScriptObj);
    pageScriptObj.scripts = updatedScripts.scripts;
    pageScriptObj.executedPremountPersistFields = executedPremountFields.executedPremountPersistFields;
  }

  return pageScriptObj;
}

export function buildPSRCards(pageScriptObj, rCards) {
  //use this so we don't end up in infinite loop when pushing items onto read obj
  let pageScriptObjClone = cloneDeep(pageScriptObj);

  if (rCards && rCards.length > 0) {
    for (let i = 0; i < rCards.length; i++) {
      for (let j = 0; j < rCards[i].questionsAndData.length; j++) {
        //remove SetID_OP# from trailing end to compare
        let rLookup = rCards[i].questionsAndData[j].lookupCode.split("-")[0];
        let rLookup_SetID_OP = rCards[i].questionsAndData[j].lookupCode;

        //pre and postmount check
        if (pageScriptObj && pageScriptObj.scripts && pageScriptObj.scripts.length > 0) {
          let updatedFields = buildPreAndPostmountCards(
            pageScriptObj.scripts,
            rLookup_SetID_OP,
            pageScriptObjClone.contingentValuesPrevPages
          );
          if (updatedFields && updatedFields.length > 0) {
            for (let field in updatedFields) {
              pageScriptObjClone.scripts.push(updatedFields[field]);
            }
          }
        }

        //prev page check
        if (
          pageScriptObj &&
          pageScriptObj.contingentValuesPrevPages &&
          pageScriptObj.contingentValuesPrevPages.length > 0
        ) {
          let rPrevPageFields = [];

          for (let s = 0; s < pageScriptObj.contingentValuesPrevPages.length; s++) {
            if (pageScriptObj.contingentValuesPrevPages[s]) {
              let rPrevPageField = cloneDeep(pageScriptObj.contingentValuesPrevPages[s]);

              //similar to premount, prevPages will only contain 1 lookupcode, per BE vm
              if (pageScriptObj.contingentValuesPrevPages[s].appliesToLookupCodes[0] === rLookup) {
                rPrevPageField.appliesToLookupCodes[0] = rCards[i].questionsAndData[j].lookupCode;
                rPrevPageFields.push(rPrevPageField);
              }
            }
          }

          if (rPrevPageFields && rPrevPageFields.length > 0) {
            for (let rPrevField in rPrevPageFields) {
              pageScriptObjClone.contingentValuesPrevPages.push(rPrevPageFields[rPrevField]);
            }
          }
        }
      }
    }
    return pageScriptObjClone;
  }
}

export function buildPreAndPostmountCards(fields, rLookup_SetID_OP, prevPgFields) {
  let rUpdatedFields = [];

  let excludedPrevPageFields = [];
  let rLookup = rLookup_SetID_OP.split("-SetID-")[0];
  let setID_OP = rLookup_SetID_OP.split("-SetID-")[1];

  //we don't want to convert a field to -OP if it's from a previous page
  if (prevPgFields && prevPgFields.length > 0) {
    for (let prevField in prevPgFields) {
      let field = prevPgFields[prevField];
      if (field) {
        for (let i = 0; i < field.appliesToLookupCodes.length; i++) {
          excludedPrevPageFields.push(field.appliesToLookupCodes[i]);
        }
      }
    }
  }

  for (let k = 0; k < fields.length; k++) {
    let psField = fields[k];
    let rClonedField;
    let rAppliesToLookupCodes = [];
    let rContingentLookupCodes = [];
    let rContingentValue;

    if (psField.appliesToLookupCodes && psField.appliesToLookupCodes.length > 0) {
      //convert contingent value against appliesToLookup, before it's converted to OP
      if (psField.contingentResult) {
        if (
          psField.appliesToLookupCodes.includes(psField.contingentResult) &&
          !excludedPrevPageFields.includes(psField.contingentResult)
        ) {
          rContingentValue = psField.contingentResult + setID_OP;
        }
        else if (psField.appliesToLookupCodes.includes(psField.contingentResult)) {
          rContingentValue = psField.contingentResult;
        }
      }

      for (let l = 0; l < psField.appliesToLookupCodes.length; l++) {
        if (
          psField.appliesToLookupCodes[l] === rLookup &&
          !excludedPrevPageFields.includes(psField.appliesToLookupCodes[l])
        ) {
          rAppliesToLookupCodes.push(rLookup_SetID_OP);
        }
        else if (psField.appliesToLookupCodes[l] === rLookup) {
          rAppliesToLookupCodes.push(rLookup);
        }
      }
    }

    if (rAppliesToLookupCodes && rAppliesToLookupCodes.length > 0) {
      rClonedField = cloneDeep(psField);
    }

    //must match rLookup for rest of obj below to be valid
    if (!util.isObjEmpty(rClonedField)) {
      //convert contingent lookup codes
      if (psField.contingentLookupCodes && psField.contingentLookupCodes.length > 0) {
        //convert contingent value if it has contingent lookup code
        if (psField.contingentResult) {
          if (
            psField.contingentLookupCodes.includes(psField.contingentResult) &&
            !excludedPrevPageFields.includes(psField.contingentResult)
          ) {
            rContingentValue = psField.contingentResult + setID_OP;
          }
          else if (psField.contingentLookupCodes.includes(psField.contingentResult)) {
            rContingentValue = psField.contingentResult;
          }
        }

        for (let m = 0; m < psField.contingentLookupCodes.length; m++) {
          let rContingentOPLookup = psField.contingentLookupCodes[m] + setID_OP;
          if (!excludedPrevPageFields.includes(psField.contingentLookupCodes[m])) {
            rContingentLookupCodes.push(rContingentOPLookup);
          }
          else {
            rContingentLookupCodes.push(psField.contingentLookupCodes[m]);
          }
        }
      }

      rClonedField.appliesToLookupCodes = rAppliesToLookupCodes; //checked above
      rClonedField.contingentLookupCodes =
        rContingentLookupCodes && rContingentLookupCodes.length > 0 ? rContingentLookupCodes : null;
      rClonedField.contingentResult = rContingentValue ? rContingentValue : psField.contingentResult;

      //push duplicated rCardField with updated lookup containing SetID_OP#
      rUpdatedFields.push(rClonedField);
    }
  }

  return rUpdatedFields;
}
