import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldWrapper } from "../../../../StylizedComponents/index";
import { Field } from "formik";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Container, Row, Col } from "react-bootstrap";
import { cloneDeep, forEach } from "lodash";
import LabelCommon from "../Shared/LabelCommon";
import ErrorMessage from "../../../../Errors/ErrorMessage/ErrorMessage";
import NestedFieldAlert from "../Alerts/NestedFieldAlert";
import enums from "../../../Enums/Enums";
import ps from "../../../Functions/PageScript";
import * as psRules from "../../../../Questions/PageScript/Rules";
import util from "../../../Functions/Functions";
import * as appActions from "../../../../App/AppActions";
import "react-bootstrap-typeahead/css/Typeahead.css";

const Address = (props) => {
  const {
    clearPropertyValueFromPSField,
    columnOffset,
    defaultValue,
    disabled,
    field: { name },
    fieldsRef,
    form: { errors, touched, setFieldTouched, setFieldValue, values },
    helptext,
    id,
    label,
    label_css,
    label_location,
    label_prefix,
    placeholder,
    popover_class,
    popover_placement,
    setFormValueCallback,
    setValueParentOrLocal,
    static_col_breakpoint,
    type,
    validateQuestionSimple,
  } = props;

  //#region stored values

  const app = useSelector((state) => state.app);
  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);
  let invalidFieldErrorMessage;
  const apiKey = app.config.find((x) => x.configKey === enums.configItems.useGooglePlaces)?.configValue;

  let hasError = isQuestionsContext
    ? util.checkFieldHasQuestionError(name, storedInvalidFields)
    : util.checkFieldHasYupError(name, errors, touched);

  if (hasError && isQuestionsContext) {
    invalidFieldErrorMessage = util.getInvalidFieldMessage(name, storedInvalidFields)?.fieldMessage;
  }

  //dispatch actions
  const dispatch = useDispatch();
  const reportError = useCallback((msg) => dispatch(appActions.reportToast(msg, enums.toastTypes.error)), [dispatch]);

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: apiKey,
    options: {
      fields: ["address_components", "types"],
      componentRestrictions: { country: "usa" },
    },
    // sessionToken: true -- if we have bulk requests
  });

  const [placeDetails, setPlaceDetails] = useState(null);
  const [streetDetails, setStreetDetails] = useState(null);

  //#endregion stored values

  //#region pageScript

  //pageScript
  let normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let setID_OP;
  let psPremount, psExecutePostmountFields, psPostmountExecutedFields;
  let psReady = {};

  if (isQuestionsContext) {

    if (id.includes("-SetID-")) {
      setID_OP = id.split("-SetID-")[1];
    }

    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    //grab array of psExecutePostmountFields since Address.js works outside the Execute.js workflow
    //we are unable to grab all the contingent and applies to fields that Execute.js normally handles
    psExecutePostmountFields = ps.getPSExecutables(questions.pageScript.scripts, enums.psExecute.postmount, normalizedId, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, questions.pageUrl);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      //we may have already applied updates to psReady during premount, so pass it back in for updating
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, questions.pageUrl);
    }

    if (psReady.value && psReady.value !== undefined && psReady.value !== values[name]) {
      if (psReady.value === "clearValue") {
        clearPropertyValueFromPSField(psReady.id, "value");
        setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
      }
      else {
        setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
      }
    }

    if (ps.hasActiveAlert(psReady)) {
      //need this to mark up input and override valid
      hasError = true;
      // hasValid = false;
    }
  }

  //#endregion pageScript

  //#region functions & actions

  //need this to set defaults coming from defaultValue field of db
  if (defaultValue && !values[name]) {
    setValueParentOrLocal(name, defaultValue, setFieldValue, setFormValueCallback);
  }

  const handleBlur = () => {
    setFieldTouched(name, true);
    if (isQuestionsContext) {
      validateQuestionSimple(name, values[name]);
    }
  };

  const handleSearch = (query) => {
    //set the value regardless, so if the query is not found, we retrain the street
    setValueParentOrLocal(name, query, setFieldValue, setFormValueCallback);
    getPlacePredictions({ input: query });
  };

  const handleSelectionDetails = (option) => {
    let place = option[0] ? option[0] : "";

    if (placePredictions.length && place.place_id) {
      placesService.getDetails(
        {
          placeId: place.place_id,
        },
        (placeDetails) => {
          setPlaceDetails(placeDetails);
        }
      );
    }
  };

  const getDataForType = (placeDetails, field) => {
    if (field === "city") {
      const fieldMatch = placeDetails.address_components.find(a => (a.types === "administrative_area_level_3") || a.types.includes("locality"));
      return fieldMatch?.long_name ?? "";
    }
    else if (field === "country") {
      const fieldMatch = placeDetails.address_components.find(a => a.types.includes("country"));
      return fieldMatch?.long_name ?? "";
    }
    else if (field === "state") {
      const fieldMatch = placeDetails.address_components.find(a => a.types.includes("administrative_area_level_1"));
      return fieldMatch?.short_name ?? "";
    }
    else if (field === "zip") {
      const fieldMatch = placeDetails.address_components.find(a => a.types.includes("postal_code") && !a.types.includes("postal_code_suffix"));
      return fieldMatch?.long_name ?? "";
    }
    else if (field === "street") {
      const streetNumberField = placeDetails.address_components.find(a => a.types.includes("street_number"));
      const streetField = placeDetails.address_components.find(a => a.types.includes("route"));
      const aptOrSuiteField = placeDetails.address_components.find(a => a.types.includes("subpremise"));
      const premise = placeDetails.address_components.find(a => a.types.includes("premise"));
      let fullStreetName = streetField?.long_name;
      if (streetNumberField) {
        fullStreetName = streetNumberField.long_name + " " + fullStreetName;
      }
      if (aptOrSuiteField) {
        fullStreetName = fullStreetName + " " + aptOrSuiteField?.long_name;
      }
      if (premise) {
        fullStreetName = premise?.long_name;
      }
      return fullStreetName;
    }
  }

  if (placeDetails && !util.isObjEmpty(placeDetails) && placeDetails.address_components.length > 0) {

    let address = {
      city: "",
      country: "",
      state: "",
      street: "",
      zip: "",
    };

    address.city = getDataForType(placeDetails, "city");
    address.country = getDataForType(placeDetails, "country");
    address.state = getDataForType(placeDetails, "state");
    address.street = getDataForType(placeDetails, "street");
    address.zip = getDataForType(placeDetails, "zip");

    if (address?.street) {

      forEach(psExecutePostmountFields, (psField) => {

        if (
          psField &&
          psField.action === enums.psOptions.setValueInField &&
          psField.contingentLookupCodes[0] === normalizedId
        ) {

          if (
            !address.city ||
            !address.country ||
            !address.state ||
            !address.zip
          ) {
            reportError("The address you entered is incomplete.  Please check the address before continuing.");
          }

          //set here to override the place details description in the AsyncTypeahead so the field shows only street address
          setStreetDetails(address.street);

          //contingentLookupCode will always be the street field, so update the formValues clone
          //with the address obj value because Formik can't update in time for the rule evaluation
          let formValues = cloneDeep(values);
          formValues[id] = address.street;


          const resultHelper = psRules.evaluateRule(psField, formValues, setID_OP, null);

          if (resultHelper.ruleResult && resultHelper.returnedValue) {
            //for each psField, if AppliesToLookup === id && action === "setValueInField"
            //get each contingentLookup code and check all other psFields with AppliesToLookupCode of that contingentLookup
            //and their returnedValues will be the facility object's properties, which we will match up and spit out one by one with the setValueParentOrLocal

            const returnValues = resultHelper.returnedValue.split(";");

            forEach(psField.appliesToLookupCodes, (appliesToLookupCode) => {
              if (appliesToLookupCode) {
                if (psField.appliesToLookupCodes.length === 1) {

                  //appliesToLookupCodes length is 1, no template syntax registered to the returnedValue
                  if (setID_OP) {
                    setValueParentOrLocal(
                      appliesToLookupCode + "-SetID-" + setID_OP,
                      address[resultHelper.returnedValue],
                      setFieldValue,
                      setFormValueCallback
                    );
                  }
                  else {
                    setValueParentOrLocal(
                      appliesToLookupCode,
                      address[resultHelper.returnedValue],
                      setFieldValue,
                      setFormValueCallback
                    );
                  }
                }
                else {

                  //appliesToLookupCodes length is greater than 1, and must match lookups with template syntax to the returnedValue
                  const lookupCodeNormal = appliesToLookupCode.split("-@")[0];
                  const itemIndex = appliesToLookupCode.split("-@")[1];
                  const returnedFieldValue = returnValues.find((v) => v.includes(itemIndex));
                  const returnedValueNormal = returnedFieldValue.split("-@")[0];

                  if (setID_OP) {
                    setValueParentOrLocal(
                      lookupCodeNormal + "-SetID-" + setID_OP,
                      address[returnedValueNormal],
                      setFieldValue,
                      setFormValueCallback
                    );
                  }
                  else {
                    setValueParentOrLocal(
                      lookupCodeNormal,
                      address[returnedValueNormal],
                      setFieldValue,
                      setFormValueCallback
                    );
                  }
                }
              }
            });
          }
        }
      });
    }

    setPlaceDetails(null);
  }

  //#endregion functions & actions

  //#region typeaheadLabel

  let input_class_val_css;
  let label_class_interpreted;
  if (hasError) {
    input_class_val_css = "error-border-typeahead ";
    label_class_interpreted += " error-msg ";
    if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
      validateQuestionSimple(name, values[name]);
    }
  }
  else {
    input_class_val_css = "";
    label_class_interpreted = "";
  }

  label_class_interpreted += label_css;

  let label_text = label ? label : "";
  if (psReady && psReady.setLabelValue) {
    label_text = psReady.setLabelValue;
  }

  let labelProps = {
    helptext,
    id,
    isQuestionsContext,
    label_class_interpreted,
    label_prefix,
    label_text,
    popover_class,
    popover_placement,
    type,
  };

  //#endregion typeaheadLabel

  return (
    <Container
      id="address_main_container_1"
      className={label_text ? "" : "no_label"}
      key={id}
      onBlur={() => handleBlur()}
    >
      <Field
        component={FieldWrapper}
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, "")}
        name={"typeahead-" + name}
        id="address_field_wrapper_1"
      >
        {label_text && label_location === enums.labelLocation.top && (
          <Row id="address_row_1">
            <Col id="address_col_1" xs={"12"}>
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row id="address_row_2">
          {label_text && label_location === enums.labelLocation.left && (
            <Col id="address_col_2">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
          <Col
            id="address_col_3"
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
          >
            <AsyncTypeahead
              id={id}
              name={name}
              filterBy={["description"]}
              labelKey={(placePredictions) => {
                if (streetDetails && !isPlacePredictionsLoading) {
                  return streetDetails;
                }
                else {
                  if (streetDetails) {
                    setStreetDetails(null);
                  }
                  return `${placePredictions.description}`;
                }
              }}
              className={input_class_val_css}
              minLength={3}
              options={placePredictions && placePredictions.length ? placePredictions : []}
              placeholder={placeholder}
              onChange={handleSelectionDetails}
              onSearch={(evt) => handleSearch(evt)}
              useCache={false}
              isLoading={isPlacePredictionsLoading ? isPlacePredictionsLoading : false}
              defaultInputValue={values[name] ? values[name] : ""}
            />
            {!isQuestionsContext && <ErrorMessage errors={errors[name]} touched={touched[name]} />}
          </Col>
          {label_text && label_location === enums.labelLocation.right && (
            <Col id="address_col_4">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
        </Row>

        <Row id="address_row_3">
          <Col
            id="address_col_5"
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}>
            <NestedFieldAlert psReady={psReady} invalidFieldErrorMessage={invalidFieldErrorMessage} />
          </Col>
        </Row>
      </Field>
    </Container>
  );
};

export default Address;
