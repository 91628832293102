
import { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from 'react-bootstrap';
import * as headerActions from "../../Header/HeaderActions";
import * as questionActions from "../../Questions/QuestionsActions";
import * as psReport from "../../Questions/PageScript/Update";


const DebugMenu = () => {

  //store
  const questions = useSelector((state) => state.questions);
  const header = useSelector((state) => state.header);

  //dispatch actions
  const dispatch = useDispatch();

  //states
  const [revealClass, toggleRevealClass] = useState("");
  const [psReports, setPSReports] = useState([]);
  let classesToAdd = "navbar-side navbar-faq navHeight";
  let latestClassesToAdd = useRef(classesToAdd); //avoids rerender of classes to add on didUpdate

  //effects
  useEffect(() => {
    if (header.debugMenuOpen) {
      toggleRevealClass(" reveal");
    }
    else {
      toggleRevealClass("");
    }
  }, [header.debugMenuOpen]);

  useEffect(() => {
    if (questions.pageScriptReporting && questions.pageScriptReportLog.length > 0) {
      setPSReports(psReport.buildPSReportLog(questions));
    }
    else {
      setPSReports([]);
    }
  }, [questions, questions.pageScriptReporting, setPSReports]);

  //outbound
  const toggleDebugMenu = useCallback(() => dispatch(headerActions.toggleDebugMenu()), [dispatch]);
  const toggleFieldDataDisplay = useCallback(() => dispatch(questionActions.toggleFieldDataDisplay()), [dispatch]);
  const togglePageScriptReporting = useCallback(() => dispatch(questionActions.togglePageScriptReporting()), [dispatch]);

  return (
    <div className={latestClassesToAdd.current + revealClass} id="debug_menu_main_div_1">
      <div id="debug_menu_div_2" className="h-100 grid">
        <div id="debug_menu_div_3" className="h-100">
          <div id="debug_menu_div_4" className={"sideClose"}>
            <div id="debug_menu_div_5" className="d-flex justify-content-center cursor">
              <span id="debug_menu_span_1" className={"closeIcon"} onClick={() => toggleDebugMenu()}>
                <i className="fa fa-times-thin" aria-hidden="true"></i>
              </span>
            </div>
          </div>

          <div id="debug_menu_div_6" className={"sideMenuContent"}>
            <div id="debug_menu_div_7" className="sideFAQ">

              {/* debug mode */}
              {questions && questions.useDebugMode && (
                <span id="debug_menu_span_2">
                  <h5 id="debug_menu_header_1" className="text-primary">Debugging Options</h5>
                  <Row id="debug_menu_row_1">
                    <Col id="debug_menu_col_1">
                      <Button id="debug_menu_btn_1" className="fieldDisplayBtn" variant={questions.fieldDataDisplay ? "danger" : "primary"} size="sm" onClick={() => toggleFieldDataDisplay()}>
                        Field Data Display <span className="font-weight-bold text-uppercase">{questions.fieldDataDisplay ? "On" : "Off"}</span>
                      </Button>
                    </Col>
                  </Row>

                  <Row id="debug_menu_row_2">
                    <Col id="debug_menu_col_2">
                      <Button id="debug_menu_btn_2" className="pageScriptReportsBtn" variant={questions.pageScriptReporting ? "danger" : "primary"} size="sm" onClick={() => togglePageScriptReporting()}>
                        PageScript Reporting <span className="font-weight-bold text-uppercase">{questions.pageScriptReporting ? "On" : "Off"}</span>
                      </Button>
                    </Col>
                  </Row>

                  {questions.pageScriptReporting && (
                    <>
                      <div id="debug_menu_span_3">
                        <h5 id="debug_menu_header_2" className="text-primary">PageScript Reports {"("}{questions.pageScriptReportLog.length}{")"}</h5>
                      </div>
                      <div id="debug_menu_div_8" style={{ minHeight: "200px", maxHeight: "600px", overflow: "auto", marginTop: "20px" }}>
                        {psReports}
                      </div>
                    </>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default DebugMenu;