export default {
  consent: "Consent is required for this payment type",
  eligible: "Unfortunately, you are not eligible for coverage at this time",
  fein: "Please enter a valid FEIN or SSN number to reveal your payment options",
  invalidEmailAddr: "Invalid email address",
  invalidEntry: "Invalid entry",
  lessThan: "Month must be less 12 or less",
  lessThan100: "Commission cannot be greater than 100",
  matchNumeric: "Entry value must be numeric only",
  maxChars: "Must be less than 100 characters",
  maxRouting: "Must be 9 digits",
  minRouting: "Must be 9 digits",
  minChars: "Must be at least 6 characters",
  minZipChars: "Must be at least 5 characters",
  pwdMatch: "Passwords must match",
  required: "Required",
};
