import { browserName, browserVersion } from "react-device-detect";

const ErrorPageDevice = () => {

  const bgStyles = {
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    position: "fixed",
    top: 0,
    left: 0
  }

  let msg = `${browserName} ${browserVersion}` + " is not compatible with this application. Please try a different browser.";

  return (
    <div style={bgStyles}>
      {alert(msg)}
    </div>
  );
};

export default ErrorPageDevice;
