import { useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";

const ChangePassword = (props) => {
  const [open, toggleCollapse] = useState(false);

  if (props.openComponent && props.openComponent !== "changePassword" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("changePassword");
  }

  return (
    <div id="change_password_main_div_1">
      <div
        id="change_password_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="pswdRow"
        aria-expanded={open}
      >
        How do I change my email or password?
      </div>
      <Collapse in={open}>
        <div id="change_password_div_3">
          <Row id="change_password_row_1">
            <Col id="change_password_col_1">
              <hr />
              <div id="change_password_div_4">
                You can change your Email or Password in your profile.  To access your profile, click on the person icon in the top right corner of your screen.
                You can enter a new email and/or a new password. Please remember to click Save Changes.
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default ChangePassword;
