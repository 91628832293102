import { Alert, Col, Row } from "react-bootstrap";
import { forEach } from "lodash";
import util from "../../../Functions/Functions";

const NestedFieldAlert = (props) => {
  const { invalidDateWarning, invalidFieldErrorMessage, psReady } = props;

  let alertDanger;
  let alertPrimary;

  const setPSAlert = () => {
    forEach(psReady, (value, key) => {
      let normalKey = key.toLowerCase();

      if (normalKey.includes("alert") && value) {
        if (normalKey.includes("danger")) {
          return (alertDanger = value);
        }
        if (normalKey.includes("primary")) {
          return (alertPrimary = value);
        }
      }
    });
  };

  if (!alertDanger && !alertPrimary && !util.isObjEmpty(psReady)) {
    setPSAlert();
  }

  return (
    <span
      id="nested_field_alert_main_span_1"
      style={{
        display:
          (!alertDanger || typeof alertDanger === "boolean") &&
            (!alertPrimary || typeof alertPrimary === "boolean") &&
            (!invalidDateWarning || typeof invalidDateWarning === "boolean") &&
            (!invalidFieldErrorMessage || typeof invalidFieldErrorMessage === "boolean")
            ? "none"
            : "block",
      }}
    >
      {(alertDanger && alertDanger !== "none" || alertPrimary && alertPrimary !== "none") && (
        <div id="nested_field_alert_div_1">
          <Row id="nested_field_alert_row_1">
            <Col id="nested_field_alert_col_1" className="noWordBreak">
              {alertDanger && <Alert id="nested_field_alert_1" variant={"danger"}>{alertDanger}</Alert>}
              {alertPrimary && <Alert id="nested_field_alert_2" variant={"primary"}>{alertPrimary}</Alert>}
            </Col>
          </Row>
        </div>
      )}
      {
        ((invalidDateWarning && invalidDateWarning !== "none") || invalidFieldErrorMessage && invalidFieldErrorMessage !== "none") &&
        (
          <div id="nested_field_alert_div_2" className={"mt-15"}>
            <Row id="nested_field_alert_row_2">
              <Col id="nested_field_alert_col_2" className="noWordBreaks">
                {invalidDateWarning && (
                  <Alert id="nested_field_alert_3" className={"mb-0"} variant={"danger"}>{invalidDateWarning}</Alert>
                )}
                {invalidFieldErrorMessage && (
                  <Alert id="nested_field_alert_4" className={"mb-0"} variant={"danger"}>{invalidFieldErrorMessage}</Alert>
                )}
              </Col>
            </Row>
          </div>
        )
      }
    </span>
  );
};

export default NestedFieldAlert;
