import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "../indexReducer";
import thunk from "redux-thunk";
import immutableCheckMiddleWare from "redux-immutable-state-invariant";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["policy", "footer"],
  stateReconciler: autoMergeLevel2, //https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-847381679
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Setup
const middleWare = [thunk];

// Immutability Check
if (process.env.NODE_ENV === "development") {
  middleWare.push(immutableCheckMiddleWare());
}

// Logger Middleware. This always has to be last
const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
  collapsed: true,
});
middleWare.push(loggerMiddleware);

export default function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  let store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middleWare)));
  let persistor = persistStore(store);
  return { store, persistor };
}
