import enums from "../../Utilities/Enums/Enums";
import util from "../../Utilities/Functions/Functions";
import * as helper from "../QuestionHelpers/QuestionsHelper";
import * as repeatingCardsHelper from "../QuestionsForm/QuestionsTypes/RepeatingCards/RepeatingCardsHelper";
import { find, forEach, filter } from "lodash";

export function cardObject(
  cardLookupCode,
  questionType,
  parentQID,
  isHidden,
  isPristine,
  ordinalPosition,
  questionsAndData,
  subpage,
  defaultValue,
  styles,
  isVisibleLookupCode,
  isVisibleValue,
) {
  this.cardLookupCode = cardLookupCode,
    this.questionType = questionType;
  this.parentQID = parentQID;
  this.isHidden = isHidden;
  this.isPristine = isPristine;
  this.ordinalPosition = ordinalPosition;
  this.questionsAndData = questionsAndData;
  this.subpage = subpage;
  this.defaultValue = defaultValue;
  this.styles = styles;
  this.isVisibleLookupCode = isVisibleLookupCode;
  this.isVisibleValue = isVisibleValue;
}

export function cardSetObject(
  questionID,
  lookupCode,
  questionType,
  pageName,
  displayOrder,
  qrDisplayOrder,
  isHidden,
  isQR,
  isSelected,
  subpage,
  cardSetLabelingText,
  isVisibleLookupCode,
  isVisibleValue,
  columnOffset
) {
  this.questionID = questionID;
  this.lookupCode = lookupCode;
  this.questionType = questionType;
  this.pageName = pageName;
  this.displayOrder = displayOrder;
  this.qrDisplayOrder = qrDisplayOrder;
  this.isHidden = isHidden;
  this.isQR = isQR;
  this.isSelected = isSelected;
  this.cardCollection = [];
  this.newCardDefaultQuestions = [];
  this.subpage = subpage;
  this.cardSetLabelingText = cardSetLabelingText;
  this.isVisibleLookupCode = isVisibleLookupCode;
  this.isVisibleValue = isVisibleValue;
  this.columnOffset = columnOffset;
}

export function inlineBlockRow(
  questionID,
  lookupCode,
  questionType,
  pageName,
  displayOrder,
  qrDisplayOrder,
  isHidden,
  isQR,
  groupClass,
  subpage
) {
  this.questionID = questionID;
  this.lookupCode = lookupCode;
  this.questionType = questionType;
  this.pageName = pageName;
  this.displayOrder = displayOrder;
  this.qrDisplayOrder = qrDisplayOrder;
  this.isHidden = isHidden;
  this.isQR = isQR;
  this.inlineSetGroupClass = groupClass;
  this.inlineBlockQuestions = [];
  this.subpage = subpage;
}

const initializeExpandingCardSet = (cardSet) => {
  if (cardSet && cardSet.cardCollection.length > 0) {
    cardSet.cardCollection = forEach(cardSet.cardCollection, (card) => {
      card.isSelected = false;
    });

    return cardSet;
  }
};

const initializeRepeatingCardSet = (cardSet, activeCardSet) => {
  if (cardSet && (cardSet.questionID || cardSet.questionID === 0)) {
    let activeCards;
    let hiddenCardPageCount;
    let showCardPageCount;
    let cardSetIsActive = false;

    if (cardSet.cardCollection && cardSet.cardCollection.length > 0) {
      activeCards = helper.extractActiveRCards(cardSet.cardCollection, 6);
      hiddenCardPageCount = repeatingCardsHelper.rCardHideOrShowCount(cardSet.cardCollection, true);
      showCardPageCount = repeatingCardsHelper.rCardHideOrShowCount(cardSet.cardCollection, false);
    }

    //have to check lookupCode because questionID will be overwritten if it's 0 then saved
    if (activeCardSet && cardSet.lookupCode === activeCardSet.lookupCode) {
      //we made an in-page save and we want to keep our pagination position
      cardSetIsActive = true;
      activeCards = helper.extractActiveRCards(
        cardSet.cardCollection,
        activeCardSet.repeatingCardsActiveRangeEnd,
        activeCardSet.showHiddenCards
      );
    }

    cardSet.activeCardRangeStart = cardSetIsActive ? activeCardSet.activeCardRangeStart : 1;
    cardSet.activeCardRangeIndex = cardSetIsActive ? activeCardSet.activeCardRangeIndex : 0;
    cardSet.dateConflictAlertAI = "";
    cardSet.emailCardModalBody = "";
    cardSet.hiddenCardPageCount = hiddenCardPageCount ? hiddenCardPageCount : 0;
    cardSet.isEditingCardAtOP = cardSetIsActive && !isNaN(activeCardSet.isEditingCardAtOP) ? activeCardSet.isEditingCardAtOP : null;
    cardSet.modalCancelButtonText = "";
    cardSet.repeatingCardsActive = activeCards ? activeCards : [];
    cardSet.repeatingCardsActiveRangeEnd = cardSetIsActive ? activeCardSet.repeatingCardsActiveRangeEnd : 6;
    cardSet.showCardPageCount = showCardPageCount ? showCardPageCount : 0;
    cardSet.showHiddenCards = cardSetIsActive ? activeCardSet.showHiddenCards : false;
    cardSet.unsavedDeleteCardWarning = "";
  }

  return cardSet;
};

export const setValidProp = (validationProp, pageName, programGroupCode, isQR) => {
  let convertedValue = "";
  if (
    validationProp && (
      validationProp.includes(pageName + ".") ||
      (validationProp.includes(".") && pageName.includes("QuickRate") && isQR === enums.bool.true)
    )) {
    convertedValue = util.normalizeLookupCode(validationProp, programGroupCode);
  }
  else if (validationProp === "FALSE" || validationProp === "TRUE") {
    return validationProp;
  }
  return convertedValue;
};

const assignDataValue = (questionData, stagedQuestionObj, programGroupCode) => {
  if (questionData) {
    return util.getOrTransformData(questionData["@datavalue"], stagedQuestionObj.questionType);
  }
  else if (
    stagedQuestionObj.defaultValue &&
    stagedQuestionObj.defaultValue.toLowerCase().includes(programGroupCode.toLowerCase())
  ) {
    //check if default value is another lookup
    return util.getOrTransformData(stagedQuestionObj.defaultValue, stagedQuestionObj.questionType);
  }
  else if (stagedQuestionObj.defaultValue) {
    //then it's some arbitary value that may need to retain its dots, for example
    return stagedQuestionObj.defaultValue;
  }
  else {
    return "";
  }
};

const assignInitialValue = (stagedQuestionObj, programGroupCode, lookupCode, valuesObject) => {
  //check if we are dealing with getQuestionsAndData questionObject or defaultInitalValues form obj for cards
  const isMainQuestionObject = valuesObject.hasOwnProperty("initialValues");
  const datavalue = stagedQuestionObj.datavalue;
  const questionType = stagedQuestionObj.questionType;
  const defaultValue = getDefaultValue(stagedQuestionObj, programGroupCode);

  //Add the key/value pair for our initial form values to track
  if (questionType === enums.fieldTypes.checkbox && (!datavalue || datavalue === "false")) {
    if (isMainQuestionObject) {
      valuesObject.initialValues[lookupCode] = enums.bool.false;
    }
    else {
      valuesObject[lookupCode] = enums.bool.false;
    }
  }
  else if (questionType === enums.fieldTypes.checkbox && datavalue === "true") {
    if (isMainQuestionObject) {
      valuesObject.initialValues[lookupCode] = enums.bool.true;
    }
    else {
      valuesObject[lookupCode] = enums.bool.true;
    }
  }
  else {
    if (isMainQuestionObject) {
      valuesObject.initialValues[lookupCode] = datavalue ? datavalue : defaultValue;
    }
    else {
      valuesObject[lookupCode] = datavalue ? datavalue : defaultValue;
    }
  }
};

const assignDefaultValue = (stagedQuestionObj, programGroupCode, questionObject) => {
  questionObject.defaultValues[stagedQuestionObj.lookupCode] = getDefaultValue(stagedQuestionObj, programGroupCode);
};

const getDefaultValue = (stagedQuestionObj, programGroupCode) => {
  if (
    programGroupCode &&
    stagedQuestionObj.defaultValue &&
    stagedQuestionObj.defaultValue.toLowerCase().includes(programGroupCode.toLowerCase())
  ) {
    //check if default value is another lookup
    return util.getOrTransformData(
      stagedQuestionObj.defaultValue,
      stagedQuestionObj.questionType
    );
  }
  else if (stagedQuestionObj.defaultValue) {
    //then it's some arbitary value that may need to retain its dots, for example
    return stagedQuestionObj.defaultValue;
  }

  return "";
};

const isQuestionVisible = (stagedQuestionObj, cardQuestions) => {
  if (stagedQuestionObj.questionType === "hidden") {
    return false
  }
  else {
    if (stagedQuestionObj.isVisibleLookupCode && stagedQuestionObj.isVisibleValue) {
      let isVisibleLookupQuestion = cardQuestions.find((q) => q.lookupCode === stagedQuestionObj.isVisibleLookupCode);
      if (isVisibleLookupQuestion && isVisibleLookupQuestion.datavalue) {
        if (!stagedQuestionObj.isVisibleValue.includes(isVisibleLookupQuestion.datavalue)) {
          return false;
        }
      }
    }
  }

  return true;
}

const setInitialCardIsPristine = (stagedQuestionObj, cardQuestions) => {
  if (isQuestionVisible(stagedQuestionObj, cardQuestions)) {
    if (stagedQuestionObj.questionType === "checkbox") {
      if (
        stagedQuestionObj.datavalue === "TRUE" &&
        stagedQuestionObj.defaultValue !== stagedQuestionObj.datavalue
      ) {
        return false;
      }
      else if (
        stagedQuestionObj.datavalue !== '' &&
        stagedQuestionObj.defaultValue !== null &&
        stagedQuestionObj.defaultValue === "FALSE" &&
        stagedQuestionObj.defaultValue !== stagedQuestionObj.datavalue
      ) {
        return false;
      }

      return true;

    }
    else if (
      stagedQuestionObj.datavalue !== '' &&
      stagedQuestionObj.defaultValue !== stagedQuestionObj.datavalue
    ) {
      return false;
    }
  }

  return true;
};

export function buildRCards(questionsData, programGroupCode, questionObject, parentData, activeCardSet) {
  // build array of cardSets
  let rCardSets = helper.getRepeatingCardSets(questionsData.questions);
  let rCardSetObjects = [];
  for (let x = 0; x < rCardSets.length; x++) {
    rCardSetObjects.push(
      new cardSetObject(
        rCardSets[x].questionID,
        rCardSets[x].lookupCode,
        rCardSets[x].questionType,
        questionsData.pageName,
        rCardSets[x].displayOrder,
        rCardSets[x].qrDisplayOrder,
        rCardSets[x].isHidden,
        rCardSets[x].isQR,
        false,
        rCardSets[x].subpage,
        rCardSets[x].questionText,
        rCardSets[x].isVisibleLookupCode,
        rCardSets[x].isVisibleValue,
        rCardSets[x].columnOffset,
      )
    );
  }

  // build inidividual cards in cardSet card collections
  let rCards = helper.getRepeatingCardsFromRaw(questionsData.questions);

  // build default questions list
  for (let i = 0; i < rCards.length; i++) {
    let cardQuestions = [];
    let defaultFormValues = {};
    let defaultQuestionIDs = {};
    let parentCardSet = find(rCardSetObjects, (item) => {
      return item.lookupCode === rCards[i].parentLookupCode;
    });
    for (let q in questionsData.questions) {
      if (
        questionsData.questions[q].cardLookupCode &&
        questionsData.questions[q].cardLookupCode === rCards[i].lookupCode
      ) {
        cardQuestions.push(questionsData.questions[q]);
      }
    }

    // build default form values
    for (let j = 0; j < cardQuestions.length; j++) {
      let defaultQuestion = cardQuestions[j];

      defaultQuestion.datavalue = "";
      defaultQuestion.questionID = defaultQuestion.questionID.toUpperCase();
      defaultQuestionIDs[defaultQuestion.lookupCode] = defaultQuestion.questionID;

      assignInitialValue(
        defaultQuestion,
        programGroupCode,
        defaultQuestion.lookupCode,
        defaultFormValues
      );
    }

    // assign default questions for adding a new card item in addNewRepeatingCardToSet
    for (let j = 0; j < rCardSetObjects.length; j++) {
      let cardSet = rCardSetObjects[j];
      if (cardSet.lookupCode === parentCardSet.lookupCode && cardSet.newCardDefaultQuestions.length === 0) {
        cardSet.newCardDefaultQuestions = cardQuestions;
        cardSet.newCardDefaultFormValues = defaultFormValues;
        cardSet.newCardDefaultQuestionLookupIDs = defaultQuestionIDs;
      }
    }

    if (parentData) {

      let isPristine = true;

      for (let p = 0; p < parentData.length; p++) {
        let questionsAndData = [];
        let isHidden = parentData[p]["@isHidden"] === "true";
        let op = parentData[p]["@ordinalPosition"];
        let opSetID = parentData[p]["@ordinalSetID"] ? parentData[p]["@ordinalSetID"] : "0";
        if (opSetID === "0") {
          //set the parentQID to 0 since this is legacy data
          parentCardSet.questionID = opSetID;
        }

        //new copy of card ?s here - otherwise overwriting itself when adding lookupchange
        let cardQuestionsCopy = JSON.parse(JSON.stringify(cardQuestions));

        for (let j = 0; j < cardQuestionsCopy.length; j++) {
          let rQuestion = cardQuestionsCopy[j];
          rQuestion.questionID = rQuestion.questionID.toUpperCase();
          rQuestion.isVisibleLookupCode = rQuestion.isVisibleLookupCode
            ? util.setRepeatingLookup(rQuestion.isVisibleLookupCode, opSetID, op)
            : "";
          let questionData = find(parentData[p].response, function (d) {
            return d["@QID"].toUpperCase() === rQuestion.questionID;
          });
          rQuestion.datavalue = assignDataValue(questionData, rQuestion, programGroupCode);
          rQuestion.lookupCode = util.setRepeatingLookup(rQuestion.lookupCode, opSetID, op);
          rQuestion.isInvalid = questionData ? questionData["@isInvalid"] : "";
          rQuestion.isInvalidText = questionData ? questionData["@isInvalidText"] : "";
          rQuestion.isEndorsed = questionData ? questionData["@isEndorsed"] : "";
          rQuestion.validEligible = setValidProp(rQuestion.validEligible, questionsData.pageName, programGroupCode, rQuestion.isQR);
          rQuestion.validPremiumIndication = setValidProp(
            rQuestion.validPremiumIndication,
            questionsData.pageName,
            programGroupCode,
            rQuestion.isQR
          );

          questionsAndData.push(rQuestion);
          //fill bulk storage to use for recombination after pagination trims initialValue obj
          assignInitialValue(rQuestion, programGroupCode, rQuestion.lookupCode, questionObject);
          assignDefaultValue(rQuestion, programGroupCode, questionObject);
          questionObject.questionIDLookups[rQuestion.lookupCode] = rQuestion.questionID;

          if (isPristine) {
            isPristine = setInitialCardIsPristine(rQuestion, cardQuestionsCopy);
          }
        }

        if (rCards[i].parentLookupCode) {
          forEach(rCardSetObjects, (cardSet) => {
            if (cardSet.lookupCode === rCards[i].parentLookupCode) {
              cardSet.cardCollection.push(
                new cardObject(
                  rCards[i].lookupCode,
                  rCards[i].questionType,
                  parentCardSet.questionID,
                  isHidden,
                  isPristine,
                  parseInt(op, 10),
                  questionsAndData,
                  rCards[i].subpage,
                  rCards[i].defaultValue,
                  null,
                  rCards[i].isVisibleLookupCode,
                  rCards[i].isVisibleValue
                )
              );
            }
          });
        }

        isPristine = true;
      }
    }
  }

  rCardSetObjects.map((card) => {
    return questionObject.questions.push(initializeRepeatingCardSet(card, activeCardSet));
  });
}

export function buildECards(questionsData, programGroupCode, questionObject, rootData) {
  let eCardSets = helper.getExpandingCardSets(questionsData.questions);
  let eCardSetObjects = [];
  for (let i = 0; i < eCardSets.length; i++) {
    eCardSetObjects.push(
      new cardSetObject(
        eCardSets[i].questionID,
        eCardSets[i].lookupCode,
        eCardSets[i].questionType,
        questionsData.pageName,
        eCardSets[i].displayOrder,
        eCardSets[i].qrDisplayOrder,
        false,
        eCardSets[i].isQR,
        false,
        eCardSets[i].subpage,
        null,
        eCardSets[i].isVisibleLookupCode,
        eCardSets[i].isVisibleValue,
        eCardSets[i].columnOffset
      )
    );
  }

  let eCards = helper.getExpandingCardsFromRaw(questionsData.questions);
  let isPristine = true;

  for (let i = 0; i < eCards.length; i++) {
    var cardQuestions = filter(questionsData.questions, function (q) {
      if (q.cardLookupCode) {
        return q.cardLookupCode === eCards[i].lookupCode;
      }
    });
    let parentCardSet = find(eCardSetObjects, (item) => {
      return item.lookupCode === eCards[i].parentLookupCode;
    });

    let questionsAndData = [];
    //use the lookup for this question to find all of its contained children
    for (let j = 0; j < cardQuestions.length; j++) {
      let eQuestion = cardQuestions[j];
      eQuestion.questionID = eQuestion.questionID.toUpperCase();
      // eslint-disable-next-line
      let questionData = find(rootData, function (d) {
        return d["@QID"].toUpperCase() === eQuestion.questionID;
      });
      eQuestion.datavalue = assignDataValue(questionData, eQuestion, programGroupCode);
      eQuestion.isInvalid = questionData ? questionData["@isInvalid"] : "";
      eQuestion.isInvalidText = questionData ? questionData["@isInvalidText"] : "";
      eQuestion.isEndorsed = questionData ? questionData["@isEndorsed"] : "";
      eQuestion.validEligible = setValidProp(eQuestion.validEligible, questionsData.pageName, programGroupCode, eQuestion.isQR);
      eQuestion.validPremiumIndication = setValidProp(
        eQuestion.validPremiumIndication,
        questionsData.pageName,
        programGroupCode,
        eQuestion.isQR
      );

      //report to the log if lookup and isVisibibleLookupCode are same - it's an error
      if (eQuestion.lookupCode === eQuestion.isVisibleLookupCode) {
        questionObject.logErrors.push({
          message:
            "A lookupCode [" +
            eQuestion.lookupCode +
            "] contains a matching isVisibleLookupCode [" +
            eQuestion.isVisibleLookupCode +
            "] and is causing a setupQuestions error in QuestionsHelper, eCards.",
          submissionID: questionsData.submissionID,
        });
      }

      questionsAndData.push(eQuestion);
      assignInitialValue(eQuestion, programGroupCode, eQuestion.lookupCode, questionObject);
      assignDefaultValue(eQuestion, programGroupCode, questionObject);
      questionObject.questionIDLookups[eQuestion.lookupCode] = eQuestion.questionID;

      if (isPristine) {
        isPristine = setInitialCardIsPristine(eQuestion, cardQuestions);
      }
    }

    if (eCards[i].parentLookupCode) {
      forEach(eCardSetObjects, (cardSet) => {
        if (cardSet.lookupCode === eCards[i].parentLookupCode) {
          cardSet.cardCollection.push(
            new cardObject(
              eCards[i].lookupCode,
              eCards[i].questionType,
              parentCardSet.questionID,
              false,
              isPristine,
              i,
              questionsAndData,
              eCards[i].subpage,
              eCards[i].defaultValue,
              eCards[i].cssGroup,
              eCards[i].isVisibleLookupCode,
              eCards[i].isVisibleValue
            )
          );
        }
      });
    }

    //reset flag
    isPristine = true;
  }

  eCardSetObjects.map((cardSet) => questionObject.questions.push(initializeExpandingCardSet(cardSet)));
}

export function buildInlineBlockRows(questionsData, programGroupCode, questionObject, rootData) {
  let inlineBlockSets = helper.getInlineBlockSets(questionsData.questions);
  let inlineSets = [];
  for (let i = 0; i < inlineBlockSets.length; i++) {
    inlineSets.push(
      new inlineBlockRow(
        inlineBlockSets[i].questionID,
        inlineBlockSets[i].lookupCode,
        inlineBlockSets[i].questionType,
        questionsData.pageName,
        inlineBlockSets[i].displayOrder,
        inlineBlockSets[i].qrDisplayOrder,
        inlineBlockSets[i].isHidden,
        inlineBlockSets[i].isQR,
        inlineBlockSets[i].cssGroup,
        inlineBlockSets[i].subpage,
      )
    );
  }

  for (let i = 0; i < inlineSets.length; i++) {
    let inlineBlockRow = inlineSets[i];

    var inBlockQuestions = filter(questionsData.questions, function (q) {
      if (q.parentLookupCode) {
        return q.parentLookupCode === inlineBlockRow.lookupCode;
      }
    });

    for (let j = 0; j < inBlockQuestions.length; j++) {
      let nQuestion = inBlockQuestions[j];
      nQuestion.questionID = nQuestion.questionID.toUpperCase();
      // eslint-disable-next-line
      let questionData = find(rootData, function (d) {
        return d["@QID"].toUpperCase() === nQuestion.questionID;
      });
      nQuestion.datavalue = assignDataValue(questionData, nQuestion, programGroupCode);
      nQuestion.isInvalid = questionData ? questionData["@isInvalid"] : "";
      nQuestion.isInvalidText = questionData ? questionData["@isInvalidText"] : "";
      nQuestion.isEndorsed = questionData ? questionData["@isEndorsed"] : "";
      nQuestion.validEligible = setValidProp(nQuestion.validEligible, questionsData.pageName, programGroupCode, nQuestion.isQR);
      nQuestion.validPremiumIndication = setValidProp(
        nQuestion.validPremiumIndication,
        questionsData.pageName,
        programGroupCode,
        nQuestion.isQR
      );

      //report to the log if lookup and isVisibibleLookupCode are same - it's an error
      if (nQuestion.lookupCode === nQuestion.isVisibleLookupCode) {
        questionObject.logErrors.push({
          message:
            "A lookupCode [" +
            nQuestion.lookupCode +
            "] contains a matching isVisibleLookupCode [" +
            nQuestion.isVisibleLookupCode +
            "] and is causing a setupQuestions error in QuestionsHelper, inBlockSet.",
          submissionID: questionsData.submissionID,
        });
      }

      assignInitialValue(nQuestion, programGroupCode, nQuestion.lookupCode, questionObject);
      assignDefaultValue(nQuestion, programGroupCode, questionObject);
      questionObject.questionIDLookups[nQuestion.lookupCode] = nQuestion.questionID;

      inlineBlockRow.inlineBlockQuestions.push(nQuestion);
    }
  }


  //TODO: Make this a foreach in all functions
  inlineSets.map((inlineBlockRow) => questionObject.questions.push(inlineBlockRow));
}


export function buildIsolatedQuestions(questionsData, programGroupCode, questionObject, rootData) {
  let isolatedQuestions = helper.getIsolatedQuestions(questionsData.questions);

  for (let i = 0; i < isolatedQuestions.length; i++) {
    let iQuestion = isolatedQuestions[i];
    iQuestion.questionID = iQuestion.questionID.toUpperCase();
    let questionData = find(rootData, function (d) {
      return d["@QID"].toUpperCase() === iQuestion.questionID;
    });
    //Add the recombined question and rootData to our root set
    iQuestion.datavalue = assignDataValue(questionData, iQuestion, programGroupCode);
    iQuestion.isInvalid = questionData ? questionData["@isInvalid"] : "";
    iQuestion.isInvalidText = questionData ? questionData["@isInvalidText"] : "";
    iQuestion.isEndorsed = questionData ? questionData["@isEndorsed"] : "";
    iQuestion.validEligible = setValidProp(iQuestion.validEligible, questionsData.pageName, programGroupCode, iQuestion.isQR);
    iQuestion.validPremiumIndication = setValidProp(
      iQuestion.validPremiumIndication,
      questionsData.pageName,
      programGroupCode,
      iQuestion.isQR
    );

    assignInitialValue(iQuestion, programGroupCode, iQuestion.lookupCode, questionObject);
    assignDefaultValue(iQuestion, programGroupCode, questionObject);
    questionObject.questionIDLookups[iQuestion.lookupCode] = iQuestion.questionID;
    questionObject.questions.push(iQuestion);
  }
}
