import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { forEach } from "lodash";
import enums from "../../Enums/Enums";
import * as appActions from "../../../App/AppActions";
import * as questionActions from "../../../Questions/QuestionsActions";
import * as axiosApi from "../../../axiosApi";
import { API_ROOT } from "../../../api-config";

const Links = (props) => {
  const { callbackFunction, helptext, id, label, questions, setValueParentOrLocal } = props;

  //dispatch
  const dispatch = useDispatch();

  //actions
  const reportToast = useCallback((msg) => dispatch(appActions.reportToast(msg, enums.toastTypes.success)), [dispatch]);
  const reportError = useCallback((msg) => dispatch(appActions.reportToast(msg, enums.toastTypes.error)), [dispatch]);

  let linkToServer;

  if (callbackFunction && callbackFunction.includes(":5000") && label) {
    // https://stackoverflow.com/questions/8317059/relative-path-but-for-port
    // can't use relative path to and change port
    linkToServer = `${API_ROOT}${label}`;
  }

  const handleLink = () => {
    const funcList = callbackFunction.split(";");

    forEach(funcList, (f) => {
      //this is coming from db defaultValue column as a string - parse it and fire
      if (f && f.includes(enums.linkFunctions.setDynamicModal)) {
        const trigger = f.split("|")[0];
        const modal = f.split("|")[1];
        setValueParentOrLocal(modal, trigger, null, null, null);
      }

      if (f && f.includes(enums.linkFunctions.showPremFinancePDF)) {
        //use this for the second call to retrieve document via link click once inside PremFinance
        reportToast("Generating Installment Plan document...");
        const installmentPlanPDFPath = sessionStorage.getItem("installmentPlanPDFPath");
        if (installmentPlanPDFPath) {
          const filePath = installmentPlanPDFPath;
          const pdfWindow = window.open();
          pdfWindow.location.href = filePath;
        }
        else {
          //TODO: should this error disable the payment option?
          reportError("The Installment Plan document is not available at this time. Please try again later.");
        }
      }

      if (f && f.includes(enums.linkFunctions.getPolicyDocumentPackage)) {
        const documentPackageName = f.split("|")[1];
        //use this for the first call into PremFinance via the payment button
        reportToast("Generating document...");

        const vm = {
          SubmissionID: questions.submissionID,
          PackageName: documentPackageName,
        };

        axiosApi.post("api/policy/pdfDirect", vm).then((result) => {
          if (result.data.response !== "ERROR" && result.data.data) {
            const filePath = result.data.data;
            const pdfWindow = window.open();
            pdfWindow.location.href = filePath;
            sessionStorage.setItem(documentPackage, filePath);
          }
          else {
            reportError(result.data.message);
          }
        },
          (error) => {
            reportError("Unable to generate the requested document. Please try again later.");
          }
        );
      }

      if (f && f.includes(enums.linkFunctions.createSubmission)) {
        const programCode = f.split("|")[1];
        dispatch(questionActions.abortSubmission(null, null, "Redirecting to selected policy...", false, true));
        dispatch(appActions.createSubmission(programCode, true)), [dispatch];
      }
    });
  };

  return (
    <Container id="link_main_container_1">
      {callbackFunction && !linkToServer && (
        <Link id={id} to={"#"} onClick={() => handleLink()}>
          {helptext}
        </Link>
      )}
      {callbackFunction && linkToServer && (
        <a href={linkToServer} target="_blank">
          {helptext}
        </a>
      )}
      {!callbackFunction && (
        <Link id={id} to={label ? label : "#"} target="_blank">
          {helptext}
        </Link>
      )}
    </Container>
  );
};

export default Links;
