import * as userMaintenanceActions from "./UserMaintenanceActionTypes";
import * as helper from "./UserMaintenanceHelper";
import enums from "../Utilities/Enums/Enums";
import { cloneDeep, has } from "lodash";

export const INITIAL_STATE = {
  allPrograms: {},
  adminIsEditingSelf: false,
  adminSelectedUser: {},
  adminSelectedUserHasDebug: enums.bool.false,
  blacklistedUsers: [],
  blacklistedUserValues: {},
  blacklistedUsersAreLoaded: false,
  entityStatuses: [],
  isEditing: false,
  mgaIsEditingSelf: false,
  mgaSelectedUser: {},
  mgaSelectedUserHasDebug: enums.bool.false,
  modalBody: "",
  openAddUserModal: false,
  openEditBlacklistModal: false,
  openPWModal: false,
  openRulesModal: false,
  openUNModal: false,
  openProgramsModal: false,
  openUserProgramsModal: false,
  userPrograms: {},
  userProgramsPristine: {},
  userRoles: [],
  users: [],
  usersAreLoaded: false,
};

export default function userMaintenanceReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case userMaintenanceActions.getAdminSelectedUserSuccess:
      let approvedAdminDebugger =
        has(action.selectedUserEntityConfig, enums.configEntityItems.useDebugMode) &&
        action.selectedUserEntityConfig.UseDebugMode === enums.bool.true;
      state = update(state, {
        adminIsEditingSelf: action.adminIsEditingSelf,
        mgaIsEditingSelf: action.mgaIsEditingSelf,
        adminSelectedUser: action.user,
        adminSelectedUserHasDebug: approvedAdminDebugger ? enums.bool.true : enums.bool.false,
        isEditing: true,
      });
      break;
    case userMaintenanceActions.getMGASelectedUserSuccess:
      let approvedMGADebugger =
        has(action.selectedUserEntityConfig, enums.configEntityItems.useDebugMode) &&
        action.selectedUserEntityConfig.UseDebugMode === enums.bool.true;
      state = update(state, {
        mgaIsEditingSelf: action.mgaIsEditingSelf,
        mgaSelectedUser: action.user,
        mgaSelectedUserHasDebug: approvedMGADebugger ? enums.bool.true : enums.bool.false,
        isEditing: true,
      });
      break;
    case userMaintenanceActions.clearUserMaintenanceState:
      state = INITIAL_STATE;
      break;
    case userMaintenanceActions.clearBlacklistedUsers:
      state = update(state, {
        blacklistedUsers: [],
        blacklistedUserValues: {},
      });
      break;
    case userMaintenanceActions.clearUsers:
      state = update(state, {
        users: [],
      });
      break;
    case userMaintenanceActions.closeModals:
      state = update(state, {
        openAddUserModal: false,
        openEditBlacklistModal: false,
        openPWModal: false,
        openRulesModal: false,
        openUNModal: false,
        openProgramsModal: false,
        openUserProgramsModal: false,
      });
      break;
    case userMaintenanceActions.getEntityStatusesSuccess:
      let statusList = helper.setEntityStatusValues(action.statuses);
      state = update(state, {
        entityStatuses: statusList,
      });
      break;
    case userMaintenanceActions.launchGlobalProgramsModalSuccess:
      let strappedAllPrograms = helper.setProgramsValues(action.allPrograms, true);
      let strappedAllProgramsBody = helper.setProgramsModalBody(strappedAllPrograms, "");
      let strappedUserProgramsPristine1 = cloneDeep(strappedAllPrograms);

      state = update(state, {
        openProgramsModal: !state.openProgramsModal,
        openRulesModal: false,
        allPrograms: strappedAllPrograms,
        modalBody: strappedAllProgramsBody,
        userProgramsPristine: strappedUserProgramsPristine1,
      });
      break;
    case userMaintenanceActions.launchUserProgramsModalSuccess:
      let strappedUserPrograms = helper.setProgramsValues(action.userPrograms, false);
      let strappedUserProgramsPristine = cloneDeep(strappedUserPrograms);
      let strappedUserBody = helper.setProgramsModalBody(strappedUserPrograms, action.entityName);

      state = update(state, {
        openUserProgramsModal: !state.openUserProgramsModal,
        openRulesModal: false,
        userPrograms: strappedUserPrograms,
        userProgramsPristine: strappedUserProgramsPristine,
        modalBody: strappedUserBody,
      });
      break;
    case userMaintenanceActions.getBlacklistedUsersSuccess:
      let strappedBlacklistedUsers = helper.setBlacklistedUserValues(action.blacklistedUsers);

      state = update(state, {
        blacklistedUsers: action.blacklistedUsers,
        blacklistedUserValues: strappedBlacklistedUsers,
        blacklistedUsersAreLoaded: true,
      });
      break;
    case userMaintenanceActions.getUserRolesSuccess:
      state = update(state, {
        userRoles: action.roles,
      });
      break;
    case userMaintenanceActions.getUsersSuccess:
      state = update(state, {
        users: action.users,
        usersAreLoaded: true,
      });
      break;
    case userMaintenanceActions.goBack:
      state = update(state, {
        adminIsEditingSelf: false,
        mgaIsEditingSelf: false,
        isEditing: false,
        adminSelectedUser: {},
        mgaSelectedUser: {},
      });
      break;
    case userMaintenanceActions.saveUserProgramsSuccess:
      state = update(state, {
        userPrograms: {},
        userProgramsPristine: {},
        openUserProgramsModal: !state.openUserProgramsModal,
      });
      break;
    case userMaintenanceActions.userProxyUpdateSuccess:
      let updatedUsers = helper.updateUsers(state.users, action.user);
      state = update(state, {
        adminSelectedUser: action.user,
        mgaSelectedUser: action.user,
        users: updatedUsers,
      });
      break;
    case userMaintenanceActions.setLoadingBlacklistedUsersFlag:
      state = update(state, {
        blacklistedUsersAreLoaded: false,
      });
      break;
    case userMaintenanceActions.setLoadingUsersFlag:
      state = update(state, {
        usersAreLoaded: false,
      });
      break;
    case userMaintenanceActions.toggleAddUserModal:
      state = update(state, {
        openAddUserModal: !state.openAddUserModal,
      });
      break;
    case userMaintenanceActions.toggleEditBlacklistModal:
      state = update(state, {
        openEditBlacklistModal: !state.openEditBlacklistModal,
      });
      break;
    case userMaintenanceActions.togglePWModal:
      state = update(state, {
        openPWModal: !state.openPWModal,
      });
      break;
    case userMaintenanceActions.toggleRulesModal:
      state = update(state, {
        openRulesModal: !state.openRulesModal,
      });
      break;
    case userMaintenanceActions.toggleUNModal:
      state = update(state, {
        openUNModal: !state.openUNModal,
      });
      break;
    default:
      /* do nothing */
      break;
  }
  return state;
}
