import { useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";

const ProofOfInsurance = (props) => {
  const [open, toggleCollapse] = useState(false);

  if (props.openComponent && props.openComponent !== "proofOfInsurance" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("proofOfInsurance");
  }

  return (
    <div id="proof_of_insurance_main_div_1">
      <div
        id="proof_of_insurance_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="proofRow"
        aria-expanded={open}
        className={"sectionHeader"}
      >
        How fast will I have Proof of Insurance?
      </div>
      <Collapse in={open}>
        <div id="proof_of_insurance_div_3">
          <Row id="proof_of_insurance_row_1">
            <Col id="proof_of_insurance_col_1">
              <hr />
              <div id="proof_of_insurance_div_4">
                Once purchased, your policy documents will be available for you to print immediately. An automatic email containing your policy documents is also sent instantly to your email account.
                You may sign in at any time to reprint your policy documents at your convenience.
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default ProofOfInsurance;
