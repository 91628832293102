import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { Form, withFormik } from "formik";
import { default as FormInputs } from "../../../../Utilities/Inputs/FormGroupsWrapper";
import ErrorBoundary from "../../../../Errors/ErrorBoundary/ErrorBoundary";
import CallbackSchema from "./CallbackSchema";
import enums from "../../../../Utilities/Enums/Enums";

const BasicForm = (props) => {
  const { dirty, errors, resetForm, values } = props;

  //store
  const app = useSelector((state) => state.app);

  const [submitting, setSubmitDisable] = useState(false);
  const hasErrors = Object.keys(errors).length > 0;

  const handleCallback = (values) => {
    setSubmitDisable(true);

    const vm = {
      name: values.userName,
      email: values.email,
      phone: values.phone,
      program: values.program,
      message: values.message,
      idnumber: values.idnumber
    };

    props.handleCallback(vm);
    resetForm();

    setTimeout(function () { setSubmitDisable(false) }, 3000);
  };

  return (
    <Form id="callback_form_1">
      <Row id="callback_form_row_1">
        <Col id="callback_form_col_1">
          <ErrorBoundary>
            <FormInputs
              type={enums.fieldTypes.text}
              id="userName"
              name="userName"
              placeholder="Full Name"
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <FormInputs
              type={enums.fieldTypes.text}
              id="email"
              name="email"
              placeholder="your@email.com"
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <FormInputs
              type={enums.fieldTypes.text}
              id="phone"
              name="phone"
              placeholder="Telephone (XXX-XXX-XXXX)"
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <FormInputs
              type={enums.fieldTypes.combo}
              id="program"
              name="program"
              options={app.products}
              placeholder="Select RVNA Product"
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <FormInputs
              type={enums.fieldTypes.text}
              id="idnumber"
              name="idnumber"
              placeholder="Enter ID Number"
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <FormInputs
              type={enums.fieldTypes.textarea}
              id="message"
              name="message"
              placeholder="What can we help you with?"
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <Col id="callback_form_col_2">
              <div id="callback_form_div_1">
                We do not sell or share your information.
              </div>
              <Button
                id="callback_form_btn_1"
                className="lg btn btn-primary mt-15"
                disabled={submitting || !dirty || hasErrors}
                onClick={() => handleCallback(values)}
              >
                Request Callback
                {submitting && (
                  <span id="callback_form_span_1">
                    <FormInputs
                      id={"loadingIcon"}
                      name={"loadingIcon"}
                      helptext={"loading"}
                      source={"ajax-loader-nobg.gif"}
                      type={enums.fieldTypes.image}
                    />
                  </span>
                )}
              </Button>
            </Col>
          </ErrorBoundary>
        </Col>
      </Row>
    </Form>
  );
};

const CallbackFormHOC = withFormik({
  validationSchema: CallbackSchema,
  mapPropsToValues: (props) => ({
    //initial values
    email: "",
    phone: "",
    userName: "",
    program: "",
    message: "",
    idnumber: ""
  }),
});

const CallbackForm = CallbackFormHOC(BasicForm);

export default CallbackForm;
