import { useSelector } from "react-redux";
import { Form, Container, Row, Col } from "react-bootstrap";
import { FieldWrapper } from '../../../../StylizedComponents/index';
import { Field } from "formik";
import LabelCommon from "../Shared/LabelCommon";
import NestedFieldAlert from "../Alerts/NestedFieldAlert";
import ErrorMessage from "../../../../Errors/ErrorMessage/ErrorMessage";
import enums from "../../../Enums/Enums";
import ps from "../../../Functions/PageScript";
import util from "../../../Functions/Functions";
import "../../../../_styles/icons/icons.scss";

const Percent = (props) => {
  const {
    clearPropertyValueFromPSField,
    columnOffset,
    contingentPSBundle,
    disabled,
    field: { name },
    fieldsRef,
    form: { errors, touched, setFieldTouched, setFieldValue, values },
    helptext,
    id,
    label,
    label_css,
    label_location,
    label_prefix,
    placeholder,
    popover_class,
    popover_placement,
    setFormValueCallback,
    setValueParentOrLocal,
    static_col_breakpoint,
    type,
    validateQuestionSimple,
    windowWidth
  } = props;

  //#region stored values

  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);
  let invalidFieldErrorMessage;

  let hasError = isQuestionsContext
    ? util.checkFieldHasQuestionError(name, storedInvalidFields)
    : util.checkFieldHasYupError(name, errors, touched);

  if (hasError && isQuestionsContext) {
    invalidFieldErrorMessage = util.getInvalidFieldMessage(name, storedInvalidFields)?.fieldMessage;
  }

  //#endregion stored values

  //#region pageScript

  const normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let psExecutePostmount, psPostmountExecutedFields;
  let psReady = {};

  if (isQuestionsContext) {
    psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, questions.pageUrl);
      if ((psReady.value || psReady.value !== undefined) && psReady.value !== value) {
        if (psReady.value === "clearValue") {
          clearPropertyValueFromPSField(psReady.id, "value");
          setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
        }
        else {
          setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
        }
      }

      if (ps.hasActiveAlert(psReady)) {
        //need this to mark up input and override valid
        hasError = true;
        // hasValid = false;
      }
    }
  }

  //#endregion pageScript

  //#region functions & actions

  const handleBlur = () => {
    setFieldTouched(name, true);
    if (psExecutePostmount) {
      contingentPSBundle(psExecutePostmount, values[name], name, enums.psExecute.postmount);
    }
    if (isQuestionsContext) {
      validateQuestionSimple(name, values[name]);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    //if value equals placeholder, leave field value alone
    if (value !== placeholder) {
      const normalValue = !value ? "" : value.replace(/[^0-9.]/g, "");
      setValueParentOrLocal(name, normalValue, setFieldValue, setFormValueCallback);
    }
  };

  //#endregion functions & actions

  //#region percentLabel

  let validation_wrapper_css;
  let input_class_val_css;
  let label_class_interpreted;

  if (hasError) {
    validation_wrapper_css = "percentError ";
    input_class_val_css = "error-border ";
    label_class_interpreted += " error-msg ";
    if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
      validateQuestionSimple(name, values[name]);
    }
  }
  else {
    validation_wrapper_css = "input-group-lg d-flex align-items-center percent";
    input_class_val_css = "input-group-addon-right ";
  }

  label_class_interpreted += label_css;

  let label_text = label ? label : "";
  if (psReady && psReady.setLabelValue) {
    label_text = psReady.setLabelValue;
  }

  const labelProps = {
    helptext, id, label_prefix, label_text, popover_class,
    popover_placement, type
  }

  //#endregion percentLabel

  return (
    <Container
      id="percent_main_container_1"
      className={label_text ? "" : "no_label"}
      key={id}
      onBlur={() => handleBlur()}
    >
      <Field
        id="percent_field_wrapper_1"
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, "")}
        component={FieldWrapper}
        name={"percent-" + name}
      >
        {label_text && label_location === enums.labelLocation.top && (
          <Row id="percent_row_1">
            <Col id="percent_col_1" xs={"12"}>
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row id="percent_row_2" className={"input-group " + validation_wrapper_css}>
          {label_text && label_location === enums.labelLocation.left && (
            <Col id="percent_col_2" className="detectPercentInlineLabel" xs={"12"} md={"6"} lg={"4"}>
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
          <Col
            id="percent_col_3"
            xs={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "3" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "2" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "2" : "12"}
          >
            <div id="percent_div_1" className={"input-group input-group-lg d-flex align-items-center " + validation_wrapper_css}>
              <Form.Control
                className={"form-control input-text-align-vertical " + input_class_val_css + " percentWidth"}
                disabled={
                  psReady && psReady.disableInput ? psReady.disableInput :
                    psReady && psReady.setValueAndDisableInput ? psReady.setValueAndDisableInput
                      : disabled
                }
                id={id}
                name={name}
                onChange={handleChange}
                placeholder={placeholder}
                type="text"
                value={values[name]}
                autoComplete={questions && questions.pageUrl === "PremInd" ? "none" : "on"}
              />
              {hasError ? (
                <div id="percent_div_2" className="input-group-append error-border-append rounded-left">
                  <span
                    id="percent_span_1"
                    className={
                      psReady && psReady.disableInput
                        ? "input-group-text rounded-right disabledGray"
                        : "input-group-text rounded-right bg-transparent"
                    }
                  >
                    <div id="percent_div_3" className={"icon-percent error-msg"}></div>
                  </span>
                </div>
              ) : (
                <div id="percent_div_4" className={"input-group-append"}>
                  <span
                    id="percent_span_2"
                    className={
                      psReady && psReady.disableInput
                        ? "input-group-text rounded-right disabledGray"
                        : "input-group-text rounded-right"
                    }
                  >
                    <div id="percent_div_5" className={"icon-percent"}></div>
                  </span>
                </div>
              )}
            </div>
          </Col>
          {label_text && label_location === enums.labelLocation.right && (
            <Col id="percent_col_4" className="detectPercentInlineLabel">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
        </Row>

        {label_text && label_location === enums.labelLocation.bottom && (
          <Row id="percent_row_3">
            <Col id="percent_col_5">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        {!isQuestionsContext && (
          <Row id="percent_row_4">
            <Col id="percent_col_6">
              <ErrorMessage errors={errors[name]} touched={touched[name]} type={"percentErrorMsg"} />
            </Col>
          </Row>
        )}

        <Row id="percent_row_5">
          <Col
            id="percent_col_7"
            xs={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "3" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "2" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "2" : "12"}>
            <NestedFieldAlert psReady={psReady} invalidFieldErrorMessage={invalidFieldErrorMessage} />
          </Col>
        </Row>
      </Field>
    </Container>
  );
};

export default Percent;
