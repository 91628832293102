import { cloneDeep, forEach } from "lodash";
import { Alert } from 'react-bootstrap';
import util from "../../Utilities/Functions/Functions";
import ReportLogItem from "./ReportLogItem";
import * as psInit from "./Initialize";

export function addPSRCard(currentPSObj, updatedPSObjWAddedCard, newOrdinalPosition) {
  //note: pageScript rCards will always load with a non-OP, hydrating value (i.e. addlInsuredName for addInsuredName-OP1 creation)
  //this function will look for the non-OP field in our executionTimes (premount, postmount) and duplicate for the new card's OP
  //...we don't need to duplicate previous page field/value information, since we don't apply -OP to prevPageFields

  if (currentPSObj && !util.isObjEmpty(currentPSObj)) {
    if (currentPSObj.scripts.length > 0) {
      for (let row in currentPSObj.scripts) {
        let field = currentPSObj.scripts[row];
        //only need to check first appliesToLookup - either it's OP-bearing or not
        //no -OP and we know this is a pure, hydrating field we can copy from
        if (field.appliesToLookupCodes[0].indexOf("-OP") < 0) {
          let newLookupOP = field.appliesToLookupCodes[0] + "-OP" + newOrdinalPosition;
          let fieldToUpdate = []; //need array to process in build func below
          fieldToUpdate.push(field);

          //update with new OP
          let psFieldToAdd = psInit.buildPreAndPostmountCards(
            fieldToUpdate,
            field.appliesToLookupCodes[0],
            newLookupOP,
            newOrdinalPosition,
            updatedPSObjWAddedCard.contingentValuesPrevPages
          );

          if (psFieldToAdd && psFieldToAdd.length > 0) {
            //our function targets exactly one field at a time with this approach, so we can safely apply 0-index
            updatedPSObjWAddedCard.scripts.push(psFieldToAdd[0]);
          }
        }
      }
    }
  }

  return updatedPSObjWAddedCard;
}

export function clearPropertyValueFromPSField(fieldID, propertyToClear, pageScripts) {
  //the psReady obj has been applied, time to clean out the property value to prevent loops
  if (fieldID && propertyToClear && pageScripts) {
    for (let i = 0; i < pageScripts.length; i++) {
      if (pageScripts[i].id === fieldID) {
        pageScripts[i][`${propertyToClear}`] = null;
        break;
      }
    }
  }

  return pageScripts;
}

export function deletePSRCard(currentPSObj, ordinalPosition) {
  let updatedPSObj = {
    contingentValuesPrevPages: [],
    scripts: [],
  };

  if (currentPSObj && !util.isObjEmpty(currentPSObj)) {
    if (currentPSObj.scripts.length > 0) {
      for (let row in currentPSObj.scripts) {
        let field = currentPSObj.scripts[row];
        for (let lookupCode in field.appliesToLookupCodes) {
          if (parseInt(field.appliesToLookupCodes[lookupCode].split("-OP")[1]) !== ordinalPosition) {
            updatedPSObj.scripts.push(field);
          }
        }
      }
    }
  }

  return updatedPSObj;
}

export function extractActivePSRCards(psAllObj, repeatingCardsActive) {
  let extractedActivePSRCards = {
    contingentValuesPrevPages: psAllObj.contingentValuesPrevPages,
    scripts: [],
  };

  if (psAllObj && !util.isObjEmpty(psAllObj)) {
    for (let i = 0; i < repeatingCardsActive.length; i++) {
      let ordinalPosition = repeatingCardsActive[i].ordinalPosition;
      if (psAllObj.scripts.length > 0) {
        for (let row in psAllObj.scripts) {
          let field = psAllObj.scripts[row];
          for (let lookup in field.appliesToLookupCodes) {
            //if our field doesn't contain an -OP we need it for hydration, so push it
            if (field.appliesToLookupCodes[lookup].indexOf("-OP") < 0 && !field.addedForHydration) {
              field.addedForHydration = true;
              extractedActivePSRCards.scripts.push(field);
            }
            else if (parseInt(field.appliesToLookupCodes[lookup].split("-OP")[1]) === ordinalPosition) {
              extractedActivePSRCards.scripts.push(field);
            }
          }
        }
      }
    }
  }

  return extractedActivePSRCards;
}

export function updatePSFieldsWithExecutedFieldsObj(executedPSFields, pageScript) {
  //this function maintains separation between the saved pageScript obj and the executedPSFields so
  //we can control their on/off application

  let psFields = pageScript.scripts;

  if (executedPSFields && pageScript) {
    //if pageScript's fields obj is out of date, replace field with updated executedPSField
    for (let i = 0; i < psFields.length; i++) {
      for (let j = 0; j < executedPSFields.length; j++) {
        let equalFields = psFields[i].id === executedPSFields[j].id;

        if (equalFields && !psFields[i].value) {
          psFields[i].value = executedPSFields[j].value;
          psFields[i] = executedPSFields[j];
        }
        else if (equalFields && psFields[i].value !== executedPSFields[j].value) {
          psFields[i] = executedPSFields[j];
        }
      }
    }
  }

  return pageScript;
}

export function updateFieldExecutionTime(fieldID, newExecutionTime, pageScripts) {
  if (fieldID && newExecutionTime && pageScripts) {
    for (let i = 0; i < pageScripts.length; i++) {
      if (pageScripts[i].id === fieldID) {
        pageScripts[i].executionTime = newExecutionTime;
        break;
      }
    }
  }

  return pageScripts;
}


const buildPSReportErrors = (errors) => {
  const items = [];

  forEach(errors, (value, index) => {
    let error = errors[index];
    let errorMsgBullets = [];

    forEach(error.Messages, (message) => {
      errorMsgBullets.push(<li>{message}</li>);
    });

    items.push(
      <li>
        <span className="text-uppercase">{error.PageScriptID}</span>
        <ul>
          {errorMsgBullets}
        </ul>
      </li>
    );
  });

  return (
    <div>
      <ol>
        {items}
      </ol>
    </div>
  );
}

export function buildPSReportLog(questions) {
  if (questions && questions.pageScriptReportLog && questions.pageScriptReportLog.length > 0) {
    let reports = [];

    const strapReport = (report, index) => {
      return (
        <div key={index} >
          <ReportLogItem report={report} index={index} />
        </div>
      );
    };

    const clonedPSReportLog = cloneDeep(questions.pageScriptReportLog);
    const sortSuccesDesc = (a, b) => a.result?.ruleResult.toString() < b.result?.ruleResult.toString() ? 1 : -1;
    const sortByRuleResultSuccess = clonedPSReportLog.sort(sortSuccesDesc);

    reports = sortByRuleResultSuccess.reduce((result, report, index) => {
      result.push(strapReport(report, index));
      return result;
    }, []);

    return reports;
  }
  else {
    return (
      <div>
        <Alert variant={'primary'}>
          <div>No reports available for {questions && questions.pageScriptPristine && questions.pageScriptPristine.scripts ? questions.pageScriptPristine.scripts.length : "0"} PageScripts.</div>
        </Alert>
      </div>
    )
  }
}
