import { useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";

const SamplePolicy = (props) => {
  const [open, toggleCollapse] = useState(false);

  if (props.openComponent && props.openComponent !== "samplePolicy" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("samplePolicy");
  }

  return (
    <div id="sample_policy_main_div_1">
      <div
        id="sample_policy_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="samplePolRow"
        aria-expanded={open}
      >
        How do I view a sample policy?
      </div>
      <Collapse in={open}>
        <div id="sample_policy_div_3">
          <Row id="sample_policy_row_1">
            <Col id="sample_policy_col_1">
              <hr />
              <div id="sample_policy_div_4">
                To view a sample policy, please e-mail your request to <a href="mailto:support@rvnuccio.com">support@rvnuccio.com</a> and include the type of insurance.
                Please allow 24-48 hours for your request to be processed during our business hours M-F 5:30am-5:00pm PST.
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default SamplePolicy;
