import { filter, forEach, uniqBy } from "lodash";
import psUtil from "../../Utilities/Functions/PageScript";
import * as psRules from "./Rules";
import enums from "../../Utilities/Enums/Enums";


const createNewPSField = (field, value, contingentValueReturned, turnOff, psObjProperty, psObjPropertyValue) => {
  if (field) {
    let newPSField = {
      id: field.id,
      pageName: field.pageName,
      ordinalPosition: field.ordinalPosition > -1 ? field.ordinalPosition : null,
      action: field.action,
      executionTime: turnOff ? null : field.executionTime === enums.psExecute.premountPersist ? enums.psExecute.postmount : field.executionTime,
      appliesToLookupCodes: field.appliesToLookupCodes,
      contingentLookupCodes: field.contingentLookupCodes,
      contingentResult: field.contingentResult,
      contingentReturnLocation: field.contingentReturnLocation,
      contingentValueReturned: (contingentValueReturned === true || contingentValueReturned === enums.bool.true) ? enums.bool.true : enums.bool.false,
      logReturnValue: field.logReturnValue,
      persisted: field.persisted ? field.persisted : field.executionTime === enums.psExecute.premountPersist ? true : false,
      pristine: true,
      preventActionOnPristine: field.preventActionOnPristine,
      value: value ? value : null,
      errors: null,
      timestamp: new Date().getUTCMilliseconds()
    };

    if (psObjProperty) {
      newPSField[`${psObjProperty}`] = psObjPropertyValue ? psObjPropertyValue : null;
    }

    return newPSField;
  }
};

const editUpdatedPSFields = {
  turnOffExecution: (updatedPSFields, index) => {
    if (updatedPSFields && index >= 0) {
      return (updatedPSFields[index].executionTime = null);
    }
  },
  updateAction: (updatedPSFields, index, action) => {
    if (updatedPSFields[index].action !== action) {
      return (updatedPSFields[index].action = action);
    }
  },
  updatePSObjProperty: (updatedPSFields, index, psObjProperty, updateValue) => {
    return (updatedPSFields[index][psObjProperty] = updateValue);
  },
  updateValue: (updatedPSFields, index, updateValue) => {
    return (updatedPSFields[index].value = updateValue);
  },
};

const issueEvaluatedAction = (
  psObjProperty,
  evaluatedReturnValue,
  updatedPSFields,
  stagedField,
  index,
  createNewField,
  clearFieldValue,
  customClearingValue,
  errors
) => {

  let interpretedIndex = index;

  if (!createNewField && evaluatedReturnValue) {
    //exists already, update it
    editUpdatedPSFields.updateAction(updatedPSFields, interpretedIndex, stagedField.action);
    editUpdatedPSFields.updatePSObjProperty(updatedPSFields, interpretedIndex, psObjProperty, evaluatedReturnValue);
    editUpdatedPSFields.updatePSObjProperty(updatedPSFields, interpretedIndex, enums.psOptions.contingentValueReturned, enums.bool.true);
    editUpdatedPSFields.updatePSObjProperty(updatedPSFields, interpretedIndex, enums.psOptions.pristine, false);
  }
  else if (createNewField && evaluatedReturnValue) {
    //create new field
    if (psObjProperty) {
      updatedPSFields.push(createNewPSField(stagedField, null, enums.bool.true, null, psObjProperty, evaluatedReturnValue));
    }
    else {
      updatedPSFields.push(createNewPSField(stagedField, evaluatedReturnValue, enums.bool.true, null, psObjProperty));
    }
    interpretedIndex = updatedPSFields.length - 1;
  }
  else if (!createNewField && !evaluatedReturnValue && (customClearingValue || clearFieldValue) && psObjProperty) {
    if (stagedField?.ordinalPosition && customClearingValue && clearFieldValue && psObjProperty !== enums.psOptions.value) {
      //in the case we are clearing an obj property, such as setClass for eCards, where we need to relay a local state clearing function after it's processed by PageScriot.js
      //we need the psReady obj to survive the contingentValueReturned false filter in PageScript.js and make it to the eCardSingle component where it can cleared at the local state level
      editUpdatedPSFields.updatePSObjProperty(updatedPSFields, interpretedIndex, enums.psOptions.value, customClearingValue);
    }
    editUpdatedPSFields.updatePSObjProperty(updatedPSFields, interpretedIndex, psObjProperty, customClearingValue);
    editUpdatedPSFields.updatePSObjProperty(updatedPSFields, interpretedIndex, enums.psOptions.contingentValueReturned, enums.bool.false);
  }
  else {
    //disable it
    updatedPSFields.push(createNewPSField(stagedField, null, enums.bool.false, false, psObjProperty, null));
    interpretedIndex = updatedPSFields.length - 1;
  }

  //report any errors
  if (errors) {
    editUpdatedPSFields.updatePSObjProperty(updatedPSFields, interpretedIndex, "errors", errors);
  }
  else {
    editUpdatedPSFields.updatePSObjProperty(updatedPSFields, interpretedIndex, "errors", "");
  }
};

export function executePageScript(
  catalystField,
  values,
  executedPSFields,
  allPreOrPostPS,
  executionTime,
  userEntityType,
  issuingField_SetID_OP,
  questionsState,
  initializing,
  pageScriptReports
) {

  //extract executionTime from scripts
  allPreOrPostPS = allPreOrPostPS.reduce(function (result, row) {
    if (row.executionTime === executionTime || row.executionTime === executionTime + "Persist") {
      result.push(row);
    }
    return result;
  }, []);

  let stagedPSFields = [];

  //Step 1: Check allPreOrPostPS contingencies against catalystField's contingentLookupCodes to see if other fields also need updating.
  //note: we'll always be either pre or postmount, hence 'allPreOrPostPS'
  let fieldsWithSameContingency = psUtil.findFieldsWithMatchingContingency(
    allPreOrPostPS,
    catalystField,
    issuingField_SetID_OP
  );

  if (fieldsWithSameContingency && fieldsWithSameContingency.length > 0) {
    forEach(fieldsWithSameContingency, (field) => {
      stagedPSFields.push(field);
    });
  }

  //Step 2: Check if there's other fields with the same appliesToLookupCode - getPSExecutable will only return the
  //first match it finds, so we need to capture the rest of the fields with same ATL, otherwise they'll go ignored
  let fieldsWithSameATL = psUtil.findFieldsWithMatchingATL(allPreOrPostPS, catalystField, issuingField_SetID_OP);

  if (fieldsWithSameATL && fieldsWithSameATL.length > 0) {
    forEach(fieldsWithSameATL, (field) => {
      stagedPSFields.push(field);
    });
  }

  //Step 3: Check if there's other fields with a contingentLookupCode matching the catalyst field's appliesToLookupCode
  let fieldsWithContingencyMatchingATL = psUtil.findFieldsWithContingencyMatchingATL(
    allPreOrPostPS,
    catalystField,
    issuingField_SetID_OP
  );

  if (fieldsWithContingencyMatchingATL && fieldsWithContingencyMatchingATL.length > 0) {
    forEach(fieldsWithContingencyMatchingATL, (field) => {
      stagedPSFields.push(field);
    });
  }

  //Step 4: Add catalystField to complete the build of stagePSFields, clean out any repeating IDs === ready for processing
  stagedPSFields.push(catalystField);

  stagedPSFields = uniqBy(stagedPSFields, function (e) {
    return e.id;
  });

  //Step 5: Execute each field in stagedPSFields array and build updatedPSFields array
  let updatedPSFields = [];

  if (executedPSFields && executedPSFields.length > 0) {
    //if we have executedFields they are already created and coming back in to be updated,
    //assign them to updatedFields instead of being created anew

    updatedPSFields = executedPSFields;
  }

  if (stagedPSFields && stagedPSFields.length > 0) {
    for (let k = 0; k < stagedPSFields.length; k++) {

      let stagedField = stagedPSFields[k];
      let createNewField = true;

      if (!stagedField) {
        continue;
      }

      let index = psUtil.getUpdatedPSFieldIndex(updatedPSFields, stagedField.id, k); //index of staged field in updatedPSFields array

      //check if the field already exists in executedPSFields to know whether to update field vs createNewPSField downstream
      if (updatedPSFields && updatedPSFields.length > 0) {
        for (let l = 0; l < updatedPSFields.length; l++) {
          if (updatedPSFields[l].id === stagedField.id) {
            createNewField = false;
            break;
          }
        }
      }

      let resultHelper = psRules.evaluateRule(
        stagedField,
        values,
        issuingField_SetID_OP,
        questionsState
      );

      if (stagedField.executionTime) {

        let pageScriptReport = {
          stagedField: stagedField,
          result: resultHelper,
        }

        pageScriptReports.push(pageScriptReport);

        //execute the action
        switch (stagedField.action) {
          case enums.psOptions.alertContingentsToValue:
          case enums.psOptions.alertContingentToValues: {

            if (!resultHelper.ruleResult) {
              //always clear on negative test result
              resultHelper.clearValue = true;
              resultHelper.returnedValue = "";
            }

            issueEvaluatedAction(
              enums.psOptions.alert,
              resultHelper.returnedValue,
              updatedPSFields,
              stagedField,
              index,
              createNewField,
              resultHelper.clearValue,
              null,
              resultHelper.errors
            );
            break;
          }

          case enums.psOptions.disable: {

            if (!resultHelper.ruleResult) {
              //always clear on negative test result
              resultHelper.clearValue = true;
              resultHelper.returnedValue = "";
            }

            issueEvaluatedAction(
              enums.psOptions.disable,
              resultHelper.returnedValue,
              updatedPSFields,
              stagedField,
              index,
              createNewField,
              resultHelper.clearValue,
              enums.psOptions.clearDisable,
              resultHelper.errors
            );
            break;
          }

          case enums.psOptions.setClass: {
            //note: on PremInd page, the Go Back button is responsible for clearing the state with a page refresh
            //this way we dont need a million contingencies for all the differ payment hide/show possibilities

            let clearData = false;
            let customClearingValue = null;

            if (!resultHelper.ruleResult) {

              //simple clear class value
              clearData = true;
              customClearingValue = enums.psOptions.clearValue;

              resultHelper.returnedValue = "";
            }

            issueEvaluatedAction(
              stagedField.action,
              resultHelper.returnedValue,
              updatedPSFields,
              stagedField,
              index,
              createNewField,
              clearData,
              customClearingValue,
              resultHelper.errors
            );
            break;
          }

          case enums.psOptions.setLabelValue: {

            if (!resultHelper.ruleResult) {
              resultHelper.clearValue = true;
              resultHelper.returnedValue = "";
            }

            issueEvaluatedAction(
              enums.psOptions.setLabelValue,
              resultHelper.returnedValue,
              updatedPSFields,
              stagedField,
              index,
              createNewField,
              resultHelper.ruleResult,
              null,
              resultHelper.errors
            );
            break;
          }

          case enums.psOptions.setValue:
          case enums.psOptions.setValueDefault:
          case enums.psOptions.setValueAndAlertTotal:
          case enums.psOptions.setValueAndDisable:
          case enums.psOptions.setValueOrExitExecution:
          case enums.psOptions.setValue: {

            if (stagedField.action === enums.psOptions.setValueOrExitExecution && !resultHelper.ruleResult) {
              //some fields are constantly listening for the value of another field
              //and it may not be appropriate to clear their value if their contingentField is empty
              //or returns an invalid result
              continue;
            }

            if (!resultHelper.ruleResult) {
              resultHelper.clearValue = true;
              resultHelper.returnedValue = "";
            }

            if (stagedField.action === enums.psOptions.setValueAndAlertTotal) {
              let total = resultHelper.selectItemValue ? resultHelper.selectItemValue : "0";

              if (!createNewField) {
                editUpdatedPSFields.updatePSObjProperty(updatedPSFields, index, enums.psOptions.alert, resultHelper.returnedValue);
                editUpdatedPSFields.updateValue(updatedPSFields, index, total);
                editUpdatedPSFields.updatePSObjProperty(updatedPSFields, index, enums.psOptions.pristine, false);
              }
              else {
                updatedPSFields.push(createNewPSField(stagedField, total, true, false, enums.psOptions.alert, resultHelper.returnedValue));
              }
            }
            else if (stagedField.action === enums.psOptions.setValueAndDisable) {

              if (!createNewField) {
                editUpdatedPSFields.updatePSObjProperty(updatedPSFields, index, enums.psOptions.disable, resultHelper.returnedValue);
                editUpdatedPSFields.updatePSObjProperty(updatedPSFields, index, enums.psOptions.pristine, false);

                if (resultHelper.returnedValue) {
                  editUpdatedPSFields.updateValue(updatedPSFields, index, resultHelper.selectItemValue);
                  editUpdatedPSFields.updatePSObjProperty(updatedPSFields, index, enums.psOptions.contingentValueReturned, enums.bool.true);
                }
                else {
                  editUpdatedPSFields.updatePSObjProperty(updatedPSFields, index, enums.psOptions.contingentValueReturned, enums.bool.false);
                }
              }
              else {
                updatedPSFields.push(createNewPSField(stagedField, resultHelper.selectItemValue, resultHelper.ruleResult, false, enums.psOptions.disable, resultHelper.returnedValue));
              }

            }
            else {
              issueEvaluatedAction(
                enums.psOptions.value,
                resultHelper.returnedValue,
                updatedPSFields,
                stagedField,
                index,
                createNewField,
                resultHelper.clearValue,
                enums.psOptions.clearValue,
                resultHelper.errors
              );
            }

            break;
          }

          default:
            break;
        }
      }
    }
  }

  //Step 6: Return updated initial 'field' and all other ancillary fields affected by contigent code

  if (initializing) {

    //if initializing separate out the premountPersisted fields converted to postmount
    //else business as usual, we are just concerned about the array of executed fields in one clump
    let initializedPSFields = {
      executedPremountPersistFields: [],
      updatedPSFields
    };

    initializedPSFields.executedPremountPersistFields = filter(updatedPSFields, function (field) {
      return field.persisted;
    });

    return initializedPSFields;
  }
  else {
    return updatedPSFields;
  }
}
