export const clearUserMaintenanceState = "CLEAR_USER_MAINTENANCE_STATE";
export const clearBlacklistedUsers = "CLEAR_BLACKLISTED_USERS";
export const clearUsers = "CLEAR_USERS";
export const closeModals = "CLOSE_MODALS";
export const getAdminSelectedUserSuccess = "GET_ADMIN_SELECTED_USER_SUCCESS";
export const getBlacklistedUsersSuccess = "GET_BLACKLISTED_USERS_SUCCESS";
export const getMGASelectedUserSuccess = "GET_MGA_SELECTED_USER_SUCCESS";
export const getUsersSuccess = "GET_USERS_SUCCESS";
export const getEntityStatusesSuccess = "GET_ENTITY_STATUSES_SUCCESS";
export const launchGlobalProgramsModalSuccess = "LAUNCH_GLOBAL_PROGRAMS_MODAL_SUCCESS";
export const launchUserProgramsModalSuccess = "LAUNCH_PROGRAMS_MODAL_SUCCESS";
export const getUserRolesSuccess = "GET_USER_ROLES_SUCCESS";
export const goBack = "GO_BACK";
export const runReportSuccess = "RUN_REPORT_SUCCESS";
export const setLoadingBlacklistedUsersFlag = "SET_LOADING_BLACKLISTED_USERS_FLAG";
export const setLoadingUsersFlag = "SET_LOADING_USERS_FLAG";
export const saveUserProgramsSuccess = "SAVE_USER_PROGRAMS_SUCCESS";
export const toggleAddUserModal = "TOGGLE_ADD_USER_MODAL";
export const toggleEditBlacklistModal = "TOGGLE_EDIT_BLACKLIST_MODAL";
export const togglePWModal = "TOGGLE_PW_MODAL";
export const toggleRulesModal = "TOGGLE_RULES_MODAL";
export const toggleUNModal = "TOGGLE_UN_MODAL";
export const updateUserEntityConfig = "UPDATE_USER_ENTITY_CONFIG";
export const userProxyUpdateSuccess = "USER_PROXY_UPDATE_SUCCESS";
