import * as userRulesActions from "./UserRulesActionTypes";
import * as helper from "./UserRulesHelper";

export const INITIAL_STATE = {
  underwriterRules: [],
  underwriters: {},
  underwriter: {},
  underwriterNames: [],
  isEditing: false,
  openRulesModal: false,
  underwriterRuleType: "",
  programs: {},
  programNames: [],
};

export default function userRulesReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case userRulesActions.getUnderwriterRulesAndProgramsSuccess:
      state = update(state, {
        underwriterRules: action.rules,
        programs: action.programs,
        underwriters: action.underwriters,
        underwriterNames: helper.setUnderwriterNames(action.underwriters),
        programNames: helper.setProgramNames(action.programs),
      });
      break;
    case userRulesActions.saveUnderwriterRuleSuccess:
      //update our underwriters
      state = update(state, {
        isEditing: false,
        openRulesModal: false,
        underwriter: {},
        underwriterRules: action.updatedUWRules,
      });
      break;
    case userRulesActions.editUnderwriterRule:
      state = update(state, {
        isEditing: true,
        openRulesModal: true,
        underwriter: action.underwriter,
      });
      break;
    case userRulesActions.cancelEditUnderwriterRule:
      state = update(state, {
        isEditing: false,
        openRulesModal: false,
        underwriter: {},
      });
      break;
    case userRulesActions.deleteUnderwriterRuleSuccess:
      //remove from the array and update underwriters
      state = update(state, {
        underwriterRules: helper.removeUnderwriterRule(action.underwriterRules, action.underwriter),
      });
      break;
    case userRulesActions.addUnderwriterRule:
      state = update(state, {
        isEditing: false,
        openRulesModal: true,
        underwriter: helper.setNewUWRInitialValues(state.underwriterNames, state.programNames),
      });
      break;
    default:
      /* do nothing */
      break;
  }
  return state;
}
