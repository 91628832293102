import { useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";

const PolicyDocuments = (props) => {
  const [open, toggleCollapse] = useState(false);

  if (props.openComponent && props.openComponent !== "policyDocuments" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("policyDocuments");
  }

  return (
    <div id="policy_documents_main_div_1">
      <div
        id="policy_documents_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="poldocRow"
        aria-expanded={open}
      >
        Where are my Policy Documents?
      </div>
      <Collapse in={open}>
        <div id="policy_documents_div_3">
          <Row id="policy_documents_row_1">
            <Col id="policy_documents_col_1" xs={"12 mt-15 bold"}>
              <hr />
              <div id="policy_documents_div_4">
                Check your Email
              </div>
            </Col>
            <Col id="policy_documents_col_2" xs={"12 mt-15"}>
              Policy documents and certificates are emailed after purchasing a policy to the email address on file.
              Make sure your documents aren’t in your Spam or Junk folder.
            </Col>
          </Row>
          <Row id="policy_documents_row_2">
            <Col id="policy_documents_col_3" xs={"12 mt-15 bold"}>
              <div id="policy_documents_div_5">
                Reprint your policy documents
              </div>
            </Col>
            <Col id="policy_documents_col_4" xs={"12 mt-15"}>
              You can reprint policy documents using our online policy management system. Please log into your online portal and click the View Quotes {"&"} Policies button
              and locate the policy you are needing. In the Manage column, click the Manage Policy button and then select to View {"&"} Email Documents.
              You will need to select the row for the documents you need. Select the documents you would like to view and click View Selected Documents.
              Your documents are PDFs that you can either save to your computer or print. You also have the option to email your documents directly from this screen.
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default PolicyDocuments;
