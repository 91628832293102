import { Button, Card, Col, Row } from "react-bootstrap";
import { PropTypes } from "prop-types";
import ErrorBoundary from "../../../../../Errors/ErrorBoundary/ErrorBoundary";
import enums from "../../../../../Utilities/Enums/Enums";

const RepeatingCardSingle = (props) => {
  const role = props.user.entityTypeCode;

  const addNewRepeatingCardToSet = (parentCardLookup, formValues, cardToCopy) => {
    props.addNewRepeatingCardToSet(parentCardLookup, formValues, cardToCopy);
  };

  const editRepeatingCard = (formValues, card, cardSet) => {
    props.editRepeatingCardBundle(formValues, card, cardSet);
  };

  const openCardEmailModal = () => {
    props.openCardEmailModal(true);
  };

  let onCardBody;
  let onCardFooter;
  let cardItems;
  let cardDates;
  // let cardIncompleteDataItems;
  let i = 0;
  let isPastDate = false;

  const strapDisplayItem = (displayItem, i, isPastDate, isDate) => {
    let addtlClass = "";
    if (isDate) {
      addtlClass = " cardDate";
    }
    return (
      <Row key={displayItem + i} className={isPastDate && i === props.cardDatesIndex ? "text-danger" + addtlClass : addtlClass}>
        <Col>{displayItem}</Col>
      </Row>
    );
  };

  // const strapIncompleteDataDisplayItem = (displayItem, i) => {
  //   return (
  //     <li key={displayItem + i} className={"text-danger"}>{displayItem}</li>
  //   );
  // };

  if (props.endDate) {
    if (new Date(props.endDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
      isPastDate = true;
    }
  }
  if (props.display) {
    cardItems = props.display.reduce(function (result, displayItem) {
      result.push(strapDisplayItem(displayItem, i, isPastDate, false));
      i++;
      return result;
    }, []);

    if (props.cardDates) {
      cardDates = strapDisplayItem(props.cardDates, i, isPastDate, true);
    }

    // if (props.displayIncompleteItems) {
    //   cardIncompleteDataItems = props.displayIncompleteItems.reduce(function (result, displayIncompleteItem) {
    //     result.push(strapIncompleteDataDisplayItem(displayIncompleteItem, i));
    //     i++;
    //     return result;
    //   }, []);
    // }

    onCardBody = (
      <>
        <Card.Title id="repeating_card_single_card_title_1" className={"border-0"}>
          <Row id="repeating_card_single_card_title_row_1">
            <Col id="repeating_card_single_card_title_col_1" xs={"2"}>
              {props.invalidItemCount === 0 && (
                <>
                  <i className={"fa fa-check checkmark_green"} aria-hidden="true"></i>
                </>
              )}
            </Col>
            <Col id="repeating_card_single_card_title_col_2" xs={"10"}>
              {props.insuredName ? props.insuredName : null}
            </Col>
          </Row>
        </Card.Title>
        <div id="repeating_card_single_div_5">
          <div id="repeating_card_single_div_6" className="rCardDisplayWrapper">
            <div id="repeating_card_single_div_7" className={"dataViolationWarning"}>
              <div id="repeating_card_single_div_8">
                {props.invalidItemCount > 0 && (
                  <>
                    <i className={"fa fa-exclamation-triangle error-msg mr-10"} aria-hidden="true"></i>
                    Please fill out the <b>{props.invalidItemCount}</b> incomplete or invalid fields in this item to
                    complete your entry.
                  </>
                )}
              </div>
              {/* <div id="repeating_card_single_div_9">
                <ul id="repeating_card_single_ul_1" className="repeating_card_ul_item">
                  {cardIncompleteDataItems}
                </ul>
              </div> */}
            </div>
            {props.dateViolationMarkup && (
              <div id="repeating_card_single_div_10" className={"dateViolationWarning"}>
                <i className={"fa fa-exclamation-triangle"} aria-hidden="true"></i>
                Error: The policy effective date may not be later than the {props.dynamicPageNameForUserFeedback}'s
                effective start date. Please adjust the conflicting insured start date{" "}
                {"(" +
                  props.startDate +
                  ") to be greater than or equal to the policy effective date (" +
                  props.policyEffectiveDate +
                  ")."}
              </div>
            )}
            {!props.dataViolationMarkup ? cardItems : null}
            {!props.dataViolationMarkup ? cardDates : null}
            {props.card.allowViewPrintCertificate && (
              <Row id="repeating_card_single_row_2" className={"certs"}>
                <Col id="repeating_card_single_col_4">
                  <i className={"fa fa-print"} aria-hidden="true" />
                  <span id="repeating_card_single_span_2" className={"action"}>
                    View/Print Certificate
                  </span>
                </Col>
                <Col id="repeating_card_single_col_5">
                  <i className={"fa fa-paper-plane-o"} aria-hidden="true" />
                  <span id="repeating_card_single_span_3" className={"action"} onClick={() => openCardEmailModal()}>
                    Email Certificate
                  </span>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </>
    );

    onCardFooter = (
      <>
        <div id="repeating_card_single_row_3" className="d-flex align-items-bottom">
          <Col id="repeating_card_single_col_6" className="text-center mt-15">
            <Row id="repeating_card_single_row_4">
              <Col id="repeating_card_single_col_7">
                <Button
                  id="repeating_card_single_btn_1"
                  key={"b1"}
                  outline={"true"}
                  variant={"outline-dark"}
                  size={"sm"}
                  className={"w-100 "}
                  onClick={() => addNewRepeatingCardToSet(props.cardSet.lookupCode, props.formValues, props.card)}
                >
                  Copy
                </Button>
              </Col>
              {props.card.allowEditRemove && (
                <Col id="repeating_card_single_col_8">
                  <Button
                    id="repeating_card_single_btn_2"
                    key={"b2"}
                    color="primary"
                    size={"sm"}
                    className={"w-100 "}
                    onClick={() => editRepeatingCard(props.formValues, props.card, props.cardSet)}
                  >
                    Edit
                  </Button>
                </Col>
              )}
            </Row>
            <Row id="repeating_card_single_row_5">
              {props.card.allowHideUnhideCardsPermissioned && (
                <Col id="repeating_card_single_col_9" className={"text-right d-inline-flex align-items-center mt-15"}>
                  <Button
                    id="repeating_card_single_btn_3"
                    key={"b2"}
                    variant={"outline-secondary"}
                    size={"sm"}
                    className={"w-100 hide_rcard_button"}
                    onClick={() => props.toggleHideCard(props.card, props.cardSet)}
                  >
                    {props.card.isHidden === true ? "UNHIDE" : "HIDE"}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </div>
      </>
    );
  }

  return (
    <div
      id="repeating_card_single_main_div_1"
      className={"card repeating_card " + props.dateViolationMarkup + " " + props.dataViolationMarkup}
    >
      <Card id="repeating_card_single_card_1" key={props.ordinalPos}>
        <Row id="repeating_card_single_row_0">
          <Col id="repeating_card_single_col_0">
            <div id="repeating_card_single_div_1">{props.ordinalPos}</div>
          </Col>
          {props.card.allowHideUnhideCards && (
            <Col
              id="repeating_card_single_col_1"
              onClick={() => props.toggleHideCard(props.card, props.cardSet)}
              className={"text-right d-inline-flex justify-content-end align-items-center"}
            >
              <div id="repeating_card_single_div_3">{props.card.isHidden === true ? "UNHIDE" : "HIDE"}</div>
              <div id="repeating_card_single_div_4">
                {props.card.isHidden && (
                  <i className="fa fa-eye" aria-hidden="true"></i>
                )}
                {!props.card.isHidden && (
                  <i className="fa fa-eye-slash" aria-hidden="true"></i>
                )}
              </div>
            </Col>
          )}
          {(props.card.allowEditRemove || role === enums.role.admin || role === enums.role.mga) && (
            <Col
              id="repeating_card_single_col_2"
              onClick={() => props.deleteCardBundle(props.ordinalPos)}
              className={"text-right hideControl d-inline-flex justify-content-end align-items-start"}
            >
              <div id="repeating_card_single_div_5">{"DELETE"}</div>
              <div id="repeating_card_single_div_6">
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </Col>
          )}
        </Row>
        <hr />
        <Card.Body id="repeating_card_single_card_body_1" className="repeating_card_body">
          <ErrorBoundary>{onCardBody}</ErrorBoundary>
        </Card.Body>
        <Card.Footer id="repeating_card_single_card_footer_1">
          <ErrorBoundary>{onCardFooter}</ErrorBoundary>
        </Card.Footer>
      </Card>
    </div>
  );
};

RepeatingCardSingle.propTypes = {
  card: PropTypes.object.isRequired,
  cardDatesIndex: PropTypes.number,
  addNewRepeatingCardToSet: PropTypes.func.isRequired,
  deleteCardBundle: PropTypes.func.isRequired,
  display: PropTypes.array,
  endDate: PropTypes.string,
  formValues: PropTypes.object.isRequired,
  isEndorsed: PropTypes.string.isRequired,
  openCardEmailModal: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  toggleHideCard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default RepeatingCardSingle;
