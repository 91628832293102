import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Collapse, Col, Row } from "react-bootstrap";
import * as appActions from "../../../../App/AppActions";
import * as headerActions from "../../../../Header/HeaderActions";
import enums from "../../../../Utilities/Enums/Enums";

const AdminNav = (props) => {
  //store
  const app = useSelector((state) => state.app);
  const [open, toggleCollapse] = useState(false);

  //dispatch actions
  const dispatch = useDispatch();

  //outbound
  const toggleBlur = useCallback(() => dispatch(appActions.toggleBlur()), [dispatch]);
  const toggleSideMenu = useCallback(() => dispatch(headerActions.toggleSideMenu()), [dispatch]);


  if (props.openComponent && props.openComponent !== "adminTasks" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("adminTasks");
  }

  const toggleSideMenuBundle = () => {
    toggleBlur();
    toggleSideMenu();
  };

  return (
    <div id="admin_nav_main_div_1">
      <div
        id="admin_nav_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="adminTasksRow"
        aria-expanded={open}
        className={"sectionHeader"}
      >
        RVNA Admin Tasks
      </div>
      <Collapse in={open}>
        <div id="admin_nav_div_3">
          <Row id="admin_nav_row_1">
            <Col id="admin_nav_col_1">
              <Link to="/usermaintenance" id="admin_nav_link_1" onClick={() => toggleSideMenuBundle()}>
                User Maintenance
              </Link>
            </Col>
          </Row>
          <Row id="admin_nav_row_2">
            <Col id="admin_nav_col_2">
              <hr />
            </Col>
          </Row>
          {app.user.entityTypeCode === enums.role.mga && (
            <Row id="admin_nav_row_3">
              <Col id="admin_nav_col_3">
                <Link to="/userrules" id="admin_nav_link_2" onClick={() => toggleSideMenuBundle()}>
                  Underwriting Rules
                </Link>
              </Col>
            </Row>
          )}
          {app.user.entityTypeCode === enums.role.mga && (
            <Row id="admin_nav_row_4">
              <Col id="admin_nav_col_4">
                <hr />
              </Col>
            </Row>
          )}
          <Row id="admin_nav_row_5">
            <Col id="admin_nav_col_5">
              <Link to="/reports" id="admin_nav_link_3" onClick={() => toggleSideMenuBundle()}>
                Reports
              </Link>
            </Col>
          </Row>
          <Row id="admin_nav_row_6">
            <Col id="admin_nav_col_6">
              <hr />
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>

  );
};

export default AdminNav;
