import * as policyActions from "./PolicyActionTypes";
import * as policyHelper from "./PolicyHelper";
import { cloneDeep, forEach } from "lodash";

export const INITIAL_STATE = {
  approvalPending: false,
  approvedGroupStatus: false,
  currentRatingSubmission: {},
  currentUWSubmission: {},
  dModal: false,
  documentFormInitialValues: {
    recipientEmail: "",
    message: "",
    eligibleDocsToShow: {},
  },
  documentList: [],
  failedDocRetrievalSubmissionID: "",
  loadingDocumentList: false,
  loadingFullPage: false,
  loadingRepeatingItemList: false,
  pModal: false,
  policiesFromQuery: [],
  policiesFromDocumentQuery: [],
  policiesAreLoaded: false,
  policyHistory: [],
  allUserPolicies: [],
  policySetLoaded: false,
  uModal: false,
  ratedata: "",
  refreshPolicies: false,
  ridModal: false,
  repeatingItemDocumentList: {},
  repeatingItemText: "",
  repeatingItemsFromDocumentQuery: [],
  repeatingItemsFormInitialValues: {
    recipientEmail: "",
    message: "",
    eligibleDocsToShow: {},
  },
  responses: [],
  responsesLoaded: false,
  rModal: false,
  showSelectAll: true,
  submission: {},
  validationHistory: [],
};

export default function policyReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case policyActions.clearDocumentList:
      state = update(state, {
        documentList: [],
      });
      break;
    case policyActions.clearRepeatingItemDocumentList:
      state = update(state, {
        repeatingItemDocumentList: {},
        repeatingItemsFromDocumentQuery: [],
        repeatingItemText: "",
        loadingRepeatingItemList: false
      });
      break;
    case policyActions.clearPolicyState:
      state = INITIAL_STATE;
      break;
    case policyActions.togglePolicyModal:
      state = update(state, {
        pModal: !state.pModal,
        submission: action.submission,
        documentList: []
      });
      break;
    case policyActions.toggleUnderwritingModal:
      state = update(state, { pModal: false, uModal: !state.uModal });
      break;
    case policyActions.toggleRatingModal:
      state = update(state, { pModal: false, rModal: !state.rModal });
      break;
    case policyActions.toggleDocsModal:
      state = update(state, { pModal: false, dModal: !state.dModal });
      break;
    case policyActions.toggleRepeatingItemDocsModal:
      state = update(state, { pModal: false, ridModal: !state.ridModal });
      break;
    case policyActions.openPolicyModal:
      state = update(state, {
        uModal: false,
        rModal: false,
        dModal: false,
        ridModal: false,
        pModal: !state.pModal,
      });
      break;
    case policyActions.toggleSelectAllDocuments:
      state = update(state, { showSelectAll: !state.showSelectAll });
      break;
    case policyActions.toggleLoadingDocumentList:
      state = update(state, {
        loadingDocumentList: !state.loadingDocumentList,
      });
      break;
    case policyActions.toggleLoadingRepeatingItemList:
      state = update(state, {
        loadingRepeatingItemList: !state.loadingRepeatingItemList,
      });
      break;
    case policyActions.togglePolicySetLoading:
      state = update(state, {
        policySetLoaded: false
      });
      break;
    case policyActions.getPoliciesFromQuerySuccess:

      if (action.deliverTo === "DocumentModal") {
        state = update(state, {
          policiesFromDocumentQuery: action.policiesFromQuery,
          policySetLoaded: true,
        });
      }
      else {
        state = update(state, {
          policiesFromQuery: action.policiesFromQuery,
          policySetLoaded: true,
        });
      }
      break;
    case policyActions.getRepeatingItemsFromQuerySuccess:
      let repeatingItemText = "Additional Insured";
      if (action.repeatingItemName.includes("Mortgagee")) {
        repeatingItemText = "Mortgagee";
      }
      else if (action.repeatingItemName.includes("LossPayee")) {
        repeatingItemText = "Loss Payee";
      }
      let repeatingItemFormValClone = cloneDeep(INITIAL_STATE.repeatingItemsFormInitialValues);
      //need to repeat documents on the inital values level for formik to be able to see it
      let newInitialRepeatingItemFormValues = policyHelper.addToInitialRepeatingItemValues(action.repeatingItemDocsFromQuery, repeatingItemFormValClone);
      //and this eligibleDocsToShow obj is unpolluted by other initialValues when we submit values for getPDFs
      newInitialRepeatingItemFormValues.eligibleDocsToShow = policyHelper.addToInitialRepeatingItemValues(action.repeatingItemDocsFromQuery, {});

      state = update(state, {
        repeatingItemsFromDocumentQuery: action.repeatingItemDocsFromQuery,
        repeatingItemText: repeatingItemText,
        repeatingItemDocumentList: {
          submissionID: action.submissionID,
          documents: repeatingItemFormValClone,
        },
        repeatingItemsFormInitialValues: newInitialRepeatingItemFormValues,
      });
      break;
    case policyActions.getAllSubmissionsSuccess:
      state = update(state, {
        allUserPolicies: action.submissions,
        policiesAreLoaded: true,
        queryResultMsg: action.queryResultMsg,
      });
      break;
    case policyActions.getSubmissionDataSuccess:
      state = update(state, {
        responses: action.responses,
        responsesLoaded: true,
      });
      break;
    case policyActions.updateChangedPolicy:
      let queriedPoliciesCloned = cloneDeep(state.policiesFromQuery);
      forEach(queriedPoliciesCloned, (policy) => {
        if (action.submissionId === policy.submissionID) {
          policy.brokerFirm = action.newUser.entityName;
          policy.ownerEmail = action.newUser.userName;
          return false;
        }
      });
      state = update(state, {
        policiesFromQuery: queriedPoliciesCloned,
        refreshPolicies: true
      });
      break;
    case policyActions.updatePolicyDate:
      let queriedPoliciesClone = cloneDeep(state.policiesFromQuery);
      forEach(queriedPoliciesClone, (policy) => {
        if (action.submissionId === policy.submissionID) {
          policy.effectiveDate = action.submission.effectiveDate;
        }
      });
      state = update(state, {
        policiesFromQuery: queriedPoliciesClone,
        refreshPolicies: true
      });
    case policyActions.getRatingDataSuccess:
      state = update(state, {
        ratedata: action.ratedata,
      });
      break;
    case policyActions.getStatusHistorySuccess:
      state = update(state, {
        policyHistory: action.policyHistory,
      });
      break;
    case policyActions.getValidationHistorySuccess:
      state = update(state, {
        validationHistory: action.validationHistory,
      });
      break;
    case policyActions.getDocumentListSuccess:
      let docFormValClone = cloneDeep(INITIAL_STATE.documentFormInitialValues);
      let underscoreDocuments = policyHelper.underscoreDocuments(action.documents);
      //need to repeat documents on the inital values level for formik to be able to see it
      let newInitialDocFormValues = policyHelper.addToInitialValues(action.documents, docFormValClone);
      //and this eligibleDocsToShow obj is unpolluted by other initialValues when we submit values for getPDFs
      newInitialDocFormValues.eligibleDocsToShow = policyHelper.addToInitialValues(action.documents, {});
      const newDocListEntry = {
        submissionID: action.submissionID,
        documents: underscoreDocuments
      }
      const docListClone = cloneDeep(state.documentList);
      let updatedDocList = docListClone;
      const existingEntry = docListClone.find((entry) => entry.submissionID === action.submissionID);
      if (!existingEntry) {
        updatedDocList = docListClone.concat([newDocListEntry]);
      }

      state = update(state, {
        documentList: updatedDocList,
        documentFormInitialValues: newInitialDocFormValues,
      });
      break;
    case policyActions.setApprovalPendingStatus:
      state = update(state, {
        approvalPending: action.status,
      });
      break;
    case policyActions.setCurrentRatingSubmission:
      state = update(state, {
        currentRatingSubmission: action.submission,
      });
      break;
    case policyActions.setCurrentUWSubmission:
      state = update(state, {
        currentUWSubmission: action.submission,
      });
      break;
    case policyActions.setApprovedGroupStatus:
      state = update(state, {
        approvedGroupStatus: action.status,
      });
      break;
    case policyActions.setFailedDocRetrievalSubmissionID:
      state = update(state, {
        failedDocRetrievalSubmissionID: action.submissionID,
      });
      break;
    case policyActions.setRefreshPolicies:
      state = update(state, {
        refreshPolicies: action.refreshPolicies,
      });
      break;
    case policyActions.toggleFullPageLoader:
      state = update(state, {
        loadingFullPage: !state.loadingFullPage,
      });
      break;
    default:
      break;
  }
  return state;
}
