import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import FAQModal from "../Utilities/Modals/FAQModal/FAQModal";
import AdminNav from "./SideMenuViews/LoggedIn/Admin/AdminNav";
import ContactUs from "./SideMenuViews/LoggedIn/ContactUs";
import Products from "./SideMenuViews/LoggedIn/Products/Products";
import enums from "../Utilities/Enums/Enums";
import * as appActions from "../App/AppActions";
import * as headerActions from "../Header/HeaderActions";
import * as sideMenuActions from "./SideMenuActions";

const SideMenu = () => {
  //store
  const app = useSelector((state) => state.app);
  const header = useSelector((state) => state.header);

  //dispatch actions
  const dispatch = useDispatch();

  //states
  const [revealClass, toggleRevealClass] = useState("");
  const [openComponentName, setOpenComponent] = useState("");
  let classesToAdd = "navbar-side navbar-user navHeight";
  let latestClassesToAdd = useRef(classesToAdd); //avoids rerender of classes to add on didUpdate

  let setOpenComponentCallback = (componentName) => {
    setOpenComponent(componentName);
  }

  //outbound
  const toggleBlur = useCallback(() => dispatch(appActions.toggleBlur()), [dispatch]);
  const toggleSideMenu = useCallback(() => dispatch(headerActions.toggleSideMenu()), [dispatch]);
  const toggleFAQModal = useCallback(() => dispatch(sideMenuActions.toggleFAQModal()), [dispatch]);

  const toggleSideMenuBundle = () => {
    toggleBlur();
    toggleSideMenu();
    //reset blink to off
  };

  const toggleFAQModalBundle = () => {
    toggleSideMenuBundle()
    toggleFAQModal();
  };

  //effects
  useEffect(() => {
    if (header.sideMenuOpen) {
      if (app.user.entityTypeCode === enums.role.admin) {
        latestClassesToAdd.current += "navHeightAdmin";
      }
      toggleRevealClass(" reveal");
    }
    else {
      toggleRevealClass("");
    }
  }, [app.user.entityTypeCode, header.products, header.sideMenuOpen]);

  return (
    <div id="side_menu_main_div_1" className={latestClassesToAdd.current + revealClass}>
      <div id="side_menu_div_2" className="h-100 grid">
        <div id="side_menu_div_3">
          <div id="side_menu_div_4" className={"sideMenuContent"}>
            {/* {app.isLoggedIn && (
                <div>
                  <Row>
                    <Col>{app.userName}</Col>
                    <Col className="text-right">
                      {app.user.entityTypeCode !== enums.role.unknown && (
                        <Link to="/usermaintenance" onClick={() => toggleSideNavBundle()}>
                          Edit Account
                        </Link>
                      )}
                    </Col>
                  </Row>
                  <hr />
                </div>
              )} */}
            {/* {app.user.entityTypeCode &&
                app.user.entityTypeCode !== enums.role.admin &&
                app.user.entityTypeCode !== enums.role.mga && ( */}
            {(app.user.entityTypeCode !== enums.role.admin && app.user.entityTypeCode !== enums.role.mga) && (
              <>
                <Row id="side_menu_row_1">
                  <Col id="side_menu_col_1">
                    <Link id="side_menu_link_1" to="#" className={"sectionHeader"}>
                      <Products openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                    </Link>
                  </Col>
                </Row>
                <Row id="side_menu_row_2">
                  <Col id="side_menu_col_2">
                    <hr />
                  </Col>
                </Row>
              </>
            )}
            <Row id="side_menu_row_3">
              <Col id="side_menu_col_3">
                <h5 id="side_menu_header_1">
                  <Link id="side_menu_link_2" to="/policylist" className={"sectionHeader"} onClick={() => toggleSideMenuBundle()}>
                    View Quotes/Policies
                  </Link>
                </h5>
              </Col>
            </Row>
            <Row id="side_menu_row_4">
              <Col id="side_menu_col_4">
                <hr />
              </Col>
            </Row>
            <Row id="side_menu_row_5">
              <Col id="side_menu_col_5">
                <h5 id="side_menu_header_2">
                  <Link id="side_menu_link_3" to="#" className={"sectionHeader"}>
                    <ContactUs openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                  </Link>
                </h5>
              </Col>
            </Row>
            <Row id="side_menu_row_6">
              <Col id="side_menu_col_6">
                <hr />
              </Col>
            </Row>
            <Row id="side_menu_row_7">
              <Col id="side_menu_col_7">
                <h5 id="side_menu_header_3">
                  <Link id="side_menu_link_4" to="#" className={"sectionHeader"} onClick={() => toggleFAQModalBundle()}>
                    FAQs
                  </Link>
                </h5>
              </Col>
            </Row>
            <Row id="side_menu_row_8">
              <Col id="side_menu_col_8">
                <hr />
              </Col>
            </Row>

            {(app.user.entityTypeCode === enums.role.admin || app.user.entityTypeCode === enums.role.mga) && (
              <>
                <Row id="side_menu_row_9">
                  <Col id="side_menu_col_9">
                    <h5 id="side_menu_header_4">
                      <div id="side_menu_div_5" to="#" className={"sectionHeader"}>
                        <AdminNav openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                      </div>
                    </h5>
                  </Col>
                </Row>
              </>
            )}

            {/* )} */}
            {/* {!app.isLoggedIn && <UnknownUser />}
            {app.user.entityTypeCode !== enums.role.admin &&
              app.user.entityTypeCode !== enums.role.mga && <QuickLinks />} */}
          </div>
          <div id="side_menu_div_5" className={"sideClose"}>
            <div id="side_menu_div_6" className="d-flex justify-content-center">
              <span id="side_menu_span_1" className={"closeIcon"} onClick={() => toggleSideMenuBundle()}>
                X
                {/* <i className="fa fa-times-thin" aria-hidden="true"></i> */}
              </span>
            </div>
          </div>
        </div>
      </div>
      <FAQModal />
    </div>
  );
};

export default SideMenu;
