import * as sideMenuActions from "./SideMenuActionTypes";
import * as axiosApi from "../axiosApi";
import * as appActions from "../App/AppActions";
import * as headerActions from "../Header/HeaderActions";
import enums from "../Utilities//Enums/Enums";

export function createCallbackRequestSuccess(successMsg) {
  return { type: sideMenuActions.createCallbackRequestSuccess, successMsg };
}

export function createCallbackRequest(vm) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.post("/api/submission/requestCallback", vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(
          appActions.reportToast(
            "Callback request failed. Please call our office at 1-800-364-2433.",
            enums.toastTypes.error
          )
        );
        // dispatch(appActions.logError("SideMenuActions - createCallbackRequest", result, false));
      }
      else {
        dispatch(createCallbackRequestSuccess(result.data.message));
      }
    }
    catch (error) {
      dispatch(
        appActions.reportToast(
          "Callback request failed. Please call our office at 1-800-364-2433.",
          enums.toastTypes.error
        )
      );
      dispatch(appActions.logError("SideMenuActions - createCallbackRequest", error, false));
    }
    finally {
      if (!window.location.href.includes("/questions")) {
        dispatch(appActions.toggleBlur());
        dispatch(headerActions.toggleSideMenu());
      }
    }
  };
}

export function toggleFAQModal() {
  return { type: sideMenuActions.toggleFAQModal };
}
