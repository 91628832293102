const bool = Object.freeze({ true: "TRUE", false: "FALSE" });

const mobile = Object.freeze({
  maxWidth: 992,
});

const fieldTypes = Object.freeze({
  alert: "alert",
  button: "button",
  checkbox: "checkbox",
  combo: "combo",
  comboItem: "comboItem",
  comboSearch: "combosearch",
  date: "date",
  header: "header",
  hidden: "hidden",
  image: "image",
  label: "label",
  labelDirty: "labelDirty",
  labelPristine: "labelPristine",
  link: "link",
  list: "list",
  listItem: "listItem",
  money: "money",
  password: "password",
  percent: "percent",
  radio: "radio",
  select: "select",
  textarea: "textarea",
  text: "text",
  typeahead: "typeahead",
});

const labelLocation = Object.freeze({
  bottom: "bottom",
  left: "left",
  right: "right",
  top: "top",
});

const lookupClasses = Object.freeze({
  existingAIs: "ExistingAIs",
  facilityAIDataOnly: "FacilityAIDataOnly",
  facilityAndAIData: "FacilityAndAIData",
});

const setTypes = Object.freeze({
  cardRepeating: "cardrepeating",
  cardRepeatingSet: "cardrepeatingset",
  cardExpanding: "cardexpanding",
  cardExpandingSet: "cardexpandingset",
  inlineBlockSet: "inlineblockset",
});

const cardDisplayTemplates = Object.freeze({
  additionalInsured: "additionalInsured",
  default: "default",
});

const configItems = Object.freeze({
  offersSurplusLine: "OffersSurplusLine",
  offersWorkersComp: "OffersWorkersComp",
  useGooglePlaces: "UseGooglePlaces",
  useQuickQuoteLink: "UseQuickQuoteLink",
  useNoQuoteQR: "UseNoQuoteQR",
  useRepeatingSection: "UseRepeatingPolicySection"
});

const configEntityItems = Object.freeze({
  useDebugMode: "UseDebugMode",
});

const configEntityDescriptions = Object.freeze({
  useDebugMode: "Allows user access to special debugging features.",
});

const buttonFunctions = Object.freeze({
  initPremFinance: "InitPremFinance",
  setSubpage: "SetSubpage",
  showPremFinancePDF: "ShowPremFinancePDF",
});

const linkFunctions = Object.freeze({
  createSubmission: "CreateSubmission",
  getPolicyDocumentPackage: "GetPolicyDocumentPackage",
  setDynamicModal: "SetDynamicModal",
  showPremFinancePDF: "ShowPremFinancePDF",
});

const breakpointsMin = Object.freeze({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
});

const environments = Object.freeze({
  development: "development",
  production: "production",
  test: "test",
});

const psExecute = Object.freeze({
  premount: "premount",
  premountPersist: "premountPersist",
  postmount: "postmount",
});

const role = Object.freeze({
  admin: "admin",
  broker: "broker",
  callrep: "callrep",
  carrier: "carrier",
  direct: "direct",
  mga: "mga",
  underwriter: "underwriter",
  unknown: "unknown",
});

const statusInternal = Object.freeze({
  approved: "APPROVED",
  autobind: "AUTOBIND",
  cancel: "CANCEL",
  declined: "DECLINED",
  endorse: "ENDORSE",
  failedValid: "FAILEDVALID",
  incomplete: "INCOMPLETE",
  locked: "LOCKED",
  more: "MORE",
  passedValid: "PASSEDVALID",
  quoted: "QUOTED",
  quoteFail: "QUOTEFAIL",
  quoteInc: "QUOTEINC",
  reinstate: "REINSTATE",
  renew: "RENEW",
  review: "REVIEW",
  subIncomplete: "SUBINCOMPLETE",
  submitted: "SUBMITTED",
});

const statusExternal = Object.freeze({
  expired: "Expired"
});

const policyListTableDataTypes = Object.freeze({
  policiesFromQuery: "policiesFromQuery",
  allUserPolicies: "allUserPolicies"
});

const programThemes = Object.freeze({
  ACC: "ACC",
  DICE: "DICE",
  DJ: "DJ",
  DO: "DO",
  DSPP: "DSPP",
  EVT: "EVT",
  FDC: "FDC",
  HIO: "HIO",
  LPL: "LPL",
  NPO: "NPO",
  PHVD: "PHVD",
  PPS: "PPS",
  PTA: "PTA",
  RAIN: "RAIN",
  REAB: "REAB",
  REI: "REI",
  SE: "SE",
  UAV: "UAV",
  WED: "WED",
  DEFAULT: "DEFAULT",
});

const psOptions = Object.freeze({
  //actions
  alertContingentsToValue: "alertContingentsToValue",
  alertContingentToValues: "alertContingentToValues",
  disable: "disable",
  setClass: "setClass",
  setValueInField: "setValueInField",
  setLabelValue: "setLabelValue",
  setValue: "setValue",
  setValueAndAlertTotal: "setValueAndAlertTotal",
  setValueAndDisable: "setValueAndDisable",
  setValueOrExitExecution: "setValueOrExitExecution",
  setValueDefault: "setValueDefault",

  //rules
  contingentValueReturned: "contingentValueReturned",

  //properties
  value: "value",

  //results
  alert: "alert",
  clearDisable: "clearDisable",
  clearValue: "clearValue",
  pristine: "pristine",

  //storage location of data
  questions: "questions",

  //values
  false: "false",
  localDateString: "localDateString",
  none: "none",
  today: "today",
  true: "true",
});

const serverResponse = Object.freeze({
  error: "ERROR",
  success: "SUCCESS"
});

const toastTypes = Object.freeze({
  error: "error",
  success: "success",
  warn: "warn",
});

//combine all on this obj
const enums = {
  bool: bool,
  breakpointsMin: breakpointsMin,
  buttonFunctions: buttonFunctions,
  cardDisplayTemplates: cardDisplayTemplates,
  configEntityDescriptions: configEntityDescriptions,
  configEntityItems: configEntityItems,
  configItems: configItems,
  environments: environments,
  fieldTypes: fieldTypes,
  labelLocation: labelLocation,
  linkFunctions: linkFunctions,
  lookupClasses: lookupClasses,
  mobile: mobile,
  policyListTableDataTypes: policyListTableDataTypes,
  programThemes: programThemes,
  psExecute: psExecute,
  psOptions: psOptions,
  role: role,
  serverResponse: serverResponse,
  setTypes: setTypes,
  statusInternal: statusInternal,
  statusExternal: statusExternal,
  toastTypes: toastTypes,
};

export default enums;
