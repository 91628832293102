import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Button } from "react-bootstrap";
import { forEach } from "lodash";
import enums from "../../Enums/Enums";
import ps from "../../Functions/PageScript";
import * as appActions from "../../../App/AppActions";
import * as axiosApi from "../../../axiosApi";

const Buttons = (props) => {
  const {
    clearForm,
    clearPropertyValueFromPSField,
    callbackFunction,
    disabled,
    field: { name },
    form: { touched, setFieldTouched, setFieldValue, values, resetForm },
    id,
    input_css,
    label,
    setFormValueCallback,
    setValueParentOrLocal
  } = props;

  //store
  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);

  //dispatch
  const dispatch = useDispatch();

  //pageScript
  const normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let psPremount, psExecutePostmount, psPostmountExecutedFields;
  let psReady = {};

  //actions
  const reportToast = useCallback((msg) => dispatch(appActions.reportToast(msg, enums.toastTypes.success)), [dispatch]);
  const reportToastError = useCallback((msg) => dispatch(appActions.reportToast(msg, enums.toastTypes.error)), [dispatch]);


  if (isQuestionsContext) {
    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, values[name]);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, values[name]);
      if (setValueParentOrLocal && (psReady.value || psReady.value !== undefined) && psReady.value !== values[name]) {
        if (psReady.value === "clearValue") {
          clearPropertyValueFromPSField(psReady.id, "value");
          setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
        }
        else {
          setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
        }
      }
    }
  }

  const clearFormOnClick = (clearForm, resetForm) => {
    resetForm();
  };

  const handleBlur = () => {
    setFieldTouched(name, false);
  };

  const handleChange = () => {
    setFieldTouched(name, true);
    const funcList = callbackFunction.split(";");

    forEach(funcList, (f) => {
      //this is coming from db defaultValue column as a string - parse it and fire
      if (f && f.includes(enums.buttonFunctions.setSubpage)) {
        const trigger = f.split("|")[0];
        const subpage = f.split("|")[1];
        setValueParentOrLocal(subpage, trigger, setFieldValue, setFormValueCallback, true);

        // else {
        //   //else it's a raw, stringed function
        //   var parsedExecutable = callbackFunction;
        //   eval("(" + parsedExecutable + ")");
        // }
      }

      if (f && f.includes(enums.buttonFunctions.showPremFinancePDF)) {
        //use this for the first call into PremFinance via the payment button
        reportToast("Generating Installment Plan document...");
        axiosApi.get("api/payment/initPremFinanceLink/" + questions.submissionID).then(result => {
          if (result.data.response !== "ERROR" && result.data.data) {
            //store in local storage before launching the pop-up window in case it fails
            const filePath = result.data.data;
            sessionStorage.setItem("installmentPlanPDFPath", filePath);
            const pdfWindow = window.open();
            pdfWindow.location.href = filePath;
          }
          else {
            //TODO: should this error disable the payment option?
            reportToastError(result.data.message);
          }
        }, error => {
          //TODO: Should we disable the Pay Now button in this case?
          reportToastError("The Installment Plan document is not available at this time. Please try again later.");
        });
      }
    });
  };

  return (
    <Container id="buttons_main_container_1" className={input_css} key={id} onBlur={() => handleBlur()}>
      <Button
        id={id}
        disabled={disabled}
        onClick={clearForm ? () => clearFormOnClick(clearForm, resetForm) : () => handleChange()}
        name={id}
        variant="outline-primary"
        action={touched[name] ? touched[name].toString() : touched[name]}
      >
        {label}
      </Button>
    </Container>
  );
};

export default Buttons;
