let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === "quote.rvnuccio.com") {
  backendHost = "https://api.rvnuccio.com";
}
else if (hostname === "68.169.28.188") {
  backendHost = "http://68.169.28.188:5000"; //staging server public IP for nonVPN, whitelist usage
}
else if (hostname === "10.8.21.50") {
  backendHost = "http://10.8.21.50:5000"; //staging server internal IP for VPN usage
}
else if (hostname === "192.168.116.13") {
  backendHost = "http://192.168.116.13:5000"; //staging server internal IP for DOXA server
}
else if (hostname === "10.8.21.30") {
  backendHost = "http://10.8.21.30:5001"; //prod server internal IP for VPN usage
}
else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || "http://localhost:5000";
}

export const API_ROOT = backendHost;
