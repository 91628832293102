import * as helper from "../RepeatingCardsHelper";
import { forEach } from "lodash";
import enums from "../../../../../Utilities/Enums/Enums";

export const getCardDisplayTemplate = (app, card, questions) => {
  let templateSeed = card.questionsAndData.find((q) => q.cardFaceTemplate !== "" && q.questionType !== 'hidden');
  let templateType = templateSeed && templateSeed.cardFaceTemplate ? templateSeed.cardFaceTemplate : enums.cardDisplayTemplates.default;

  let displayItems = {
    all: [],
    cardDates: "",
    cardDatesIndex: null,
    dataViolationMarkup: "",
    dateViolationMarkup: "",
    endDate: "",
    // incompleteItems: [],
    insuredName: "",
    invalidItemCount: 0,
    startDate: "",
    templateType: templateType
  };

  if (templateType === enums.cardDisplayTemplates.additionalInsured) {
    let cardAddress = "";
    let cardAddressStreet = "";
    let cardAddressIndex;
    let cardDates = "";

    if (questions.rCardsWithError && questions.rCardsWithError.length > 0) {
      const cardHasError = questions.rCardsWithError.find((rCard) => rCard === card.ordinalPosition);
      if (cardHasError) {
        displayItems.dataViolationMarkup = "border-danger rCard-errors";
        displayItems.invalidItemCount++;
      }
    }

    for (let i = 0; i < card.questionsAndData.length; i++) {
      let cardQs = card.questionsAndData[i];
      if (cardQs.cardFaceTemplate) {
        if (cardQs.lookupCode.indexOf("AddlInsured_Name-") > 0 || cardQs.lookupCode.indexOf("AddlInsured2_Name-") > 0 || cardQs.lookupCode.indexOf("InsuredName-") > 0) {
          if (cardQs.datavalue) {
            displayItems.insuredName = cardQs.datavalue;
          }
          else {
            // displayItems.incompleteItems.push("Additional Insured's Name");
            displayItems.invalidItemCount++;
          }

          if (!displayItems.dataViolationMarkup) {
            displayItems.dataViolationMarkup = helper.incompleteDataViolationClass(cardQs.datavalue);
          }
        }
        if (cardQs.lookupCode.indexOf("Street") > 0 || cardQs.lookupCode.indexOf("InsuredAddress") > 0) {
          if (cardQs.datavalue) {
            cardAddressStreet = cardQs.datavalue;
            displayItems.all.push(cardAddressStreet);
          }
          else {
            // displayItems.incompleteItems.push("City");
            displayItems.invalidItemCount++;
          }

          if (!displayItems.dataViolationMarkup) {
            displayItems.dataViolationMarkup = helper.incompleteDataViolationClass(cardQs.datavalue);
          }
        }
        else if (cardQs.lookupCode.indexOf("City") > 0) {
          if (cardQs.datavalue) {
            cardAddress += cardQs.datavalue + ", ";
            cardAddressIndex = displayItems.all.push(cardAddress) - 1;
          }
          else {
            // displayItems.incompleteItems.push("City");
            displayItems.invalidItemCount++;
          }

          if (!displayItems.dataViolationMarkup) {
            displayItems.dataViolationMarkup = helper.incompleteDataViolationClass(cardQs.datavalue);
          }
        }
        else if (cardQs.lookupCode.indexOf("State") > 0) {
          if (cardQs.datavalue) {
            cardAddress += cardQs.datavalue + " ";
            displayItems.all[cardAddressIndex] = cardAddress;
          }
          else {
            // displayItems.incompleteItems.push("State");
            displayItems.invalidItemCount++;
          }

          if (!displayItems.dataViolationMarkup) {
            displayItems.dataViolationMarkup = helper.incompleteDataViolationClass(cardQs.datavalue);
          }
        }
        else if (cardQs.lookupCode.indexOf("Zip") > 0) {
          if (cardQs.datavalue) {
            cardAddress += cardQs.datavalue;
            displayItems.all[cardAddressIndex] = cardAddress;
          }
          else {
            // displayItems.incompleteItems.push("Zipcode");
            displayItems.invalidItemCount++;
          }

          if (!displayItems.dataViolationMarkup) {
            displayItems.dataViolationMarkup = helper.incompleteDataViolationClass(cardQs.datavalue);
          }
        }
        else if (cardQs.lookupCode.indexOf("StartDate") > 0) {
          if (cardQs.datavalue) {
            displayItems.startDate = cardQs.datavalue;
            cardDates += cardQs.datavalue;
            displayItems.dateViolationMarkup = helper.hasEffDateViolation(
              cardQs.datavalue,
              questions.policyEffectiveDate
            ) ? "border-danger rCard-errors" : "";
          }
          else {
            // displayItems.incompleteItems.push("StartDate");
            displayItems.invalidItemCount++;
          }

          if (!displayItems.dataViolationMarkup) {
            displayItems.dataViolationMarkup = helper.incompleteDataViolationClass(cardQs.datavalue);
          }
        }
        else if (cardQs.lookupCode.indexOf("EndDate") > 0) {
          if (cardQs.datavalue) {
            displayItems.endDate = cardQs.datavalue;
            cardDates += " - " + cardQs.datavalue;
            displayItems.cardDates = cardDates;
          }
          else {
            // displayItems.incompleteItems.push("EndDate");
            displayItems.invalidItemCount++;
          }

          if (!displayItems.dataViolationMarkup) {
            displayItems.dataViolationMarkup = helper.incompleteDataViolationClass(cardQs.datavalue);
          }
        }
      }
    }
  }
  else {
    //question has cardFaceTemplate
    let filteredDisplayQuestions = card.questionsAndData.filter((question) => question.cardFaceTemplate);

    //always check questionText fisrt, then pdfApplicationText as the fallback
    //many fields don't have questionText because they are labeless address fields, etc
    forEach(filteredDisplayQuestions, question => {

      if (question.datavalue) {
        // //if we have help text and data, print answers to display
        // if (question.questionText || question.pdfApplicationText) {
        //   if (question.questionText) {
        //     displayItems.all.push(question.questionText ? question.questionText + ": " + question.datavalue : question.datavalue);
        //   } else {
        //     displayItems.all.push(question.pdfApplicationText ? question.pdfApplicationText + ": " + question.datavalue : question.datavalue);
        //   }
        // }
        // else {
        //else push the data itself to the display without the help text
        displayItems.all.push(question.datavalue);
        // }
      }

      //else there is missing help text or missing data
      else {
        //if missing help text, warn developer -- note: removed this feature since we no longer handle incompleteItems
        // if (app.environment === enums.environments.development) {
        //   displayItems.incompleteItems.push(question.lookupCode + " is missing its PDFApplicationText text");
        // }

        //simply report the number of missing items in the card
        displayItems.invalidItemCount++;
        displayItems.dataViolationMarkup = "border-danger";
      }
    });
  }

  return displayItems;
};
