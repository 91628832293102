import { forwardRef, memo } from "react";
import { OverlayTrigger, Popover, Button, Row, Col } from "react-bootstrap";
import ErrorBoundary from "../../../../Errors/ErrorBoundary/ErrorBoundary";
import enums from "../../../Enums/Enums";
import "../../../../_styles/icons/icons.scss";

const LabelCommon = forwardRef((props, ref) => {
  const {
    helptext,
    id,
    label_class_interpreted,
    label_prefix,
    label_text,
    popover_class,
    popover_placement,
    type,
    disableLabel
  } = props;

  let is_stand_alone_label = type === enums.fieldTypes.label;

  const popover = (
    <Popover
      id={`popover-positioned-${id}`}
      {...props}
    >
      <Popover.Body id="label_popover_body_1">
        <div id="label_common_div_4">
          <span dangerouslySetInnerHTML={{ __html: helptext }}></span>
        </div>
        <div id="label_common_div_5" className="d-flex justify-content-end">
          <Button
            id="label_common_btn_1"
            variant="secondary"
            size="sm"
            className={"popover-btn mt-15"}
            onClick={() => document.body.click()}
          >
            Got it!
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <ErrorBoundary>
      <Row
        id="label_common_row_1"
        className={is_stand_alone_label ? "stand_alone_label " + label_class_interpreted : label_class_interpreted}
        style={{
          paddingTop: label_class_interpreted === "visibilityParentLabel" ? "" :
            !label_class_interpreted ? "3px" : ""
        }}
      >
        <Col id="label_common_col_1" className={label_prefix ? "" : "d-none"}>
          <div id="label_common_div_1">{label_prefix}</div>
        </Col>
        <Col id="label_common_col_2" ref={ref}>
          <Row id="label_common_row_2" className="d-inline-flex">
            <Col id="label_common_col_3" className={"d-inline-flex " + label_class_interpreted}>
              <div id="label_common_div_2" className={disableLabel ? "disabledLabelText" : ""}>
                {label_text && label_text.includes("href=") ?
                  (
                    <span dangerouslySetInnerHTML={{ __html: label_text }}></span>
                  ) :
                  (
                    label_text
                  )
                }
              </div>
              <OverlayTrigger
                id="label_common_overlay_trigger_1"
                trigger="click"
                key={id}
                placement={"bottom"}
                overlay={popover}
                rootClose
              >
                <div id="label_common_div_3" className={type === enums.fieldTypes.header ? "headerPopContainer" : ""}>
                  <div id="label_common_div_4">
                    <div
                      className={helptext ? "fa fa-question-circle " + popover_class : "d-none"}
                      id={"Popover-" + id}
                    ></div>
                  </div>
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>
      </Row>
    </ErrorBoundary>
  )
});

export default memo(LabelCommon);