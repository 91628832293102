import { forEach } from "lodash";
import enums from "../Utilities/Enums/Enums";

export const getConfigItems = (programOrGroupCode, config) => {
  let configItems = {}

  forEach(enums.configItems, configField => {
    for (let i = 0; i < config.length; i++) {
      let item = config[i];
      if ((!item.programOrGroupCode || item.programOrGroupCode === programOrGroupCode) && item.configKey === configField) {
        //if null, add it, since it applies to all programs
        configItems[configField] = item.configValue;
      }
    }
  });

  return configItems;
};

export const buildConfigEntity = (entityConfigArray) => {
  let configEntity = {};

  for (let i = 0; i < entityConfigArray.length; i++) {
    let configItem = entityConfigArray[i];
    if (configItem.configKey && configItem.configValue) {
      let key = configItem.configKey.toString();
      let value = configItem.configValue;
      configEntity[`${key}`] = value;
    }
  }

  return configEntity;
}


function menuItem(value, label) {
  this.value = value;
  this.label = label;
}

export function setMenuPrograms(programs) {
  let options = [];
  for (let i = 0; i < programs.length; i++) {
    let item = new menuItem(programs[i].programCode, programs[i].programName);
    options.push(item);
  }
  return options;
}

export function checkStringInputLengths(vm) {
  let invalidProperties = [];
  for (const property in vm) {
    if (vm.hasOwnProperty(property)) {
      if (property === "zipcode") {
        if (vm[property] && vm[property].length > 8) {
          invalidProperties.push("Zip Code");
        }
      }
      else if (property === "commission") {
        if (vm[property] && vm[property].length > 16) {
          invalidProperties.push("Commission");
        }
      }
      else {
        if (vm[property] && vm[property].length > 64) {
          const propertyName = property.replace("entity", "");
          const firstletter = propertyName.charAt(0);
          const firstLetterCap = firstletter.toUpperCase();
          const remainingLetters = propertyName.slice(1);
          const capitalizedFirstLetter = firstLetterCap + remainingLetters;
          invalidProperties.push(capitalizedFirstLetter);
        }
      }
    }
  }
  return invalidProperties;
}
