import { Container, Row, Col } from "react-bootstrap";
import { Field } from "formik";
import { FieldWrapper } from '../../../StylizedComponents/index';
import LabelCommon from "./Shared/LabelCommon";

const Label = (props) => {
  const {
    columnOffset,
    fieldsRef,
    helptext,
    id,
    input_css,
    label,
    label_css,
    label_prefix,
    popover_class,
    popover_placement,
    type
  } = props;

  let label_text = label;
  let label_class_interpreted = label_css;

  let labelProps = {
    helptext, id, label_class_interpreted, label_prefix, label_text,
    popover_class, popover_placement, type
  }

  return (
    <Container id="label_main_container_1" className={input_css} key={id}>
      <Field
        id="labeL_field_wrapper_1"
        className={columnOffset}
        component={FieldWrapper}
      >
        <Row id="label_row_1">
          <Col id="label_col_1">
            <LabelCommon ref={fieldsRef} {...labelProps} />
          </Col>
        </Row>
      </Field>
    </Container>
  );
};

export default Label;
