import { find, forEach } from "lodash";

export function setNewUWRInitialValues(underwriterNames, programNames) {
  let firstUnderwriter = "";
  let firstProgram = "";
  if (underwriterNames.length > 0) {
    firstUnderwriter = underwriterNames[0];
  }
  if (programNames.length > 0) {
    firstProgram = programNames[0];
  }
  let initialValue = {
    underwriter: firstUnderwriter,
    effectiveStartDate: "",
    effectiveEndDate: "",
    ruleType: "Please Select",
    rangeBegin: "A",
    rangeEnd: "A",
    programName: firstProgram,
  };
  return initialValue;
}

export function setUnderwriterRuleVM(underwriterRule, underwriter, underwriters, programs) {
  let entityID = underwriter.underwriterID;
  let programCode = underwriter.programCode;
  let underwriterName = underwriterRule.mgauwRuleID ? underwriterRule.entityName : underwriter.underwriter;
  //Get the ID for the newly selected underwriter, otherwise, it's just an edit
  if (underwriterName !== underwriter.entityName) {
    let foundEntity = find(underwriters, (u) => {
      return u.entityName === underwriterRule.underwriter;
    });
    entityID = foundEntity.entityId;
  }
  //Do the same for the program code
  if (underwriterRule.programName !== underwriter.programName) {
    let foundProgram = find(programs, (p) => {
      return p.programName === underwriterRule.programName;
    });
    programCode = foundProgram.programCode;
  }

  let UnderwriterRuleVM = {
    MGAUWRuleID: underwriter.mgauwRuleID,
    MGAID: underwriter.mgaid,
    UnderwriterID: entityID,
    EntityName: underwriterName,
    ProgramName: underwriterRule.ruleType === "By Program" ? underwriterRule.programName : null,
    ProgramCode: programCode,
    RuleType: underwriterRule.ruleType,
    RangeBegin: underwriterRule.ruleType === "By Program" ? null : underwriterRule.rangeBegin,
    RangeEnd: underwriterRule.ruleType === "By Program" ? null : underwriterRule.rangeEnd,
    EffectiveStartDate: underwriterRule.effectiveStartDate,
    EffectiveEndDate: underwriterRule.effectiveEndDate,
  };
  return UnderwriterRuleVM;
}

export function setUnderwriterNames(underwriters) {
  let underwriterNames = [];
  forEach(underwriters, (underwriter) => {
    underwriterNames.push(underwriter.entityName);
  });
  return underwriterNames;
}

export function setProgramNames(programs) {
  let programNames = [];
  forEach(programs, (program) => {
    programNames.push(program.programName);
  });
  return programNames;
}

export function removeUnderwriterRule(underwriterRules, underwriter) {
  let underwriterRulesCopy = [...underwriterRules];

  for (let i = 0; i < underwriterRules.length; i++) {
    if (underwriterRules[i].mgauwRuleID === underwriter.mgauwRuleID) {
      delete underwriterRulesCopy[i];
    }
  }
  return underwriterRulesCopy;
}
