import { cloneDeep, filter, forEach } from "lodash";
import * as questionsActions from "../QuestionsActions";
import * as Errors from "../../Errors/ErrorSchemaMessages/ErrorSchemaMessages";
import util from "../../Utilities/Functions/Functions";
import enums from "../../Utilities/Enums/Enums";
import historyObj from "../../Routing/browserHistory";

const isHardRequiredForQR = (q) => {
  if (q.validRequired === enums.bool.true && q.isQR) {
    return true;
  }
};

const hasValidAttributeSet = (q) => {
  return (
    (q.validRequired && q.validRequired !== enums.bool.false) ||
    q.validPremiumIndication || q.validEligible
  )
};

const buildSimpleValidationObjects = (question, questionObject) => {
  const shouldMarkupError = (q) => {
    if (
      ((hasValidAttributeSet(q) && !q.datavalue) || q.isInvalid !== "" || q.isInvalidText !== "") &&
      q.questionType !== "label" &&
      util.isNotDuplicateValidation(q, questionObject.storedInvalidFields)
    ) {
      return true;
    }
  };

  const shouldMarkupValid = (q) => {
    if (
      hasValidAttributeSet(q) &&
      q.datavalue &&
      q.isInvalid === "" &&
      q.isInvalidText === "" &&
      q.questionType !== "label" &&
      util.isNotDuplicateValidation(q, questionObject.storedValidFields)
    ) {
      return true;
    }
  };

  if (shouldMarkupError(question)) {
    questionObject.storedInvalidFields.push(applySimpleValidation(question, question.datavalue));
  }
  else if (shouldMarkupValid(question)) {
    questionObject.storedValidFields.push(applySimpleValidation(question, question.datavalue));
  }
}

const buildListeningValidationFields = (question, questionObject) => {
  if (hasValidAttributeSet(question) || question.validRexExp !== null) {
    //*** Load in stored markup rules for real time page validation downstream in RenderFields ***//
    questionObject.listeningValidationFields.push(question);
  }
}


export function applySimpleValidationToStored(pageName, pageWasPreviouslyVisited, questionObject) {

  const questionObjectCopy = cloneDeep(questionObject);

  if (questionObjectCopy?.questions) {

    forEach(questionObjectCopy.questions, (questionOrCardObject) => {

      //is this item a card object with nested questions?
      if (questionOrCardObject?.cardCollection) {
        forEach(questionOrCardObject.cardCollection, (cardObject) => {

          //if page was visited build out rcard nested field validations
          forEach(cardObject.questionsAndData, (question) => {
            if (pageWasPreviouslyVisited && !cardObject.isPristine) {
              buildSimpleValidationObjects(question, questionObject);
            }

            //always build out our list of listeningValidationFields for each rcard's repeating set of questions
            buildListeningValidationFields(question, questionObject);
          });
        });
      }

      //else it's a standard question object
      else {
        if (questionOrCardObject && pageName.indexOf("QuickRate") >= 0) {
          // if we are on quick rate, enforced required fields
          if (isHardRequiredForQR(questionOrCardObject)) {
            //hard no for these on qr, unlike rest which just warn "required" until nav to premInd
            //this array will stop navigation and disables the get QR btn
            questionObject.qrHardRequired.push(questionOrCardObject);
          }
        }

        if (pageWasPreviouslyVisited) {
          //build simple validation for standard, non-repeating fields
          buildSimpleValidationObjects(questionOrCardObject, questionObject);
        }

        buildListeningValidationFields(questionOrCardObject, questionObject);
      }
    });
  }
}

export function updateFieldValidations(eligibleFields, valid, currentInvalidFields, currentValidFields) {
  //here we simply add or remove entire field object from invalid or valid arrays
  let updatedFieldValidations = {
    invalidFields: [],
    validFields: [],
  };

  //reassemble current obj
  if (currentInvalidFields && currentInvalidFields.length > 0) {
    updatedFieldValidations.invalidFields = currentInvalidFields;
  }
  if (currentValidFields && currentValidFields.length > 0) {
    updatedFieldValidations.validFields = currentValidFields;
  }

  //assemble new obj
  const buildObj = (eligibleField) => {

    const existsInValidArray = currentValidFields.find((q) => q.lookupCode === eligibleField.lookupCode);
    const existsInInvalidArray = currentInvalidFields.find((q) => q.lookupCode === eligibleField.lookupCode);

    //if not in valid array & valid, add it
    if (((currentValidFields && !existsInValidArray) || !currentValidFields) && valid) {
      updatedFieldValidations.validFields.push(eligibleField);
    }

    //if in valid array
    else if (currentValidFields && existsInValidArray) {
      for (let i = 0; i < currentValidFields.length; i++) {
        if (currentValidFields[i].lookupCode === eligibleField.lookupCode) {

          //not valid, remove it
          if (!valid) {
            // delete updatedFieldValidations.validFields[i];
            updatedFieldValidations.validFields.splice(i, 1);
            break;
          }

          //if not duplicate and already exists in valid, update it
          else if (util.isNotDuplicateValidation(eligibleField, currentValidFields)) {
            updatedFieldValidations.validFields[i] = eligibleField;
            break;
          }
        }
      }
    }

    //if in invalid array
    if (currentInvalidFields && existsInInvalidArray) {
      for (let i = 0; i < currentInvalidFields.length; i++) {
        if (currentInvalidFields[i].lookupCode === eligibleField.lookupCode) {

          //valid, remove it
          if (valid) {
            // delete updatedFieldValidations.invalidFields[i];
            updatedFieldValidations.invalidFields.splice(i, 1);
            break;
          }

          //if not duplicate and already exists in valid, update it
          else if (util.isNotDuplicateValidation(eligibleField, currentInvalidFields)) {
            updatedFieldValidations.invalidFields[i] = eligibleField;
            break;
          }
        }
      }
    }

    //if not in invalid array & invalid add it
    else if (((currentInvalidFields && !existsInInvalidArray) || !currentInvalidFields) && !valid) {
      updatedFieldValidations.invalidFields.push(eligibleField);
    }
  };

  if (eligibleFields) {
    eligibleFields.forEach(buildObj);
  }

  return updatedFieldValidations;
}

const checkIsFailingRequiredValue = (validationField, fieldValue) => {
  if (validationField && validationField.validRequired === enums.bool.true && !fieldValue) {
    return true;
  }

  return false;
}

const checkIsNotEligibleValue = (validationField, fieldValue) => {
  if (validationField && validationField.validEligible && fieldValue) {
    if (validationField.questionType === enums.fieldTypes.combo) {
      let comboItems;
      let normalizedEligibleItems;
      if (validationField.validEligible.includes(";")) {
        //db field has mult values to test against
        comboItems = validationField.validEligible.split(";");
        normalizedEligibleItems = comboItems.reduce(function (result, comboItem) {
          result.push(util.getOrTransformData(comboItem, enums.fieldTypes.combo));
          return result;
        }, []);

        if (normalizedEligibleItems.includes(fieldValue)) {
          return true;
        }
      }
      else {
        //else db field has only one value to test against
        if (util.getOrTransformData(validationField.validEligible, validationField.questionType) === fieldValue) {
          return true;
        }
      }
    }

    if (
      validationField.questionType === enums.fieldTypes.radio ||
      validationField.questionType === enums.fieldTypes.list
    ) {
      //not eligible if they match
      if (validationField.validEligible === fieldValue) {
        return true;
      }
    }
  }

  return false;
}

const checkIsNotValidPremIndValue = (validationField, fieldValue) => {
  if (validationField.validPremiumIndication && historyObj.location.pathname.includes("PremInd") && fieldValue) {
    if (validationField.questionType === enums.fieldTypes.combo) {
      let comboItems;
      let normalizedEligibleItems;
      if (validationField.validPremiumIndication.includes(";")) {
        comboItems = validationField.validPremiumIndication.split(";");
        normalizedEligibleItems = comboItems.reduce(function (result, comboItem) {
          result.push(util.getOrTransformData(comboItem, enums.fieldTypes.combo));
          return result;
        }, []);

        if (normalizedEligibleItems.includes(fieldValue)) {
          return true;
        }
      }
      else {
        if (
          util.getOrTransformData(validationField.validPremiumIndication, validationField.questionType) === fieldValue
        ) {
          return true;
        }
      }
    }
  }

  return false;
}

const checkIsNotValidRegExValue = (validationField, fieldValue) => {
  if (validationField && validationField.validRegExp && fieldValue) {
    let normalizedRegex = new RegExp(validationField.validRegExp, "i");
    if (!normalizedRegex.test(fieldValue)) {
      return true;
    }
  }

  return false;
}

export function applySimpleValidation(validationField, fieldValue) {
  //TODO: In field, if error & message, swap out red X for info icon in red and make a popover

  //here we will add or remove any special error messaging or markup based on stored validation rules
  let strappedField = {};

  //don't want to deal with whole fieldObj create the new strappedField
  strappedField.lookupCode = validationField.lookupCode;

  //valid required
  const isFailingRequiredValue = checkIsFailingRequiredValue(validationField, fieldValue);
  if (isFailingRequiredValue) {
    strappedField.validRequired = Errors.default.required;
    //field messages will be delivered to the NestedFieldAlert unlike the required trigger, which can issue a modal on Question.js
    strappedField.fieldMessage = validationField.validRequiredText ? validationField.validRequiredText : "";
  }
  else if (!isFailingRequiredValue && validationField.validRequired === enums.bool.true) {
    delete strappedField.validRequired;
  }

  //validEligible
  const isNotEligibleValue = checkIsNotEligibleValue(validationField, fieldValue);
  if (isNotEligibleValue) {
    strappedField.validEligible = Errors.default.eligible;
    strappedField.fieldMessage = validationField.validEligibleText ? validationField.validEligibleText : "";
  }
  else if (!isNotEligibleValue && strappedField.validEligible) {
    delete strappedField.validEligible;
  }

  //validPremiumIndication
  const isNotValidPremIndValue = checkIsNotValidPremIndValue(validationField, fieldValue);
  if (isNotValidPremIndValue) {
    strappedField.validPremiumIndication = Errors.default.eligible;
    strappedField.fieldMessage = validationField.validPremiumText ? validationField.validPremiumText : "";
  }
  else if (!isNotValidPremIndValue && strappedField.validPremiumIndication) {
    delete strappedField.validPremiumIndication;
  }

  //validRegExp
  const isNotValidRegExpValue = checkIsNotValidRegExValue(validationField, fieldValue);
  if (isNotValidRegExpValue) {
    strappedField.validRegExp = Errors.default.invalidEntry;
    strappedField.fieldMessage = validationField.validRegExpText ? validationField.validRegExpText : "";
  }
  else if (!isNotValidRegExpValue && strappedField.validRegExp) {
    delete strappedField.validRegExp;
  }

  return strappedField;
}


export function checkHasInvalidResponse(validationField, fieldValue) {
  //valid required
  if (checkIsFailingRequiredValue(validationField, fieldValue)) {
    return true;
  }

  //validEligible
  if (checkIsNotEligibleValue(validationField, fieldValue)) {
    return true;
  }

  //validPremiumIndication
  if (checkIsNotValidPremIndValue(validationField, fieldValue)) {
    return true;
  }

  //validRegExp
  if (checkIsNotValidRegExValue(validationField, fieldValue)) {
    return true;
  }

  return false;
}

export function validateQuestionSimple(fieldName, fieldValue) {
  return async (dispatch, getState) => {
    let questions = getState().questions;
    let storedInvalidFields = questions.storedInvalidFields;
    let storedValidFields = questions.storedValidFields;

    let eligibleValidationField;
    if (!util.isObjEmpty(questions) && updateFieldValidations && fieldName) {
      eligibleValidationField = filter(questions.listeningValidationFields, function (q) {
        if (q.lookupCode === fieldName) {
          return q;
        }
      });

      if (!util.isObjEmpty(eligibleValidationField)) {
        let strappedField = questions.applySimpleValidation(eligibleValidationField[0], fieldValue);

        //check if an error is present
        var errorExists = Object.keys(strappedField).some(function (k) {
          //test all properties for population except for the obj name
          if (k !== "lookupCode" && strappedField[k] !== "") {
            return true;
          }

          return false;
        });

        if (errorExists) {
          //turn markup on and issue error message only if one doesn't already exist for this field
          if (storedInvalidFields && util.isNotDuplicateValidation(strappedField, storedInvalidFields)) {
            dispatch(
              questionsActions.updateFieldValidations(strappedField, false, storedInvalidFields, storedValidFields)
            );
          }
        }
        else {
          //add to valid fields and/or clear of invalid markup only if it doesn't already exist as a valid field
          if (storedValidFields && util.isNotDuplicateValidation(strappedField, storedValidFields)) {
            dispatch(
              questionsActions.updateFieldValidations(strappedField, true, storedInvalidFields, storedValidFields)
            );
          }
        }
      }
      //TOOD: Note, if we want to add in optional field validation, we would include an else block here
      //and pass to storedValidFields if not duplicate -- not need to test for errors if there's no store
      //requirements
    }
  };
}
