import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import CallbackForm from "./CallbackForm/CallbackForm";
import * as sideMenuActions from "../../SideMenuActions";

const RequestCallback = () => {
  //store
  const app = useSelector((state) => state.app);

  //dispatch actions
  const dispatch = useDispatch();

  //states
  const [open, toggleCollapse] = useState(false);

  //outbound
  const handleCallback = useCallback((vm) => dispatch(sideMenuActions.createCallbackRequest(vm)), [dispatch]);

  return (
    <div id="request_callback_main_div_1">
      <div
        id="request_callback_div_2"
        onClick={() => toggleCollapse(!open)}
        aria-controls="requestOpened"
        aria-expanded={open}
        style={{ color: "#0d6efd", textDecoration: "underline" }}
      >
        Request a Callback
      </div>
      <Collapse in={open}>
        <div id="request_callback_div_3">
          <CallbackForm handleCallback={handleCallback} />
        </div>
      </Collapse>
    </div>
  );
};

export default RequestCallback;
