import { useSelector } from "react-redux";
import Alert from "react-bootstrap/Alert";
import util from "../../Utilities/Functions/Functions";


const FieldDataDisplay = (props) => {
  //store
  const questions = useSelector((state) => state.questions);

  const { name, id, value, psReady, displayOrder, qrDisplayOrder } = props;

  return (
    <div id="field_data_display_main_div_1" className={questions && questions.useDebugMode && questions.fieldDataDisplay ? "" : "d-none"}>
      <Alert id="field_data_display_alert_1" variant={"primary"}>
        <div id="field_data_display_div_2">
          <span id="field_data_display_span_1" className="font-weight-bold">Field ID:</span> {id}
        </div>
        <div id="field_data_display_div_3">
          <span id="field_data_display_span_2" className="font-weight-bold">Field Name:</span> {name}
        </div>
        <div id="field_data_display_div_4">
          <span id="field_data_display_span_3" className="font-weight-bold">Display Order:</span> {displayOrder}
        </div>
        <div id="field_data_display_div_5">
          <span id="field_data_display_span_4" className="font-weight-bold">QR Display Order:</span> {qrDisplayOrder}
        </div>
        <div id="field_data_display_div_6">
          <span id="field_data_display_span_5" className="font-weight-bold">Field Form Value:</span> {value ? value : "None"}
        </div>
        <div id="field_data_display_div_7">
          <span id="field_data_display_span_6" className="font-weight-bold">Window Width:</span> {props.windowWidth + "px"}
        </div>
        <div id="field_data_display_div_8">
          <span id="field_data_display_span_7" className="font-weight-bold">PageScript Result:</span> {!util.isObjEmpty(psReady) ? JSON.stringify(psReady, null, 1) : "None"}
        </div>
        {/* Uncomment to see all props */}
        {/* <div style={{maxWidth: "500px"}}>
          <span className="font-weight-bold">All Props:</span> {!util.isObjEmpty(props) ? JSON.stringify(props, null, 1) : "None"}
        </div> */}
      </Alert>
    </div>
  );
};

export default FieldDataDisplay;
