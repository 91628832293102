import { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldWrapper } from '../../StylizedComponents/index';
import { Field } from "formik";
import { Col, Form } from "react-bootstrap";
import RenderField from "./RenderFields/RenderField";
import FieldDataDisplay from "../../Errors/DebugMode/FieldDataDisplay";
import * as questionsActions from "../../Questions/QuestionsActions";
import enums from "../Enums/Enums";
import ps from "../Functions/PageScript";
import util from "../Functions/Functions";

const FormGroups = (props) => {
  //store
  const app = useSelector((state) => state.app);
  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const hasLabelClass = props.label ? "" : "no_label";

  //dispatch actions
  const dispatch = useDispatch();

  //local state
  const [labelCSS, setLabelCSS] = useState("");
  const [psGroupClass, setPSGroupClass] = useState("");

  const setLabelClassVal = (css) => { setLabelCSS(css); };
  let listOffsetClass;
  if (props.type === enums.fieldTypes.list) {
    listOffsetClass = util.interpretOffsetClassContext(props.label, props.label_prefix, props.columnOffset, "");
  }
  let isAdmin;
  if (app.user && app.user.entityTypeCode) {
    const userRole = app.user.entityTypeCode;
    isAdmin = (userRole === enums.role.admin) || (userRole === enums.role.callrep);
  }

  //local
  const hideAllInputs = useCallback((bool) => dispatch(questionsActions.hideAllInputs(bool)), [dispatch]);

  //pageScript
  const normalizedId = ps.removeSetIDAndOPFromLookupCode(props.id);
  let psPremount, psPostmountExecutedFields;
  let psReady = useMemo(() => { }, []);

  if (isQuestionsContext) {
    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, props.id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, props.id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      psReady = ps.applyPS(psPostmountExecutedFields, psReady);
    }
  }

  let questionIsTempHidden = () => {
    return ((psGroupClass !== "show" && (props?.group_css?.includes("hideThenShowBlock") || props?.group_css?.includes("hideThenShowInline"))) || psGroupClass === "hide");
  }

  useEffect(() => {
    if (isQuestionsContext) {
      if (
        props.id &&
        props.id.includes("HideAllInputs") &&
        psReady &&
        psReady.value === "TRUE" &&
        !questions.hideAllInputs
      ) {
        hideAllInputs(true);
      }

      //only way to toggle hide/unhide scenario via pageScript is to pass psReady?.setClassGroup in
      //without protection to clear any value, even if the psReady object is empty, and rely on the dependency array to limit rerenders
      //this is due to the contingentValueReturned requirement inside applyPS that prevent's psReady location calls if the ruleResult returns
      //contingentValueReturned = false and React's one way data flow that makes preventing unwanted rerenders/infinite loops difficult to
      //prevent with permanent listeners that need to know when/how to toggle an object flag
      setPSGroupClass(psReady?.setClassGroup);
    }
  }, [props.id, psReady, questions.hideAllInputs, psGroupClass, hideAllInputs, setPSGroupClass, isQuestionsContext]);

  return (
    <Form.Group
      id="form_groups_main_container_1"
      className={
        (questions.hideAllInputs || props.type === "hidden" || questionIsTempHidden()) ? "hide"
          : props.subpage_css ? "subpage " + props.subpage_css + " " + props.type + " " + hasLabelClass
            : props.type + " " + hasLabelClass
      }
      as={props.isInlineRow && questions.pageUrl === "PremInd" && props.type !== "button" ? Col : Form.Row}
    >
      <Col id="form_groups_col_1">
        {props.type === enums.fieldTypes.list && (
          <Field
            id="form_groups_field_wrapper_1"
            component={FieldWrapper}
            name={"default-" + name}
          >
            <Form.Label
              id="form_groups_label_1"
              htmlFor={props.id}
              className={listOffsetClass + " list-text mt-15 " + labelCSS}
            >
              {props.label}
            </Form.Label>
          </Field>
        )}

        {/* TODO: Add in the props.listClass via db */}
        {props.type === enums.fieldTypes.list && props.isUl && (
          <Field
            id="form_groups_field_wrapper_2"
            className={listOffsetClass}
            component={FieldWrapper}
            name={"default-" + name}
          >
            <ul id={"form_groups_ul_1"}>
              {props.options}
            </ul>
          </Field>
        )}
        {props.type === enums.fieldTypes.list && !props.isUl && (
          <Field
            id="form_groups_field_wrapper_3"
            className={listOffsetClass}
            component={FieldWrapper}
            name={"default-" + name}
          >
            <ol id={"form_groups_ol_1"} className={labelCSS}>
              {props.options}
            </ol>
          </Field>
        )}

        <Field
          clearForm={props.clearForm}
          color={props.color}
          columnOffset={props.columnOffset}
          component={RenderField}
          customRadioNo={props.customRadioNo}
          customRadioYes={props.customRadioYes}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          fontSize={props.fontSize}
          handleFocusCallback={props.handleFocusCallback}
          helptext={props.helptext}
          id={props.id}
          input_css={props.input_css}
          isCoverage={props.isCoverage}
          isAdmin={isAdmin}
          label={props.label}
          label_css={props.label_css}
          label_location={props.label_location}
          label_prefix={props.label_prefix}
          lookupClass={props.lookupClass}
          minDate={props.minDate}
          name={props.name}
          options={props.options}
          parentCardLookupCode={props.parentCardLookupCode}
          parentLookupCode={props.parentLookupCode}
          placeholder={props.placeholder}
          premiumIndication={props.premiumIndication}
          setFormValueCallback={props.setFormValueCallback}
          setLabelClassVal={setLabelClassVal}
          source={props.source}
          static_col_breakpoint={props.static_col_breakpoint}
          tooltipImg={props.tooltipImg}
          type={props.type}
          validationOff={props.validationOff}
          value={props.value}
          windowWidth={props.windowWidth}
        />

        {/* debug mode  */}
        <span className={
          props.type !== "alert" &&
            props.type !== "button" &&
            props.type !== "header" &&
            props.type !== "image" &&
            props.type !== "link" ? "" : "d-none"}
        >
          <FieldDataDisplay {...props} psReady={psReady} />
        </span>
      </Col>
    </Form.Group>
  );
};

export default FormGroups;
