import { Form, withFormik } from "formik";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { default as FormInputs } from "../../../../../Utilities/Inputs/FormGroupsWrapper";
import QuoteAndBuySchema from "./QuoteAndBuySchema";
import enums from "../../../../../Utilities/Enums/Enums";

const BasicForm = () => {
  //TODO: Set this up and reformat
  return (
    <Form id="quote_and_buy_form_1">
      <Row id="quote_and_buy_form_row_1">
        <Col id="quote_and_buy_form_col_1" xs={"12 mt-15"}>
          All RVNA Insurance Products are available to Quote {"&"} Buy Online 24/7
        </Col>
      </Row>
      <Row id="quote_and_buy_form_row_2">
        <Col id="quote_and_buy_form_col_2" xs={"12 mt-15 bold"}>
          By Phone
        </Col>
        <Col id="quote_and_buy_form_col_3" xs={"12 mt-15"}>
          RVNA representatives are available to assist you Monday - Friday 5:30 AM to 5:00 PM PT at 1-800-364-2433.
        </Col>
      </Row>
      <Row id="quote_and_buy_form_row_3">
        <Col id="quote_and_buy_form_col_4" xs={"12 mt-15 bold"}>
          Request a Callback
        </Col>
        <Col id="quote_and_buy_form_col_5" xs={"12 mt-15"}>
          RVNA representatives are available to assist you Monday - Friday 5:30 AM to 5:00 PM PT at 1-800-364-2433.
        </Col>
        <Col id="quote_and_buy_form_col_6" xs={"12"}>
          <FormInputs
            id="fullName"
            name="fullName"
            type={enums.fieldTypes.text}
            placeholder="Full Name"
          />
        </Col>
        <Col id="quote_and_buy_form_col_7" xs={"12"}>
          <FormInputs
            id="qbEmail"
            name="qbEmail"
            type="email"
            placeholder="Email Address"
          />
        </Col>
        <Col id="quote_and_buy_form_col_8" xs={"12"}>
          <FormInputs
            id="qbphone"
            name="qbphone"
            type={enums.fieldTypes.text}
            placeholder="Telephone (xxx-xxx-xxxx)"
          />
        </Col>
        <Col id="quote_and_buy_form_col_9" xs={"12"}>
          <FormInputs
            id="message"
            name="message"
            type={enums.fieldTypes.textarea}
            placeholder="What can we help you with?"
          />
        </Col>
        <Col id="quote_and_buy_form_col_10" xs={"12 mt-15"}>
          We do not sell or share your information.
        </Col>
        <Col id="quote_and_buy_form_col_11" xs={"12 mt-15"}>
          <Button id="quote_and_buy_form_btn_1" color="primary" className="w-100">
            Request Callback
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const QuoteAndBuyFormHOC = withFormik({
  validationSchema: QuoteAndBuySchema,
  mapPropsToValues: (props) => ({
    //initial values
    selectProduct: "",
    fullName: "",
    qbEmail: "",
    qbphone: "",
    message: "",
  }),
});

const QuoteAndBuyForm = QuoteAndBuyFormHOC(BasicForm);

export default QuoteAndBuyForm;
