import { useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";
import QuoteAndBuyForm from "./QuoteAndBuyForm/QuoteAndBuyForm";
import ProductTypeForm from "../../../../SideMenu/SideMenuViews/LoggedIn/Products/ProductTypeForm";

const QuoteAndBuy = (props) => {
  const [open, toggleCollapse] = useState(false);

  if (props.openComponent && props.openComponent !== "quoteAndBuy" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    props.openComponentCallback("quoteAndBuy");
    toggleCollapse(!open);
  }

  return (
    <div id="quote_and_buy_main_div_1">
      <div
        id="quote_and_buy_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="quoteRow"
        aria-expanded={open}
      >
        How do I get a quote and buy a policy?
      </div>
      <Collapse in={open}>
        <Row id="quote_and_buy_row_1">
          <Col id="quote_and_buy_col_1">
            <hr />
            <ProductTypeForm />
            <QuoteAndBuyForm />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default QuoteAndBuy;
