import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../Errors/ErrorBoundary/ErrorBoundary";
import { SearchContext } from "../../Store/SearchStore";
import SearchButtons from "../../Search/SearchButtons";
import SearchForm from "../../Search/SearchForm";
import { Col, Collapse, Row, Nav, Navbar } from "react-bootstrap";
import * as appActions from "../../App/AppActions";
import * as headerActions from "../../Header/HeaderActions";
import util from "../../Utilities/Functions/Functions";
import enums from "../../Utilities/Enums/Enums";
import historyObj from "../../Routing/browserHistory";


const UserBanner = () => {

  //store
  const app = useSelector((state) => state.app);
  const questions = useSelector((state) => state.questions);

  //context
  const {
    searching: [isSearching, setIsSearching],
    searchForm: [searchFormIsOpen, setSearchFormOpen],
    searchResult: [searchResults, setSearchResults],
  } = useContext(SearchContext);

  //state
  const [showSearchBtn, setShowSearchBtn] = useState(false);
  const [showClearBtn, setShowClearBtn] = useState(false);

  //dispatch actions
  const dispatch = useDispatch();

  //local
  const isMobilePhone = () => {
    return util.getWindowDimensions().width < 768;
  };

  const showSignOut = () => {
    return app.user.entityName && app.isLoggedIn && app.user.entityTypeCode !== enums.role.unknown;
  };

  //outbound
  const clearHeaderState = useCallback(() => dispatch(headerActions.clearState()), [dispatch]);

  const logout = useCallback(() => dispatch(appActions.logout()), [dispatch]);

  const navigateToOriginalSite = () => {
    let navigateToLink = "http://www.rvnuccio.com/";
    if (app && app.config && app.config.length > 0) {
      const programCode = app.programGroupCode;
      const configItemForProgram = app.config.find((i) => i.configKey === "LogoutDestination_ProgramSpecific" && i.programOrGroupCode === programCode);
      if (configItemForProgram) {
        navigateToLink = configItemForProgram.configValue;
      }
    }
    window.location.href = navigateToLink;
  }

  const logoutBundle = () => {
    navigateToOriginalSite();
    clearHeaderState();
    logout();
  };

  useEffect(() => {
    if (!searchFormIsOpen && searchResults && !showClearBtn) {
      setShowSearchBtn(false);
      setShowClearBtn(true);
    }
    else if (!searchResults && !showSearchBtn) {
      setShowClearBtn(false);
      setShowSearchBtn(true);
    }
  }, [app.isDirectToQR, app.isLoggedIn, searchFormIsOpen, searchResults, showClearBtn, showSearchBtn]);

  useEffect(() => {
    if (app.user.entityTypeCode === enums.role.callrep || questions.userEntityType === enums.role.admin) {
      //open the SearchForm on the first entry to the page
      //since employees use it extensively
      setSearchFormOpen(true);
    }
    //intentionally set to emppty array to ensure it only fires once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar id="user_banner_navbar_1">
        <Col id="user_banner_col_1">
          <Nav id="user_banner_nav_1" className={isMobilePhone() ? "" : "mr-auto"}>
            {showSignOut() && (
              <>
                {!isMobilePhone() && (
                  <Col id="user_banner_col_2" className="d-inline-flex">
                    <div id="user_banner_div_1">
                      {app.user.entityName}
                    </div>
                    <div
                      id="user_banner_div_2"
                      className={"link sign-out"}
                      onClick={() => logoutBundle()}
                    >
                      Sign Out
                    </div>
                  </Col>
                )}
                {isMobilePhone() && (
                  <Col
                    id="user_banner_col_3"
                    className={"link sign-out"}
                    onClick={() => logoutBundle()}
                    xs="12"
                  >
                    Sign Out
                  </Col>
                )}
              </>
            )}
          </Nav>
        </Col>

        <Col id="user_banner_col_3" className="d-flex justify-content-end" md={"4"} lg={"2"}>
          {app.isLoggedIn && !app.isDirectToQR && app.user.entityTypeCode !== enums.role.unknown && (historyObj.location.pathname === "/" || historyObj.location.pathname === "/policylist") && (
            <SearchButtons showSearchBtn={showSearchBtn} showClearBtn={showClearBtn} />
          )}

          {app.isLoggedIn && historyObj.location.pathname.includes("/questions") && app.user.entityTypeCode !== enums.role.unknown && (
            <Nav id="user_banner_nav_2">
              <div id="user_banner_div_5"> Client ID: {app.currentSubmission.submissionNumber}</div>
            </Nav>
          )}
        </Col>
      </Navbar>
      <Row id="user_banner_row_1"
        className={searchFormIsOpen && (historyObj.location.pathname === "/" || historyObj.location.pathname === "/policylist") ? "" : "d-none"}
      >
        <Col id="user_banner_col_4">
          <Collapse in={searchFormIsOpen}>
            <ErrorBoundary>
              <SearchForm />
            </ErrorBoundary>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default UserBanner;
