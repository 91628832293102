export const clearDocumentList = "CLEAR_DOCUMENT_LIST";
export const clearRepeatingItemDocumentList = "CLEAR_REPEATING_ITEM_DOCUMENT_LIST";
export const clearPolicyState = "CLEAR_POLICY_STATE";
export const getAllSubmissionsSuccess = "GET_ALL_SUBMISSIONS_SUCCESS";
export const getDocumentListSuccess = "DOCUMENT_LIST_SUCCESS";
export const getPoliciesFromQuerySuccess = "GET_POLICIES_FROM_QUERY_SUCCESS";
export const getRepeatingItemsFromQuerySuccess = "GET_REPEATING_ITEMS_FROM_QUERY_SUCCESS";
export const getRatingDataSuccess = "GET_RATING_DATA_SUCCESS";
export const getSubmissionDataSuccess = "GET_SUBMISSION_DATA_SUCCESS";
export const getStatusHistorySuccess = "GET_STATUS_HISTORY_SUCCESS";
export const getValidationHistorySuccess = "GET_VALIDATION_HISTORY_SUCCESS";
export const openPolicyModal = "OPEN_POLICY_MODAL";
export const setApprovalPendingStatus = "SET_APPROVAL_PENDING_STATUS";
export const setApprovedGroupStatus = "SET_APPROVED_GROUP_STATUS";
export const setCurrentRatingSubmission = "SET_CURRENT_RATING_SUBMISSION";
export const setCurrentUWSubmission = "SET_CURRENT_UW_SUBMISSION";
export const setFailedDocRetrievalSubmissionID = "SET_FAILED_DOC_RETRIEVAL_SUBMISSION_ID";
export const setRefreshPolicies = "SET_REFRESH_POLICIES";
export const toggleDocsModal = "TOGGLE_DOCS_MODAL";
export const toggleRepeatingItemDocsModal = "TOGGLE_REPEATING_ITEM_DOCS_MODAL";
export const toggleLoadingDocumentList = "TOGGLE_LOADING_DOCUMENT_LIST";
export const toggleLoadingRepeatingItemList = "TOGGLE_LOADING_REPEATING_ITEM_LIST";
export const toggleEmailDocuments = "TOGGLE_EMAIL_DOCUMENTS";
export const toggleFullPageLoader = "TOGGLE_FULL_PAGE_LOADER";
export const togglePolicySetLoading = "TOGGLE_POLICY_SET_LOADING";
export const togglePolicyModal = "TOGGLE_POLICY_MODAL";
export const toggleRatingModal = "TOGGLE_RATING_MODAL";
export const toggleSelectAllDocuments = "TOGGLE_SELECT_ALL_DOCUMENTS";
export const toggleUnderwritingModal = "TOGGLE_UNDERWRITING_MODAL";
export const updateChangedPolicy = "UPDATE_CHANGED_POLICY";
export const updatePolicyDate = "UPDATE_POLICY_DATE";
