import { toast } from "react-toastify";
import * as appActions from "./AppActionTypes";
import * as helper from "./AppHelper";

export const INITIAL_STATE = {
  blurBackground: false,
  config: [],
  configEntity: {},
  currentSubmission: {},
  facilityRefCode: "",
  internalStatuses: [],
  isDirectToQR: false,
  isLoggedIn: false,
  loading: false,
  openResetPwModal: false,
  policyCount: 0,
  policyCountExists: false,
  productName: "",
  products: [],
  programGroups: [],
  programCode: "",
  resetPwEmail: "",
  resetPwSuccess: false,
  submissionStatusTypes: [],
  toastMsg: "",
  toastType: "",
  user: {},
  username: "",
  usStates: [],
};

export default function appReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case appActions.clearCurrentSubmission:
      state = update(state, {
        currentSubmission: {},
      });
      break;
    case appActions.clearFacilityRefCode:
      state = update(state, {
        facilityRefCode: ""
      });
      break;
    case appActions.clearPwModal:
      state = update(state, {
        openResetPwModal: false,
      });
      break;
    case appActions.clearResetPwEmail:
      state = update(state, {
        resetPwEmail: ""
      });
      break;
    case appActions.clearResetPwSuccess:
      state = update(state, {
        resetPwSuccess: false,
      });
      break;
    case appActions.clearToast:
      state = update(state, {
        toastMsg: "",
        toastType: "",
      });
      break;
    case appActions.getEntityConfigSuccess:
      state = update(state, {
        configEntity: action.entityConfig,
      });
      break;
    case appActions.getFacilityConfigSuccess:
      state = update(state, {
        facilityRefCode: action.facilityRefCode,
      });
      break;
    case appActions.getLoginDataSuccess:
      state = update(state, {
        config: action.loginData.frontEndConfig,
        usStates: action.loginData.states,
      });
      break;
    case appActions.getUserProgramListSuccess:
      let programList = helper.setMenuPrograms(action.programs);
      state = update(state, {
        products: programList,
      });
      break;
    case appActions.getProgramGroupListSuccess:
      let programGroupsList = helper.setMenuPrograms(action.programGroups);
      state = update(state, {
        programGroups: programGroupsList,
      });
      break;
    case appActions.getSubmissionStatusTypesSuccess:
      state = update(state, {
        submissionStatusTypes: action.statuses,
      });
      break;
    case appActions.getStatusTypesInternalSuccess:
      state = update(state, {
        internalStatuses: action.statuses,
      });
      break;
    case appActions.loggingIn:
      state = update(state, { isLoggedIn: false });
      break;
    case appActions.loginSuccess:
      state = update(state, { isLoggedIn: true });
      break;
    case appActions.logoutSuccess:
      state = INITIAL_STATE;
      break;
    case appActions.openResetPwModal:
      state = update(state, {
        openResetPwModal: true,
      });
      break;
    case appActions.reportToast:
      state = update(state, { toastMsg: action.toastMsg, toastType: action.toastType });
      break;
    case appActions.resetPwEmail:
      state = update(state, {
        resetPwEmail: action.email,
      });
      break;
    case appActions.resetPwSuccess:
      state = update(state, {
        resetPwSuccess: true,
      });
      toast.success(action.successResponse);
      break;
    case appActions.setCurrentSubmission:
      state = update(state, {
        currentSubmission: action.submission,
      });
      break;
    case appActions.setDirectToQR:
      state = update(state, {
        isDirectToQR: action.directToQR,
      });
      break;
    case appActions.setPolicyCount:
      state = update(state, {
        policyCount: action.count,
        policyCountExists: true,
      });
      break;
    case appActions.setPolicyCountError:
      state = update(state, {
        policyCountExists: false,
      });
      toast.error(action.errorResponse);
      break;
    case appActions.setProgramGroupCode:
      state = update(state, {
        programGroupCode: action.programGroupCode,
      });
      break;
    case appActions.setUSStates:
      state = update(state, {
        usStates: action.states,
      });
      break;
    case appActions.toggleBlur:
      state = update(state, {
        blurBackground: !state.blurBackground,
      });
      break;
    case appActions.toggleLoading:
      state = update(state, {
        loading: action.bool,
      });
      break;
    case appActions.updateUser:
      state = update(state, {
        user: action.user,
        username: action.username,
      });
      break;
    case appActions.useDefaultConfig:
      state = update(state, {
        config: [], //TODO add a default config item to populate here
      });
      break;
    default:
      break;
  }
  return state;
}
