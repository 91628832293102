
const FullPageLoader = () => {
  return (
    <div id="full_page_loader_main_div_1" className={"loading-container"}>
      <div id="full_page_loader_div_2" className={"loader"}></div>
      <div id="full_page_loader_div_3" className={"loading-text"}>loading</div>
    </div>
  );
};

export default FullPageLoader;
