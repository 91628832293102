import { useSelector } from "react-redux";
import { Form, Container, Row, Col } from "react-bootstrap";
import { FieldWrapper } from "../../../../StylizedComponents/index";
import { Field } from "formik";
import LabelCommon from "../Shared/LabelCommon";
import NestedFieldAlert from "../Alerts/NestedFieldAlert";
import ErrorMessage from "../../../../Errors/ErrorMessage/ErrorMessage";
import enums from "../../../Enums/Enums";
import ps from "../../../Functions/PageScript";
import util from "../../../Functions/Functions";

const Default = (props) => {
  const {
    clearPropertyValueFromPSField,
    columnOffset,
    contingentPSBundle,
    disabled,
    field: { name },
    fieldsRef,
    form: { errors, touched, setFieldTouched, setFieldValue, values }, //from Formik
    handleFocusCallback,
    helptext,
    id,
    input_css,
    label,
    label_css,
    label_location,
    label_prefix,
    placeholder,
    popover_class,
    popover_placement,
    setFormValueCallback,
    setValueParentOrLocal,
    static_col_breakpoint,
    type,
    validateQuestionSimple,
    windowWidth,
  } = props;

  //#region stored values
  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);
  let invalidFieldErrorMessage;

  let hasError = isQuestionsContext
    ? util.checkFieldHasQuestionError(name, storedInvalidFields)
    : util.checkFieldHasYupError(name, errors, touched);

  if (hasError && isQuestionsContext) {
    invalidFieldErrorMessage = util.getInvalidFieldMessage(name, storedInvalidFields)?.fieldMessage;
  }

  //#endregion stored values

  //#region pageScript

  const normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let psPremount, psExecutePostmount, psPostmountExecutedFields;
  let psReady = {};

  if (isQuestionsContext) {
    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, values[name]);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      //we've may have already applied updates to psReady during premount, so pass it back in for updating
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, values[name]);
    }

    if (psReady.value && psReady.value !== undefined && psReady.value !== values[name]) {
      if (psReady.value === "clearValue") {
        clearPropertyValueFromPSField(psReady.id, "value");
        setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
      }
      else {
        setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
      }
    }

    if (ps.hasActiveAlert(psReady)) {
      //need this to mark up input and override valid
      hasError = true;
      // hasValid = false;
    }
  }

  //#endregion pageScript

  //#region functions & actions

  const handleBlur = () => {
    setFieldTouched(name, true);
    setValueParentOrLocal(name, values[name], setFieldValue, setFormValueCallback);
    if (psExecutePostmount) {
      contingentPSBundle(psExecutePostmount, values[name], name, enums.psExecute.postmount);
    }
    if (isQuestionsContext) {
      validateQuestionSimple(name, values[name]);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const normalValue = !value ? "" : value;
    setValueParentOrLocal(name, normalValue, setFieldValue, setFormValueCallback);
  };

  const numberInputOnWheelPreventChange = (e) => {
    if (e.target.type === "number") {
      // Prevent the input value change
      e.target.blur()

      // Prevent the page/container scrolling
      e.stopPropagation()

      // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        e.target.focus()
      }, 0);
    }
  }
  //#endregion functions & actions

  //#region defaultLabel

  let validation_wrapper_css;
  let input_class_val_css;
  let label_class_interpreted;
  let readonly;

  if (hasError) {
    validation_wrapper_css = "defaultError ";
    input_class_val_css = disabled ? "" : "error-border ";
    label_class_interpreted += " error-msg ";
    if (type === "readonly") {
      readonly = "error-readonly ";
    }
    if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
      validateQuestionSimple(name, values[name]);
    }
  }
  else {
    validation_wrapper_css = "default ";
    input_class_val_css = "";
    label_class_interpreted = "";
  }

  label_class_interpreted += label_css;

  let label_text = label ? label : "";
  if (psReady && psReady.setLabelValue) {
    label_text = psReady.setLabelValue;
  }

  const labelProps = {
    helptext,
    id,
    label_class_interpreted,
    label_prefix,
    label_text,
    popover_class,
    popover_placement,
    type,
  };

  //#endregion defaultLabel

  return (
    <Container
      id="default_main_container_1"
      key={id}
      onBlur={() => handleBlur()}
      className={label_text ? "" : "no_label"}
    >
      <Field
        id="default_field_wrapper_1"
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, "")}
        component={FieldWrapper}
        name={"default-" + name}
      >
        {label_text && label_location === enums.labelLocation.top && (
          <Row id="default_row_1">
            <Col id="default_col_1" xs={"12"}>
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row
          id="default_row_2"
          className={label_text ? validation_wrapper_css + " " + label_location + "_label" : validation_wrapper_css}
        >
          {label_text && label_location === enums.labelLocation.left && (
            <Col id="default_col_2" xs={"8"} md={"4"}>
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
          <Col
            id="default_col_3"
            className={label_text ? "has_label" : ""}
            xs={
              static_col_breakpoint
                ? static_col_breakpoint
                : input_css === "totalPercentWidth"
                  ? "4 totalPercentWidth"
                  : "12"
            }
            sm={
              static_col_breakpoint
                ? static_col_breakpoint
                : input_css === "totalPercentWidth"
                  ? "4 totalPercentWidth"
                  : "12"
            }
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
          >
            <div
              id="default_div_1"
              className={"input-group input-group-lg d-flex align-items-center " + validation_wrapper_css}
            >
              <Form.Control
                className={"form-control input-text-align-vertical " + input_class_val_css}
                disabled={
                  psReady && psReady.disableInput
                    ? psReady.disableInput
                    : psReady && psReady.setValueAndDisableInput
                      ? psReady.setValueAndDisableInput
                      : disabled
                }
                id={id}
                name={name}
                onChange={handleChange}
                onFocus={handleFocusCallback}
                placeholder={placeholder}
                value={values[name]}
                type={type}
                onWheel={numberInputOnWheelPreventChange}
                min={type === "number" ? "0" : ""}
                autoComplete={questions && questions.pageUrl === "PremInd" ? "none" : "on"}
              />
            </div>
          </Col>
          {label_text && label_location === enums.labelLocation.right && (
            <Col id="default_col_4">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
        </Row>

        {label_text && label_location === enums.labelLocation.bottom && (
          <Row id="default_row_3">
            <Col id="default_col_5">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        {!isQuestionsContext && (
          // report yup errors - outside of questions
          <Row id="default_row_4">
            <Col id="default_col_6">
              <ErrorMessage errors={errors[name]} touched={touched[name]} />
            </Col>
          </Row>
        )}

        <Row id="default_row_5">
          <Col
            id="default_col_7"
            xs={
              static_col_breakpoint
                ? static_col_breakpoint
                : input_css === "totalPercentWidth"
                  ? "4 totalPercentWidth"
                  : "12"
            }
            sm={
              static_col_breakpoint
                ? static_col_breakpoint
                : input_css === "totalPercentWidth"
                  ? "4 totalPercentWidth"
                  : "12"
            }
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}>
            <NestedFieldAlert psReady={psReady} invalidFieldErrorMessage={invalidFieldErrorMessage} />
          </Col>
        </Row>
      </Field>
    </Container>
  );
};

export default Default;
