import * as footerActions from "./FooterActionTypes";

export const INITIAL_STATE = {
  privacyTermsModal: false,
  termsOfUseModalOpen: false,
};

export default function footerReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case footerActions.togglePrivacyModal:
      state = update(state, {
        privacyTermsModal: !state.privacyTermsModal,
      });
      break;
    case footerActions.toggleTermsOfUseModal:
      state = update(state, {
        termsOfUseModalOpen: !state.termsOfUseModalOpen,
      });
      break;
    default:
      break;
  }
  return state;
}
