import { useCallback, useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CallbackForm from "./CallbackForm/CallbackForm";
import * as sideMenuActions from "../../SideMenuActions";

const ContactUs = (props) => {
  const [open, toggleCollapse] = useState(false);

  if (props.openComponent && props.openComponent !== "contactUs" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("contactUs");
  }

  //dispatch actions
  const dispatch = useDispatch();

  //outbound
  const handleCallback = useCallback((vm) => dispatch(sideMenuActions.createCallbackRequest(vm)), [dispatch]);

  return (
    <div id="contact_us_main_div_1">
      <div
        id="contact_us_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="contactRVNARow"
        aria-expanded={open}
      >
        Contact Us
      </div>
      <Collapse in={open} className="collapseComponent">
        <div id="contact_us_div_3">
          <Row id="contact_us_row_1">
            <Col id="contact_us_col_1">
              <span id="contact_us_span_1">Call RVNA</span><span id="contact_us_span_2">1 800-364-2433</span>
            </Col>
          </Row>
          <Row id="contact_us_row_2">
            <Col id="contact_us_col_2"><span id="contact_us_span_3">Would you like us to call you?</span></Col>
          </Row>
          <Row id="contact_us_row_3">
            <Col id="contact_us_col_3">Request a Callback and an RVNA insurance expert will call you.</Col>
          </Row>
          <Row id="contact_us_row_4">
            <Col id="contact_us_col_4">
              <CallbackForm handleCallback={handleCallback} />
            </Col>
          </Row>
          <Row id="contact_us_row_5">
            <Col id="contact_us_col_5">
              <div id="contact_us_div_1">
                Need Help adding a Certificate of Insurance?
              </div>
              <span id="contact_us_span_4" onClick={() => window.open("https://www.rvnuccio.com/contact/", "_blank")}>
                Click Here
              </span>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default ContactUs;
