import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from 'react-bootstrap';
import * as footerActions from "../../../Footer/FooterActions";

const TermsModal = () => {
  //store
  const footer = useSelector((state) => state.footer);

  //dispatch actions
  const dispatch = useDispatch();

  //outbound
  const toggleTermsOfUseModal = useCallback(() => dispatch(footerActions.toggleTermsOfUseModal()), [dispatch]);

  //local
  const close = () => {
    toggleTermsOfUseModal();
  };

  return (
    <Modal id="terms_modal_1" show={footer.termsOfUseModalOpen} onHide={close}>
      <Modal.Header id="terms_modal_header_1" className={"d-flex align-items-center border-0 modalHeader"}>
        <div id="terms_modal_div_1">Terms of Use</div>
        <button id="terms_modal_close_btn_1" onClick={() => close()}>X</button>
      </Modal.Header>
      <Modal.Body id="terms_modal_body_1">
        <div id="terms_modal_div_2">
          <div id="terms_modal_div_3">
            <Row id="terms_modal_row_1">
              <Col id="terms_modal_col_1" xs={"12"}>
                WELCOME TO R.V. NUCCIO AND ASSOCIATES THIS WEBSITE IS OFFERED TO YOU CONDITIONED UPON YOUR ACCEPTANCE WITHOUT MODIFICATION OF THE TERMS,
                CONDITIONS AND NOTICES CONTAINED HEREIN. BY ACCESSING OR USING THIS SITE, YOU AGREE TO ALL OF THE R.V. NUCCIO AND ASSOCIATES. POLICY AND ITS TERMS.
                IF YOU DO NOT AGREE TO ANY OF THESE TERMS OR CONDITIONS, DO NOT USE THIS SITE.
              </Col>
              <Col id="terms_modal_col_2" xs={"12 mt-15"}>
                R.V. Nuccio and Associates reserves the right to modify, alter or update the terms at any time and you agree to be bound by any such modification, alteration or update.
              </Col>
              <Col id="terms_modal_col_3" xs={"12 mt-15"}>
                <strong>1. Disclaimer.</strong> The site and the information, services, products and materials contained in this site, including without limitation, text, graphics,
                and links, are provided “AS IS” and without warranties. Visitors to this site assume all responsibility and risk for the use of this site.
                TO THE FULL EXTENT PERMITTED BY LAW, R.V. NUCCIO AND ASSOCIATES DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
                TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE.
                Information provided or contained in this site is provided without charge as a convenience to visitors only. R.V. Nuccio and Associates does not warrant or represent
                the results that may be obtained from the use of the site. R.V. Nuccio and Associates does not warrant or represent that the site or its content is accurate, suitable,
                complete, or up-to-date, nor that access to or use of the site or the server which makes it available will be uninterrupted, error free, or free of viruses or other harmful components.
                You hereby acknowledge that the content of the site may include technical inaccuracies or typographical errors.
              </Col>
              <Col id="terms_modal_col_4" xs={"12 mt-15"}>
                <strong>2. Copyright.</strong> This site, and the text, site design, logos, images, graphics, interfaces, audio clips, software all other content contained herein and the selection,
                arrangement and compilation of all such content are exclusively owned by R.V. Nuccio and Associates and its subsidiaries and affiliates. Permission is granted to view,
                electronically copy and print hard copy portions of this site for the sole purpose of using this site as a resource for your personal non-commercial use only and for no other purpose.
                Any other use, including without limitation the reproduction, modification, distribution, transmission, republication, display, use or performance of the content on this site,
                is strictly prohibited and protected by U.S. and international copyright laws.
              </Col>
              <Col id="terms_modal_col_5" xs={"12 mt-15"}>
                <strong>3. Trademarks.</strong> All trademarks, service marks, trade names, graphics, and logos are proprietary to R.V. Nuccio and Associates or other respective owners that
                have granted R.V. Nuccio and Associates the right and license to use or display such marks. R.V. Nuccio and Associates, and all other R.V. Nuccio and Associates,
                businesses and products (QicQuote®) contained in the site are trademarks of R.V. Nuccio and Associates and/or its subsidiaries or affiliates. R.V. Nuccio and Associates
                does not authorize, and hereby prohibits, the use of any trade name, trademark, service mark, logo, graphics or copyrighted material contained herein.
              </Col>
              <Col id="terms_modal_col_6" xs={"12 mt-15"}>
                <strong>4. Limitation of Liability.</strong> R.V. Nuccio and Associates is not responsible for any infections, contaminations, delay in operation or transmission, line failure,
                errors, omissions, interruptions or defects arising out of your use of this site or with respect to the material provided or contained in this site. In no event shall R.V. Nuccio and
                Associates be liable for any special, indirect, incidental, consequential damages or other damages of any kind, resulting from whatever cause, including but not limited to loss
                of use, loss of profits, loss of data or loss of business opportunity, even if R.V. Nuccio and Associates has been advised of the possibility of such damages. Notwithstanding
                the above, in no event shall R.V. Nuccio and Associates liability to you for any and all claims, damages, losses, and causes of action of any type exceed the amount paid by you,
                if any, for accessing this site. In any event, liability is hereby limited to the fullest extent permitted by applicable law.
              </Col>
              <Col id="terms_modal_col_7" xs={"12 mt-15"}>
                <strong>5. Use of Site.</strong> You expressly agree that the use of this site is at your own risk. This site is for your personal and non-commercial use only and may not be used for
                any purpose that is unlawful or prohibited by these terms. You shall not use this site to send unsolicited advertising, promotional material or other forms of solicitation to other
                users or persons. You shall not post or transmit any unlawful, threatening, abusive, harassing, libelous, defamatory, vulgar, obscene, sexually explicit, pornographic, profane, hateful,
                racially, ethnically or otherwise objectionable material or any other material that would constitute or encourage conduct that is a criminal offense, give rise to civil liability or
                otherwise violate any applicable local, state, provincial, national or international law. You are solely responsible for any information, communication or material you submit to this
                site and you, not R.V. Nuccio and Associates, shall have full responsibility for such information, communication or material, including its legality, reliability, appropriateness,
                originality or copyright. R.V. Nuccio and Associates may terminate or suspend your access without notice for any conduct that R.V. Nuccio and Associates believes, in its sole
                discretion, violates any applicable law or is harmful to the interests of another user, third party provider, or R.V. Nuccio and Associates or its merchants, suppliers or customers.
              </Col>
              <Col id="terms_modal_col_8" xs={"12 mt-15"}>
                <strong>6. Jurisdiction and Choice of Law.</strong>  This site is operated by R.V. Nuccio and Associates from its offices in Toluca Lake, State of California, United States of America.
                R.V. Nuccio and Associates makes no representations that the materials in this site are available or appropriate for use in other locations. Those who access this site from other
                locations do so at their own risk and are responsible for compliance with any and all local laws. This Agreement, and its validity, enforceability, construction and interpretation, shall be
                governed under the laws of the State of California, without regard to choice of law rules. Any legal action regarding this Policy and Terms or this site shall be brought in a court of
                competent jurisdiction located within County of San Bernardino, California and by using this site you consent to personal jurisdiction within these courts.
              </Col>
              <Col id="terms_modal_col_9" xs={"12 mt-15"}>
                <strong>7. Third Party Sites.</strong> R.V. Nuccio and Associates may from time to time provide links or pointers to other sites maintained by third parties and may provide third
                party materials on this site. These third party sites or materials are provided for your convenience only. R.V. Nuccio and Associates does not operate or control in any respect any
                information, products, services or material available on these third party sites and R.V. Nuccio and Associates is not responsible for their content. The fact that R.V. Nuccio and
                Associates has provided a link to such sites is NOT an endorsement, authorization, sponsorship, affiliation, joint venture or partnership by R.V. Nuccio and Associates with
                respect to such sites, its owners or providers. You access these sites at your own risk.
              </Col>
              <Col id="terms_modal_col_10" xs={"12 mt-15"}>
                <strong>FORWARD LOOKING STATEMENTS:</strong><br />
                The words “believes”, “expects”, “estimates”, “projects”, “plans”, “could”, “may”, “anticipates” or the negative thereof or other variations or similar terminology, or discussions of
                strategies or plans identify forward looking statements. These forward looking statements reflect R.V. Nuccio and Associates reasonable judgments with respect to future events
                and are subject to risks and uncertainties that could cause actual results to differ materially from such forward looking statements.
              </Col>
              <Col id="terms_modal_col_11" xs={"12 mt-15"}>
                <strong>ADDITIONAL IMPORTANT PRIVACY INFORMATION:</strong><br />
                Any communication, material or information which you post or transmit to R.V. Nuccio and Associates over the internet, by phone, FAX or through the mail is, and will be
                treated as, confidential and proprietary. R.V. Nuccio and Associates may share aggregated statistical information such as web-site traffic or demographic information with
                its business partners; however, in no case will your personally identifiable information be disclosed. R.V. Nuccio and Associates uses secure server software and encryption
                technologies to assist in protecting your personal information. Secure Socket Layer (SSL) encrypts all of the information you submit on your application and any survey forms before
                they are sent to R.V. Nuccio and Associates.
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer id="terms_modal_footer_1" />
    </Modal>
  );
};

export default TermsModal;
