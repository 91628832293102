import { combineReducers } from "redux";
import app from "./App/AppReducer";
import footer from "./Footer/FooterReducer";
import header from "./Header/HeaderReducer";
import policy from "./PolicyList/PolicyReducer";
import questions from "./Questions/QuestionsReducer";
import reports from "./Reports/ReportsReducer";
import sideMenu from "./SideMenu/SideMenuReducer";
import userMaintenance from "./UserMaintenance/UserMaintenanceReducer";
import userRules from "./UserMaintenance/UserRules/UserRulesReducer";
import historyObj from "./Routing/browserHistory";


const rootReducer = combineReducers({
  app,
  header,
  footer,
  policy,
  questions,
  reports,
  sideMenu,
  userMaintenance,
  userRules,
});

const metaRootReducer = (state, action) => {
  if (action) {
    switch (action.type) {
      case "LOGOUT_SUCCESS":
        sessionStorage.removeItem(`persist:root`);
        state = undefined;
        historyObj.push("/login");
        break;
      default:
        break;
    }
    return rootReducer(state, action);
  }
  else {
    return rootReducer;
  }
};

export default metaRootReducer;
