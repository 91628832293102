import FullPageLoader from "../Loaders/FullPageLoader/FullPageLoader";
import enums from "../Enums/Enums";
import { isArray, isEqual } from "lodash";

const addDays = (days) => {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

const checkCustomRadioValue = (radioValuesArray, substring) => {
  for (let i = 0; i < radioValuesArray.length; i++) {
    if (radioValuesArray[i].includes(substring)) {
      return radioValuesArray[i];
    }
  }
};

const checkFieldHasYupError = (name, errors, touched) => {
  if (touched[name] && errors[name]) {
    return true;
  }

  return false;
};

const checkFieldHasQuestionError = (name, storedInvalidFields) => {
  if (!isNotDuplicateValidation(name, storedInvalidFields)) {
    //if is duplicate, means we can confirm error exists
    return true;
  }

  return false;
};

const convertToOffsetClass = (offsetNumber) => {
  return offsetNumber ? "offset_" + String(offsetNumber) : "";
};

const interpretOffsetClassContext = (label_text, label_prefix, column_offset, word_wrap_class) => {
  if (label_text && label_prefix && !column_offset) {
    return "offset_input_auto_1";
  }
  else if (((!label_text && !label_prefix) || (label_text && !label_prefix)) && column_offset) {
    return column_offset + "_no_prefix " + word_wrap_class;
  }

  return column_offset;
};

const isNotDuplicateValidation = (eligibleField, validationFieldsArray) => {
  if (validationFieldsArray.length > 0) {
    let eligibleFieldLookup;

    //at different points in workflow eligibleField may be obj or just the field name
    if (eligibleField && eligibleField.lookupCode) {
      eligibleFieldLookup = eligibleField.lookupCode;
    }
    else {
      eligibleFieldLookup = eligibleField;
    }

    for (let field in validationFieldsArray) {
      const existingValidationField = validationFieldsArray[field];
      if (
        existingValidationField.lookupCode === eligibleFieldLookup
      ) {

        //fields must check for equal properties since we it may already be in the validation array
        //but the error type has changed, which is not a duplicate validation
        if (typeof eligibleField === "object" && !isEqual(existingValidationField, eligibleField)) {
          return true;
        }

        return false;
      }
    }
  }

  return true;
};

//check if object is truely empty (note: lodash will not)
const isObjEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] !== "") {
      return false;
    }
  }
  return true;
};

const formatCardNumber = (node, value) => {
  // remove all non digit characters
  let inputValue = value.replace(/\D/g, "");
  let formattedValue;
  let maxLength;
  // american express, 15 digits
  if (/^3[47]\d{0,13}$/.test(inputValue)) {
    formattedValue = inputValue.replace(/(\d{4})/, "$1 ").replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    maxLength = 17;
  }
  else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(inputValue)) {
    // diner's club, 14 digits
    formattedValue = inputValue.replace(/(\d{4})/, "$1 ").replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    maxLength = 16;
  }
  else if (/^\d{0,16}$/.test(inputValue)) {
    // regular cc number, 16 digits
    formattedValue = inputValue
      .replace(/(\d{4})/, "$1 ")
      .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
      .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
    maxLength = 19;
  }

  node.setAttribute("maxlength", maxLength);
  return formattedValue;
};

const formatDate = (dateToFormat) => {
  let formattedDate;
  let date = new Date(dateToFormat);
  if (date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    formattedDate = month + "/" + day + "/" + year;

    return formattedDate;
  }
};

const fullPageLoader = () => {
  return (
    <div>
      <FullPageLoader />
    </div>
  );
};

const millisecondsConverter = (interval, amount) => {
  switch (interval) {
    case "seconds":
      return 1000 * amount;
    case "minutes":
      return 60000 * amount;
    case "hours":
      return 3600000 * amount;
    case "days":
      return 86400000 * amount;
    case "weeks":
      return 604800000 * amount;
    default:
      return false;
  }
};

const getInvalidFieldMessage = (name, storedInvalidFields) => {
  return storedInvalidFields.find((error) => error.lookupCode === name);
};

//for combo items inside the json response obj that need their values converted on way back in
const getOrTransformData = (dataObj, type) => {
  if (dataObj) {
    if (type === enums.fieldTypes.combo) {
      const regExp = /\./g;
      return dataObj.replace(regExp, "_").trim();
    }
    return dataObj;
  }
  return "";
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const setBreakpointForWindowDimension = (xs, sm, md, lg, xl) => {
  const { innerWidth: width } = window;

  if (width <= 575) {
    return xs;
  }
  else if (width >= 576 && width <= 767) {
    return sm;
  }
  else if (width >= 768 && width <= 991) {
    return md;
  }
  else if (width >= 992 && width <= 1199) {
    return lg;
  }
  else if (width >= 1200) {
    return xl;
  }
};

const getRepeatingCardActiveRangeEnd = (cardSet) => {
  return cardSet.showHiddenCards
    ? Math.ceil(cardSet.hiddenCardPageCount / 6) * 6
    : Math.ceil(cardSet.showCardPageCount / 6) * 6;
};

const normalizeLookupCode = (lookupCode, programGroupCode) => {
  const regExp = /\./g;

  let normalizedLookupsArray = [];

  //check in case we are receiving an array
  if (lookupCode && isArray(lookupCode) && lookupCode.length > 0) {
    for (let i = 0; i < lookupCode.length; i++) {
      //check that lookupCode includes programGroupCode,contingentResult may include an arbitray string
      //and we don't want to replace periods with underscores in that case
      if (programGroupCode && (lookupCode[i].includes(programGroupCode) || lookupCode[i].includes("PremInd"))) {
        normalizedLookupsArray.push(lookupCode[i].replace(regExp, "_").trim());
      }
      else {
        normalizedLookupsArray.push(lookupCode[i]);
      }
    }
    return normalizedLookupsArray;
  }
  else if (lookupCode && (lookupCode.includes(programGroupCode) || lookupCode.includes("PremInd"))) {
    //restore any unintentionally replaced decimals and return
    return lookupCode.replace(regExp, "_").trim();
  }
  else {
    return lookupCode;
  }
};

const selectFilterConfig = () => {
  //filter defaults
  let ignoreCase = false;
  let ignoreAccents = false;
  let trim = false;
  //let matchFrom = matchFromStart ? "start" : "any"; //if we want to pass in a param later
  let matchFrom = "any";

  const filterConfig = {
    ignoreCase,
    ignoreAccents,
    trim,
    matchFrom,
  };

  return filterConfig;
};

function setRepeatingLookup(lookupCode, QID, ordinalPosition) {
  if (lookupCode && QID && ordinalPosition) {
    return lookupCode.split("-")[0] + "-SetID-" + QID.toUpperCase() + "-OP" + ordinalPosition;
  }
  return lookupCode;
}

const sortByKey = (objToSort) => {
  let newSortedObj = {};

  //sort for viewing pleasure
  Object.keys(objToSort)
    .sort()
    .forEach((key) => {
      newSortedObj[key] = objToSort[key];
    });

  return newSortedObj;
};

//regex
const quoteFormatRegex = { recipe: /\B(?=(\d{3})+(?!\d))/g };

//combine all on this obj
const util = {
  addDays: addDays,
  checkCustomRadioValue: checkCustomRadioValue,
  checkFieldHasYupError: checkFieldHasYupError,
  checkFieldHasQuestionError: checkFieldHasQuestionError,
  convertToOffsetClass: convertToOffsetClass,
  formatCardNumber: formatCardNumber,
  formatDate: formatDate,
  fullPageLoader: fullPageLoader,
  getInvalidFieldMessage: getInvalidFieldMessage,
  getOrTransformData: getOrTransformData,
  getRepeatingCardActiveRangeEnd: getRepeatingCardActiveRangeEnd,
  getWindowDimensions: getWindowDimensions,
  interpretOffsetClassContext: interpretOffsetClassContext,
  isNotDuplicateValidation: isNotDuplicateValidation,
  isObjEmpty: isObjEmpty,
  millisecondsConverter: millisecondsConverter,
  normalizeLookupCode: normalizeLookupCode,
  quoteFormatRegex: quoteFormatRegex,
  selectFilterConfig: selectFilterConfig,
  setBreakpointForWindowDimension: setBreakpointForWindowDimension,
  setRepeatingLookup: setRepeatingLookup,
  sortByKey: sortByKey
};

export default util;
