import * as policyActions from "./PolicyActionTypes";
import * as helper from "./PolicyHelper";
import * as axiosApi from "../axiosApi";
import * as appActions from "../App/AppActions";
import enums from "../Utilities/Enums/Enums";
import { cloneDeep } from "lodash";
import historyObj from "../Routing/browserHistory";

export function clearDocumentList() {
  return { type: policyActions.clearDocumentList };
}

export function clearRepeatingItemDocumentList() {
  return { type: policyActions.clearRepeatingItemDocumentList };
}

export function clearPolicyState() {
  return { type: policyActions.clearPolicyState };
}

export function getAllSubmissions() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/submission/all");
      if (result.data.response === enums.serverResponse.error) {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("PolicyActions - getAllSubmissions", result, false));
        //push home to clear out any state issues and user can try to access allUserPolicies again
        historyObj.push("/");
      }
      else {
        dispatch(getAllSubmissionsSuccess(result.data.data, result.data.message));
        return Promise.resolve(true);
      }
    }
    catch (error) {
      //push home to clear out any state issues and user can try to access allUserPolicies again
      historyObj.push("/");
      return Promise.reject(error);
    }
  };
}

export function getAllSubmissionsSuccess(submissions, queryResultMsg) {
  return { type: policyActions.getAllSubmissionsSuccess, submissions, queryResultMsg };
}

export function getApprovedGroupStatus() {
  return async (dispatch, getState) => {
    try {
      const currentStatus = getState().policy.submission.internalStatus;
      const result = await axiosApi.get("/api/Submission/getApprovedGroupStatus/" + currentStatus);

      if (result.data.response === enums.serverResponse.success) {
        dispatch(setApprovedGroupStatus(result.data.data));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Not authorized to access this feature", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getApprovedGroupStatus", error, false));
    }
  };
}

export function getDocumentList(submissionID) {
  return async (dispatch, getState) => {
    try {
      dispatch(toggleDocumentListLoading());
      //first see if we have it in our local cache for this submission
      const cachedDocList = getState().policy.documentList;
      let subHasCache = false;
      let docCacheForSub;

      if (cachedDocList && cachedDocList.length > 0) {
        docCacheForSub = cachedDocList.find((cache) => cache.submissionID === submissionID);
        subHasCache = docCacheForSub ? true : false;
      }
      if (subHasCache) {
        dispatch(getDocumentListSuccess(submissionID, docCacheForSub.documents));
      }
      else {
        const result = await axiosApi.get("api/policy/doclist/" + submissionID);

        if (result.data.response !== enums.serverResponse.error) {
          dispatch(getDocumentListSuccess(submissionID, result.data.data));
        }
        else {
          dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
          // dispatch(appActions.logError("PolicyActions - getDocumentList", result, false));
          dispatch(setFailedDocRetrievalSubmissionID(submissionID));
        }
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get document list.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getDocumentList", error, false));
      dispatch(setFailedDocRetrievalSubmissionID(submissionID));
    }
    finally {
      dispatch(toggleDocumentListLoading());
    }
  };
}

export function toggleDocumentListLoading() {
  return { type: policyActions.toggleLoadingDocumentList };
}

export function toggleRepeatingItemListLoading() {
  return { type: policyActions.toggleLoadingRepeatingItemList };
}

export function getDocumentListSuccess(submissionID, documents) {
  return { type: policyActions.getDocumentListSuccess, submissionID, documents };
}

export function getPoliciesFromQuery(submissionNumber, deliverTo) {
  return async (dispatch) => {
    try {
      dispatch(togglePolicySetLoading());
      const result = await axiosApi.get("api/submission/policies/" + submissionNumber);
      if (result.data.response === enums.serverResponse.error) {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("PolicyActions - getPoliciesFromQuery", result, false));
      }
      else {
        dispatch(getPoliciesFromQuerySuccess(result.data.data, deliverTo));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get policy set.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getPoliciesFromQuery", error, false));
    }
  };
}

export function getPoliciesFromQuerySuccess(policiesFromQuery, deliverTo) {
  //default is deliver to our master queried list shown on the policyList table
  return { type: policyActions.getPoliciesFromQuerySuccess, policiesFromQuery, deliverTo };
}

export function togglePolicySetLoading() {
  return { type: policyActions.togglePolicySetLoading };
}

export function getRepeatingItemsFromQuery(submissionId, repeatingItemName) {
  return async (dispatch) => {
    try {
      dispatch(toggleRepeatingItemListLoading());
      const vm = {
        submissionId,
        RepeatingItemPageName: repeatingItemName.trim()
      }
      const result = await axiosApi.post("api/submission/getRepeatingItemData", vm);
      if (result.data.response === enums.serverResponse.error) {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
      }
      else {
        dispatch(getRepeatingItemsFromQuerySuccess(result.data.data, repeatingItemName));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get document list.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getRepeatingItemsFromQuery", error, false));
    }
    finally {
      dispatch(toggleRepeatingItemListLoading());
    }
  };
}

export function getRepeatingItemsFromQuerySuccess(repeatingItemDocsFromQuery, repeatingItemName) {
  return { type: policyActions.getRepeatingItemsFromQuerySuccess, repeatingItemDocsFromQuery, repeatingItemName };
}

export function getRatingData(submission) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/submission/ratedata/" + submission.submissionID);
      if (result.data.response !== enums.serverResponse.error) {
        dispatch(getRatingDataSuccess(result.data.data));
        dispatch(setCurrentRatingSubmission(submission));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("PolicyActions - getRatingData", result, false));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get rating data.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getRatingData", error, false));
    }
  };
}

export function getRatingDataSuccess(ratedata) {
  return { type: policyActions.getRatingDataSuccess, ratedata };
}

export function setCurrentRatingSubmission(submission) {
  return { type: policyActions.setCurrentRatingSubmission, submission };
}

export function getSelectedPDFs(vm) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.post("api/policy/pdf", vm);
      if (result.data.response !== enums.serverResponse.error) {
        if (vm.EmailDocuments) {
          dispatch(appActions.reportToast("Documents successfully emailed.", enums.toastTypes.success));
        }
        else {
          const filePath = result.data.data;
          const pdfWindow = window.open(filePath, "", "height=700,width=500");
          if (pdfWindow) {
            pdfWindow.location.href = filePath;
          }
          else {
            dispatch(
              appActions.reportToast(
                "An error occured with the selected document. Please adjust your pop-up blocker settings and try again.",
                enums.toastTypes.error
              )
            );
          }
        }
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        dispatch(setFailedDocRetrievalSubmissionID(vm.SubmissionID));
        // dispatch(appActions.logError("PolicyActions - getSelectedPDFs", result, false));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get selected documents.", enums.toastTypes.error));
      dispatch(setFailedDocRetrievalSubmissionID(vm.SubmissionID));
      dispatch(appActions.logError("PolicyActions - getSelectedPDFs", error, false));
    }
  };
}

export function getSelectedRepeatingItemPDFs(vm) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.post("api/policy/repeatingItemsPDF", vm);
      if (result.data.response !== enums.serverResponse.error) {
        if (vm.EmailDocuments) {
          dispatch(appActions.reportToast("Documents successfully emailed.", enums.toastTypes.success));
        }
        else {
          const filePath = result.data.data;
          const pdfWindow = window.open(filePath, "", "height=700,width=500");
          if (pdfWindow) {
            pdfWindow.location.href = filePath;
          }
          else {
            dispatch(
              appActions.reportToast(
                "An error occured with the selected document. Please adjust your pop-up blocker settings and try again.",
                enums.toastTypes.error
              )
            );
          }
        }
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get selected documents.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getSelectedRepeatingItemPDFs", error, false));
    }
  }
}

export function getStatusHistory(submissionNumber) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/submission/history/" + submissionNumber);

      if (result.data.response !== enums.serverResponse.error) {
        dispatch(getStatusHistorySuccess(result.data.data));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("PolicyActions - getStatusHistory", result, false));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get status history.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getStatusHistory", error, false));
    }
  };
}

export function getStatusHistorySuccess(policyHistory) {
  return { type: policyActions.getStatusHistorySuccess, policyHistory };
}

export function getSubmissionData(submission) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/submission/data/" + submission.submissionID);
      if (result.data.response !== enums.serverResponse.error) {
        dispatch(getSubmissionDataSuccess(result.data.data));
        dispatch(setCurrentUWSubmission(submission));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("PolicyActions - getSubmissionData", result, false));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get submission data.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getSubmissionData", error, false));
    }
  };
}

export function getSubmissionDataSuccess(responses) {
  return { type: policyActions.getSubmissionDataSuccess, responses };
}

export function setCurrentUWSubmission(submission) {
  return { type: policyActions.setCurrentUWSubmission, submission };
}

export function getValidationHistory(submissionNumber) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/submission/validationhistory/" + submissionNumber);
      if (result.data.response !== enums.serverResponse.error) {
        dispatch(getValidationHistorySuccess(result.data.data));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("PolicyActions - getValidationHistory", result, false));
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to get validation history.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - getValidationHistory", error, false));
    }
  };
}

export function changePolicyOwner(email, submissionId, submissionNumber) {
  return async (dispatch, getState) => {
    try {
      const vm = {
        Email: email,
        SubmissionId: submissionId,
        AutoRegisterDirect: false,
      };

      const result = await axiosApi.post("api/submission/changeuser", vm);

      if (result.data.response !== enums.serverResponse.error) {
        dispatch(
          appActions.reportToast(
            "The broker for policy " + submissionNumber + " is now " + email,
            enums.toastTypes.success
          )
        );
        dispatch(togglePolicyModal(getState().policy.submission));
        dispatch(updateChangedPolicy(submissionId, result.data.data.user));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
      }
    }
    catch (error) {
      dispatch(appActions.logError("PolicyActions - changePolicyOwner", error, false));
    }
  };
}

export function changePolicyDate(submissionId, submissionNumber, programCode, newEffectiveDate) {
  return async (dispatch, getState) => {
    try {
      const submission = getState().policy.submission;
      const programCodeToUse = programCode ? programCode : submission.programCode;
      const vm = {
        SubmissionId: submissionId,
        ProgramCode: programCodeToUse,
        NewEffectiveDate: newEffectiveDate
      };
      dispatch(appActions.reportToast("Changing Effective Date...", enums.toastTypes.success));

      const result = await axiosApi.post("api/submission/changePolicyDate", vm);

      if (result.data.response !== enums.serverResponse.error) {
        dispatch(appActions.reportToast("The effective date for submission# " + submissionNumber + " is now " + newEffectiveDate, enums.toastTypes.success));
        dispatch(togglePolicyModal(getState().policy.submission));
        dispatch(updatePolicyDate(submissionId, result.data.data));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
      }
    }
    catch (error) {
      dispatch(appActions.logError("PolicyActions - changePolicyDate", error, false));
    }
  }
}

export function updateChangedPolicy(submissionId, newUser) {
  return { type: policyActions.updateChangedPolicy, submissionId, newUser };
}

export function updatePolicyDate(submissionId, submission) {
  return { type: policyActions.updatePolicyDate, submissionId, submission };
}

export function getValidationHistorySuccess(validationHistory) {
  return { type: policyActions.getValidationHistorySuccess, validationHistory };
}

export function managePolicy(vm) {
  return async (dispatch, getState) => {
    try {
      dispatch(setApprovalPendingStatus(true));
      const result = await axiosApi.post("api/policy/manage", vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("PolicyActions - managePolicy", result, false));
      }
      else {
        //dispatch appropriately based on the mode
        switch (vm.Mode) {
          case "approve":
          case "decline":
          case "cancel":
          case "reinstate":
          case "cancelEndorsement":
          case "cancelRenewal":
          case "lock":
          case "unlock":
            dispatch(appActions.reportToast(result.data.message, enums.toastTypes.success));
            dispatch(getAllSubmissions());
            break;
          default:
            break;
        }
        if (result.data.data) {
          const newSubmissionData = result.data.data;
          dispatch(appActions.setCurrentSubmission(newSubmissionData)); //update the currentSubmission

          //if we have a policiesFromQuery list, then we need to locate the submission and update it there first
          const policiesFromQueryCopy = cloneDeep(getState().policy.policiesFromQuery);

          if (policiesFromQueryCopy && policiesFromQueryCopy.length > 0) {
            const updatedPoliciesFromQuery = helper.updatePolicyList(newSubmissionData, policiesFromQueryCopy);
            dispatch(getPoliciesFromQuerySuccess(updatedPoliciesFromQuery));
          }

          //then update the bulk list of allUserPolicies
          const allUserPoliciesCopy = cloneDeep(getState().policy.allUserPolicies);

          if (allUserPoliciesCopy && allUserPoliciesCopy.length > 0) {
            const updatedPolicyList = helper.updatePolicyList(newSubmissionData, allUserPoliciesCopy);
            dispatch(getAllSubmissionsSuccess(updatedPolicyList, null));
          }

          //tell the policy list we have updated data and it should reload reducer values
          dispatch(setRefreshPolicies(true));
          dispatch(togglePolicyModal(newSubmissionData));
        }
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable modify the policy status at this time.", enums.toastTypes.error));
      dispatch(appActions.logError("PolicyActions - managePolicy", error, false));
    }
    finally {
      dispatch(setApprovalPendingStatus(false));
    }
  };
}

export function setApprovalPendingStatus(status) {
  return { type: policyActions.setApprovalPendingStatus, status };
}

export function openPolicyModal() {
  return { type: policyActions.openPolicyModal };
}

export function setApprovedGroupStatus(status) {
  return { type: policyActions.setApprovedGroupStatus, status };
}

export function setFailedDocRetrievalSubmissionID(submissionID) {
  return { type: policyActions.setFailedDocRetrievalSubmissionID, submissionID };
}

export function setRefreshPolicies(refreshPolicies) {
  return { type: policyActions.setRefreshPolicies, refreshPolicies };
}

export function showPolicyModal(submission) {
  return { type: policyActions.togglePolicyModal, submission };
}

export function toggleDocsModal() {
  return { type: policyActions.toggleDocsModal };
}

export function toggleRepeatingItemDocsModal() {
  return { type: policyActions.toggleRepeatingItemDocsModal };
}

export function toggleEmailDocuments() {
  return { type: policyActions.toggleEmailDocuments };
}

export function toggleFullPageLoader() {
  return { type: policyActions.toggleFullPageLoader };
}

export function togglePolicyModal(submission) {
  return (dispatch) => {
    dispatch(appActions.setCurrentSubmission(submission, submission.programCode));
    dispatch(showPolicyModal(submission));
  };
}

export function toggleRatingModal() {
  return { type: policyActions.toggleRatingModal };
}

export function toggleSelectAllDocuments() {
  return { type: policyActions.toggleSelectAllDocuments };
}

export function toggleUnderwritingModal() {
  return { type: policyActions.toggleUnderwritingModal };
}
