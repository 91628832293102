import * as questionActions from "./QuestionsActionTypes";
import * as helper from "./QuestionHelpers/QuestionsHelper";
import * as premIndHelper from "./QuestionHelpers/PremIndHelper";
import * as repeatingCardsHelper from "./QuestionsForm/QuestionsTypes/RepeatingCards/RepeatingCardsHelper";
import * as valuesHelper from "./QuestionHelpers/ValuesHelper";
import * as axiosApi from "../axiosApi";
import * as appActions from "../App/AppActions";
import util from "../Utilities/Functions/Functions";
import enums from "../Utilities/Enums/Enums";
import { clone, cloneDeep, filter, forEach, find, merge } from "lodash";
import historyObj from "../Routing/browserHistory";

export function abortSubmission(error, toastErrorMsg, toastSuccessMsg, redirectToErrorPage, redirectToPolicyList) {
  return (dispatch) => {
    const errorMsg = toastErrorMsg ? toastErrorMsg : "A critical error occured. Please try again later.";

    //clear state, report toast, redirect to error page
    dispatch(clearQuestionsState());

    if (toastSuccessMsg) {
      dispatch(appActions.reportToast(toastSuccessMsg, enums.toastTypes.success));
    }
    else {
      dispatch(appActions.reportToast(errorMsg, enums.toastTypes.error));
    }

    if (error) {
      dispatch(appActions.logError("QuestionsActions - abortSubmission", error, redirectToErrorPage));
    }

    if (redirectToPolicyList) {
      historyObj.push("/policylist");
    }
  };
}

export function checkDateLessThanAIs(date, submissionID, programCode) {
  return async (dispatch) => {
    try {
      if (submissionID && submissionID !== "") {
        const vm = {
          NewEffectiveDate: date,
          ProgramCode: programCode,
          SubmissionID: submissionID,
        };

        const result = await axiosApi.post("api/submission/checkDate/", vm);

        if (result === enums.serverResponse.error) {
          dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
          // dispatch(appActions.logError("QuestionsActions - checkDateLessThanAIs", result, false));
        }
        else {
          dispatch(setDateConflictAlert(result.data.data, date, true));
        }
      }
    }
    catch (error) {
      dispatch(appActions.logError("QuestionsActions - checkDateLessThanAIs", error, false));
    }
  };
}

export function clearAppliedDataDefaults() {
  return { type: questionActions.clearAppliedDataDefaults };
}

export function clearLoadingFlags() {
  return { type: questionActions.clearLoadingFlags };
}

export function clearPropertyValueFromPSField(fieldID, propertyToClear) {
  return { type: questionActions.clearPropertyValueFromPSField, fieldID, propertyToClear };
}

export function clearPSReportLog() {
  return { type: questionActions.clearPSReportLog };
}

export function clearQuestionsState() {
  return { type: questionActions.clearQuestionsState };
}

export function clearSubpage() {
  return { type: questionActions.clearSubpage };
}

export function clearQuestionsToast() {
  return { type: questionActions.clearQuestionsToast };
}

export function emailCardCert(emailAddress, submissionID) {
  return async (dispatch) => {
    try {
      const vm = {
        SubmissionID: submissionID,
        ToAddress: emailAddress,
        FromAddress: "",
        DateSent: "",
        Status: "",
        Subject: "",
      };

      const result = await axiosApi.post("api/Submission/emailCert", vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("QuestionsActions - emailCardCert".result, false));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.success));
      }
    }
    catch (error) {
      dispatch(
        appActions.reportToast(
          "Sorry, we could not send the certificate at this time. Please try again later.",
          enums.toastTypes.error
        )
      );
      dispatch(appActions.logError("QuestionsActions - emailCardCert", error, false));
    }
  };
}

export function emailQuoteLetter(vm) {
  return async (dispatch) => {
    try {
      dispatch(appActions.reportToast("Emailing quote letter...", enums.toastTypes.success));
      const result = await axiosApi.post("api/Submission/emailQuoteLetter", vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("QuestionsActions - emailQuoteLetter", result, false));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.success));
      }
    }
    catch (error) {
      dispatch(
        appActions.reportToast("Unable to email quote at this time. Please try again later.", enums.toastTypes.error)
      );
      dispatch(appActions.logError("QuestionsActions - emailQuoteLetter", error, false));
    }
  };
}

export function executePageScript(field, value, values, issuingInputLookup, executionTime, userEntityType) {
  return {
    type: questionActions.executePageScript,
    field,
    value,
    values,
    issuingInputLookup,
    executionTime,
    userEntityType,
  };
}

export function exitSubmissionWithoutSave() {
  return (dispatch) => {
    dispatch(clearQuestionsState());
    historyObj.push("/policylist");
  };
}

export function getNavigationData(submissionID, pageUrl) {
  return async (dispatch) => {
    try {
      if (!submissionID) {
        dispatch(
          abortSubmission(
            { message: "" },
            "Sorry, we cannot find an active policy at this time. Please try again later.",
            null,
            false,
            true
          )
        );
        return Promise.reject(null);
      }
      else {
        const result = await axiosApi.get("api/Submission/Navigation/" + submissionID);

        if (result.data.response === enums.serverResponse.error) {
          //give message back to parent to decide on error outcome
          appActions.reportToast(result.data.message, enums.toastTypes.error);
          return Promise.reject(null);
        }
        else {
          const landingPage = pageUrl ? pageUrl : result.data.data[0].pageUrl;
          dispatch(getNavigationDataSuccess(result.data.data, landingPage));
          return Promise.resolve(result.data.data[0]);
        }
      }
    }
    catch (error) {
      dispatch(
        abortSubmission(error, "Unable to retrieve navigation data. Please try again later.", null, true, false)
      );
      return Promise.reject(null);
    }
  };
}

export function getNavigationDataSuccess(navigationData, pageUrl) {
  return {
    type: questionActions.getNavigationDataSuccess,
    navigationData,
    pageUrl,
  };
}

export function getDocumentPackagePDF(packageName, submissionID) {
  return async (dispatch) => {
    try {
      dispatch(appActions.reportToast("Retrieving quote letter...", enums.toastTypes.success));
      const vm = {
        SubmissionID: submissionID,
        PackageName: packageName,
      };

      const result = await axiosApi.post("api/policy/pdfDirect", vm);

      if (result.data.response !== enums.serverResponse.error) {
        const filePath = result.data.data;
        const pdfWindow = window.open(filePath, "", "height=700,width=500");
        if (pdfWindow) {
          pdfWindow.location.href = filePath;
        }
        else {
          dispatch(
            appActions.reportToast(
              "An error occured with the selected document. Please adjust your pop-up blocker settings and try again.",
              enums.toastTypes.error
            )
          );
        }
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("QuestionsActions - getDocumentPackagePDF", result, false));
      }
    }
    catch (error) {
      dispatch(
        appActions.reportToast(
          "The policy documents are unavailable at this time. Please try again later.",
          enums.toastTypes.error
        )
      );
      dispatch(appActions.logError("QuestionsActions - getDocumentPackagePDF", error, false));
    }
  };
}

export function getFacilityConfigSuccess(facilityRefCode, facilityConfigData) {
  return { type: questionActions.getFacilityConfigSuccess, facilityRefCode, facilityConfigData };
}

export function getPremQuote(quoteBundle) {
  return async (dispatch, getState) => {
    try {
      const appLevelRole = getState().app.user.entityTypeCode;
      const { navigateToPageName, navigateToPageUrl, pageUrl, programCode, submissionID } = quoteBundle;
      const vm = helper.buildQuoteVM(submissionID, pageUrl, navigateToPageUrl);

      const result = await axiosApi.post("api/rating/rate/", vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        throw result;
      }
      else {
        dispatch(
          validateAllPagesSuccess(
            result.data.data.isValid,
            result.data.data.pageValidations,
            result.data.data.requiresReview,
            result.data.data.quote,
            result.data.data.quoteMinusCommission,
            result.data.data.downPayment,
            navigateToPageName,
            navigateToPageUrl,
            appLevelRole,
            result.data.data.hasNegativeQuote,
            result.data.data.hasWorkersCompCoverage,
            result.data.data.hasSurplusLineCoverage,
            result.data.data.requiresOutOfStateUnemploymentNumber
          )
        );
        await dispatch(getQuestionsAndData(submissionID, navigateToPageUrl, navigateToPageName, programCode));
        historyObj.push("/questions/" + navigateToPageUrl);
        return Promise.resolve(true);
      }
    }
    catch (error) {
      return Promise.reject(error);
    }
  };
}

export function getQuestionsAndData(submissionID, pageUrl, pageName, programCode, entityTypeCode) {
  return async (dispatch, getState) => {
    try {
      const config = getState().app.config;
      const configEntity = getState().app.configEntity;
      const entityID = getState().app.user.entityId;
      const appLevelRole = getState().app.user.entityTypeCode;

      const result = await axiosApi.get(
        "api/ComponentData/GetQuestionsAndData/" + submissionID + "/" + pageUrl + "/" + programCode
      );

      if (result.data.response === enums.serverResponse.error) {
        if (result.data.data === enums.statusInternal.locked) {
          //hard abort, reset questions state and bail out to policyList
          dispatch(abortSubmission(null, result.data.message, null, false, true));
        }
        else {
          //inform via toast and throw to catch
          dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
          throw result;
        }
      }
      else {
        dispatch(
          getQuestionsAndDataSuccess(
            result.data.data,
            submissionID,
            pageUrl,
            pageName,
            programCode,
            result.data.data.submissionOwnerRole,
            null,
            config,
            configEntity,
            entityID,
            appLevelRole
          )
        );
        return Promise.resolve(true);
      }
    }
    catch (error) {
      //allow parent to interpret error and log it
      return Promise.reject(error);
    }
  };
}

export function getQuestionsAndDataSuccess(
  questionData,
  submissionID,
  pageUrl,
  pageName,
  programCode,
  userEntityType,
  activeCardSet,
  config,
  configEntity,
  entityID,
  appLevelRole
) {
  if (questionData && submissionID) {
    questionData.submissionID = submissionID;
  }
  return {
    type: questionActions.getQuestionsAndDataSuccess,
    questionData,
    submissionID,
    pageUrl,
    pageName,
    programCode,
    userEntityType,
    activeCardSet,
    config,
    configEntity,
    entityID,
    appLevelRole,
  };
}

export function getQuote(formValues) {
  return async (dispatch, getState) => {
    const questions = getState().questions;
    const appLevelRole = getState().app.user.entityTypeCode;

    const clonedValues = cloneDeep(formValues);
    const cleanFormValues = helper.removeHiddenECardValues(clonedValues, questions.questions);

    //clean out default child values of deselected visibility parent fields
    const cleanValues = helper.clearHiddenChildFieldValues(questions.questions, cleanFormValues);

    dispatch(setLoadingQuoteFlag());
    //hide qr form to show quote page
    dispatch(toggleQRFormHide());

    try {
      //save the data
      await dispatch(save(questions, cleanValues, false));

      //rate it
      const vm = helper.buildQuoteVM(questions.submissionID, questions.pageUrl);
      const result = await axiosApi.post("api/rating/rate/", vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(toggleQRFormHide());
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("QuestionsActions - getQuote", result, false));
      }
      else {
        const rateResult = result.data.data;
        dispatch(
          validatePageSuccess(
            rateResult.isValid,
            rateResult.pageValidations,
            rateResult.quote,
            questions.navigationPage,
            questions.pageUrl,
            appLevelRole,
            rateResult.hasNegativeQuote,
            rateResult.hasWorkersCompCoverage,
            rateResult.hasSurplusLineCoverage,
            rateResult.requiresOutOfStateUnemploymentNumber
          )
        );
      }
    }
    catch (error) {
      dispatch(toggleQRFormHide());
      dispatch(
        appActions.reportToast(
          "We're unable to quote your policy at this time. Please try again later.",
          enums.toastTypes.error
        )
      );
      dispatch(appActions.logError("QuestionsActions - getQuote", error, false));
    }
    finally {
      dispatch(clearLoadingFlags());
    }
  };
}

export function hideAllInputs(bool) {
  return { type: questionActions.hideAllInputs, bool };
}

export function issueExitWithoutSaveModal(message) {
  return { type: questionActions.issueExitWithoutSaveModal, message };
}

export function issueSaveAndExitModal() {
  return { type: questionActions.issueSaveAndExitModal };
}

export function toggleSaveAndExitAIModal() {
  return { type: questionActions.toggleSaveAndExitAIModal };
}

export function navigate(
  questions,
  formValues,
  navigateToPageUrl,
  navigateToPageName,
  withoutSaveOrValidation,
  saveAndExit,
  saveAndIgnoreValidation
) {
  return (dispatch, getState) => {
    dispatch(setLoadingFlag());
    dispatch(hideAllInputs(false));
    dispatch(clearSubpage());
    const appLevelRole = getState().app.user.entityTypeCode;

    //clean out default child values of deselected visibility parent fields
    const cleanValues = helper.clearHiddenChildFieldValues(questions.questions, formValues);

    //CASE 1: navigate without validation
    if (withoutSaveOrValidation) {
      if (!navigateToPageUrl && !navigateToPageName) {
        //coming from modal ignore validation and nav
        dispatch(
          getQuestionsAndData(
            questions.submissionID,
            questions.navigateTo.pageUrl,
            questions.navigateTo.pageName,
            questions.programCode
          )
        )
          .then((result) => {
            dispatch(setNavigationPage(questions.navigateTo.pageName, questions.navigateTo.pageUrl));
            historyObj.push("/questions/" + questions.navigateTo.pageUrl);
          })
          .catch((error) => {
            dispatch(appActions.logError("QuestionsActions - navigate", error, false));
          })
          .finally(() => {
            dispatch(clearLoadingFlags());
          });
      }
      else {
        //coming from errors page
        dispatch(
          getQuestionsAndData(questions.submissionID, navigateToPageUrl, navigateToPageName, questions.programCode)
        )
          .then((result) => {
            dispatch(setNavigationPage(navigateToPageName, navigateToPageUrl));
            historyObj.push("/questions/" + navigateToPageUrl);
          })
          .catch((error) => {
            dispatch(appActions.logError("QuestionsActions - navigate", error, false));
          })
          .finally(() => {
            dispatch(clearLoadingFlags());
          });
      }
    }
    //CASE 2: standard, nonRCard-bearing page navigation
    else {
      if (saveAndExit) {
        //user is bailing out of submission without navigating
        dispatch(save(questions, cleanValues, false, null, true));
        dispatch(appActions.clearFacilityRefCode());
        historyObj.push("/");
      }
      else {
        dispatch(setNavigateTo(navigateToPageName, navigateToPageUrl));
        dispatch(
          saveAndNavigate(
            questions.navigationPage,
            questions.pageUrl,
            cleanValues,
            questions.questionIDLookups,
            questions.submissionID,
            navigateToPageUrl,
            navigateToPageName,
            questions.programCode,
            saveAndIgnoreValidation,
            appLevelRole
          )
        )
          .catch((error) => {
            dispatch(appActions.logError("QuestionsActions - navigate".error, false));
          })
          .finally(() => {
            dispatch(clearLoadingFlags());
          });
      }
    }
  };
}

export function logPSReports(psReports) {
  return async (dispatch, getState) => {
    const vm = {
      SubmissionID: getState().questions.submissionID,
      ValidationType: "PAGESCRIPT",
      Reports: psReports,
    };

    try {
      //just a fire-and-forget, don't care or need any return values
      await axiosApi.post("api/submission/logValidation", vm);
      // dispatch(clearPSReportLog());
    }
    catch (error) {
      return;
    }
  };
}

export function reportQuestionsToast(toastMsg, toastType) {
  return {
    type: questionActions.reportQuestionsToast,
    toastMsg,
    toastType,
  };
}

export function save(questionsState, formValues, returnPageData, ordinalPos, navigateToHome) {
  return async (dispatch, getState) => {
    const questions = questionsState ? questionsState : getState().questions;
    let result;

    try {
      const vm = helper.buildQuestionVM(questions, formValues, navigateToHome);
      vm.ordinalPosition = ordinalPos ? ordinalPos : 0;

      if (returnPageData) {
        result = await axiosApi.post("api/ComponentData/saveAndReturnPageData", vm);
      }
      else {
        result = await axiosApi.post("api/Rules/saveAndValidatePage", vm);
      }

      if (result.data.response === enums.serverResponse.error) {
        if (result.data.data === enums.statusInternal.locked) {
          //hard abort, reset questions state and bail out to policyList
          dispatch(issueExitWithoutSaveModal("This submission is locked."));
        }
        else {
          dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
          throw result;
        }
      }
      else {
        //always update program code in case we've gone from group to child
        dispatch(updateProgramCode(result.data.data.programCode));
        return Promise.resolve(result.data.data);
      }
    }
    catch (error) {
      return Promise.reject(error);
    }
  };
}

export function saveAndNavigate(
  pageName,
  pageUrl,
  values,
  questionIDLookups,
  submissionID,
  navigateToPageUrl,
  navigateToPageName,
  programCode,
  saveAndIgnoreValidation, //e.g. back button
  appLevelRole
) {
  return async (dispatch, getState) => {
    const state = getState();
    const clonedValues = cloneDeep(values);
    const cleanFormValues = helper.removeHiddenECardValues(clonedValues, state.questions.questions);

    try {
      const saveResult = await dispatch(save(state.questions, cleanFormValues, false));

      //CASE 1: Invalid form, show message and wait to navigate
      if (!saveResult.isValid && !saveAndIgnoreValidation) {
        dispatch(
          validatePageSuccess(
            saveResult.isPageValid,
            saveResult.pageValidations,
            saveResult.quote,
            pageName,
            pageUrl,
            appLevelRole
          )
        );
        //invalid, reload the form values that were cleared on submission
        dispatch(updateInitialValues(cleanFormValues, questionIDLookups));
        historyObj.push("/questions/" + pageUrl);
        return Promise.resolve(true);
      }

      //CASE 2: Valid form, proceed to navigation
      else {
        const result = await dispatch(getNavigationData(submissionID, navigateToPageUrl));

        if (result) {
          if (navigateToPageUrl.indexOf("PremInd") > -1) {
            const quoteBundle = {
              navigateToPageName: navigateToPageName,
              navigateToPageUrl: navigateToPageUrl,
              pageName: pageName,
              pageUrl: pageUrl,
              programCode: programCode,
              submissionID: submissionID,
            };

            await dispatch(getPremQuote(quoteBundle));
            return Promise.resolve(true);
          }
          else {
            dispatch(
              validatePageSuccess(
                saveResult.isValid,
                saveResult.pageValidations,
                saveResult.quote,
                pageName,
                pageUrl,
                appLevelRole
              )
            );
            await dispatch(
              getQuestionsAndData(submissionID, navigateToPageUrl, navigateToPageName, saveResult.programCode)
            );

            historyObj.push("/questions/" + navigateToPageUrl);
            return Promise.resolve(true);
          }
        }
        else {
          //catch data translation error, reload page with values, don't allow to proceed
          dispatch(validatePageSuccess(true, null, null, pageName, pageUrl, appLevelRole));
          dispatch(updateInitialValues(cleanFormValues, questionIDLookups));
          dispatch(appActions.reportToast("Could not navigate at this time. Please try again.", enums.toastTypes.error));
        }
      }
    }
    catch (error) {
      //catch data translation error, reload page with values, don't allow to proceed
      dispatch(validatePageSuccess(true, null, null, pageName, pageUrl, appLevelRole));
      dispatch(updateInitialValues(cleanFormValues, questionIDLookups));

      //pass error up to parent
      return Promise.reject(error);
    }
  };
}

export function saveAndExit(questions, values, navigateToPageUrl, navigateToPageName, withoutSaveOrValidation) {
  return async (dispatch) => {
    try {
      await dispatch(navigate(questions, values, navigateToPageUrl, navigateToPageName, withoutSaveOrValidation, true));
      if (questions.userEntityType === enums.role.unknown) {
        dispatch(appActions.logout());
      }
    }
    catch (error) {
      return;
    }
  };
}

export function setDateConflictAlert(alert, newEffectiveDate, isAIAlert) {
  return { type: questionActions.setDateConflictAlert, alert, newEffectiveDate, isAIAlert };
}

export function setLoadingFlag() {
  return { type: questionActions.setLoadingFlag };
}

export function setLoadingQuoteFlag() {
  return { type: questionActions.setLoadingQuoteFlag };
}

export function setNavigateTo(pageName, pageUrl) {
  return { type: questionActions.setNavigateTo, pageName, pageUrl };
}

export function setNavigationPage(pageName, pageUrl) {
  return { type: questionActions.setNavigationPage, pageName, pageUrl };
}

export function setProgramCode(programCode) {
  return { type: questionActions.setProgramCode, programCode };
}

export function setSubmissionID(submissionID) {
  return { type: questionActions.setSubmissionID, submissionID };
}

export function setupQuestions() {
  return async (dispatch, getState) => {
    const currentSubmission = getState().app.currentSubmission;
    const facilityRefCode = getState().app.facilityRefCode; //need this for pageScript, which can't run off of appState!!
    const programCode = currentSubmission.programCode;
    const submissionID = currentSubmission.submissionID;

    //need nav data to determine data needs and first page, endorsed policies will have different first page & no QR
    try {
      const navDataResult = await dispatch(getNavigationData(submissionID));
      if (navDataResult) {
        dispatch(getFacilityConfigSuccess(facilityRefCode));
        //access the policy store and then build the questions to avoid the parameter being passed in the url
        await dispatch(
          getQuestionsAndData(submissionID, navDataResult.pageUrl, navDataResult.pageDisplayName, programCode)
        );
        dispatch(toggleSubmissionLoaded());
      }
      else {
        appActions.reportToast("Could not setup questions at this time, please try again later.", enums.toastTypes.error);
      }
    }
    catch (error) {
      dispatch(abortSubmission(error, null, null, false, true));
    }
  };
}

export function toggleModal() {
  return { type: questionActions.toggleModal };
}

export function toggleQRFormHide() {
  return { type: questionActions.toggleQRFormHide };
}

export function toggleResetFormikToInitial() {
  return { type: questionActions.toggleResetFormikToInitial };
}

export function toggleSubmissionLoaded() {
  return { type: questionActions.toggleSubmissionLoaded };
}

export function updateFieldValidations(fields, valid) {
  return { type: questionActions.updateFieldValidations, fields, valid };
}

export function updateInitialValues(values, newIDs) {
  return { type: questionActions.updateInitialValues, values, newIDs };
}

export function updateProgramCode(programCode) {
  return { type: questionActions.updateProgramCode, programCode };
}

export function validatePageSuccess(
  isValid,
  pageValidations,
  quote,
  pageName,
  pageUrl,
  appLevelRole,
  hasNegativeQuote,
  hasWorkersCompCoverage,
  hasSurplusLineCoverage,
  requiresOutOfStateUnemploymentNumber
) {
  return {
    type: questionActions.validationResultSingle,
    isValid,
    pageValidations,
    quote,
    pageName,
    pageUrl,
    appLevelRole,
    hasNegativeQuote,
    hasWorkersCompCoverage,
    hasSurplusLineCoverage,
    requiresOutOfStateUnemploymentNumber,
  };
}

export function validateAllPagesSuccess(
  isValid,
  pageValidations,
  requiresReview,
  quote,
  quoteMinusCommission,
  downPayment,
  pageName,
  pageUrl,
  appLevelRole,
  hasNegativeQuote,
  hasWorkersCompCoverage,
  hasSurplusLineCoverage,
  requiresOutOfStateUnemploymentNumber
) {
  return {
    type: questionActions.validationResultAll,
    isValid,
    pageValidations,
    requiresReview,
    quote,
    quoteMinusCommission,
    downPayment,
    pageName,
    pageUrl,
    appLevelRole,
    hasNegativeQuote,
    hasWorkersCompCoverage,
    hasSurplusLineCoverage,
    requiresOutOfStateUnemploymentNumber,
  };
}

export function validateSubmissionSuccess(validationResult, pageName, pageUrl) {
  return {
    type: questionActions.submissionValidationResult,
    validationResult,
    pageName,
    pageUrl,
  };
}

export function finalQuoteLetter(submissionID) {
  return async (dispatch) => {
    try {
      dispatch(appActions.reportToast("Retrieving final quote letter...", enums.toastTypes.success));
      const vm = {
        SubmissionID: submissionID,
        PackageName: "Quote Letter",
      };

      const result = await axiosApi.post("api/policy/pdfDirect", vm);

      if (result.data.response !== enums.serverResponse.error) {
        const filePath = result.data.data;
        const pdfWindow = window.open(filePath, "", "height=700,width=500");
        if (!pdfWindow) {
          dispatch(
            appActions.reportToast(
              "An error occured with the selected document. Please adjust your pop-up blocker settings and try again.",
              enums.toastTypes.error
            )
          );
        }
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("QuestionsActions - finalQuoteLetter", result, false));
      }
    }
    catch (error) {
      dispatch(
        appActions.reportToast(
          "Final quote letter is unavailable at this time. Please try again later.",
          enums.toastTypes.error
        )
      );
      dispatch(appActions.logError("QuestionsActions - finalQuoteLetter", error, false));
    }
  };
}

export function getPolicyDocumentPackage(packageName) {
  return async (dispatch, getState) => {
    try {
      const vm = {
        SubmissionID: getState().questions.submissionID,
        PackageName: packageName,
      };

      const result = await axiosApi.post("api/policy/pdfDirect", vm);

      if (result.data.response !== enums.serverResponse.error) {
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("QuestionsActions - getPolicyDocumentPackage", result, false));
      }
    }
    catch (error) {
      dispatch(
        appActions.reportToast(
          "The policy documents are unavailable at this time. Please try again later.",
          enums.toastTypes.error
        )
      );
    }
  };
}

export function resetCardAndValuesToPristine(cleanedValues, selectedCard) {
  return async (dispatch, getState) => {
    const questionsClone = cloneDeep(getState().questions);
    const updatedQuestionsAndCardPristineState = helper.setCardToPristineAndHide(
      selectedCard.cardLookupCode,
      questionsClone
    );
    dispatch(setCardAndValuesToPristineSuccess(cleanedValues, updatedQuestionsAndCardPristineState.questions));
  };
}

export function setCardAndValuesToPristineSuccess(cleanedValues, questions) {
  return { type: questionActions.setCardAndValuesToPristine, cleanedValues, questions };
}

export function setSubpage(providedSubpage, providedQuestionsState) {
  return async (dispatch, getState) => {
    const questions =
      providedQuestionsState && providedQuestionsState.length > 0
        ? cloneDeep(providedQuestionsState)
        : cloneDeep(getState().questions.questions);

    const subpage = providedSubpage ? providedSubpage : clone(getState().questions.subpage);

    const subpageQuestions = filter(questions, (q) => {
      if (q?.cardCollection) {
        //we are inside a cardset obj -- single out the individual card that matches our subpage
        q.cardCollection = filter(q.cardCollection, (card) => {
          return card.cardLookupCode === subpage;
        });

        //return the card set with only the card obj matching the subpage as lookupcode inside it
        //if not at least one card inside, omit this item/continue
        if (q.cardCollection.length === 1) {
          //filter out any questions inside the selected card that should no show now that the card is selected
          q.cardCollection[0].questionsAndData = filter(q.cardCollection[0].questionsAndData, (question) => {
            const subpages = question.subpage ? question.subpage.split(";") : null;
            let includesSubpage = false;

            forEach(subpages, (sp) => {
              if (sp === subpage) {
                includesSubpage = true;
              }
            });

            return includesSubpage;
          });

          return q;
        }

        return;
      }

      const subpages = q.subpage ? q.subpage.split(";") : null;
      let includesSubpage = false;

      forEach(subpages, (sp) => {
        if (sp === subpage) {
          includesSubpage = true;
        }
      });

      return includesSubpage;
    });

    dispatch(setSubpageSuccess(subpage, subpageQuestions));
  };
}

export function setSubpageSuccess(subpage, subpageQuestions) {
  return { type: questionActions.setSubpageQuestions, subpage, subpageQuestions };
}

export function setValidPurchase(isMailInPurchase) {
  return { type: questionActions.setValidPurchase, isMailInPurchase };
}

export function sendConversion(vmData) {
  if (!vmData.isEndorsed) {
    window.gtag("event", "conversion", {
      send_to: "AW-1071826125/WQAkCOvmy-0YEM2Ji_8D",
      value: vmData.amount,
      currency: "USD",
      transaction_id: vmData.submissionNumber,
    });
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'purchase_complete_event',
    'conversionData': {
      'event': 'purchase_complete',
      'product': vmData.programCode,
      'type': vmData.policyType
    }
  });

  // now clear the dataLayer variable so the data doesn't hang around forever - it should be in google's internal queue now ??
  window.dataLayer.push(function () {
    this.reset();
  });

  //Bing conversion tracking
  window.uetq = window.uetq || [];
  window.uetq.push('event', 'purchase', { "revenue_value": vmData.amount, "currency": "USD"});
}

export function toggleCardObjHiddenState(show, card, formValues, parentCardLookupCode) {
  return async (dispatch, getState) => {
    const questionsClone = cloneDeep(getState().questions);
    const updatedQuestionsAndCardHiddenState = helper.toggleCardHiddenValue(show, card, questionsClone);

    dispatch(updateQuestions(updatedQuestionsAndCardHiddenState.questions));
    if (!show) {
      dispatch(toggleCardObjPristineState(questionsClone, formValues, parentCardLookupCode));
    }
  };
}

export function toggleCardObjPristineState(questionsClone, formValues, parentCardLookupCode) {
  return async (dispatch) => {
    const updatedQuestionsAndCardPristineState = helper.toggleCardPristineValue(
      formValues,
      parentCardLookupCode,
      questionsClone
    );

    dispatch(updateQuestions(updatedQuestionsAndCardPristineState.questions));

    if (
      updatedQuestionsAndCardPristineState.subpageQuestions &&
      updatedQuestionsAndCardPristineState.subpageQuestions.length > 0
    ) {
      dispatch(setSubpage(null, updatedQuestionsAndCardPristineState.questions));
    }
  };
}

export function toggleEmailQuoteModal() {
  return { type: questionActions.toggleEmailQuoteModal };
}

export function toggleFieldDataDisplay() {
  return { type: questionActions.toggleFieldDataDisplay };
}

export function togglePageHasBanner() {
  return { type: questionActions.togglePageHasBanner };
}

export function togglePageScriptReporting() {
  return { type: questionActions.togglePageScriptReporting };
}

export function purchase(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingFlag());

      const clonedValues = cloneDeep(values);
      const questionsClone = cloneDeep(getState().questions);
      const pageName = questionsClone.pageName;
      const finalQuote = questionsClone.premQuote;
      const requiresReview = questionsClone.isReviewDependentPurchase;
      const isMailInPurchase =
        clonedValues && clonedValues["PremInd_MailIn"] && clonedValues["PremInd_MailIn"] === "TRUE" ? true : false;
      const isCreditCard =
        clonedValues && clonedValues["PremInd_Credit"] && clonedValues["PremInd_Credit"] === "TRUE" ? true : false;
      const isECheck =
        clonedValues && clonedValues["PremInd_ECheck"] && clonedValues["PremInd_ECheck"] === "TRUE" ? true : false;
      const isPremFinance =
        clonedValues && clonedValues["PremInd_PremFinance"] && clonedValues["PremInd_PremFinance"] === "TRUE" ? true : false;
      clonedValues.submissionID = questionsClone.submissionID;
      clonedValues.pageName = pageName;
      clonedValues.finalQuote = finalQuote;
      let canPurchase = true;

      const vm = premIndHelper.mapPaymentVM(clonedValues, requiresReview);
      if (isCreditCard) {
        const creditNumberValid = premIndHelper.validateCardNumber(
          null,
          null,
          true,
          clonedValues["PremInd_CCNumber"],
          clonedValues["PremInd_CCType"]
        );
        const cvcValid = premIndHelper.validateCardCVC(
          null,
          null,
          true,
          clonedValues["PremInd_CCNumber"],
          clonedValues["PremInd_CCCVC"]
        );
        const monthValid = premIndHelper.validateCardMonth(null, null, true, clonedValues["PremInd_CCExpMonth"]);
        const yearValid = premIndHelper.validateCardYear(null, null, true, clonedValues["PremInd_CCExpYear"]);

        if (!creditNumberValid) {
          canPurchase = false;
          dispatch(
            appActions.reportToast(
              "Your Credit Card Number is either incomplete or invalid. Please fix it and try again.",
              enums.toastTypes.error
            )
          );
        }
        if (!cvcValid) {
          canPurchase = false;
          dispatch(
            appActions.reportToast(
              "Your CVC is either incomplete or invalid. Please fix it and try again.",
              enums.toastTypes.error
            )
          );
        }
        if (!monthValid) {
          canPurchase = false;
          dispatch(
            appActions.reportToast(
              "Your Expiration Month is either incomplete or invalid. Please fix it and try again.",
              enums.toastTypes.error
            )
          );
        }
        if (!yearValid) {
          canPurchase = false;
          dispatch(
            appActions.reportToast(
              "Your Expiration Year is either incomplete or invalid. Please fix it and try again.",
              enums.toastTypes.error
            )
          );
        }
      }
      if (isECheck || isPremFinance) {
        const routingNumberValid = premIndHelper.validateRoutingNumber(clonedValues["PremInd_BankRouting"]);

        if (!routingNumberValid) {
          canPurchase = false;
          dispatch(
            appActions.reportToast(
              "Your Routing Number is either incomplete or invalid. Please fix it and try again.",
              enums.toastTypes.error
            )
          );
        }

        const confirmAccountNumber = premIndHelper.confirmAccountNumber(clonedValues["PremInd_BankAccount"], clonedValues["PremInd_BankAccountConfirm"]);

        if (!confirmAccountNumber) {
          canPurchase = false;
          dispatch(
            appActions.reportToast(
              "Your Account Numbers do not match. Please fix it and try again.",
              enums.toastTypes.error
            )
          );
        }
      }

      if (canPurchase) {
        const result = await axiosApi.post("api/payment/purchase", vm);

        if (result.data.response === enums.serverResponse.error) {
          await dispatch(navigate(questionsClone, "", questionsClone.pageUrl, questionsClone.pageName, true, false));
          dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        }
        else {
          sendConversion(result.data.data);
          dispatch(setValidPurchase(isMailInPurchase));
          historyObj.push("/questions/payment-complete");
        }
      }
      else {
        dispatch(clearLoadingFlags());
      }
    }
    catch (error) {
      dispatch(clearLoadingFlags());
    }
  };
}

//****************** Expanding Cards **************** //
export function toggleExpandingCardSelection(incomingCard, select, formValues) {
  return (dispatch, getState) => {
    const allQuestions = cloneDeep(getState().questions);

    let question = find(allQuestions.questions, (item) => {
      return item.questionID === incomingCard.parentQID;
    });

    question.cardCollection.map((card) => {
      if (card.ordinalPosition === incomingCard.ordinalPosition) {
        card.isSelected = select;
      }
    });

    dispatch(toggleCardSelectionSuccess(allQuestions.questions));
    dispatch(toggleCardObjPristineState(allQuestions, formValues, incomingCard.cardLookupCode));
  };
}

export function toggleCardSelectionSuccess(updatedQuestions) {
  return { type: questionActions.toggleCardSelection, updatedQuestions };
}

export function updateQuestions(questions) {
  return { type: questionActions.updateQuestions, questions };
}

// ***************** Repeating Cards **************** //

export function addNewRepeatingCardToSet(parentCardSetLookup, formValues, cardToCopy, copyExternalDataToCard = null) {
  return async (dispatch, getState) => {
    const questionsStateClone = cloneDeep(getState().questions);
    let formValuesClone = cloneDeep(formValues);
    let parentCardSet;
    let newRepeatingCard;
    const isNewCardCopy = cardToCopy && cardToCopy.ordinalPosition ? true : false;
    dispatch(appActions.reportToast("Adding item...", enums.toastTypes.success));
    dispatch(clearSaveAndExitAIModal());

    // locate cardSet to build default new card within it
    if (parentCardSetLookup) {
      for (let i = 0; i < questionsStateClone.questions.length; i++) {
        if (questionsStateClone.questions[i].lookupCode === parentCardSetLookup) {
          // locate cardSet
          parentCardSet = questionsStateClone.questions[i];
          break;
        }
      }
    }

    //NOTE: copyDataToCard is coming from a typeahead search for repeating items that need to
    //be transposed to new repeating item on a different page

    if (isNewCardCopy && (!copyExternalDataToCard || (copyExternalDataToCard && copyExternalDataToCard.length === 0))) {
      // copy our copy card
      newRepeatingCard = cloneDeep(cardToCopy);
    }
    else if (isNewCardCopy && copyExternalDataToCard && copyExternalDataToCard.length > 0) {
      // instantiate default card
      newRepeatingCard = new valuesHelper.cardObject(
        null,
        enums.setTypes.cardRepeating,
        parentCardSet.displayOrder,
        false,
        true,
        parentCardSet.cardCollection.length + 1,
        parentCardSet.newCardDefaultQuestions,
        null, //TODO: How do we want to handle new rCard subpage and default values?
        null,
        null
      );

      const newRepeatingCardCopyData = cloneDeep(copyExternalDataToCard);

      // build out default card properties
      for (let i = 0; i < newRepeatingCard.questionsAndData.length; i++) {
        let newQuestion = newRepeatingCard.questionsAndData[i];

        for (let j = 0; j < newRepeatingCardCopyData.length; j++) {
          const copyData = newRepeatingCardCopyData[j];

          if (copyData.lookupCode === newQuestion.lookupCode) {
            let isViewOnlySubmissionIdField = false;

            if (newQuestion.lookupCode.toLowerCase().includes("viewonlysubmissionid")) {
              //need to inform datavalue to always use the current submission id
              //since we may be entering an endorsement and we need to differentiate new
              //and only repeating items
              isViewOnlySubmissionIdField = true;
            }

            newQuestion.QID = newQuestion.questionID;
            newQuestion.datavalue = isViewOnlySubmissionIdField ? questionsStateClone.submissionID : copyData.value;
            newQuestion.isInvalid = "";
            newQuestion.isInvalidText = "";
            newQuestion.isEndorsed = "";
            newQuestion.validEligible = valuesHelper.setValidProp(
              newQuestion.validEligible,
              parentCardSet.pageName,
              questionsStateClone.programGroupCode
            );
            newQuestion.validPremiumIndication = valuesHelper.setValidProp(
              newQuestion.validPremiumIndication,
              parentCardSet.pageName,
              questionsStateClone.programGroupCode
            );
          }
        }
      }
    }
    else {
      // instantiate default card
      newRepeatingCard = new valuesHelper.cardObject(
        null,
        enums.setTypes.cardRepeating,
        parentCardSet.displayOrder,
        false,
        true,
        parentCardSet.cardCollection.length + 1,
        parentCardSet.newCardDefaultQuestions,
        null, //TODO: How do we want to handle new rCard subpage and default values?
        null,
        null
      );
    }

    // create new card in set's collection
    parentCardSet = repeatingCardsHelper.addNewCardToSet(parentCardSet, newRepeatingCard);

    // grab new card ordinal position
    const newOrdinalPosition = parentCardSet.cardCollection.length;

    // update form values with the newOrdinalPosition and add it to the global formValues for processing
    if (isNewCardCopy && (!copyExternalDataToCard || (copyExternalDataToCard && copyExternalDataToCard.length === 0))) {
      formValuesClone = repeatingCardsHelper.copyRepeatingCardItemsAndIncrementOPs(
        parentCardSet.questionID,
        cardToCopy.ordinalPosition,
        newOrdinalPosition,
        formValuesClone,
        questionsStateClone.submissionID
      );

      // update state's questionIDLookups with the newOrdinalPosition and add it to the global questionIDLookups
      questionsStateClone.questionIDLookups = repeatingCardsHelper.copyRepeatingCardItemsAndIncrementOPs(
        parentCardSet.questionID,
        cardToCopy.ordinalPosition,
        newOrdinalPosition,
        questionsStateClone.questionIDLookups,
        null
      );
    }
    else {
      let updatedDefaultFormValues;

      if (copyExternalDataToCard && copyExternalDataToCard.length > 0) {
        let newCardDefaultFormValues = cloneDeep(parentCardSet.newCardDefaultFormValues);
        const newRepeatingCardCopyData = cloneDeep(copyExternalDataToCard);

        for (let i = 0; i < newRepeatingCardCopyData.length; i++) {
          const copyData = newRepeatingCardCopyData[i];

          if (newCardDefaultFormValues.hasOwnProperty(copyData.lookupCode)) {
            newCardDefaultFormValues[copyData.lookupCode] = copyData.value;
          }
        }

        updatedDefaultFormValues = repeatingCardsHelper.updateDefaultCardSetObjWithNewOP(
          parentCardSet.questionID,
          newCardDefaultFormValues,
          newOrdinalPosition
        );
      }
      else {
        updatedDefaultFormValues = repeatingCardsHelper.updateDefaultCardSetObjWithNewOP(
          parentCardSet.questionID,
          parentCardSet.newCardDefaultFormValues,
          newOrdinalPosition
        );
      }

      // update the parent's default questionIDLookups with the newOrdinalPosition and add it to the global questionIDLookups
      const updatedDefaultQuestionLookupIDs = repeatingCardsHelper.updateDefaultCardSetObjWithNewOP(
        parentCardSet.questionID,
        parentCardSet.newCardDefaultQuestionLookupIDs,
        newOrdinalPosition
      );

      // merge in our updated data
      merge(formValuesClone, updatedDefaultFormValues);
      merge(questionsStateClone.questionIDLookups, updatedDefaultQuestionLookupIDs);
    }

    //update active range end, in case we need to shift active cards and pagination
    if (parentCardSet.showHiddenCards) {
      parentCardSet.hiddenCardPageCount += 1;
    }
    else {
      parentCardSet.showCardPageCount += 1;
    }
    parentCardSet.repeatingCardsActiveRangeEnd = util.getRepeatingCardActiveRangeEnd(parentCardSet);
    parentCardSet.activeCardRangeStart = parentCardSet.repeatingCardsActiveRangeEnd - 5;
    parentCardSet.activeCardRangeIndex = Math.ceil(parentCardSet.repeatingCardsActiveRangeEnd / 6);
    parentCardSet.isEditingCardAtOP = isNewCardCopy ? null : newOrdinalPosition;

    // ship it off for save and return an updated state obj
    dispatch(save(questionsStateClone, formValuesClone, true))
      .then((result) => {
        dispatch(
          getQuestionsAndDataSuccess(
            result,
            questionsStateClone.submissionID,
            questionsStateClone.pageUrl,
            questionsStateClone.navigationPage,
            questionsStateClone.programCode,
            questionsStateClone.userEntityType,
            parentCardSet //carry over so we don't reset pagination
          )
        );
        if (isNewCardCopy && cardToCopy.isHidden) {
          dispatch(appActions.reportToast("Card successfully copied to 'Active Cards'.", enums.toastTypes.success));
        }
      })
      .catch((error) => {
        dispatch(appActions.reportToast("Unable to add item at this time.", enums.toastTypes.error));
        dispatch(appActions.logError("QuestionsActions - addNewRepeatingCardToSet", error, false));
      });
  };
}

export function closeRepeatingCard(cardSet, isNavigating) {
  return { type: questionActions.closeRepeatingCard, cardSet, isNavigating };
}

export function clearSaveAndExitAIModal() {
  return { type: questionActions.clearSaveAndExitAIModal };
}

export function deleteRepeatingCard(parentCardSetLookup, ordinalPositionToDelete, formValues) {
  return async (dispatch, getState) => {
    let questionsStateClone = cloneDeep(getState().questions);
    let formValuesClone = cloneDeep(formValues);
    let parentCardSet;
    dispatch(appActions.reportToast("The selected item is being deleted.", enums.toastTypes.success));

    // locate cardSet to build default new card within it
    if (parentCardSetLookup) {
      for (let i = 0; i < questionsStateClone.questions.length; i++) {
        if (questionsStateClone.questions[i].lookupCode === parentCardSetLookup) {
          parentCardSet = questionsStateClone.questions[i];
          questionsStateClone.questions[i] = repeatingCardsHelper.deleteCardFromCardSet(
            parentCardSet,
            ordinalPositionToDelete
          );
          break;
        }
      }
    }

    // cleaned formValues
    formValuesClone = repeatingCardsHelper.removeRepeatingCardItemsAndDecrementGreaterOPs(
      parentCardSet.questionID,
      ordinalPositionToDelete,
      formValuesClone
    );

    // cleaned questionLookupIDs
    questionsStateClone.questionIDLookups = repeatingCardsHelper.removeRepeatingCardItemsAndDecrementGreaterOPs(
      parentCardSet.questionID,
      ordinalPositionToDelete,
      questionsStateClone.questionIDLookups
    );

    let totalCardCount = 0;

    //update active range end, in case we need to shift active cards and pagination
    if (parentCardSet.showHiddenCards) {
      parentCardSet.hiddenCardPageCount -= 1;
      totalCardCount = parentCardSet.hiddenCardPageCount;
    }
    else {
      parentCardSet.showCardPageCount -= 1;
      totalCardCount = parentCardSet.showCardPageCount;
    }

    //if ordinal pos is active range start
    parentCardSet.repeatingCardsActiveRangeEnd = util.getRepeatingCardActiveRangeEnd(parentCardSet);

    if (
      totalCardCount === parentCardSet.repeatingCardsActiveRangeEnd &&
      parentCardSet.activeCardRangeStart > 1 &&
      parentCardSet.activeCardRangeIndex > 0
    ) {
      //fall back to previous range
      //if it's greater than minimum range start of 1
      parentCardSet.activeCardRangeStart = parentCardSet.activeCardRangeStart - 6;
      parentCardSet.activeCardRangeIndex = parentCardSet.activeCardRangeIndex - 1;
    } //else maintain current range data

    parentCardSet.isEditingCardAtOP = null;

    // ship it off for save and return an updated state obj
    dispatch(save(questionsStateClone, formValuesClone, true))
      .then((result) => {
        dispatch(
          getQuestionsAndDataSuccess(
            result,
            questionsStateClone.submissionID,
            questionsStateClone.pageUrl,
            questionsStateClone.navigationPage,
            questionsStateClone.programCode,
            questionsStateClone.userEntityType,
            parentCardSet //carry over so we don't reset pagination
          )
        );
      })
      .catch((error) => {
        dispatch(appActions.reportToast("Unable to delete item at this time.", enums.toastTypes.error));
        dispatch(appActions.logError("QuestionsActions - deleteRepeatingCard", error, false));
      });
  };
}

export function editRepeatingCard(selectedCard, selectedCardSet) {
  return { type: questionActions.editRepeatingCard, selectedCard, selectedCardSet };
}

export function getAdditionalInsuredResponses(parentCardSetLookup, card, formValues) {
  return async (dispatch) => {
    try {
      dispatch(appActions.reportToast("Adding additional insured...", enums.toastTypes.success));
      const result = await axiosApi.post("api/submission/getAdditionalInsuredResponses", card);

      if (result.data.response !== enums.serverResponse.error) {
        dispatch(addNewRepeatingCardToSet(parentCardSetLookup, formValues, card, result.data.data));
      }
      else {
        dispatch(appActions.reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(appActions.logError("QuestionsActions - getAdditionalInsuredResponses", result, false));
      }
    }
    catch (error) {
      dispatch(appActions.logError("QuestionsActions - getAdditionalInsuredResponses", error, true));
    }
  };
}

export function saveRepeatingCard(formValues, cardSet) {
  return async (dispatch, getState) => {
    const questionsStateClone = cloneDeep(getState().questions);
    const formValuesClone = cloneDeep(formValues);
    const pageName = questionsStateClone.navigationPage;
    const pageUrl = questionsStateClone.pageUrl;
    const appLevelRole = getState().app.user.entityTypeCode;

    try {
      const saveResult = await dispatch(save(questionsStateClone, formValuesClone, false, cardSet.isEditingCardAtOP));

      //close card after save
      const cardSetClone = cloneDeep(cardSet);
      if (saveResult.isValid) {
        cardSetClone.isEditingCardAtOP = null;
        dispatch(resetRCardsWithError());
        dispatch(
          validatePageSuccess(
            saveResult.isValid,
            saveResult.pageValidations,
            saveResult.quote,
            pageName,
            pageUrl,
            appLevelRole
          )
        );
        await dispatch(
          getQuestionsAndData(questionsStateClone.submissionID, pageUrl, pageName, saveResult.programCode)
        );

        return Promise.resolve(true);
      }
      else {
        dispatch(
          validatePageSuccess(
            saveResult.isValid,
            saveResult.pageValidations,
            saveResult.quote,
            pageName,
            pageUrl,
            appLevelRole
          )
        );
        // dispatch(appActions.reportToast("Cannot save invalid card. Please fill out incomplete/invalid fields or cancel.", enums.toastTypes.error));

        return Promise.resolve(true);
      }
    }
    catch (error) {
      dispatch(appActions.reportToast("Unable to save item at this time.", enums.toastTypes.error));
      dispatch(appActions.logError("QuestionsActions - saveRepeatingCard".error, false));

      //pass error up to parent
      return Promise.reject(error);
    }
  };
}

export function resetRCardsWithError() {
  return { type: questionActions.resetRCardsWithError };
}

export function setDynamicModal(modalName) {
  return { type: questionActions.setDynamicModal, modalName };
}

export function setRepeatingCardHiddenFlag(card, cardSet, activeCardRangeEnd, showHiddenCards) {
  return { type: questionActions.setRepeatingCardHiddenFlag, card, cardSet, activeCardRangeEnd, showHiddenCards };
}

export function setPromoCode(promoCode) {
  return { type: questionActions.setPromoCode, promoCode };
}

export function toggleShowHideAllRepeatingCards(cardSet, showHiddenCards) {
  return { type: questionActions.toggleShowHideAllRepeatingCards, cardSet, showHiddenCards };
}

export function updateActiveRepeatingCards(
  cardSet,
  activeRangeStart,
  activeRangeIndex,
  activeRangeEnd,
  showHiddenCards
) {
  return {
    type: questionActions.updateActiveRepeatingCards,
    cardSet,
    activeRangeStart,
    activeRangeIndex,
    activeRangeEnd,
    showHiddenCards,
  };
}
