import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from 'react-bootstrap';
import * as footerActions from "../../../Footer/FooterActions";

const PrivacyModal = () => {
  //store
  const footer = useSelector((state) => state.footer);

  //dispatch actions
  const dispatch = useDispatch();

  //outbound
  const togglePrivacyModal = useCallback(() => dispatch(footerActions.togglePrivacyModal()), [dispatch]);

  //local
  const close = () => {
    togglePrivacyModal();
  };

  return (
    <Modal id="privacy_modal_1" show={footer.privacyTermsModal} onHide={close}>
      <Modal.Header id="privacy_modal_header_1" className={"d-flex align-items-center modalHeader border-0"}>
        <div id="privacy_modal_div_1">Your Privacy</div>
        <button id="privacy_modal_close_btn_1" onClick={() => close()}>X</button>
      </Modal.Header>
      <Modal.Body id="privacy_modal_body_1">
        <div id="privacy_modal_div_2">
          <div id="privacy_modal_div_3">
            <Row id="privacy_modal_row_1">
              <Col id="privacy_modal_col_1" xs={"12 mt-15"}>
                PROTECTING YOUR PRIVACY AND YOUR PERSONAL INFORMATION IS IMPORTANT TO R.V. NUCCIO {"&"} ASSOCIATES INSURANCE BROKERS (RVNA)
              </Col>
              <Col id="privacy_modal_col_2" xs={"12 mt-15"}>
              Our policy is to maintain appropriate confidentially with regard to all personal information we obtain from you in the course of doing business with you.  We do not share any of your personal information with anyone, except those companies through which we have procured, or through which we are attempting to procure insurance on your behalf.   <br/><br/>

<strong>Personal Information We Collect</strong><br/>
This Privacy Policy describes how your personal information is collected, used, and shared when you visit an RVNA website (the “Site”) or make a purchase from RVNA. <br/><br/>

When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.” <br/><br/>

We collect Device Information using the following technologies: <br/>
<ul><li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</li>
<li>“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
<li>“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</li></ul>

Additionally, when you make a purchase or attempt to make a purchase, we collect certain information from you, including your name, billing address, email address, and phone number. <br/><br/>

<strong>Sharing Your Personal Information</strong><br/>
We do not sell your Personal Information (name, email address, phone, etc.) to third parties. We may share your Personal Information to comply with applicable laws and regulations, to respond to insurance claims, a subpoena, or other lawful request for information we receive, or to otherwise protect our rights. <br/><br/>

<strong>How We Use Your Personal Information</strong><br/>
We use the Information that we collect to generate quotes and issue insurance policies through the Site. Additionally, we use the Information you provide to communicate with you and additional insureds, and screen purchases for potential risk or fraud. <br/><br/>

<strong>Communications with RVNA</strong><br/>
By providing your contact information, you consent to receive telephone calls, emails, text messages, mailers, or other similar communications in connection from us even if your phone number is on any “do not call” list.  We send two types of emails: transaction emails (policy confirmations, payment notifications, and password resets) and customer service emails (policy reminders, announcements, marketing, and feedback requests) to maintain your insurance policy.  We do not participate in third-party marketing, selling email addresses, or spamming. If you feel you have you received an email in error, you may unsubscribe from customer service emails, change your email preferences online, or contact us to voice your concerns. <br/><br/>

Any communication, material or information which you post or transmit to R.V. Nuccio and Associates over the internet, by phone, fax or through the mail is, and will be treated as, confidential and proprietary. R.V. Nuccio and Associates may share aggregated statistical information such as web-site traffic or demographic information with its business partners; however, in no case will your personally identifiable information be disclosed. R.V. Nuccio and Associates uses secure server software and encryption technologies to assist in protecting your personal information. Secure Socket Layer (SSL) encrypts all of the information you submit on your application and any survey forms before they are sent to R.V. Nuccio and Associates <br/><br/>

<strong>Consent To Receive Electronic Insurance Documents</strong><br/>
By purchasing an insurance policy, you consent to receive all insurance documents in electronic form only. A paper copy of any document may be requested. A fee may be applied to request such copies. You may make such requests by notifying us through email. Official policy notices and documents will be sent solely and directly to the email address you provided and will not be emailed to other users without your written consent. <br/><br/>

<strong>Term & Conditions for Electronic Communications </strong><br/>
You agree that documents and official notices which we are required to send may be sent to you electronically rather than in paper form. You agree these paperless communications are the legal equivalent of officially required communications relating to your policy(ies) which you would otherwise receive in paper form. These communications may include, but are not limited to, policy declarations, policy forms and endorsements and related forms, billing statements, legally required notices, and other official correspondence. You agree to receive all communications electronically, of which may even include cancellations or non-renewal notices. <br/><br/>

<strong>Privacy Policy Changes</strong><br/>
We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. <br/><br/>

<strong>Contact Us</strong><br/>
For more information about our privacy practices, or if you have questions, please contact us by e-mail at support@rvnuccio.com or by mail using the details provided below: <br/>
10148 Riverside Drive, Toluca Lake, CA, 91602, United States


              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer id="privacy_modal_footer_1" />
    </Modal>
  );
};

export default PrivacyModal;
