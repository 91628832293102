import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../Store/SearchStore";
import { Button, Col, Row } from "react-bootstrap";
import { Form, withFormik } from "formik";
import { default as FormInputs } from "../Utilities/Inputs/FormGroupsWrapper";
import enums from "../Utilities/Enums/Enums";
import * as appActions from "../App/AppActions";
import * as policyActions from "../PolicyList/PolicyActions";
import * as axiosApi from "../axiosApi";
import historyObj from "../Routing/browserHistory";

const BasicForm = (props) => {
  const { dirty, errors, resetForm, values } = props;

  //dispatch actions
  const dispatch = useDispatch();

  //store
  const app = useSelector((state) => state.app);

  //context
  const {
    clearSearchStore: clearSearchStore,
    searching: [isSearching, setIsSearching],
    searchForm: [searchFormIsOpen, setSearchFormOpen],
    searchResult: [searchResults, setSearchResults],
  } = useContext(SearchContext);

  //states
  const hasErrors = Object.keys(errors).length > 0;

  //outbound
  const setPolicies = useCallback(
    (policies) => dispatch(policyActions.getPoliciesFromQuerySuccess(policies)),
    [dispatch]
  );

  const isNotBrokerOrDirect =
    app.user.entityTypeCode !== enums.role.direct && app.user.entityTypeCode !== enums.role.broker;

  const handleSearch = async () => {
    setIsSearching(true);

    const searchQueryVM = {
      EndDate: values.endDate,
      PolicyStatus: values.policyStatuses,
      ProgramCode: values.programCode,
      SearchApplicant: values.searchApplicant?.trim(),
      SearchBroker: values.searchBroker?.trim(),
      SearchID: values.searchID?.trim(),
      StartDate: values.startDate,
    };

    if (searchQueryVM) {
      try {
        const result = await axiosApi.post("api/submission/search", searchQueryVM);
        if (result.data.response === enums.serverResponse.error) {
          dispatch(
            appActions.reportToast(
              "An error occured when fetching the policies. Please try again later.",
              enums.toastTypes.error
            )
          );
          // dispatch(appActions.logError("SearchForm - handleSearch", result, false));
        }
        else {
          setPolicies(result.data.data);
          setSearchResults(true);
          if (historyObj.location.pathname === "/") {
            historyObj.push("/policylist");
          }
        }
      }
      catch (error) {
        dispatch(
          appActions.reportToast(
            "An error occured when fetching the policies. Please try again later.",
            enums.toastTypes.error
          )
        );
        dispatch(appActions.logError("SearchForm - handleSearch", error, false));
        clearSearchStore();
        historyObj.push("/");
      }
      finally {
        setIsSearching(false);
        resetForm();
        setSearchFormOpen(false);
      }
    }
  };

  return (
    <Form id="search_form_1">
      <Row id="search_form_row_1">
        <Col id="search_form_col_1" xs={"12"} lg={isNotBrokerOrDirect ? "4" : "6"}>
          <FormInputs type="text" id="searchID" label="Client ID or Policy #" label_location={"top"} name="searchID" />
        </Col>
        <Col id="search_form_col_2" xs={"12"} lg={isNotBrokerOrDirect ? "4" : "6"}>
          <FormInputs
            type="text"
            id="searchApplicant"
            label={isNotBrokerOrDirect ? "Applicant Email/Last Name" : "Applicant Name"}
            label_location={"top"}
            name="searchApplicant"
          />
        </Col>
        {isNotBrokerOrDirect && (
          <Col id="search_form_col_3" xs={"12"} lg={"4"}>
            <FormInputs
              type="text"
              id="searchBroker"
              label="Broker Agency Name"
              label_location={"top"}
              name="searchBroker"
            />
          </Col>
        )}
      </Row>
      <Row id="search_form_row_2">
        <Col id="search_form_col_4" xs={"12"} lg={"3"}>
          <FormInputs
            type={enums.fieldTypes.date}
            id="startDate"
            label="Start Date"
            label_location={"top"}
            name="startDate"
            placeholder="mm/dd/yyyy"
          />
        </Col>
        <Col id="search_form_col_5" xs={"12"} lg={"3"}>
          <FormInputs
            type={enums.fieldTypes.date}
            id="endDate"
            label="End Date"
            label_location={"top"}
            name="endDate"
            placeholder="mm/dd/yyyy"
          />
        </Col>
        <Col id="search_form_col_6" xs={"12"} lg={"3"}>
          <FormInputs
            type={enums.fieldTypes.combo}
            id="programCode"
            label="Type of Insurance"
            label_location={"top"}
            name="programCode"
            options={app.products}
            placeholder="Please Select"
          />
        </Col>
        <Col id="search_form_col_7" xs={"12"} lg={"3"}>
          <FormInputs
            type={enums.fieldTypes.combo}
            id="policyStatuses"
            label="Policy Status"
            label_location={"top"}
            name="policyStatuses"
            options={app.submissionStatusTypes}
            placeholder="Please Select"
          />
        </Col>
      </Row>
      <Row id="search_form_row_8">
        <Col id="search_form_col_8" xs={"12 mt-30-xs"} md={"5 mt-30"} lg={"3"}>
          <Button
            id="search_form_btn_1"
            disabled={isSearching || !dirty}
            onClick={() => clearSearchStore()}
            outline="true"
          >
            Clear Search Results
          </Button>
        </Col>
        <Col id="search_form_col_9" xs={"12 mt-15-xs"} md={"4 mt-30"} lg={"3"} xl={"2"}>
          <Button
            id="search_form_btn_2"
            disabled={isSearching || !dirty || hasErrors}
            style={{ minWidth: "150px" }}
            className="searchSubmitBtn"
            onClick={() => handleSearch()}
            outline="true"
            type="submit"
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const SearchFormHOC = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    //initial values
    endDate: "",
    policyStatuses: "",
    programCode: "",
    searchApplicant: "",
    searchBroker: "",
    searchID: "",
    startDate: "",
  }),
});

const SearchForm = SearchFormHOC(BasicForm);

export default SearchForm;
