import * as appActions from "../App/AppActions";
import * as axiosApi from "../axiosApi";
import * as headerActions from "./HeaderActionTypes";

export function clearState() {
  return { type: headerActions.clearState };
}

export function disableSideNav(bool) {
  return { type: headerActions.disableSideNav, bool };
}

export function getProductBanner(code) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let programCode = "";

      //1) navigating to new submission and passing in the code directly
      if (code) {
        programCode = code;
      }

      //2) if we have current submission, get banner for that program code
      else if (state.app.currentSubmission && state.app.currentSubmission.programCode) {
        programCode = state.app.currentSubmission.programCode;
      }

      if (programCode) {
        const result = await axiosApi.get("api/ComponentData/getProductBanner/" + programCode);
        dispatch(getProductBannerSuccess(result.data.data, programCode));
      }
      else {
        // 3) if we don't have current submission, clear image
        // dispatch(getProductBannerSuccess("Banner_Default.png", "")); //uncomment for default banner
        dispatch(getProductBannerSuccess("", ""));
      }
    }
    catch (error) {
      dispatch(getProductBannerSuccess("", ""));
      return Promise.reject(error);
    }
  };
}

export function getProductBannerSuccess(imagePath, bannerProgramCode) {
  return {
    type: headerActions.setProductBanner,
    imagePath,
    bannerProgramCode,
  };
}

export function toggleDebugMenu() {
  return { type: headerActions.toggleDebugMenu };
}

export function toggleSideMenu() {
  return { type: headerActions.toggleSideMenu };
}
