import * as headerActions from "./HeaderActionTypes";

export const INITIAL_STATE = {
  bannerProgramCode: "",
  debugMenuOpen: false,
  disableSideNav: "disabled",
  productBanner: "",
  sideMenuOpen: false,
};

export default function historyReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case headerActions.clearState:
      state = INITIAL_STATE;
      break;
    case headerActions.disableSideNav:
      let disable = action.bool ? "disabled" : "";
      state = update(state, {
        disableSideNav: disable,
      });
      break;
    case headerActions.setProductBanner:
      state = update(state, {
        bannerProgramCode: action.bannerProgramCode,
        productBanner: action.imagePath,
      });
      break;
    case headerActions.toggleDebugMenu:
      state = update(state, {
        debugMenuOpen: !state.debugMenuOpen
      });
      break;
    case headerActions.toggleSideMenu:
      state = update(state, {
        sideMenuOpen: !state.sideMenuOpen
      });
      break;
    default:
      break;
  }
  return state;
}
