import { cloneDeep, forEach } from "lodash";
import util from "../Functions/Functions";
import enums from "../Enums/Enums";

const applyPS = (psUnappliedFields, psReadyActive, currentValue) => {
  let psReady = {};

  if (!util.isObjEmpty(psReadyActive)) {
    psReady = cloneDeep(psReadyActive);
  }

  //build psReady obj with unapplied fields
  if (psUnappliedFields && psUnappliedFields.length > 0) {
    for (let field in psUnappliedFields) {
      let psExField = psUnappliedFields[field];
      let camelActionLocationProperty;

      if (psExField && (!psExField.preventActionOnPristine || !psExField.pristine && psExField.preventActionOnPristine)) {

        if (psExField.contingentValueReturned === enums.bool.true) {
          //if our return location includes input/container/wrapper/group,
          //combine it with action to hit proper property (e.g. disableInput)
          if (
            psExField.action &&
            psExField.contingentReturnLocation &&
            psExField.action !== psExField.contingentReturnLocation
          ) {
            camelActionLocationProperty =
              psExField.action +
              psExField.contingentReturnLocation.charAt(0).toUpperCase() +
              psExField.contingentReturnLocation.slice(1);

            //if it's an alert, update camelActionLocationProperty to be generic, either it's danger or it's primary
            let normalActionLocation = camelActionLocationProperty.toLowerCase();

            if (normalActionLocation.includes("alert")) {
              if (normalActionLocation.includes("danger")) {
                camelActionLocationProperty = "alertDanger";
              }
              if (normalActionLocation.includes("primary")) {
                camelActionLocationProperty = "alertPrimary";
              }
            }
          }

          //if we have a actionLocationProperty (e.g. disableInput) we are targeting
          //and our contingentReturnLocation is not already handling it, then update psReady with the property
          //and action for the correct elemental layer of the component
          if (camelActionLocationProperty) {
            if (psExField.action && action(psExField)) {
              psReady[`${camelActionLocationProperty}`] = action(psExField);
            }
          }
          //else we are using the contingentReturnLocation's field and if it isn't already populated in the
          //psReady object then apply that property and value to psReady
          else if (psExField.action && action(psExField) && psExField.contingentReturnLocation) {
            //else action is same as contingentReturnLocation
            psReady[`${psExField.contingentReturnLocation}`] = action(psExField);
          }
          else if (psExField.action && action(psExField) && !psExField.contingentReturnLocation) {
            //else action is our return location (i.e. setLabelValue)
            psReady[`${psExField.action}`] = action(psExField);
          }
        }

        //update the psReady value
        if (psExField.value && psReady.value && psExField.value !== psReady.value) {
          psReady.value = psExField.value;
        }
        //clear the value unless it's a radio/checkbox bool as those can default to FALSE
        else if (psExField.value === "clearValue" && currentValue && currentValue !== enums.bool.false) {
          psReady["value"] = currentValue === enums.bool.true ? enums.bool.false : psExField.value;
        }
        else if (psExField.contingentValueReturned === enums.bool.true && psExField.value && !psReady.hasOwnProperty("value")) {
          ///there is no value property, add it
          psReady["value"] = psExField.value;
        }

        //attach the id to easily locate and clear these values
        if (!util.isObjEmpty(psReady) && psExField.id) {
          psReady["id"] = psExField.id;
        }
      }
    }
  }

  return psReady;
};

const daysToMilliseconds = (days) => {
  return days * 24 * 60 * 60 * 1000;
}

const findFieldsWithMatchingATL = (allPreOrPostPS, catalystField, issuingField_SetID_OP) => {
  let allExecutablesWithATL = [];

  if (allPreOrPostPS && allPreOrPostPS.length > 0) {
    for (let i = 0; i < allPreOrPostPS.length; i++) {
      let psField = allPreOrPostPS[i];
      if (psField.appliesToLookupCodes && psField.appliesToLookupCodes.length > 0) {
        for (let j = 0; j < psField.appliesToLookupCodes.length; j++) {
          let lookupCode = psField.appliesToLookupCodes[j]
            ? psField.appliesToLookupCodes[j].split("-@")[0]
            : psField.appliesToLookupCodes[j];
          if (
            catalystField.id !== psField.id &&
            catalystField.appliesToLookupCodes &&
            catalystField.appliesToLookupCodes.includes(lookupCode)
          ) {
            //make sure we don't produce duplicate unique ps field ids
            let isUniquePSID = true;

            for (let k = 0; k < allExecutablesWithATL.length; k++) {
              if (allExecutablesWithATL[k].id === psField.id) {
                isUniquePSID = false;
                break;
              }
            }

            if (isUniquePSID) {
              //if we are dealing with an rCard we'll have an issuingField_SetID_OP
              //prevent from pushing if the field doesn't contain the SetID-OP so we stay in the individual card's context
              if (
                issuingField_SetID_OP &&
                psField.appliesToLookupCodes[0].split("-SetID-")[1] === issuingField_SetID_OP
              ) {
                allExecutablesWithATL.push(psField);
              }
              else if (!issuingField_SetID_OP) {
                allExecutablesWithATL.push(psField);
              }
            }
          }
        }
      }
    }
  }

  return allExecutablesWithATL;
};

const findFieldsWithMatchingContingency = (allPreOrPostPS, catalystField, issuingField_SetID_OP) => {
  let otherFieldsWithSameContingency = [];

  if (allPreOrPostPS && allPreOrPostPS.length > 0) {
    for (let i = 0; i < allPreOrPostPS.length; i++) {
      let psField = allPreOrPostPS[i];
      if (psField.contingentLookupCodes && psField.contingentLookupCodes.length > 0) {
        for (let j = 0; j < psField.contingentLookupCodes.length; j++) {
          let lookupCode = psField.contingentLookupCodes[j]
            ? psField.contingentLookupCodes[j].split("-@")[0]
            : psField.contingentLookupCodes[j];
          if (
            catalystField.id !== psField.id &&
            catalystField.contingentLookupCodes &&
            catalystField.contingentLookupCodes.includes(lookupCode)
          ) {
            //make sure we don't produce duplicate unique ps field ids
            let isUniquePSID = true;

            for (let k = 0; k < otherFieldsWithSameContingency.length; k++) {
              if (otherFieldsWithSameContingency[k].id === psField.id) {
                isUniquePSID = false;
                break;
              }
            }

            if (isUniquePSID) {
              //if we are dealing with an rCard we'll have an issuingField_SetID_OP
              //prevent from pushing if the field doesn't contain the SetID-OP so we stay in the individual card's context
              if (
                issuingField_SetID_OP &&
                psField.appliesToLookupCodes[0].split("-SetID-")[1] === issuingField_SetID_OP
              ) {
                otherFieldsWithSameContingency.push(psField);
              }
              else if (!issuingField_SetID_OP) {
                otherFieldsWithSameContingency.push(psField);
              }
            }
          }
        }
      }
    }
  }

  return otherFieldsWithSameContingency;
};

const findFieldsWithContingencyMatchingATL = (allPreOrPostPS, catalystField, issuingField_SetID_OP) => {
  let atlContingencyMatchedFields = [];

  if (allPreOrPostPS && allPreOrPostPS.length > 0) {
    for (let i = 0; i < allPreOrPostPS.length; i++) {
      let psField = allPreOrPostPS[i];
      if (psField.contingentLookupCodes && psField.contingentLookupCodes.length > 0) {
        for (let j = 0; j < psField.contingentLookupCodes.length; j++) {
          let lookupCode = psField.contingentLookupCodes[j]
            ? psField.contingentLookupCodes[j].split("-@")[0]
            : psField.contingentLookupCodes[j];
          if (
            catalystField.id !== psField.id &&
            catalystField.contingentLookupCodes &&
            catalystField.appliesToLookupCodes.includes(lookupCode)
          ) {
            //make sure we don't produce duplicate unique ps field ids
            let isUniquePSID = true;

            for (let k = 0; k < atlContingencyMatchedFields.length; k++) {
              if (atlContingencyMatchedFields[k].id === psField.id) {
                isUniquePSID = false;
                break;
              }
            }

            if (isUniquePSID) {
              //if we are dealing with an rCard we'll have an issuingField_SetID_OP
              //prevent from pushing if the field doesn't contain the SetID-OP so we stay in the individual card's context
              if (issuingField_SetID_OP && psField.appliesToLookupCodes[0].split("-OP")[1] === issuingField_SetID_OP) {
                atlContingencyMatchedFields.push(psField);
              }
              else if (!issuingField_SetID_OP) {
                atlContingencyMatchedFields.push(psField);
              }
            }
          }
        }
      }
    }
  }

  return atlContingencyMatchedFields;
};

const checkIfContingentIsFalseCheckbox = (field, values) => {
  if (
    field &&
    values &&
    field.contingentLookupCodes.length === 1 &&
    values[field.contingentLookupCodes[0]] === "FALSE"
  ) {
    return true;
  }
  return false;
};

const checkPSUnique = (psField, psExecutedFields, value) => {
  let executedFields;
  let executedPSCount = 0;
  let matchCount = 0;

  //depending on time in flow, psExecutedFields may be a singular array or a complex obj, test for both
  if (psField && psExecutedFields && Array.isArray(psExecutedFields) && psExecutedFields.length > 0) {
    executedFields = psExecutedFields;
  }

  if (psField && !executedFields && !util.isObjEmpty(psExecutedFields)) {
    executedFields = psExecutedFields.scripts;
  }

  if (executedFields && executedFields.length > 0) {
    for (let i = 0; i < executedFields.length; i++) {
      let executedField = executedFields[i];
      let equalFields = psField.id === executedField.id;

      if (equalFields && value && value === executedField.value) {
        matchCount++;
      }
      else if (equalFields && !value && value !== false && !executedField.value && executedField.value !== false) {
        matchCount++;
      }

      //tally processed executedPS field
      executedPSCount++;
    }
  }
  else {
    //empty then unique, proceed to executePageScript
    return true;
  }

  //counts don't match, allow executePageScript to run
  return executedPSCount !== matchCount;
};

const convertToArray = (psString, programGroupCode) => {
  if (psString && psString.includes(";")) {
    //convert to array - strip white space for string comparisons later on
    let psArray = psString.replace(/\s/g, "").split(";");
    return util.normalizeLookupCode(psArray, programGroupCode);
  }
  else if (psString) {
    return [util.normalizeLookupCode(psString, programGroupCode)];
  }
  else {
    return null;
  }
};

const hasActiveAlert = (psReady) => {
  let hasActiveAlert = false;

  if (psReady && !util.isObjEmpty(psReady)) {
    forEach(psReady, (value, key) => {
      if (key.toLowerCase().includes("alert") && value) {
        return (hasActiveAlert = true);
      }
    });
  }

  return hasActiveAlert;
};

const interpretRuleTestValue = (testValue) => {
  let interpretedTestValue = testValue ? testValue.toLowerCase() : null;

  switch (interpretedTestValue) {
    case "null":
      return "";
    case "true":
    case "trueany":
      return true;
    case "false":
    case "falseany":
      return false;
    default:
      return null;
  }
}

const getPSExecutable = (psFields, executionTime, rawLookup) => {

  let eligibleField = undefined;

  //get psAction from array so we can deliver proper field to input below
  if (psFields && psFields.length > 0 && executionTime && rawLookup) {
    //for each field in array
    for (let i = 0; i < psFields.length; i++) {

      const appliesToLookupMatch = psFields[i].appliesToLookupCodes.findIndex(item => item === rawLookup);
      const contingentLookupMatch = psFields[i].contingentLookupCodes.findIndex(item => item === rawLookup);
      if (
        (psFields[i].executionTime === executionTime || psFields[i].executionTime === executionTime + "Persist") && appliesToLookupMatch > -1
      ) {
        eligibleField = psFields[i];
        break;
      }
      else if (
        (psFields[i].executionTime === executionTime || psFields[i].executionTime === executionTime + "Persist") && contingentLookupMatch > -1
      ) {
        eligibleField = psFields[i];
        break;
      }
    }
  }

  return eligibleField;
};

const getPSExecutables = (psFields, executionTime, normalizedLookup, rawLookup) => {

  let eligibleFields = [];

  //get psAction from array so we can deliver proper field to input below
  if (psFields && psFields.length > 0 && executionTime && (normalizedLookup || rawLookup)) {
    //for each field in array
    for (let i = 0; i < psFields.length; i++) {

      //the pagescript ATL/CL array can contain either the normalizedLookup (e.g. DICECoverage.StartDate) or the rawLookup in a repeating context (e.g. DICECoverage.StartDate-SetID-123-OP1)
      const appliesToLookupMatch = psFields[i].appliesToLookupCodes.findIndex(item => (item === normalizedLookup || item === rawLookup));
      const contingentLookupMatch = psFields[i].contingentLookupCodes.findIndex(item => (item === normalizedLookup || item === rawLookup));

      if (
        (psFields[i].executionTime === executionTime || psFields[i].executionTime === executionTime + "Persist") &&
        (appliesToLookupMatch > -1 || contingentLookupMatch > -1)
      ) {
        eligibleFields.push(psFields[i]);
      }
    }
  }

  //return undefined for fields looking for a single value that doesn't exist since they are looking for a single result
  if (eligibleFields.length === 0) eligibleFields.push(undefined);

  return eligibleFields;
};

const getAllPreOrPostExecutables = (psFields, executionTime) => {
  let allPreOrPostFields = [];

  //grab all pre or postmount fields from bulk obj
  if (psFields && psFields.length > 0 && executionTime) {
    for (let i = 0; i < psFields.length; i++) {
      //use includes since we may be dealing with added specs (i.e. premountPersist)
      if (psFields[i].executionTime === executionTime || psFields[i].executionTime === executionTime + "Persist") {
        allPreOrPostFields.push(psFields[i]);
      }
    }
  }

  return allPreOrPostFields;
};

const getPSUpdates = (psFields, executionTime, normalizedLookup, rawLookup) => {
  //get psAction from array so we can deliver proper field to input below
  if (psFields && psFields.length > 0 && executionTime && (normalizedLookup || rawLookup)) {
    let stagedFieldsToCombine = [];

    //for each field in array
    for (let i = 0; i < psFields.length; i++) {

      //the pagescript ATL array can contain either the normalizedLookup (e.g. DICECoverage.StartDate) or the rawLookup in a repeating context (e.g. DICECoverage.StartDate-SetID-123-OP1)
      const appliesToLookupMatch = psFields[i].appliesToLookupCodes.findIndex(item => (item === normalizedLookup || item === rawLookup));

      if (
        (psFields[i].executionTime === executionTime || psFields[i].executionTime === executionTime + "Persist") &&
        appliesToLookupMatch > -1
      ) {
        //combine fields with same appliesToLookupCodes into one psField for single event loop
        stagedFieldsToCombine.push(psFields[i]);
      }
    }

    return stagedFieldsToCombine;
  }
};

const getLeadingComparisonTestSymbol = (contingentTestValue) => {
  let symbol = contingentTestValue.slice(0, 1);
  return symbol;
};

const getComparisonTestValue = (formValues, contingentTestValue, comparisonTestSymbol) => {
  //first position will always be the operative symbol, so grab everything afterwards
  let amount = contingentTestValue.slice(comparisonTestSymbol.length);

  if (amount && isNaN(amount) && amount.includes("_") && formValues && !util.isObjEmpty(formValues)) {
    //2b) we've confirmed it's lookupCode, now capture the formValue so we can reassign the amount with a real numeric value
    if (formValues.hasOwnProperty(amount) && formValues[amount] !== "" && !isNaN(parseFloat(formValues[amount]))) {
      amount = parseFloat(formValues[amount].replace(/,/g, ""));
    }
    else {
      //value does not exist
      return false;
    }
  }
  else if (amount && !isNaN(amount)) {
    amount = parseFloat(amount.replace(/,/g, ""));
  }
  else {
    return false;
  }

  return amount;
};

const getUpdatedPSFieldIndex = (updatedPSFields, stagedPSFieldId, stagedFieldsIndex) => {
  let containsStagedField = updatedPSFields.findIndex((x) => x.id === stagedPSFieldId) > -1;

  if (updatedPSFields && updatedPSFields.length > 0 && containsStagedField) {
    return updatedPSFields.findIndex((x) => x.id === stagedPSFieldId);
  }
  else {
    return stagedFieldsIndex;
  }
};

const removeSetIDAndOPFromLookupCode = (lookupCode) => {
  //create a new copy so we don't update by reference
  let normalizedLookupCode = lookupCode;

  if (normalizedLookupCode && normalizedLookupCode.includes("-SetID-")) {
    //in case we are dealing with an rCard value that gets the SetID and OP tacked on
    //pop off the suffix so we can retrieve the updates/executables
    normalizedLookupCode = normalizedLookupCode.split("-SetID-")[0];
  }

  return normalizedLookupCode;
};

const removeTemplateSyntaxFromPrevPage = (prevPageFields) => {
  let normalPrevPageFields = [];
  if (prevPageFields) {
    for (let field in prevPageFields) {
      let appliesToLookupCodes = prevPageFields[field].appliesToLookupCodes;
      let value = prevPageFields[field].value;
      let normalLookups = [];
      for (let i = 0; i < appliesToLookupCodes.length; i++) {
        let lookup = appliesToLookupCodes[i];
        if (lookup.includes("-@")) {
          lookup = lookup.split("-@")[0];
        }

        normalLookups.push(lookup);
      }

      normalPrevPageFields.push({ appliesToLookupCodes: normalLookups, value: value });
    }
  }

  return normalPrevPageFields;
};

const isQuestionsContext = (questions) => {
  if (questions && questions.submissionID) {
    return true;
  }

  return false;
};

//input actions
const validateElementalLocation = (actionLocation, returnValue) => {
  switch (actionLocation) {
    case "danger":
      return returnValue;
    case "group":
      return returnValue;
    case "input":
      return returnValue;
    case "label":
      return returnValue;
    case "primary":
      return returnValue;
    case "wrapper":
      return returnValue;
    default:
      if (actionLocation) {
        console.log(actionLocation + " is not hooked up");
      }
      break;
  }
};

//get the action and apply to approriate elemental layer of the input
const action = (psUpdates) => {
  if (psUpdates && psUpdates.action) {
    //normalize the camel case for includes testing
    let normalAction = cloneDeep(psUpdates.action).toLowerCase();

    if (normalAction.toLowerCase().includes("alert")) {
      normalAction = "alert";
    }

    switch (normalAction) {
      case "alert":
        return validateElementalLocation(psUpdates.contingentReturnLocation, psUpdates.alert);
      case "disable" || "setvalueanddisable":
        return validateElementalLocation(psUpdates.contingentReturnLocation, psUpdates.disable);
      case "setclass":
        return validateElementalLocation(psUpdates.contingentReturnLocation, psUpdates.setClass);
      case "setlabelvalue":
        return validateElementalLocation("label", psUpdates.setLabelValue);
      case "setvalue":
        return validateElementalLocation(psUpdates.contingentReturnLocation, psUpdates.value);
      case "setvalueanddisable":
        return validateElementalLocation(psUpdates.contingentReturnLocation, psUpdates.disable);
      default:
        break;
    }
  }
};

//combine all on this obj
const ps = {
  action: action,
  applyPS: applyPS,
  checkIfContingentIsFalseCheckbox: checkIfContingentIsFalseCheckbox,
  checkPSUnique: checkPSUnique,
  convertToArray: convertToArray,
  daysToMilliseconds: daysToMilliseconds,
  findFieldsWithContingencyMatchingATL: findFieldsWithContingencyMatchingATL,
  findFieldsWithMatchingATL: findFieldsWithMatchingATL,
  findFieldsWithMatchingContingency: findFieldsWithMatchingContingency,
  getAllPreOrPostExecutables: getAllPreOrPostExecutables,
  getComparisonTestValue: getComparisonTestValue,
  getLeadingComparisonTestSymbol: getLeadingComparisonTestSymbol,
  getPSExecutable: getPSExecutable,
  getPSExecutables: getPSExecutables,
  getPSUpdates: getPSUpdates,
  getUpdatedPSFieldIndex: getUpdatedPSFieldIndex,
  hasActiveAlert: hasActiveAlert,
  interpretRuleTestValue: interpretRuleTestValue,
  isQuestionsContext: isQuestionsContext,
  removeSetIDAndOPFromLookupCode: removeSetIDAndOPFromLookupCode,
  removeTemplateSyntaxFromPrevPage: removeTemplateSyntaxFromPrevPage,
};

export default ps;
