import { useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";

const PolicyChanges = (props) => {
  const [open, toggleCollapse] = useState(false);

  if (props.openComponent && props.openComponent !== "policyChanges" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("policyChanges");
  }

  return (
    <div id="policy_changes_main_div_1">
      <div
        id="policy_changes_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="polChngRow"
        aria-expanded={open}
      >
        How do I make changes to my policy?
      </div>
      <Collapse in={open}>
        <div id="policy_changes_div_3">
          <Row id="policy_changes_row_1">
            <Col id="policy_changes_col_1">
              <hr />
              <div id="policy_changes_div_4">
                If your policy has not been purchased you can edit your application and add and edit coverages using our online system.
                Click the View Quotes {"&"} Policies button.  Locate the policy. In the Manage column, click the Manage Policy button and then select
                “Complete Application.” Add or update coverages, make changes and then complete your quote and purchase the policy.
                <br />
                <br />
                If you have already purchased the policy, please e-mail your request to <a href="mailto:support@rvnuccio.com">support@rvnuccio.com</a>.
                Please allow 24-48 hours for your request to be processed during our business hours M-F 5:30am-5:00pm PST.
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default PolicyChanges;
