import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ErrorPage from "../Errors/ErrorPage/ErrorPage.js";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import enums from "../Utilities/Enums/Enums";
import util from "../Utilities/Functions/Functions";
import * as appActions from "./AppActions";
import * as headerActions from "../Header/HeaderActions";
import "react-toastify/dist/ReactToastify.css";
import "../_styles/icons/icons.scss";
import historyObj from "../Routing/browserHistory";

const ForgotPassword = lazy(() => import("../Credentials/ForgotPassword/ForgotPassword"));
const Home = lazy(() => import("../Home/Home"));
const Login = lazy(() => import("../Login/Login"));
const PolicyList = lazy(() => import("../PolicyList/PolicyList"));
const Questions = lazy(() => import("../Questions/Questions"));
const Register = lazy(() => import("../Register/Register"));
const Reports = lazy(() => import("../Reports/Reports"));
const ResetPasswordCode = lazy(() => import("../Credentials/ResetPasswordCode/ResetPasswordCode.js"));
const UserMaintenance = lazy(() => import("../UserMaintenance/UserMaintenance"));
const EditUser = lazy(() => import("../UserMaintenance/EditUser/EditUser"));
const UserRules = lazy(() => import("../UserMaintenance/UserRules/UserRules"));

const App = () => {
  //store
  const app = useSelector((state) => state.app);
  const header = useSelector((state) => state.header);
  const currentPath = historyObj.location.pathname.substring(1);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const userRole = app.user.entityTypeCode;
  const isRealUser = userRole && userRole !== enums.role.unknown;

  //states
  const [unknownGettingQuickQuote, isUnknownGettingQuickQuote] = useState(false);

  //dispatch actions
  const dispatch = useDispatch();

  //local
  const clearToast = useCallback(() => dispatch(appActions.clearToast()), [dispatch]);
  const toggleBlur = useCallback(() => dispatch(appActions.toggleBlur()), [dispatch]);

  //outbound
  const toggleSideMenu = useCallback(() => dispatch(headerActions.toggleSideMenu()), [dispatch]);

  const closeSideMenu = () => {
    if (header.sideMenuOpen) {
      toggleBlur();
      toggleSideMenu();
    }
  };

  const reportToast = (toastMsg, toastType) => {
    if (toastType === enums.toastTypes.error) {
      return toast.error(toastMsg);
    }
    if (toastType === enums.toastTypes.success) {
      return toast.success(toastMsg);
    }
    return toast(toastMsg);
  };

  const UnknownUserRoute = () => {
    if (!util.isObjEmpty(params) && !unknownGettingQuickQuote) {

      let facilityRefCode;

      if (params.ref) {
        facilityRefCode = params.ref;
      }

      if (params.Status === "unknown" && params.ProgramCode) {
        if (app.isLoggedIn && app.user.entityTypeCode !== enums.role.unknown) {
          //log user out for clean session
          dispatch(appActions.logout());
        }

        const unknownLoginVM = {
          username: "unknown",
          password: "",
          remember: false,
        };

        isUnknownGettingQuickQuote(true);
        if (!app.isLoggedIn) {
          dispatch(appActions.login(unknownLoginVM, facilityRefCode, params.ProgramCode, params.PromoCode));
        }
        else {
          dispatch(appActions.loginSuccess());
          dispatch(appActions.createSubmission(params.ProgramCode));
        }
      }
    }
  }

  /////////////////////////////////////////////////////
  ///////////// ******* Effects ******* //////////////
  ////////////////////////////////////////////////////

  useEffect(() => {
    if (app.toastMsg && app.toastType) {
      reportToast(app.toastMsg, app.toastType);
      setTimeout(function () {
        clearToast();
      }, 3000);
    }
  }, [app.toastMsg, app.toastType, clearToast]);

  useEffect(() => {
    if (app.blurBackground && !header.sideMenuOpen) {
      toggleBlur();
    }
  }, [app.blurBackground, header.sideMenuOpen, toggleBlur]);

  useEffect(() => {
    if (currentPath && unknownGettingQuickQuote) {
      isUnknownGettingQuickQuote(false);
    }
  }, [currentPath, unknownGettingQuickQuote, isUnknownGettingQuickQuote]);

  useEffect(() => {
    if (
      userRole === enums.role.unknown &&
      !currentPath.toLowerCase().includes("questions") &&
      !currentPath.toLowerCase().includes("reset") &&
      !currentPath.toLowerCase().includes("verification") &&
      !unknownGettingQuickQuote
    ) {
      //if user completes quick quote and doesn't sign in
      //closes browser and comes back, we need to flush all the state so
      //they can log in w/o token interefence and bad flags
      dispatch(appActions.logout());
    }
  }, [userRole, currentPath, dispatch, unknownGettingQuickQuote]);

  useEffect(() => {
    if (params.ProgramCode && app.programCode !== params.ProgramCode && !unknownGettingQuickQuote) {
      //todo: will this need a way to convert to group code for qs string?
      dispatch(appActions.setProgramGroupCode(params.ProgramCode));
    }
  }, [app.programCode, dispatch, params, unknownGettingQuickQuote]);

  //always set the facilityRefCode if we are given one
  useEffect(() => {
    if (params.ref) {
      dispatch(appActions.getFacilityConfigSuccess(params.ref));
    }
  }, [dispatch, params.ref]);


  /////////////////////////////////////////////////////
  ///////////// ******* Routes ******* //////////////
  ////////////////////////////////////////////////////

  return (
    <div id={app.programGroupCode ? app.programGroupCode : "DEFAULT"}>
      <Container
        id="app_main_container_1"
        className={currentPath !== "questions" ? "app_context" : "questions_context"}
      >
        <Card id="app_main_card_1">
          <Card.Body id="app_main_card_body_1">
            <Header />
            <>
              {(app.loading || unknownGettingQuickQuote) && <div id="app_loader_div_1">{util.fullPageLoader()}</div>}
              {(!app.loading && !unknownGettingQuickQuote) && (
                <div
                  id="app_content_div_2"
                  className={app.blurBackground ? "blur" : ""}
                  onClick={() => closeSideMenu()}
                >
                  <Suspense fallback={util.fullPageLoader()}>
                    <Routes>
                      <Route exact path="/" redirectTo="/login"
                        element={
                          <RequireAuth logoutOnRedirect={!isRealUser && !unknownGettingQuickQuote}>
                            <Home />
                          </RequireAuth>
                        }
                      />
                      <Route path=":status" element={UnknownUserRoute()} />

                      <Route
                        path="/error"
                        element={
                          //explicit error page populated with historyObj state messaging
                          <ErrorPage />
                        }
                      />
                      <Route path="/login" element={<Login isRealUser={isRealUser} />} />
                      <Route path="/forgot" element={<ForgotPassword />} />
                      <Route path="/verification" element={<ResetPasswordCode />} />
                      <Route path="/register" element={<Register />} />
                      <Route
                        path="/policylist"
                        element={
                          <RequireAuth redirectTo="/login" logoutOnRedirect={!isRealUser}>
                            <PolicyList />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/usermaintenance"
                        element={
                          <RequireAuth redirectTo="/login" logoutOnRedirect={!isRealUser}>
                            <UserMaintenance />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/useraccount"
                        element={
                          <RequireAuth redirectTo="/login" logoutOnRedirect={!isRealUser}>
                            <EditUser />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/questions/*"
                        element={
                          <RequireAuth redirectTo="/login">
                            <Questions />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/userrules"
                        element={
                          <RequireAuth redirectTo="/login" logoutOnRedirect={!isRealUser}>
                            <UserRules />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/reports"
                        element={
                          <RequireAuth redirectTo="/login" logoutOnRedirect={!isRealUser}>
                            <Reports />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="*"
                        element={
                          //passive fallback error page populated with props
                          <ErrorPage
                            customHeaderMsg="Page not found"
                            customSubheaderMsg="Whoops!"
                            customBodyMsg="Please check the url and try again or click the RVNA logo to head home."
                          />
                        }
                      />
                    </Routes>
                  </Suspense>
                  {/* Handles API response messages */}
                  <ToastContainer
                    // autoClose={50000}
                    progressClassName="toast-progress"
                    bodyClassName="toast-body"
                    position="top-right"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </div>
              )}

            </>
          </Card.Body>
          <Card.Footer id="app_main_card_footer_1">
            <Footer />
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
};

const Logout = () => {
  //dispatch actions
  const dispatch = useDispatch();

  dispatch(appActions.logout());
  return <Navigate to="/login" />;
};

const RequireAuth = ({ children, redirectTo, logoutOnRedirect }) => {
  const isAuthenticated = sessionStorage.getItem("token");
  return logoutOnRedirect ? Logout() : isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export default App;
