export const clearCurrentSubmission = "CLEAR_CURRENT_SUBMISSION";
export const clearFacilityRefCode = "CLEAR_FACILITY_REF_CODE";
export const clearPwModal = "CLEAR_PW_MODAL";
export const clearResetPwEmail = "CLEAR_RESET_PW_EMAIL";
export const clearResetPwSuccess = "CLEAR_RESET_PW_SUCCESS";
export const clearToast = "CLEAR_TOAST";
export const getUserProgramListSuccess = "GET_ALL_MENU_PROGRAMS_SUCCESS";
export const getProgramGroupListSuccess = "GET_ALL_PROGRAM_GROUPS_SUCCESS";
export const getEntityConfigSuccess = "GET_ENTITY_CONFIG_SUCCESS";
export const getFacilityConfigSuccess = "GET_FACILITY_CONFIG_SUCCESS";
export const getLoginDataSuccess = "GET_LOGIN_DATA_SUCCESS";
export const getSelectedPDFsSuccess = "GET_SELECTED_PDFS_SUCCESS";
export const getSubmissionStatusTypesSuccess = "GET_SUBMISSION_STATUS_TYPES_SUCCESS";
export const getStatusTypesInternalSuccess = "GET_STATUS_TYPES_INTERNAL_SUCCESS";
export const getUser = "GET_USER";
export const hasValidToken = "HAS_VALID_TOKEN";
export const login = "LOGIN";
export const loggingIn = "LOGGING_IN";
export const loginSuccess = "LOGIN_SUCCESS";
export const logout = "LOGOUT";
export const logoutSuccess = "LOGOUT_SUCCESS";
export const navigate = "NAVIGATE";
export const openResetPwModal = "OPEN_RESET_PW_MODAL";
export const register = "REGISTER";
export const reportToast = "REPORT_TOAST";
export const resetPwEmail = "RESET_PW_EMAIL";
export const resetPwSuccess = "RESET_PW_SUCCESS";
export const setCurrentSubmission = "SET_CURRENT_SUBMISSION";
export const setDirectToQR = "SET_DIRECT_TO_QR";
export const setPolicyCount = "SET_POLICY_COUNT";
export const setPolicyCountError = "SET_POLICY_COUNT_ERROR";
export const setProgramGroupCode = "SET_PROGRAM_GROUP_CODE";
export const setUSStates = "SET_US_STATES";
export const toggleBlur = "TOGGLE_BLUR";
export const toggleLoading = "TOGGLE_LOADING";
export const updateUser = "UPDATE_USER";
export const useDefaultConfig = "USE_DEFAULT_CONFIG";