import psUtil from "../../Utilities/Functions/PageScript";
import util from "../../Utilities/Functions/Functions";
import * as premHelper from "../QuestionHelpers/PremIndHelper";
import enums from "../../Utilities/Enums/Enums";
import { clone, forEach, union } from "lodash";
import moment from "moment";

let globalFieldIDForReporting = "";
let resultHelper;
let errorMessages;

export function evaluateRule(stagedField, formValues, issuingField_SetID_OP, questionsState) {
  globalFieldIDForReporting = stagedField.id;
  const contingentResult = clone(stagedField.contingentResult);
  let executeResultArray;

  resultHelper = {
    clearValue: false,
    returnedValue: null,
    returnedType: null,
    ruleResult: false,
    selectItemValue: null,
    errors: [],
    ruleHistory: []
  };

  try {
    //replaceAll is escaping any backslashes that our nested regex values
    //may contain. this allows Json.Parse to parse without error
    //note: remove the escaping backslahes downstream
    //in compareRegex just prior to constructing the RegExp() obj
    executeResultArray = JSON.parse(contingentResult.replaceAll("\\", "\\\\"));
  }
  catch (e) {
    resultHelper.errors.push(
      {
        PageScriptID: globalFieldIDForReporting,
        Messages: "failed to parse contingentResult"
      }
    );

    return resultHelper;
  }

  const targetLevelItems = [];
  errorMessages = [];

  const processExecutionResult = (executionArray) => {

    forEach(executionArray, (level, index) => {
      if (typeof level === 'object' && level.hasOwnProperty("RESULT") && targetLevelItems[index] === undefined) {

        let result = execute(level);
        executeResultArray[index].RESULT = result;
        level.RESULT = result;
        targetLevelItems.push(level);

        //log for ps reporting
        const ruleHistoryItem = {
          execution: level,
          result: result
        }

        resultHelper.ruleHistory.push(ruleHistoryItem);

      }
    });

    if (executeResultArray && executeResultArray.length > 0) {

      let finalResult = executionArray[executionArray.length - 1];

      if (finalResult.hasOwnProperty("RETURN") && finalResult.TESTITEM.length === 1) {

        let returnValueTest = executeResultArray[finalResult.TESTITEM[0]].RESULT;
        let returnItem = executeResultArray[finalResult.RETURNITEM[0]];

        if (returnItem && !finalResult.RETURNVALUE) {

          //if we want to return a specific item's result
          if (returnItem.RESULT === enums.psOptions.localDateString) {
            resultHelper.returnedValue = moment().format("MM/DD/YYYY");
            //regex replaces periods with underscores for lookups, so we need to use hex
            resultHelper.returnedValue = String(returnItem.RESULT).replace(/&#46/gi, ".");
          }
          else {
            resultHelper.returnedValue = returnItem.RESULT;
          }

        }
        else if (returnItem && finalResult.RETURNVALUE) {

          //setValueAndReturnTotal or any other compound return of an item and a custom message
          if (finalResult.RETURNVALUE === enums.psOptions.localDateString) {
            resultHelper.returnedValue = moment().format("MM/DD/YYYY");
          }
          else {
            resultHelper.returnedValue = String(finalResult.RETURNVALUE).replace(/&#46/gi, ".");
          }

          if (returnItem.RESULT === enums.psOptions.localDateString) {
            resultHelper.selectItemValue = moment().format("MM/DD/YYYY");
          }
          else {
            resultHelper.selectItemValue = String(returnItem.RESULT).replace(/&#46/gi, ".");
          }

        }
        else {

          //simple return value
          if (finalResult.RETURNVALUE === enums.psOptions.localDateString) {
            resultHelper.returnedValue = moment().format("MM/DD/YYYY");
          }
          else {
            resultHelper.returnedValue = String(finalResult.RETURNVALUE).replace(/&#46/gi, ".");
          }

        }

        resultHelper.returnedType = "string";
        resultHelper.ruleResult = returnValueTest;
      }

      else {
        resultHelper.ruleResult = finalResult.RESULT;
      }
    }
  }

  const execute = (item) => {

    if (item.EXECUTE === "autoValidate") {
      return true;
    }

    if (item.EXECUTE === "cast") {
      const result = cast(formValues, questionsState, item.VALUE, item.VALUETYPE, item.RETURNTYPE, item?.CONTAINS, item.RESULT, issuingField_SetID_OP);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("cast: failed to cast items");
      return;
    }

    if (item.EXECUTE === "castResult") {
      const result = castResult(formValues, questionsState, item.VALUETYPE, item.RETURNTYPE, item.ITEM, targetLevelItems, issuingField_SetID_OP);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("castResult: failed to cast item result");
      return;
    }

    if (item.EXECUTE === "compare") {
      const result = compare(item.SIGN, item.TEST, item.ITEMS, targetLevelItems);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("compare: failed to compare items");
      return;
    }

    if (item.EXECUTE === "compareRegex") {
      const result = compareRegex(item.SIGN, item.TEST, item.ITEMS, targetLevelItems);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("compareRegex: failed to regex compare items");
      return;
    }

    if (item.EXECUTE === "math") {
      const result = mathExpr(item.SIGN, item.ITEMS, targetLevelItems, false);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("math: failed to calculate items");
      return;
    }

    if (item.EXECUTE === "mathDate") {
      const result = mathExpr(item.SIGN, item.ITEMS, targetLevelItems, true);
      if (result !== undefined) {

        //return our standard ms calc if designated
        if (item.RETURNTYPE === "ms") {
          return result;
        }

        //else we want formatted date string
        return moment(new Date(result)).format("MM/DD/YYYY");
      }

      errorMessages.push("mathDate: failed to calculate date items");
      return;
    }

    if (item.EXECUTE === "validateCardCVC") {
      const result = premHelper.validateCardCVC(item.ITEMS, targetLevelItems);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("validateCardCVC: failed to validate card cvc");
      return;
    }

    if (item.EXECUTE === "validateCardMonth") {
      const result = premHelper.validateCardMonth(item.ITEMS, targetLevelItems);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("validateCardMonth: failed to validate card month");
      return;
    }

    if (item.EXECUTE === "validateCardNumber") {
      const result = premHelper.validateCardNumber(item.ITEMS, targetLevelItems);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("validateCardNumber: failed to validate card number");
      return;
    }

    if (item.EXECUTE === "validateCardYear") {
      const result = premHelper.validateCardYear(item.ITEMS, targetLevelItems);
      if (result !== undefined) {
        return result;
      }

      errorMessages.push("validateCardYear: failed to validate card year");
      return;
    }

    if (errorMessages && errorMessages.length > 0) {
      resultHelper.errors.push(
        {
          PageScriptID: globalFieldIDForReporting,
          Messages: union(errorMessages)
        }
      );
    }
  };

  processExecutionResult(executeResultArray);

  return resultHelper;
}


export function cast(formValues, questionsState, value, valueType, returnType, containedTestResult, preassignedResult, issuingField_SetID_OP) {

  //interpreted value may be empty string, so we need to test for undefined below if we are
  //to trust the fallback value passed in above
  let interpretedValue;

  if (value && valueType && returnType) {

    //assign intrepretedValue if exist
    if (valueType === "questionState" && questionsState && !util.isObjEmpty(questionsState) && questionsState.hasOwnProperty(value)) {
      interpretedValue = questionsState[value];
    }
    else if (formValues && typeof value === "string" && value.includes("_") && formValues.hasOwnProperty(value + "-SetID-" + issuingField_SetID_OP)) {
      interpretedValue = formValues[value + "-SetID-" + issuingField_SetID_OP];
    }
    else if (formValues && typeof value === "string" && value.includes("_")) {
      interpretedValue = formValues[value];
    }

    //cast
    if (returnType === "array") {
      if (interpretedValue && Array.isArray(interpretedValue)) {
        return interpretedValue;
      }
      else if (interpretedValue === undefined && Array.isArray(value)) {
        return value;
      }
    }
    else if (returnType === "bool") {
      if (valueType === "boolString") {
        if (interpretedValue === enums.bool.true || value === enums.bool.true) {
          return true;
        }
        else if (interpretedValue === enums.bool.false || value === enums.bool.false) {
          return false;
        }
      }
      else if (interpretedValue === "true" || value === "true") {
        return true;
      }
      else if (interpretedValue === "false" || value === "false") {
        return false;
      }
      else if (typeof interpretedValue === "boolean" || typeof value === "boolean") {
        if (interpretedValue === true || value === true) {
          return true;
        }
        else {
          return false;
        }
      }
    }
    else if (returnType === "boolString") {
      if (interpretedValue === true || interpretedValue === enums.bool.true) {
        return enums.bool.true;
      }
      else {
        return enums.bool.false;
      }
    }
    else if (returnType === "date") {
      if (interpretedValue && interpretedValue === "today" || value === "today") {
        return moment().format("MM/DD/YYYY");
      }
      else if (interpretedValue && valueType === "date") {
        return moment(new Date(interpretedValue)).format("MM/DD/YYYY");
      }
      else if (interpretedValue === undefined) {
        return moment(new Date(value)).format("MM/DD/YYYY");
      }
    }
    else if (returnType === "ms") {
      if (interpretedValue && interpretedValue === "today" || value === "today") {
        return moment().valueOf();
      }
      else if (interpretedValue && valueType === "date") {
        return moment(new Date(interpretedValue)).valueOf();
      }
      else if (interpretedValue && valueType === "days") {
        return psUtil.daysToMilliseconds(interpretedValue);
      }
      else if (interpretedValue === undefined && valueType === "days") {
        if (typeof value === "string") {
          return psUtil.daysToMilliseconds(parseInt(value.replace(/\D/g, "")));
        }
        return psUtil.daysToMilliseconds(value);
      }
      else {
        return "";
      }
    }
    else if (returnType === "int") {
      let normalValue = interpretedValue ? interpretedValue : interpretedValue === undefined && value ? value : 0;

      if (normalValue) {
        if (normalValue.includes("_")) {
          //restore intended decimals that may have been converted to underscores
          //during the lookupCode conversion process
          normalValue = normalValue.replace(/_/g, ".");
        }

        normalValue = normalValue.replace(/[^0-9.]/g, "")

        return parseFloat(normalValue);
      }
      else {
        return null;
      }
    }
    else if (returnType === "string") {
      return String(interpretedValue ? interpretedValue : interpretedValue === undefined && value ? value : "");
    }
    else if (returnType === "stringSplit") {
      if (interpretedValue && interpretedValue.includes(containedTestResult)) {
        return String(interpretedValue.split(preassignedResult)[1]);
      }
      else {
        return "";
      }
    }
    else if (interpretedValue) {
      return interpretedValue;
    }
  }

  return undefined;
}


export function castResult(formValues, questionsState, valueType, returnType, resultItemIndex, resultItems, issuingField_SetID_OP) {

  if (valueType && returnType && resultItems !== undefined && resultItemIndex !== undefined && resultItems[resultItemIndex[0]] !== undefined) {
    const item = resultItems[resultItemIndex[0]];
    return cast(formValues, questionsState, item.RESULT, valueType, returnType, null, null, issuingField_SetID_OP);
  }

  return undefined;
}


export function compare(sign, testValue, testItemIndexes, testItems) {

  const targetTestResults = [];
  let interpretedTestValue;
  const invertedTestValues = [];

  if (testValue) {
    if (testValue.includes(";")) {
      invertedTestValues = testValue.split(";");
    }
    else {
      interpretedTestValue = psUtil.interpretRuleTestValue(testValue);
    }
  }

  if (sign && testItemIndexes && testItemIndexes.length > 0) {
    let item_left;
    let item_right;

    forEach(testItems, (result, index) => {
      if (testItems[testItemIndexes[index]] !== undefined) {

        let itemResult = testItems[testItemIndexes[index]].RESULT;

        if (invertedTestValues && invertedTestValues.length > 0) {
          interpretedTestValue = itemResult;
        }
        else if (testValue) {
          if (testValue === "emptyArray") {
            targetTestResults.push(itemResult?.length === 0);
            interpretedTestValue = true;
          }
          else if (testValue === "undefined") {
            targetTestResults.push(typeof itemResult === "undefined" ? "undefined" : null);
            interpretedTestValue = "undefined";
          }
          else if (testValue === "null") {
            targetTestResults.push(itemResult === "" ? "null" : itemResult);
            interpretedTestValue = "null";
          }
          else {
            targetTestResults.push(itemResult);

            if (!interpretedTestValue && typeof interpretedTestValue !== "boolean") {
              interpretedTestValue = testValue;
            }
          }
        }
        else if (testItemIndexes.length === 2 && !testValue) {
          if (item_left) {
            item_right = itemResult;
          }
          else {
            item_left = itemResult;
          }
        }
      }
    });

    switch (sign) {

      case ">":
        if (invertedTestValues && invertedTestValues.length > 0) {
          return invertedTestValues.some(i => i > interpretedTestValue);
        }
        else if (testValue && testValue.toLowerCase().includes("any")) {
          return targetTestResults.some(i => i > interpretedTestValue);
        }
        return testValue ? targetTestResults.every(i => i > interpretedTestValue) : item_left > item_right;

      case ">=":
        if (invertedTestValues && invertedTestValues.length > 0) {
          return invertedTestValues.some(i => i >= interpretedTestValue);
        }
        else if (testValue && testValue.toLowerCase().includes("any")) {
          return targetTestResults.some(i => i >= interpretedTestValue);
        }
        return testValue ? targetTestResults.every(i => i >= interpretedTestValue) : item_left >= item_right;

      case "<":
        if (invertedTestValues && invertedTestValues.length > 0) {
          return invertedTestValues.some(i => i < interpretedTestValue);
        }
        else if (testValue && testValue.toLowerCase().includes("any")) {
          return targetTestResults.some(i => i < interpretedTestValue);
        }
        return testValue ? targetTestResults.every(i => i < interpretedTestValue) : item_left < item_right;

      case "<=":
        if (invertedTestValues && invertedTestValues.length > 0) {
          return invertedTestValues.some(i => i <= interpretedTestValue);
        }
        else if (testValue && testValue.toLowerCase().includes("any")) {
          return targetTestResults.some(i => i <= interpretedTestValue);
        }
        return testValue ? targetTestResults.every(i => i <= interpretedTestValue) : item_left <= item_right;

      case "===":
        if (invertedTestValues && invertedTestValues.length > 0) {
          return invertedTestValues.some(i => i === interpretedTestValue);
        }
        else if (testValue && testValue.toLowerCase().includes("any")) {
          return targetTestResults.some(i => i === interpretedTestValue);
        }
        return testValue ? targetTestResults.every(i => i === interpretedTestValue) : item_left === item_right;

      case "!==":
        if (invertedTestValues && invertedTestValues.length > 0) {
          return invertedTestValues.some(i => i !== interpretedTestValue);
        }
        else if (testValue && testValue.toLowerCase().includes("any")) {
          return targetTestResults.some(i => i !== interpretedTestValue);
        }
        return testValue ? targetTestResults.every(i => i !== interpretedTestValue) : item_left !== item_right;

      default:
        break;
    }
  }

  return undefined;
}


export function compareRegex(sign, regexTest, testItemIndexes, testItems) {

  const targetTestResults = [];


  if (sign && testItemIndexes && testItemIndexes.length > 0 && regexTest) {

    var normalizedRegexValue;

    //first, check our regexTest is not wrapped in forward slashes
    //if it is, remove them so RegExp can constuct and add them in natively
    if (regexTest.length > 2 && regexTest.charAt(0) === "/" && regexTest.charAt(regexTest.length - 1) === "/") {
      normalizedRegexValue = regexTest.substring(1, regexTest.length - 1);
    }

    //second, we need to remove the escaping backslashes we added upstream
    //on our initial JSON.Parse... four backslashes detects double backslash
    //and two backslashes resolves to a single backslash, so this replaceAll
    //will convert double backslashes to single backslash
    normalizedRegexValue = normalizedRegexValue ? normalizedRegexValue.replaceAll('\\\\', '\\') : regexTest.replaceAll('\\\\', '\\');
    const regexToTest = new RegExp(normalizedRegexValue);

    forEach(testItems, (result, index) => {
      if (testItems[testItemIndexes[index]] !== undefined) {
        const resultToTest = testItems[testItemIndexes[index]].RESULT;
        if (resultToTest && String(resultToTest).match(regexToTest)) {
          targetTestResults.push(true);
        }
        else {
          targetTestResults.push(false);
        }
      }
    });

    switch (sign) {
      case "===":
        return targetTestResults.every(i => i === true);
      case "!==":
        return targetTestResults.every(i => i !== true);
      default:
        break;
    }
  }

  return undefined;
}


export function mathExpr(sign, itemIndexes, targetLevelItems, isDateCalc) {
  let total;

  forEach(targetLevelItems, (item, index) => {
    let targetItem = targetLevelItems[itemIndexes[index]];

    if (targetItem !== undefined && !isNaN(targetItem.RESULT)) {
      if (sign !== undefined && total !== undefined) {
        switch (sign) {
          case "+":
            total += targetItem.RESULT;
            break;
          case "-":
            total -= targetItem.RESULT;
            break;
          case "*":
            total = (total * targetItem.RESULT).toFixed(2);
            break;
          default:
            break;
        }
      }
      else {
        total = targetItem.RESULT;
      }
    }
    else if (
      !isDateCalc &&
      targetItem !== undefined &&
      targetItem.VALUE &&
      targetItem.VALUETYPE === "string" &&
      !isNaN(targetItem.VALUE)
    ) {
      //else apply a default fallback value
      total = parseInt(targetItem.VALUE.replace(/\D/g, ""));
    }
  });

  return total !== undefined && total >= 0 ? total : !isDateCalc ? 0 : undefined;
}