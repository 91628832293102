import { useSelector } from "react-redux";
import { FieldWrapper } from '../../../../StylizedComponents/index';
import { Field } from "formik";
import { Container, Row, Col } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import LabelCommon from "../Shared/LabelCommon";
import ErrorMessage from "../../../../Errors/ErrorMessage/ErrorMessage";
import NestedFieldAlert from "../Alerts/NestedFieldAlert";
import enums from "../../../Enums/Enums";
import { forEach } from "lodash";
import ps from "../../../Functions/PageScript";
import util from "../../../Functions/Functions";

const Selects = (props) => {
  const {
    clearPropertyValueFromPSField,
    columnOffset,
    contingentPSBundle,
    defaultValue,
    disabled,
    field: { name },
    fieldsRef,
    form: { errors, touched, setFieldTouched, setFieldValue, values },
    helptext,
    id,
    isSearchable,
    label,
    label_css,
    label_location,
    label_prefix,
    options,
    placeholder,
    popover_class,
    popover_placement,
    setFormValueCallback,
    setValueParentOrLocal,
    static_col_breakpoint,
    type,
    validateQuestionSimple
  } = props;

  //#region stored values

  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);
  let invalidFieldErrorMessage;

  let hasError = isQuestionsContext
    ? util.checkFieldHasQuestionError(name, storedInvalidFields)
    : util.checkFieldHasYupError(name, errors, touched);

  if (hasError && isQuestionsContext) {
    invalidFieldErrorMessage = util.getInvalidFieldMessage(name, storedInvalidFields)?.fieldMessage;
  }

  //#endregion stored values

  //#region pageScript

  //pageScript
  const normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let psPremount, psExecutePostmount, psPostmountExecutedFields;
  let psReady = {};

  if (isQuestionsContext) {
    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, values[name]);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      //we may have already applied updates to psReady during premount, so pass it back in for updating
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, values[name]);
    }

    if (psReady.value && psReady.value !== undefined && psReady.value !== values[name]) {
      if (psReady.value === "clearValue") {
        clearPropertyValueFromPSField(psReady.id, "value");
        setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
      }
      else {
        setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
      }
    }

    if (ps.hasActiveAlert(psReady)) {
      //need this to mark up input and override valid
      hasError = true;
      // hasValid = false;
    }
  }

  //#endregion pageScript

  //#region functions & actions

  //need this to set defaults coming from defaultValue field of db
  if (defaultValue && !values[name]) {
    setValueParentOrLocal(name, defaultValue, setFieldValue, setFormValueCallback);
  }

  const handleBlur = () => {
    setFieldTouched(name, true);
    if (isQuestionsContext) {
      validateQuestionSimple(name, values[name]);
    }
  };

  const handleChange = (option) => {
    const value = option ? option.value : "";
    //if value equals placeholder, leave field value alone
    if (value !== placeholder) {
      const normalValue = !value ? "" : value;
      setFieldTouched(name, true);
      setValueParentOrLocal(name, normalValue, setFieldValue, setFormValueCallback);

      if (isQuestionsContext) {
        validateQuestionSimple(name, normalValue);
      }
    }
    if (psExecutePostmount) {
      contingentPSBundle(psExecutePostmount, value, name, enums.psExecute.postmount);
    }
  };

  //#endregion functions & actions

  //#region selectLabel

  let input_class_val_css;
  let label_class_interpreted;

  if (hasError) {
    input_class_val_css = "error-border-select ";
    label_class_interpreted += " error-msg ";
    if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
      validateQuestionSimple(name, values[name]);
    }
  }
  else {
    input_class_val_css = "";
    label_class_interpreted = "";
  }

  label_class_interpreted += label_css;

  let label_text = label ? label : "";
  if (psReady && psReady.setLabelValue) {
    label_text = psReady.setLabelValue;
  }

  //get the label from options for the selected value
  let defaultedLabel = "";

  if (options) {
    forEach(options, (optionObj, key) => {
      if (values && name && values[name] !== "") {
        if (values[name] === optionObj.value || values[name] === optionObj.label) {
          return (defaultedLabel = optionObj.label);
        }
      }
      else if (defaultValue && !values[name]) {
        if (defaultValue === optionObj.value) {
          return (defaultedLabel = optionObj.label);
        }
      }
    });
  }

  const labelProps = {
    helptext, id, isQuestionsContext, label_class_interpreted, label_prefix,
    label_text, popover_class, popover_placement, type
  }

  //#endregion selectLabel

  //#region custom react-select style obj

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? "red" : "blue",
      ":active": {
        color: values[name] !== "" ? "white" : "black",
        backgroundColor: values[name] !== "" ? "#0275d8" : "white",
      },
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "0%",
      fontWeight: "400",
      height: "41px",
      paddingLeft: "5px",
      paddingTop: "1px",
      //borderColor: hasError ? "#d9534f" : hasValid ? "#00c851" : "#ccc",
      borderColor: hasError ? "#d9534f" : "#ccc",
      boxShadow: "none", //disable the focus blue border
      ":hover": {
        //borderColor: hasError ? "#d9534f" : hasValid ? "#00c851" : "#ccc",
        borderColor: hasError ? "#d9534f" : "#ccc",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      // height: "35px",
      display: "none",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#999999",
      paddingBottom: isSearchable ? "16px" : "16px",
      paddingRight: "5px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#999999",
      paddingBottom: isSearchable ? "16px" : "10px",
      paddingLeft: "5px",
      paddingRight: "5px",
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingBottom: isSearchable ? "10px" : "2px",
      color: "#999",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingBottom: isSearchable ? "10px" : "2px",
      color: "#212529",
    }),
  };


  //#endregion custom react-select style obj

  return (
    <Container
      id="select_main_container_1"
      className={label_text ? "" : "no_label"}
      key={id}
      onBlur={() => handleBlur()}
    >
      <Field
        id="select_field_wrapper_1"
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, "")}
        component={FieldWrapper}
        name={"select-" + name}
      >
        {label_text && label_location === enums.labelLocation.top && (
          <Row id="select_row_1">
            <Col id="select_col_1">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row id="select_row_2">
          {label_text && label_location === enums.labelLocation.left && (
            <Col id="select_col_2">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
          <Col
            id="select_col_3"
            className={label_text ? "select_dynamic_fix global_select has_label" : "select_dynamic_fix global_select"}
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
          >
            <Select
              autoFocus={false}
              className={input_class_val_css}
              isDisabled={
                psReady && psReady.disableInput ? psReady.disableInput :
                  psReady && psReady.setValueAndDisableInput ? psReady.setValueAndDisableInput
                    : disabled
              }
              filterOptions={createFilter(util.selectFilterConfig())}
              id={id}
              // isClearable={(placeholder && placeholder === "noBlankOption") || !values[name] ? false : true}
              isSearchable={isSearchable}
              // menuIsOpen={true} //-- use this for debugging
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              options={options}
              placeholder={placeholder}
              styles={customStyles}
              value={
                defaultValue && !values[name]
                  ? { value: defaultValue, label: defaultedLabel }
                  : values[name]
                    ? { value: values[name], label: defaultedLabel }
                    : { value: "", label: placeholder }
              }
              autoComplete={questions && questions.pageUrl === "PremInd" ? "none" : "on"}
            />
            {!isQuestionsContext && <ErrorMessage errors={errors[name]} touched={touched[name]} />}
          </Col>
          {label_text && label_location === enums.labelLocation.right && (
            <Col id="select_col_4">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
        </Row>

        <Row id="select_row_3">
          <Col
            id="select_col_5"
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}>
            <NestedFieldAlert psReady={psReady} invalidFieldErrorMessage={invalidFieldErrorMessage} />
          </Col>
        </Row>
      </Field>
    </Container>
  );
};

export default Selects;
