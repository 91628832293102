import { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../Store/SearchStore";
import { isEmpty } from "lodash";
import { Navigate } from "react-router-dom";
import { Col, Navbar, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import historyObj from "../Routing/browserHistory";
import { default as FormInputs } from "../Utilities/Inputs/FormGroupsWrapper";
import { Formik } from "formik";
import ErrorBoundary from "../Errors/ErrorBoundary/ErrorBoundary";
import ProductBanner from "./ProductBanner/ProductBanner";
import UserBanner from "./UserBanner/UserBanner";
import DebugMenu from "../Errors/DebugMode/DebugMenu.js";
import SideMenu from "../SideMenu/SideMenu.js";
import FAQModal from "../Utilities/Modals/FAQModal/FAQModal.js";
import util from "../Utilities/Functions/Functions";
import enums from "../Utilities/Enums/Enums";
import * as appActions from "../App/AppActions";
import * as headerActions from "./HeaderActions";
import * as policyActions from "../PolicyList/PolicyActions";
import * as questionsActions from "../Questions/QuestionsActions";


const Header = () => {
  //store
  const app = useSelector((state) => state.app);
  const header = useSelector((state) => state.header);
  const questions = useSelector((state) => state.questions);
  const currentPath = historyObj.location.pathname.substring(1);

  //dispatch actions
  const dispatch = useDispatch();

  //states
  const [redirectToReferrer, toggleRedirect] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //context
  const {
    searching: [isSearching, setIsSearching],
    searchForm: [searchFormIsOpen, setSearchFormOpen],
    searchResult: [searchResults, setSearchResults],
  } = useContext(SearchContext);

  //local
  //const disableSideMenu = useCallback(bool => dispatch(headerActions.disableSideMenu(bool)), [dispatch]);

  const getProductBanner = useCallback((programCode) => dispatch(headerActions.getProductBanner(programCode)), [dispatch]);
  const clearPolicyState = useCallback(() => dispatch(policyActions.clearPolicyState()), [dispatch]);
  const toggleBlur = useCallback(() => dispatch(appActions.toggleBlur()), [dispatch]);
  const toggleDebugMenu = useCallback(() => dispatch(headerActions.toggleDebugMenu()), [dispatch]);
  const toggleSideMenu = useCallback(() => dispatch(headerActions.toggleSideMenu()), [dispatch]);
  const issueSaveAndExitModal = useCallback(() => dispatch(questionsActions.issueSaveAndExitModal()), [dispatch]);

  const toggleSideMenuBundle = () => {
    toggleBlur();
    toggleSideMenu();
  };

  const navigateToUserAccount = () => {
    if (header.sideMenuOpen) {
      toggleBlur();
      toggleSideMenu();
    }
    historyObj.push("/useraccount");
  };

  const handleQuestionsHomeButton = () => {
    if (currentPath === "questions/payment-complete") {
      toggleRedirect(!redirectToReferrer);
    }
    else if (!questions.openSaveAndExitModal) {
      issueSaveAndExitModal();
    }
  }

  //local
  const setNavStyles = () => {
    let navbarClass = "d-flex flex-row align-items-center";
    if (app.productName !== "") {
      if (window.matchMedia("(min-width: 450px)").matches) {
        navbarClass += " whitenavbar";
      }
    }
    return navbarClass;
  };

  //effects

  useEffect(() => {
    if (!header.productBanner || (header.bannerProgramCode !== questions.programCode)) {
      getProductBanner(questions.programCode);
    }
  }, [header.productBanner, header.bannerProgramCode, questions.programCode, getProductBanner]);

  useEffect(() => {
    toggleRedirect(false);
  }, [redirectToReferrer, toggleRedirect]);

  useLayoutEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWindowWidth);
    if (windowWidth !== window.innerWidth) {
      updateWindowWidth();
    }
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, [windowWidth]);


  // useEffect(() => {
  //   if (window.location.href.includes("/questions") || !app.isLoggedIn) {
  //     disableSideMenu(true);
  //   } else {
  //     disableSideMenu(false);
  //   }
  // }, [app.isLoggedIn, disableSideMenu]);

  //redirect effect
  if (redirectToReferrer === true) {
    //need policyList path check since app.currentSubmnission gets loaded on actionModal launch
    if (
      util.isObjEmpty(app.currentSubmission) ||
      currentPath === "policylist" ||
      currentPath === "questions/payment-complete" ||
      currentPath === "reports" ||
      currentPath === "usermaintenance"
    ) {
      if (currentPath === "policylist") {
        clearPolicyState();
        setIsSearching(false);
        setSearchResults(false);
      }
      return <Navigate to={"/"} />;
    }
    else if (!questions.openSaveAndExitModal) {
      issueSaveAndExitModal();
    }

    toggleRedirect(false);
  }

  return (
    <div id="header_main_div_1">
      <Navbar id="header_navbar_1" color="faded" light={"true"} className={setNavStyles()}>
        <Col id="header_col_1">
          <Nav id="header_nav_1">
            <Nav.Item id="header_nav_item_1" className="btnNoOutline">
              {!currentPath.includes("questions") && app.isLoggedIn && app.user.entityTypeCode !== enums.role.unknown && (
                <div id="header_nav_link_1" onClick={() => toggleSideMenuBundle()}>
                  <i className={"fa fa-bars sideBars"} aria-hidden="true" />
                </div>
              )}
              {currentPath.includes("questions") && app.isLoggedIn && (
                <div
                  id="header_nav_link_2"
                  disabled={questions.loading || questions.loadingQuote}
                  onClick={() => handleQuestionsHomeButton()}
                >
                  <i className={"fa fa-home"} aria-hidden="true"></i>
                </div>
              )}
            </Nav.Item>
          </Nav>
        </Col>
        <Col id="header_col_2">
          <Nav id="header_nav_2">
            <Nav.Item id="header_nav_item_2">
              <div id="header_navbar_div_2" className="btnNoOutline" onClick={() => toggleRedirect(!redirectToReferrer)}>
                <Formik>
                  <FormInputs
                    id={"rvnaLogo"}
                    name={"rvnaLogo"}
                    helptext={"RVNA Logo"}
                    source={"RVNA_logo.png"}
                    type={enums.fieldTypes.image}
                  />
                </Formik>
              </div>
            </Nav.Item>
          </Nav>
        </Col>
        <Col id="header_col_3">
          <Nav id="header_nav_3" className={"d-flex justify-content-end"} navbar>
            <Nav.Item id="header_nav_item_3">
              {!window.location.href.includes("/questions") && app.isLoggedIn && app.user.entityTypeCode !== enums.role.unknown && (
                <div id="header_nav_link_3" className="cursor">
                  <OverlayTrigger
                    key={"tooltipUser"}
                    placement={"left"}
                    overlay={
                      <Tooltip id={`tooltip-${"left"}`}>
                        View Profile
                      </Tooltip>
                    }
                  >
                    <div id="header_div_3" style={{ cursor: "pointer" }} onClick={() => navigateToUserAccount()}>
                      <i className="fa fa-user user-icon"></i>
                    </div>
                  </OverlayTrigger>
                </div>
              )}
              {window.location.href.includes("/questions") && app.isLoggedIn && questions.useDebugMode && (
                <div id="header_nav_link_4" disabled={questions.loading || questions.loadingQuote} onClick={() => toggleDebugMenu()}>
                  <i className={"fa fa-bars debug-icon"} aria-hidden="true"></i>
                </div>
              )}
            </Nav.Item>
          </Nav>
        </Col>
      </Navbar>
      <UserBanner />
      {questions && questions.submissionID && (
        <ErrorBoundary>
          <ProductBanner windowWidth={windowWidth} />
        </ErrorBoundary>
      )}
      <ErrorBoundary>
        <DebugMenu />
      </ErrorBoundary>
      <ErrorBoundary>
        <SideMenu />
      </ErrorBoundary>
      <ErrorBoundary>
        <FAQModal />
      </ErrorBoundary>
    </div>
  );
};

export default Header;
