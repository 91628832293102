import { useSelector } from "react-redux";
import { FieldWrapper } from '../../../../StylizedComponents/index';
import { Field } from "formik";
import { Form, FormCheck, Container, Row, Col } from "react-bootstrap";
import ErrorMessage from "../../../../Errors/ErrorMessage/ErrorMessage";
import LabelCommon from "../Shared/LabelCommon";
import CheckboxInfo from "./CheckboxInfo";
import ps from "../../../Functions/PageScript";
import util from "../../../Functions/Functions";
import enums from "../../../Enums/Enums";

const Checkbox = (props) => {
  const {
    clearPropertyValueFromPSField,
    contingentPSBundle,
    columnOffset,
    defaultValue,
    disabled,
    field: { name },
    fieldsRef,
    form: { errors, touched, setFieldTouched, setFieldValue, values },
    helptext,
    id,
    label,
    label_css,
    label_location,
    label_prefix,
    popover_class,
    popover_placement,
    setFormValueCallback,
    setValueParentOrLocal,
    tooltipImg,
    type,
    validateQuestionSimple,
    windowWidth
  } = props;

  //#region stored values

  let questions = useSelector((state) => state.questions);
  let isQuestionsContext = ps.isQuestionsContext(questions);
  let storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);

  //validation
  let hasError = isQuestionsContext
    ? util.checkFieldHasQuestionError(name, storedInvalidFields)
    : util.checkFieldHasYupError(name, errors, touched);

  let value = defaultValue && !values[id] ? defaultValue : !values[id] ? enums.bool.false : values[id];

  //#endregion stored values

  //#region pageScript

  let normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let psPremount, psExecutePostmount, psPostmountExecutedFields;
  let psReady = {};

  if (isQuestionsContext) {
    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, value);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, value);

      if (psReady.value && psReady.value !== undefined && psReady.value !== values[name]) {
        if (psReady.value === "") {
          setValueParentOrLocal(name, "FALSE", setFieldValue, setFormValueCallback);
        }
        else if (psReady.value === "clearValue") {
          clearPropertyValueFromPSField(psReady.id, "value");
          setValueParentOrLocal(name, "FALSE", setFieldValue, setFormValueCallback);
        }
        else {
          setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
        }
      }
    }

    if (ps.hasActiveAlert(psReady)) {
      //need this to mark up input and override valid
      hasError = true;
      // hasValid = false;
    }
  }

  //#endregion pageScript

  //#region functions & actions

  const handleBlur = () => {
    setFieldTouched(name, true);
    if (isQuestionsContext) {
      validateQuestionSimple(name, value);
    }
  };

  const handleChange = () => {

    let newValue = value === enums.bool.true ? enums.bool.false : enums.bool.true;
    setFieldTouched(name, true);
    setValueParentOrLocal(name, newValue, setFieldValue, setFormValueCallback);

    if (psExecutePostmount) {
      //must submit opposite value - formik wont update in time
      contingentPSBundle(psExecutePostmount, newValue, name, enums.psExecute.postmount);
    }
  };

  //#endregion functions & actions

  //#region checkboxLabel

  let label_class_interpreted;
  let word_wrap_class = "";

  if (hasError) {
    label_class_interpreted += " error-msg ";
    if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
      validateQuestionSimple(name, value);
    }
  }
  if (touched[name] && value === enums.bool.false || !hasError) {
    label_class_interpreted = "";
    //if the label is longer than 26 characters we need it to wrap so the inputs don't overlap the labels
    if (label && label.length > 26) {
      word_wrap_class = " word_wrap";
    }
  }

  label_class_interpreted += label_css;

  //mobile needs <hr> to help designate parent checkbox areas in ExCards,
  //cut out lineMobile from label_class and reassign to new var
  //else it will display none in elements that take labelClass

  let lineMobile = windowWidth < enums.mobile.maxWidth && isQuestionsContext ? "lineMobile" : "";
  let label_text = label ? label : "";

  const isDisabled = psReady && psReady.disableInput
    ? psReady.disableInput
    : psReady && psReady.setValueAndDisableInput
      ? psReady.setValueAndDisableInput
      : disabled;

  const disableLabel = isDisabled;

  if (psReady && psReady.setLabelValue) { label_text = psReady.setLabelValue; }

  let labelProps = {
    helptext, id, label_class_interpreted,
    label_location, label_prefix, label_text, popover_class,
    popover_placement, type, disableLabel
  }

  //#endregion checkboxLabel

  return (
    <Container
      id="checkbox_main_container_1"
      className={label_text ? "" : "no_label"}
      key={id}
      onBlur={() => handleBlur()}
    >
      <Field
        id="checkbox_field_wrapper_1"
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, word_wrap_class)}
        component={FieldWrapper}
        name={"checkbox-" + name}
      >
        <hr id="checkbox_hr_1" className={lineMobile ? lineMobile : "d-none"}></hr>

        {label_text && label_location === enums.labelLocation.top && (
          <Row id="checkbox_row_1">
            <Col id="checkbox_col_1" xs={"12"}>
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row id="checkbox_row_2">
          {label_text && label_location === enums.labelLocation.left && (
            <Col id="checkbox_col_2">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}

          <Col id="checkbox_col_3" className="checkbox">
            <Form.Check
              type={enums.fieldTypes.checkbox}
              key={id + 1}
              className={helptext ? "inline-block" : ""}
              custom
              disabled={isDisabled}
              id={id}
              name={name}
              inline
              aria-label={id}
            >
              <Form.Check.Input
                type={enums.fieldTypes.checkbox}
                checked={value === enums.bool.true}
                name={name}
                id={id}
                onChange={() => handleChange()}
                disabled={isDisabled}
              />
              <FormCheck.Label></FormCheck.Label>
            </Form.Check>
          </Col>

          {label_text && label_location === enums.labelLocation.right && (
            <Col id="checkbox_col_4">
              {/* <Row>
                <Col xs="11" > */}
              <LabelCommon ref={fieldsRef} {...labelProps} />
              {/* </Col> */}
              {/* {tooltipImg && ( //TODO: do we need to move this into Label Common?
                  <Col className={"col-1"}>
                    <CheckboxInfo ref={fieldsRef} {...props} />
                  </Col>
                )} */}
              {/* </Row> */}
            </Col>
          )}
          {!isQuestionsContext && <ErrorMessage errors={errors[name]} touched={touched[name]} />}
        </Row>

        {label_text && label_location === enums.labelLocation.bottom && (
          <Row id="checkbox_row_3">
            <Col id="checkbox_col_5">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

      </Field>
    </Container>
  );
};

export default Checkbox;
