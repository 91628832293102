//question types
export const clearAppliedDataDefaults = "CLEAR_APPLIED_DATA_DEFAULTS";
export const clearLoadingFlags = "CLEAR_LOADING_FLAGS";
export const clearPropertyValueFromPSField = "CLEAR_PROPERTY_VALUE_FROM_PS_FIELD";
export const clearPSReportLog = "CLEAR_PS_REPORT_LOG";
export const clearQuestionsState = "CLEAR_QUESTIONS_STATE";
export const clearSaveAndExitAIModal = "CLEAR_SAVE_AND_EXIT_AI_MODAL";
export const clearSubpage = "CLEAR_SUBPAGE";
export const clearQuestionsToast = "CLEAR_QUESTIONS_TOAST";
export const executePageScript = "EXECUTE_PAGE_SCRIPT";
export const getFacilityConfigSuccess = "GET_FACILITY_CONFIG_SUCCESS";
export const getNavigationData = "GET_NAVIGATION_DATA";
export const getNavigationDataSuccess = "GET_NAVIGATION_DATA_SUCCESS";
export const getQuestionsAndData = "GET_QUESTIONS_AND_DATA";
export const getQuestionsAndDataSuccess = "GET_QUESTIONS_AND_DATA_SUCCESS";
export const getQuote = "GET_QUOTE";
export const hideAllInputs = "HIDE_ALL_INPUTS";
export const issueExitWithoutSaveModal = "ISSUE_EXIT_WITHOUT_SAVE_MODAL";
export const issueSaveAndExitModal = "ISSUE_SAVE_AND_EXIT_MODAL";
export const toggleSaveAndExitAIModal = "ISSUE_SAVE_AND_EXIT_AI_MODAL";
export const reportQuestionsToast = "REPORT_QUESTIONS_TOAST";
export const setCardAndValuesToPristine = "SET_CARD_AND_VALUES_TO_PRISTINE";
export const setDateConflictAlert = "SET_AI_DATE_CONFLICT_ALERT";
export const setLoadingFlag = "SET_LOADING_FLAG";
export const setLoadingQuoteFlag = "SET_LOADING_QUOTE_FLAG";
export const setDynamicModal = "SET_DYNAMIC_MODAL";
export const setNavigateTo = "SET_NAVIGATION_TO";
export const setNavigationPage = "SET_NAVIGATION_PAGE";
export const setProgramCode = "SET_PROGRAM_CODE";
export const setPromoCode = "SET_PROMO_CODE";
export const setQuestionsQueryParam = "SET_QUESTIONS_QUERY_PARAM";
export const setupQuestions = "SETUP_QUESTIONS";
export const setSubmissionID = "SET_SUBMISSION_ID";
export const setValidPurchase = "SET_VALID_PURCHASE";
export const submissionValidationResult = "SUBMISSION_VALIDATION_RESULT";
export const toggleEmailQuoteModal = "TOGGLE_EMAIL_QUOTE_MODAL";
export const toggleFieldDataDisplay = "TOGGLE_FIELD_DATA_DISPLAY";
export const togglePageHasBanner = "TOGGLE_PAGE_HAS_BANNER";
export const togglePageScriptReporting = "TOGGLE_PAGE_SCRIPT_REPORTING";
export const toggleModal = "TOGGLE_MODAL";
export const toggleQRFormHide = "TOGGLE_QR_FORM_HIDE";
export const toggleResetFormikToInitial = "TOGGLE_RESET_FORMIK_TO_INITIAL";
export const toggleSubmissionLoaded = "TOGGLE_SUBMISSION_LOADED";
export const validatePage = "VALIDATE_PAGE";
export const validationResultSingle = "VALIDATION_RESULT_SINGLE";
export const validationResultAll = "VALIDATION_RESULT_ALL";
export const validationSubmission = "VALIDATION_SUBMISSION";
export const updateFieldValidations = "UPDATE_FIELD_VALIDATIONS";
export const updateInitialValues = "UPDATE_INITIAL_VALUES";
export const updateProgramCode = "UPDATE_PROGRAM_CODE";
export const updateQuestions = "UPDATE_QUESTIONS";

//expanding card types
export const toggleCardSelection = "TOGGLE_CARD_SELECTION";

//repeating cards types
export const addNewRepeatingCardToSet = "ADD_NEW_REPEATING_CARD_TO_SET";
export const closeRepeatingCard = "CLOSE_REPEATING_CARD";
export const editRepeatingCard = "EDIT_REPEATING_CARD";
export const resetRCardsWithError = "RESET_RCARDS_WITH_ERROR";
export const setRepeatingCardHiddenFlag = "SET_REPEATING_CARD_HIDDEN_FLAG";
export const setSubpageQuestions = "SET_SUBPAGE_QUESTIONS";
export const toggleShowHideAllRepeatingCards = "TOGGLE_SHOW_HIDE_ALL_REPEATING_CARDS";
export const updateActiveRepeatingCards = "UPDATE_ACTIVE_REPEATING_CARDS";
