import { useEffect, useState } from "react";
import { Col, Button, Modal } from "react-bootstrap";

const IndependentModal = (props) => {
  const [open, toggleOpen] = useState(false);

  const acceptCallback = () => {
    toggleOpen(false);
    if (props.acceptCallback) {
      props.acceptCallback();
    }
  };

  const cancelCallback = () => {
    toggleOpen(false);
    if (props.cancelCallback) {
      props.cancelCallback();
    }
  };

  //toggle open once
  useEffect(() => {
    if (props.isOpen) {
      toggleOpen(true);
    }
  }, [props.isOpen]);

  return (
    <Modal id="independent_modal_1" show={open} onHide={() => toggleOpen(!open)} className={props.className} backdrop="static" dialogClassName={props.dialogClassName}>
      {props.cancelBtn && (
        <Modal.Header id="independent_modal_header_1" className={"border-0"} closeButton onClick={() => cancelCallback()}>
          {props.modalHeader}
        </Modal.Header>
      )}
      {!props.cancelBtn && <Modal.Header id="independent_modal_header_2">{props.modalHeader}</Modal.Header>}
      <Modal.Body id="independent_modal_body_1">{props.content}</Modal.Body>
      <Modal.Footer id="independent_modal_footer_1" xs={"12"}>
        {props.cancelBtn && (
          <Col xs={"12"} lg={"5"}>
            <Button id="independent_modal_btn_1" onClick={() => cancelCallback()} variant="outline-primary">
              {props.cancelBtnText ? props.cancelBtnText : "Cancel"}
            </Button>
          </Col>
        )}
        <Col xs={"12"} lg={"5"} className={"text-nowrap"}>
          <Button id="independent_modal_btn_2" color="primary" onClick={() => acceptCallback()}>
            {props.acceptBtnText ? props.acceptBtnText : "Ok"}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default IndependentModal;
