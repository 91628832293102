import { useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldWrapper } from '../../../../StylizedComponents/index';
import { Field } from "formik";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Container, Row, Col } from "react-bootstrap";
import { debounce, forEach } from "lodash";
import LabelCommon from "../Shared/LabelCommon";
import ErrorMessage from "../../../../Errors/ErrorMessage/ErrorMessage";
import NestedFieldAlert from "../Alerts/NestedFieldAlert";
import enums from "../../../Enums/Enums";
import ps from "../../../Functions/PageScript";
import util from "../../../Functions/Functions";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as appActions from "../../../../App/AppActions";
import * as questionsActions from "../../../../Questions/QuestionsActions";
import * as psRules from "../../../../Questions/PageScript/Rules";
import * as axiosApi from "../../../../axiosApi";

const Typeahead = (props) => {
  const {
    clearPropertyValueFromPSField,
    columnOffset,
    defaultValue,
    disabled,
    field: { name },
    fieldsRef,
    form: { errors, touched, setFieldTouched, setFieldValue, values },
    helptext,
    id,
    label,
    label_css,
    label_location,
    label_prefix,
    lookupClass,
    parentLookupCode,
    placeholder,
    popover_class,
    popover_placement,
    setFormValueCallback,
    setValueParentOrLocal,
    static_col_breakpoint,
    type,
    validateQuestionSimple
  } = props;

  //#region stored values

  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);
  const _typeaheadLookup = useRef(null);
  // const _typeaheadBasic = useRef(null);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  let invalidFieldErrorMessage;
  let normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let setID_OP;
  let psPremount, psExecutePostmountFields, psPostmountExecutedFields;
  let psReady = {};
  let debouncedFunction;

  let hasError = isQuestionsContext
    ? util.checkFieldHasQuestionError(name, storedInvalidFields)
    : util.checkFieldHasYupError(name, errors, touched);

  if (hasError && isQuestionsContext) {
    invalidFieldErrorMessage = util.getInvalidFieldMessage(name, storedInvalidFields)?.fieldMessage;
  }

  //dispatch
  const dispatch = useDispatch();

  //actions
  const reportError = useCallback((msg) => dispatch(appActions.reportToast(msg, enums.toastTypes.error)), [dispatch]);
  const getAdditionalInsuredResponses = useCallback((parentCardSetLookup, card, formValues) =>
    dispatch(questionsActions.getAdditionalInsuredResponses(parentCardSetLookup, card, formValues)),
    [dispatch]
  );

  //#endregion stored values

  //#region pageScript
  if (isQuestionsContext) {

    if (id.includes("-SetID-")) {
      setID_OP = id.split("-SetID-")[1];
    }

    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    //grab array of psExecutePostmountFields since Typeahead.js works outside the Execute.js workflow
    //we are unable to grab all the contingent and applies to fields that Execute.js normally handles
    psExecutePostmountFields = ps.getPSExecutables(questions.pageScript.scripts, enums.psExecute.postmount, normalizedId, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, values[name]);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      //we've may have already applied updates to psReady during premount, so pass it back in for updating
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, values[name]);
    }

    if (psReady.value && psReady.value !== undefined && psReady.value !== values[name]) {
      if (psReady.value === "clearValue") {
        clearPropertyValueFromPSField(psReady.id, "value");
        setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
      }
      else {
        setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
      }
    }

    if (ps.hasActiveAlert(psReady)) {
      //need this to mark up input and override valid
      hasError = true;
      // hasValid = false;
    }
  }

  //#endregion pageScript

  //#region functions & actions

  //need this to set defaults coming from defaultValue field of db
  if (defaultValue && !values[name]) {
    setValueParentOrLocal(name, defaultValue, setFieldValue, setFormValueCallback);
  }

  const handleBlur = () => {
    setFieldTouched(name, true);
    if (isQuestionsContext) {
      validateQuestionSimple(name, values[name]);
    }
  };

  const handleSearch = (query) => {

    if (
      (query || (!query && values[name])) &&
      (!lookupClass || (lookupClass && lookupClass !== enums.lookupClasses.existingAIs))
    ) {
      setValueParentOrLocal(name, query, setFieldValue, setFormValueCallback);
    }

    if (
      !lookupClass || query.length < 3 ||
      (lookupClass === enums.lookupClasses.existingAIs && !query.includes("@"))
    ) {
      return;
    }

    setLoading(true);

    let vm = {
      FieldID: null,
      FieldDisplayValue: query,
      LookupClass: lookupClass,
      SubmissionID: questions.submissionID,
      ProgramCode: questions.programCode,
      PageName: questions.pageUrl
    };

    //terminate stale debounced function
    if (debouncedFunction && debouncedFunction.cancel) {
      debouncedFunction.cancel();
      setLoading(false);
    }

    debouncedFunction = debounce(async () => {
      try {
        await axiosApi.post("api/ComponentData/getLookupClassData", vm).then(response => {
          const results = response.data.data;
          const dropdownOptions = [];
          forEach(results, (item) => {
            if (item.fieldID && item.fieldDisplayValue) {
              if (item?.facilityRow) {
                dropdownOptions.push({ value: item.fieldID, label: item.fieldDisplayValue, facility: item.facilityRow });
              }
              else if (item?.additionalInsuredRow) {
                dropdownOptions.push({ value: item.fieldID, label: item.fieldDisplayValue, additionalInsured: item.additionalInsuredRow });
              }
            }
          });

          setOptions(dropdownOptions);
        }, error => {
          reportError(error);
        });
      }
      catch (error) {
        debouncedFunction.cancel();
      }
      finally {
        setLoading(false);
      }
    }, 1000);

    debouncedFunction();
  };

  const handleChange = (option) => {

    if (!option) {
      return;
    }

    if (
      lookupClass === enums.lookupClasses.facilityAIDataOnly ||
      lookupClass === enums.lookupClasses.facilityAndAIData
    ) {

      const fieldLabel = option[0] ? option[0].label : "";
      const facility = option[0] ? option[0].facility : null;

      //set field value regardless of async post - user may be entering an unregistered facility
      setValueParentOrLocal(name, fieldLabel, setFieldValue, setFormValueCallback);

      if (facility) {
        forEach(psExecutePostmountFields, (psField) => {

          if (
            psField &&
            psField.action === enums.psOptions.setValueInField &&
            psField.contingentLookupCodes[0] === normalizedId
          ) {

            const resultHelper = psRules.evaluateRule(
              psField,
              values,
              setID_OP,
              null
            );

            if (resultHelper.ruleResult) {
              //for each psField, if AppliesToLookup === id && action === "setValueInField"
              //get each contingentLookup code and check all other psFields with AppliesToLookupCode of that contingentLookup
              //and their returnedValues will be the facility object's properties, which we will match up and spit out one by one with the setValueParentOrLocal

              const returnValues = resultHelper?.returnedValue.split(";");

              forEach(psField.appliesToLookupCodes, (appliesToLookupCode) => {
                if (appliesToLookupCode) {
                  if (psField.appliesToLookupCodes.length === 1) {

                    let normalAppliesToLookupCode = appliesToLookupCode;

                    if (setID_OP) {
                      normalAppliesToLookupCode = appliesToLookupCode + "-SetID-" + setID_OP;
                    }

                    //appliesToLookupCodes length is 1, no template syntax registered to the returnedValue
                    setValueParentOrLocal(
                      normalAppliesToLookupCode,
                      facility[resultHelper.returnedValue],
                      setFieldValue,
                      setFormValueCallback
                    );

                    if (isQuestionsContext) {
                      validateQuestionSimple(normalAppliesToLookupCode, facility[resultHelper.returnedValue]);
                    }
                  }
                  else {

                    //appliesToLookupCodes length is greater than 1, and must match lookups with template syntax to the returnedValue
                    let lookupCodeNormal = appliesToLookupCode.split("-@")[0];
                    const itemIndex = appliesToLookupCode.split("-@")[1];
                    const returnedFieldValue = returnValues?.find(v => v.includes(parseInt(itemIndex)));
                    const returnedValueNormal = returnedFieldValue?.split("-@")[0];

                    if (setID_OP) {
                      lookupCodeNormal = lookupCodeNormal + "-SetID-" + setID_OP;
                    }

                    setValueParentOrLocal(
                      lookupCodeNormal,
                      facility[returnedValueNormal],
                      setFieldValue,
                      setFormValueCallback
                    );

                    if (isQuestionsContext) {
                      validateQuestionSimple(lookupCodeNormal, facility[returnedValueNormal]);
                    }
                  }
                }
              });
            }
          }

        });
      }
    }
    else if (lookupClass === enums.lookupClasses.existingAIs) {
      const selectedAICard = option[0] ? option[0].additionalInsured : null;
      if (selectedAICard) {
        getAdditionalInsuredResponses(parentLookupCode, selectedAICard, values);
      }

      _typeaheadLookup.current.clear();
    }
  };

  //#endregion functions & actions

  //#region typeaheadLabel

  let input_class_val_css;
  let label_class_interpreted;
  if (hasError) {
    input_class_val_css = "error-border-typeahead ";
    label_class_interpreted += " error-msg ";
    if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
      validateQuestionSimple(name, values[name]);
    }
  }
  else {
    input_class_val_css = "";
    label_class_interpreted = "";
  }

  label_class_interpreted += label_css;

  let label_text = label ? label : "";
  if (psReady && psReady.setLabelValue) {
    label_text = psReady.setLabelValue;
  }

  let labelProps = {
    helptext, id, isQuestionsContext, label_class_interpreted, label_prefix,
    label_text, popover_class, popover_placement, type
  }

  //#endregion typeaheadLabel

  return (
    <Container
      id="typeahead_main_container_1"
      className={label_text ? "" : "no_label"}
      key={id}
      onBlur={() => handleBlur()}
    >
      <Field
        id="typeahead_field_wrapper_1"
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, "")}
        component={FieldWrapper}
        name={"typeahead-" + name}
      >
        {label_text && label_location === enums.labelLocation.top && (
          <Row id="typeahead_field_row_1">
            <Col id="typeahead_field_col_1">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row id="typeahead_field_row_2">
          {label_text && label_location === enums.labelLocation.left && (
            <Col id="typeahead_field_col_2">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
          <Col
            id="typeahead_field_col_3"
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
          >
            {
              lookupClass ?
                <AsyncTypeahead
                  id={id}
                  name={name}
                  filterBy={['label']}
                  labelKey={option => `${option.label}`}
                  className={input_class_val_css}
                  minLength={3}
                  options={options}
                  placeholder={placeholder}
                  onChange={handleChange}
                  onSearch={handleSearch}
                  isLoading={loading}
                  defaultInputValue={values[name] ? values[name] : ""}
                  ref={_typeaheadLookup}
                />
                :
                <AsyncTypeahead
                  id={id}
                  name={name}
                  filterBy={['label']}
                  labelKey={null}
                  className={input_class_val_css}
                  minLength={3}
                  options={[]}
                  placeholder={placeholder}
                  onChange={handleChange}
                  onSearch={handleSearch}
                  isLoading={false}
                  defaultInputValue={values[name] ? values[name] : ""}
                // ref={_typeaheadBasic}
                />
            }
            {!isQuestionsContext && <ErrorMessage errors={errors[name]} touched={touched[name]} />}
          </Col>
          {label_text && label_location === enums.labelLocation.right && (
            <Col id="typeahead_field_col_4">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
        </Row>

        <Row id="typeahead_field_row_3">
          <Col
            id="typeahead_field_col_5"
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}>
            <NestedFieldAlert psReady={psReady} invalidFieldErrorMessage={invalidFieldErrorMessage} />
          </Col>
        </Row>
      </Field>
    </Container>
  );
};

export default Typeahead;
