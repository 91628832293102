const ErrorMessage = (props) => {
  //store
  return (
    <div id="error_message_main_div_1">
      {props.errors && props.touched && <div id="error_message_main_div_2" className={"error-msg " + props.type}>{props.errors}</div>}
    </div>
  );
};
//

export default ErrorMessage;
