import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import LabelCommon from "../Shared/LabelCommon";
import { FieldWrapper } from '../../../../StylizedComponents/index';
import { Field } from "formik";
import ps from "../../../Functions/PageScript";

const Headers = (props) => {
  const {
    columnOffset,
    field: { name },
    fieldsRef,
    helptext,
    id,
    label,
    label_css,
    label_location,
    label_prefix,
    popover_class,
    popover_placement,
    type,
    windowWidth
  } = props;

  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const label_text = label;
  const label_class_interpreted = label_css;

  const labelProps = {
    helptext, id, isQuestionsContext, label_class_interpreted, label_location,
    label_prefix, label_text, popover_class, popover_placement, type
  }

  return (
    <Container
      id="headers_main_container"
      key={id}
    >
      <Field
        id="headers_field_wrapper_1"
        className={columnOffset}
        component={FieldWrapper}
        name={"headers-" + name}
      >
        {(!label_css || label_css && !label_css.includes("pageTitle")) && (
          <Row id="headers_row_1">
            <Col id="headers_col_1">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}
        {(label_css && label_css.includes("pageTitle")) && (
          <Row id="headers_row_2">
            <Col id="headers_col_2" className={label_css}>
              {label}
            </Col>
          </Row>
        )}
      </Field>
    </Container>
  );
};

export default Headers;
