import { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import historyObj from "../../Routing/browserHistory";
import RequestCallback from "../../SideMenu/SideMenuViews/LoggedIn/RequestCallback";


export default class ErrorPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      collapseItem: "",
      redirectToHome: false,
      customHeaderMsg: this.props.customHeaderMsg, //when we manually pass in a msg
      customSubheaderMsg: this.props.customSubheaderMsg,
      customBodyMsg: this.props.customBodyMsg,
      isErrorBoundaryAll: this.props.isErrorBoundaryAll,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error?.toString(),
      errorInfo: errorInfo,
    });
  }

  render() {

    //for general explicit error, just route to /error and show the Oops! fallback message
    //else if explicit error with custom messsagin, set the state object with custom properties above
    const historyErrorObj = historyObj.location; //browser history allows us to pass state via history obj if we want

    // Example:
    // historyObj.push("/error", {
    //     customHeaderMsg: "This is a main header msg example",
    //     customSubheaderMsg: "This is a sub-header msg example",
    //     customBodyMsg: "This is a body msg example",
    //     hideContactForm: "true"
    //   }
    //);

    return (
      <Card id="error_page_card_1" className={this.state.isErrorBoundaryAll ? "no-rounded-corners" : ""}>
        <Card.Body id="error_page_card_body_1" className={this.state.isErrorBoundaryAll ? "mt-30 mb-30" : ""}>
          <Row id="error_page_row_1">
            <Col id="error_page_col_1" className="d-flex justify-content-center">
              {
                historyErrorObj?.state?.customHeaderMsg ? (
                  <h1 id="error_page_header_1" className={"error"}>{historyErrorObj.state.customHeaderMsg}</h1>
                ) : this.state.customHeaderMsg ? (
                  <h1 id="error_page_header_1" className={"error"}>{this.state.customHeaderMsg}</h1>
                ) : (
                  <h1 id="error_page_header_1" className={"error"}>Oops!</h1>
                )
              }
            </Col>
          </Row>
          <Row id="error_page_row_2" className="d-flex justify-content-center mt-30">
            <Col id="error_page_col_2" xs={10} lg={8}>
              {
                historyErrorObj?.state?.customSubheaderMsg ? (
                  <h3 id="error_page_header_2">{historyErrorObj.state.customSubheaderMsg}</h3>
                ) : this.state.customSubheaderMsg ? (
                  <h3 id="error_page_header_2">{this.state.customSubheaderMsg}</h3>
                ) : (
                  <h3 id="error_page_header_2">This is a little embarrassing. It appears you have found an error.</h3>
                )
              }
            </Col>
          </Row>
          <Row id="error_page_row_3" className="d-flex justify-content-center">
            <Col id="error_page_col_3" xs={10} lg={8}>
              {
                historyErrorObj?.state?.customBodyMsg ? (
                  <p id="error_page_p_1">{historyErrorObj.state.customBodyMsg}</p>
                ) :
                  this.state.customBodyMsg ? (
                    <p id="error_page_p_1">{this.state.customBodyMsg}</p>
                  ) : (
                    <p id="error_page_p_1">
                      We’ve already notified our team, who will work on getting this fixed as soon as humanly possible.{" "}
                      <br />
                      In the meantime, RVNA prides ourselves on giving our customers plenty of options. Thank you for the
                      opportunity to assist you. We appreciate your business.
                    </p>
                  )
              }
            </Col>
          </Row>
          <Row
            id="error_page_row_4"
            className={
              historyErrorObj?.state?.hideContactForm === "true" ?
                "d-none" : "d-flex justify-content-center mt-15"
            }
          >
            <Col id="error_page_col_4" xs={10} lg={8}>
              <h3 id="error_page_header_3">
                <span className={"headerNumber"}>1</span> Call us
              </h3>
              <div id="error_page_div_3">
                <p id="error_page_p_2">
                  Monday - Friday, 5:30 AM - 5:00 PM Pacific Time
                  <br />
                  Toll-free at 1 (800) 364-2433
                </p>
              </div>
            </Col>
          </Row>
          <Row
            id="error_page_row_5"
            className={
              historyErrorObj?.state?.hideContactForm === "true" ?
                "d-none" : "d-flex justify-content-center mt-15"
            }
          >
            <Col id="error_page_col_5" xs={10} lg={8}>
              <h3 id="error_page_header_4">
                <span className={"headerNumber"}>2</span> Email us
              </h3>
              <div id="error_page_div_4">
                <p id="error_page_p_3" className={"error"}>
                  <a href="mailto:support@rvnuccio.com">support@rvnuccio.com</a>
                </p>
              </div>
            </Col>
          </Row>
          <Row
            id="error_page_row_6"
            className={
              historyErrorObj?.state?.hideContactForm === "true" ? "d-none" :
                this.state.isErrorBoundaryAll ? "d-none" :
                  "d-flex justify-content-center mt-15"
            }
          >
            <Col id="error_page_col_6" xs={10} lg={8}>
              <h3 id="error_page_header_5">
                <span className={"headerNumber"}>3</span> Request a Callback
              </h3>
              <div id="error_page_div_5">
                <p id="error_page_p_4">
                  One of our representatives will call you back to answer any questions you may have about our
                  insurance.
                </p>
                <Row id="error_page_row_7">
                  <Col id="error_page_col_7" md={8} lg={8} xl={6}>
                    <div id="error_page_div_6">
                      <RequestCallback />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row
            id="error_page_row_8"
            className={
              historyErrorObj?.state?.hideContactForm === "true" ? "d-none" :
                this.state.isErrorBoundaryAll ? "d-none" :
                  "d-flex justify-content-center mt-15"
            }
          >
            <Col id="error_page_col_8" xs={10} lg={8}>
              <h3 id="error_page_header_6">
                <span className={"headerNumber"}>4</span> Head back home
              </h3>
              <div id="error_page_div_7">
                <p id="error_page_p_5">Sometimes home is the best place to go.</p>
                <p id="error_page_p_6" className={"error"}>
                  <Link to="/">RVNA home</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}