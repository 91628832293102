import { forwardRef, memo, useState } from "react";
import { Row, Col, OverlayTrigger, Button, Popover } from "react-bootstrap";
import enums from "../../../Enums/Enums";

const RadiosLabel = forwardRef((props, ref) => {

  const [open, togglePopover] = useState(false);

  const {
    helptext,
    id,
    label_class_interpreted,
    label_prefix,
    label_text,
    popover_class,
    type
  } = props;

  const popover = (
    <Popover
      id={`popover-positioned-${id}`}
      {...props}
    >
      <Popover.Body id="label_popover_body_1">
        <div id="radios_labels_div_4">
          <span dangerouslySetInnerHTML={{ __html: helptext }}></span>
        </div>
        <div id="radios_label_div_5" className="d-flex justify-content-end">
          <Button
            id="radios_label_btn_1"
            variant="secondary"
            size="sm"
            className={"popover-btn mt-15"}
            onClick={() => togglePopover(!open)}
          >
            Got it!
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Row id="radios_label_row_1" className={"form-check radios d-inline-flex"}>
      <Col
        id="radios_label_col_1"
        className={label_prefix ? "" : "d-none"}
      >
        <div id="radios_label_div_1">
          {label_prefix}
        </div>
      </Col>
      <Col
        id="radios_label_col_2"
        className={"radio-label"}
        style={{ display: label_text && type !== enums.fieldTypes.list ? "block" : "none" }}
      >
        <Row id="radios_label_row_2" className="d-inline-flex">
          <Col id="radios_label_col_3" className={"d-inline-flex " + label_class_interpreted}>
            <div id="radios_label_div_2" htmlFor={id} className={enums.fieldTypes.radio + " " + label_class_interpreted}>
              {label_text}
            </div>
            <OverlayTrigger
              id="radios_label_overlay_trigger_1"
              trigger="click"
              key={id}
              placement={"bottom"}
              show={open && helptext != null}
              overlay={popover}
            >
              <div id="radios_label_div_3" className={type === enums.fieldTypes.header ? "headerPopContainer" : ""}>
                <div id="radios_label_div_4">
                  <div
                    className={helptext ? "fa fa-question-circle " + popover_class : "d-none"}
                    id={"Popover-" + id}
                    onClick={() => togglePopover(!open)}
                  ></div>
                </div>
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      </Col>
    </Row>
  )
});

export default memo(RadiosLabel);