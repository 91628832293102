import { useSelector } from "react-redux";
import { Form, Container, Row, Col } from "react-bootstrap";
import { FieldWrapper } from '../../../../StylizedComponents/index';
import { Field } from "formik";
import LabelCommon from "../Shared/LabelCommon";
import NestedFieldAlert from "../Alerts/NestedFieldAlert";
import ErrorMessage from "../../../../Errors/ErrorMessage/ErrorMessage";
import enums from "../../../Enums/Enums";
import ps from "../../../Functions/PageScript";
import util from "../../../Functions/Functions";
import "../../../../_styles/icons/icons.scss";

const Money = (props) => {
  const {
    clearPropertyValueFromPSField,
    columnOffset,
    contingentPSBundle,
    disabled,
    field: { name, value },
    fieldsRef,
    form: { errors, touched, setFieldTouched, setFieldValue, values },
    helptext,
    id,
    label,
    label_css,
    label_location,
    label_prefix,
    placeholder,
    popover_class,
    popover_placement,
    setFormValueCallback,
    setValueParentOrLocal,
    static_col_breakpoint,
    type,
    validateQuestionSimple,
    windowWidth
  } = props;

  //#region stored values

  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);
  let invalidFieldErrorMessage;

  let hasError = isQuestionsContext
    ? util.checkFieldHasQuestionError(name, storedInvalidFields)
    : util.checkFieldHasYupError(name, errors, touched);

  if (hasError && isQuestionsContext) {
    invalidFieldErrorMessage = util.getInvalidFieldMessage(name, storedInvalidFields)?.fieldMessage;
  }

  //#endregion stored values

  //#region pageScript

  const normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let psPremount, psExecutePostmount, psPostmountExecutedFields;
  let psReady = {};

  if (isQuestionsContext) {
    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, values[name]);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      //we may have already applied updates to psReady during premount, so pass it back in for updating
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, values[name]);
    }

    if ((psReady.value || psReady.value !== undefined) && psReady.value !== values[name]) {
      if (psReady.value === "clearValue") {
        clearPropertyValueFromPSField(psReady.id, "value");
        setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
      }
      else {
        setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
        clearPropertyValueFromPSField(psReady.id, "value");
      }
    }

    if (ps.hasActiveAlert(psReady)) {
      //need this to mark up input and override valid
      hasError = true;
      // hasValid = false;
    }
  }

  //#endregion pageScript

  //#region functions & actions

  const handleBlur = () => {
    const normalValue = !value ? "0" : value;
    setFieldTouched(name, true);

    if (
      psExecutePostmount &&
      normalValue &&
      normalValue !== psExecutePostmount.value
    ) {
      contingentPSBundle(psExecutePostmount, normalValue, name, enums.psExecute.postmount);
    }

    setValueParentOrLocal(name, normalValue, setFieldValue, setFormValueCallback);

    if (isQuestionsContext) {
      validateQuestionSimple(name, normalValue);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setValueParentOrLocal(name, value, setFieldValue, setFormValueCallback);
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0);
  }

  //#endregion functions & actions

  //#region moneyLabel

  let validation_wrapper_css;
  let input_class_val_css;
  let label_class_interpreted;

  if (hasError) {
    validation_wrapper_css = " moneyError ";
    input_class_val_css = "error-border ";
    label_class_interpreted += " error-msg ";
    if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
      validateQuestionSimple(name, value);
    }
  }
  else {
    validation_wrapper_css = "money ";
    input_class_val_css = "input-group-addon-left ";
    label_class_interpreted = "";
  }

  label_class_interpreted += label_css;

  let label_text = label ? label : "";
  if (psReady && psReady.setLabelValue) {
    label_text = psReady.setLabelValue;
  }

  const labelProps = {
    helptext, id, label_class_interpreted, label_prefix, label_text,
    popover_class, popover_placement, type
  }

  //#endregion moneyLabel

  return (
    <Container
      id="money_main_container_1"
      className={label_text ? "" : "no_label"}
      key={id}
      onBlur={() => handleBlur()}
    >
      <Field
        id="money_field_wrapper_1"
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, "")}
        component={FieldWrapper}
        name={"money-" + name}
      >
        {label_text && label_location === enums.labelLocation.top && (
          <Row id="money_row_1">
            <Col id="money_col_1" xs={"12"}>
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row id="money_row_2">

          {label_text && label_location === enums.labelLocation.left && (
            <Col id="money_col_2">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}

          <Col
            id="money_col_3"
            className={label_text ? "has_label" : ""}
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
          >
            <div id="money_div_1" className={"input-group input-group-lg d-flex align-items-center " + validation_wrapper_css}>
              {hasError ? (
                <span id="money_span_1" className={"input-group-prepend"}>
                  <div
                    id="money_div_2"
                    className={
                      psReady && psReady.disableInput
                        ? "input-group-text error-msg disabledGray"
                        : "input-group-text error-msg bg-transparent"
                    }
                  >
                    <div id="money_div_3" className={"icon-us-dollar error-msg"}></div>
                  </div>
                </span>
              ) : (
                <span id="money_span_2" className={"input-group-prepend "}>
                  <div
                    id="money_div_4"
                    className={
                      psReady &&
                        psReady.disableInput ? "input-group-text disabledGray" :
                        "input-group-text"
                    }
                  >
                    <div id="money_div_5" className={"icon-us-dollar"}></div>
                  </div>
                </span>
              )}
              <Form.Control
                className={
                  "form-control input-text-align-vertical " +
                  input_class_val_css +
                  (psReady && psReady.setClassInput ? " " + psReady.setClassInput : " ")
                }
                disabled={
                  psReady && psReady.disableInput ? psReady.disableInput :
                    psReady && psReady.setValueAndDisableInput ? psReady.setValueAndDisableInput
                      : disabled
                }
                id={id}
                name={name}
                onChange={handleChange}
                placeholder={placeholder}
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                value={value?.replace(/[^\d.-]+/g, '')}
                autoComplete={questions && questions.pageUrl === "PremInd" ? "none" : "on"}
              />
            </div>
          </Col>

          {label_text && label_location === enums.labelLocation.right && (
            <Col id="money_col_4">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
        </Row>

        {label_text && label_location === enums.labelLocation.bottom && (
          <Row id="money_row_3">
            <Col id="money_col_5">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        {!isQuestionsContext && (
          <Row id="money_row_4">
            <Col id="money_col_6">
              <ErrorMessage errors={errors[name]} touched={touched[name]} />
            </Col>
          </Row>
        )}

        <Row id="money_row_5">
          <Col
            id="money_col_7"
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}>
            <NestedFieldAlert psReady={psReady} invalidFieldErrorMessage={invalidFieldErrorMessage} />
          </Col>
        </Row>

      </Field>
    </Container>
  );
};

export default Money;
