import { Alert, Container, Col, Row } from "react-bootstrap";

const AlertField = (props) => {
  const { id, label } = props;

  return (
    <Container key={id} id="alert_field_main_container_1">
      {label && label !== "" && (
        <Row id="alert_field_row_1">
          <Col id="alert_field_col_1" className="noWordBreaks" xs={"12"}>
            <Alert id={id} variant={"primary"}>
              {label}
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AlertField;
