import * as sideMenuActions from "./SideMenuActionTypes";
import { toast } from "react-toastify";

export const INITIAL_STATE = {
  callbackSuccess: false,
  faqModalOpen: false
};

export default function sideMenuReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case sideMenuActions.createCallbackRequestSuccess:
      state = update(state, {
        callbackSuccess: true,
      });
      toast.success(action.successMsg);
      break;
    case sideMenuActions.toggleFAQModal:
      state = update(state, {
        faqModalOpen: !state.faqModalOpen
      });
      break;
    default:
      break;
  }
  return state;
}
