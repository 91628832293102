import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { Field } from "formik";
import Address from "./Address/Address";
import AlertField from "./Alerts/AlertField";
import Buttons from "./Buttons";
import Checkbox from "./Checkbox/Checkbox";
import DateField from "./DateField/DateField";
import Default from "./Default/Default";
import Headers from "./Headers/Headers";
import Image from "./Images";
import Label from "./Label";
import Link from "./Link";
import Money from "./Money/Money";
import Percent from "./Percent/Percent";
import Radios from "./Radios/Radios";
import Select from "./Select/Select";
import Textarea from "./Textarea/Textarea";
import Typeahead from "./Typeahead/Typeahead";
import ps from "../../Functions/PageScript";
import enums from "../../Enums/Enums";
import * as questionsActions from "../../../Questions/QuestionsActions";
import * as questionsValidation from "../../../Questions/QuestionHelpers/ValidationHelper";
import "react-datepicker/dist/react-datepicker.css";

const RenderField = (props) => {
  const {
    clearForm,
    color,
    columnOffset,
    customRadioNo,
    customRadioYes,
    defaultValue,
    disabled,
    field: { name },
    fontSize,
    form: { values }, //from Formik
    handleFocusCallback,
    helptext,
    id,
    input_css,
    isAdmin,
    isCoverage,
    label,
    label_css,
    label_location,
    label_prefix,
    lookupClass,
    minDate,
    options,
    parentCardLookupCode,
    parentLookupCode,
    placeholder,
    setLabelClassVal,
    setFormValueCallback,
    source,
    static_col_breakpoint,
    tooltipImg,
    type,
    validationOff,
    windowWidth
  } = props;

  //store
  const questions = useSelector((state) => state.questions);
  const submissionOwnerRole = questions.userEntityType;

  //dispatch actions
  const dispatch = useDispatch();

  //outbound
  const checkDateLessThanAIs = useCallback(
    (date, submissionID, programCode) =>
      dispatch(questionsActions.checkDateLessThanAIs(date, submissionID, programCode)),
    [dispatch]
  );

  const clearPropertyValueFromPSField = useCallback(
    (fieldID, propertyToClear) => dispatch(questionsActions.clearPropertyValueFromPSField(fieldID, propertyToClear)),
    [dispatch]
  );

  const contingentPSBundle = (psPostmount, value, issuingInputLookup, executionTime) => {
    //can't count on formiks timing/handling of its internal state's 'values'
    const formValues = cloneDeep(values);
    //due to re-render, unable to place this in effect on question, must fire Field onBlur
    if (questions.pageScript && ps.checkPSUnique(psPostmount, questions.psExecutedFields, value)) {
      executePageScript(psPostmount, value, formValues, issuingInputLookup, executionTime, submissionOwnerRole);
    }
  };

  const executePageScript = useCallback(
    (field, value, values, issuingInputLookup, executionTime, submissionOwnerRole) =>
      dispatch(
        questionsActions.executePageScript(field, value, values, issuingInputLookup, executionTime, submissionOwnerRole)
      ),
    [dispatch]
  );

  const setDynamicModal = useCallback((modalName) => dispatch(questionsActions.setDynamicModal(modalName)), [dispatch]);

  const setSubpage = useCallback(
    (fieldName, fieldValue) => dispatch(questionsActions.setSubpage(fieldName, fieldValue)),
    [dispatch]
  );

  const validateQuestionSimple = useCallback(
    (fieldName, fieldValue) => dispatch(questionsValidation.validateQuestionSimple(fieldName, fieldValue)),
    [dispatch]
  );

  //execute at proper component level
  const setValueParentOrLocal = (name, value, setFieldValue, setFormValueCallback, isEvalCallback) => {
    if (value === enums.buttonFunctions.setSubpage && isEvalCallback) {
      ///check for subpage trigger and set on reducer to rerender questions
      //the Preprocessor will check to see if the subpage property is null or populated and filter
      //all the other questions by new column, SubPage in Q2

      setSubpage(name);

      value = values[name] === enums.bool.false || !values[name] ? enums.bool.true : enums.bool.false;
    }
    else if (value === enums.linkFunctions.setDynamicModal) {
      //open the modal and jump out, no parent fields need values set, only local function
      setDynamicModal(name);
      return;
    }
    else if (value === true) {
      value = enums.bool.true;
    }

    if (setFormValueCallback) {
      //** Note: this old code handles the case where we want to have a string function that gets evaluated
      // as of now there aren't any, since it's not as clean and Eval is slower **
      //if this is an isEvalCallback, it's a dom accessed target obj passed in as the name value, so name.id
      // setFormValueCallback(name.id, value, isCoverage, parentCardLookupCode);

      setFormValueCallback(name, value, isCoverage, parentCardLookupCode);
    }
    else {
      setFieldValue(name, value, isCoverage, parentCardLookupCode);
    }

    if (isEvalCallback) {
      //isEvalCallback is coming from the button field and doesn't have access to a name/value
      //inside its component since it's acting on behalf of some other function
      //stored inside Question2 DefaultValue
      //so call the contingetPSBundle here where we have access to necessary info
      const psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
      if (psExecutePostmount) {
        contingentPSBundle(psExecutePostmount, value, name, enums.psExecute.postmount);
      }
    }
  };

  let popover_class;
  let popover_placement;
  if (helptext) {
    popover_class = type === "header" ? "headerPop" : "generalPop";
    popover_placement = "bottom";
  }

  const fieldsRef = React.createRef();

  return (
    <>
      {options == null &&
        type !== "address_ac" &&
        type !== "alert" &&
        type !== "button" &&
        type !== "checkbox" &&
        type !== "combo" &&
        type !== "combosearch" &&
        type !== "date" &&
        type !== "header" &&
        type !== "image" &&
        type !== "label" &&
        type !== "link" &&
        type !== "list" &&
        type !== "money" &&
        type !== "percent" &&
        type !== "radio" &&
        type !== "select" &&
        type !== "textarea" &&
        type !== "typeahead" && (
          <Field
            component={Default}
            clearPropertyValueFromPSField={clearPropertyValueFromPSField}
            columnOffset={columnOffset}
            contingentPSBundle={contingentPSBundle}
            disabled={disabled}
            fieldsRef={fieldsRef}
            handleFocusCallback={handleFocusCallback}
            helptext={helptext}
            id={id}
            input_css={input_css}
            label={label}
            label_css={label_css}
            label_location={label_location}
            label_prefix={label_prefix}
            name={name}
            placeholder={placeholder}
            popover_class={popover_class}
            popover_placement={popover_placement}
            setFormValueCallback={setFormValueCallback}
            setValueParentOrLocal={setValueParentOrLocal}
            static_col_breakpoint={static_col_breakpoint}
            type={type}
            validateQuestionSimple={validateQuestionSimple}
            windowWidth={windowWidth}
          />
        )}

      {type === "alert" && (
        <Field component={AlertField} id={id} label={label} static_col_breakpoint={static_col_breakpoint} type={type} />
      )}

      {type === "button" && (
        <Field
          component={Buttons}
          callbackFunction={defaultValue}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          contingentPSBundle={contingentPSBundle}
          disabled={disabled}
          helptext={helptext}
          id={id}
          label={label}
          input_css={input_css}
          name={name}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          tooltipImg={tooltipImg}
          type={type}
          windowWidth={windowWidth}
        />
      )}

      {type === "checkbox" && (
        <Field
          component={Checkbox}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          columnOffset={columnOffset}
          contingentPSBundle={contingentPSBundle}
          defaultValue={defaultValue}
          disabled={disabled}
          fieldsRef={fieldsRef}
          helptext={helptext}
          id={id}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          name={name}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          tooltipImg={tooltipImg}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
          windowWidth={windowWidth}
        />
      )}

      {(type === "combo" || type === "combosearch") && (
        <Field
          component={Select}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          contingentPSBundle={contingentPSBundle}
          columnOffset={columnOffset}
          defaultValue={defaultValue}
          disabled={disabled}
          fieldsRef={fieldsRef}
          helptext={helptext}
          id={id}
          isSearchable={type === "combosearch"}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          name={name}
          options={options}
          placeholder={placeholder}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
        />
      )}

      {type === "date" && (
        <Field
          component={DateField}
          checkDateLessThanAIs={checkDateLessThanAIs}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          contingentPSBundle={contingentPSBundle}
          columnOffset={columnOffset}
          defaultValue={defaultValue}
          disabled={disabled}
          fieldsRef={fieldsRef}
          helptext={helptext}
          id={id}
          isAdmin={isAdmin}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          minDate={minDate}
          name={name}
          placeholder={placeholder}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
          windowWidth={windowWidth}
        />
      )}

      {type === "header" && (
        <Field
          component={Headers}
          columnOffset={columnOffset}
          fieldsRef={fieldsRef}
          helptext={helptext}
          id={id}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          name={name}
          popover_class={popover_class}
          popover_placement={popover_placement}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          windowWidth={windowWidth}
        />
      )}

      {type === "image" && (
        <Field
          component={Image}
          color={color}
          fontSize={fontSize}
          helptext={helptext}
          id={id}
          input_css={input_css}
          name={name}
          source={source}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
        />
      )}

      {type === "label" && (
        <Field
          component={Label}
          columnOffset={columnOffset}
          fieldsRef={fieldsRef}
          helptext={helptext}
          id={id}
          input_css={input_css}
          label={label}
          label_css={label_css}
          label_prefix={label_prefix}
          name={name}
          popover_class={popover_class}
          popover_placement={popover_placement}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          windowWidth={windowWidth}
        />
      )}

      {type === "link" && (
        <Field
          component={Link}
          callbackFunction={defaultValue}
          helptext={helptext}
          id={id}
          label={label}
          name={name}
          questions={questions}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
        />
      )}

      {type === "money" && (
        <Field
          component={Money}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          columnOffset={columnOffset}
          contingentPSBundle={contingentPSBundle}
          disabled={disabled}
          fieldsRef={fieldsRef}
          helptext={helptext}
          id={id}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          name={name}
          placeholder={placeholder}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
          windowWidth={windowWidth}
        />
      )}

      {type === "percent" && (
        <Field
          component={Percent}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          columnOffset={columnOffset}
          contingentPSBundle={contingentPSBundle}
          disabled={disabled}
          fieldsRef={fieldsRef}
          id={id}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          name={name}
          placeholder={placeholder}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
          windowWidth={windowWidth}
        />
      )}

      {(type === "radio" || type === "list") && (
        <Field
          component={Radios}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          columnOffset={columnOffset}
          clearForm={clearForm}
          contingentPSBundle={contingentPSBundle}
          customRadioNo={customRadioNo}
          customRadioYes={customRadioYes}
          defaultValue={defaultValue}
          fieldsRef={fieldsRef}
          helptext={helptext}
          id={id}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          name={name}
          placeholder={placeholder}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setLabelClassVal={setLabelClassVal}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
          validationOff={validationOff}
          windowWidth={windowWidth}
        />
      )}

      {type === "textarea" && (
        <Field
          component={Textarea}
          columnOffset={columnOffset}
          disabled={disabled}
          fieldsRef={fieldsRef}
          id={id}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          name={name}
          placeholder={placeholder}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
          windowWidth={windowWidth}
        />
      )}

      {type === "typeahead" && (
        <Field
          component={Typeahead}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          columnOffset={columnOffset}
          disabled={disabled}
          fieldsRef={fieldsRef}
          id={id}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          lookupClass={lookupClass}
          name={name}
          parentLookupCode={parentLookupCode}
          placeholder={placeholder}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
          windowWidth={windowWidth}
        />
      )}

      {type === "address_ac" && (
        <Field
          component={Address}
          clearPropertyValueFromPSField={clearPropertyValueFromPSField}
          columnOffset={columnOffset}
          disabled={disabled}
          fieldsRef={fieldsRef}
          id={id}
          label={label}
          label_css={label_css}
          label_location={label_location}
          label_prefix={label_prefix}
          name={name}
          options={options}
          placeholder={placeholder}
          popover_class={popover_class}
          popover_placement={popover_placement}
          setFormValueCallback={setFormValueCallback}
          setValueParentOrLocal={setValueParentOrLocal}
          static_col_breakpoint={static_col_breakpoint}
          type={type}
          validateQuestionSimple={validateQuestionSimple}
          windowWidth={windowWidth}
        />
      )}
    </>
  );
};

export default RenderField;
