import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import IndependentModal from "../../../../Utilities/Modals/IndependentModal";
import { default as FormInputs } from "../../../../Utilities/Inputs/FormGroupsWrapper";
import util from "../../../../Utilities/Functions/Functions";
import enums from "../../../../Utilities/Enums/Enums";
import CustomQuestion from "./CustomQuestion";
import * as helper from "../../../QuestionHelpers/QuestionsHelper";
import * as appActions from "../../../../App/AppActions";

const Question = (props) => {

  const {
    defaultValue,
    expandingCard,
    formError,
    formValues,
    isInlineRow,
    onModal,
    ordinalPos,
    parentCardSetType,
    questionData,
    setQuestionsFormValue,
    value,
    windowWidth
  } = props;

  //#region stored values

  const app = useSelector((state) => state.app);
  const questions = useSelector((state) => state.questions);
  const submissionID = questions.submissionID;
  const isVisible = helper.checkQuestionIsVisible(questionData, formValues);
  const clearQuestionValue = helper.clearQuestionValue(isVisible, questionData, formValues);
  let disabled = false;

  //#endregion stored values

  //#region functions & actions

  //dispatch actions
  const dispatch = useDispatch();

  //local
  const isQuickRate = () => {
    return questions.pageUrl.includes("QuickRate");
  };

  //outbound
  const logValidation = useCallback(
    (submissionID, validationType, details) =>
      dispatch(appActions.logValidation(submissionID, validationType, details)),
    [dispatch]
  );

  //#endregion functions & actions

  //#region validation reporting

  const notEligMsg = "Unfortunately, you are not eligible for coverage at this time.";
  const requiresReviewMsg =
    "This selection requires underwriter review for policy issuance." +
    " For additional information or assistance, please call our office at 1-800-364-2433 to speak with a representative.";
  const notEligibleIf = questionData.validEligible;
  const requiresReviewIf = questionData.validPremiumIndication;


  //for validation log
  let details = {
    LookupCode: questionData.lookupCode,
    Question: questionData.questionText ? questionData.questionText : questionData.pdfApplicationText,
    PlaceholderText: questionData.placeholderText,
    Answer: value,
    ErrorMessage: questionData.validEligibleText || notEligMsg,
  };
  if (requiresReviewIf && value && requiresReviewIf === value) {
    details.ErrorMessage = questionData.validPremiumText || requiresReviewMsg;
  }

  //#endregion validation reporting

  //#region effects

  useEffect(() => {
    if (clearQuestionValue) {
      setTimeout(function () {
        let restoreDefaultValueOrClear = questionData.defaultValue ?? "";
        if (questionData.questionType === enums.fieldTypes.checkbox) {
          restoreDefaultValueOrClear = enums.bool.false;
        }
        setQuestionsFormValue(questionData.lookupCode, restoreDefaultValueOrClear);
      });
    }
  }, [clearQuestionValue, questionData.lookupCode, questionData.questionType, setQuestionsFormValue, questionData.defaultValue]);

  //#endregion effects

  //#region render question

  const renderQuestion = (questionType) => {

    let customRadioNo;
    let customRadioYes;

    if (!isVisible) {
      return;
    }

    if (
      questionType === "hidden" &&
      questionData.cssInput === "triggerCustomComponent" &&
      questionData.defaultValue
    ) {
      //there are times, esp in PremInd, where we need a more robust component than what questions allows
      //with specific page placement, so we use the hidden db field as the placeholder to trigger it
      //at the desired displayOrder
      const normalComponentName = props?.questionData?.defaultValue?.replace(/\s/g, "");
      return CustomQuestion(normalComponentName, app, questions, formValues, questionData);
    }
    else {
      const type = questionData.questionType;
      let items = null;
      let label_location = isQuickRate() && questionData.qrTextLocation ? questionData.qrTextLocation : questionData.questionTextLocation ? questionData.questionTextLocation : "top"; //Move this into db for checkboxes
      let isUl = false;
      if (type === enums.fieldTypes.checkbox) {
        label_location = isQuickRate() && questionData.qrTextLocation ? questionData.qrTextLocation : questionData.questionTextLocation ? questionData.questionTextLocation : "right";
      }

      if ((type === enums.fieldTypes.combo || type === enums.fieldTypes.comboSearch)) {
        items = [];
        if (questionData.comboItems !== null && questionData.comboItems.length > 0) {
          for (let i = 0; i < questionData.comboItems.length; i++) {
            let comboItem = {
              text: "",
              value: "",
            };
            comboItem.text = questionData.comboItems[i].questionText;
            comboItem.value = questionData.comboItems[i].lookupCode;
            items.push(comboItem);
          }
        }
        else if (questionData.defaultValue != null && questionData.defaultValue.includes("dateNow")) {
          //e.g. dateNow+8 for 8 years out from this year
          let currentYear = new Date().getFullYear();
          let yearsOut = questionData.defaultValue.split("+")[1];

          for (let i = 0; i < yearsOut; i++) {
            let comboItem = {
              text: "",
              value: "",
            };
            let yearPlus = currentYear + i;
            comboItem.text = yearPlus.toString();
            comboItem.value = yearPlus.toString();
            items.push(comboItem);
          }
        }
      }

      if (type === enums.fieldTypes.list && questionData.listItems != null) {
        items = [];
        for (let i = 0; i < questionData.listItems.length; i++) {
          items.push(questionData.listItems[i].questionText);
        }
        isUl = questionData.cssGroup?.indexOf("ul") > -1 ? true : false;
      }

      if (type === enums.fieldTypes.radio || type === enums.fieldTypes.list) {
        if (questionData.placeholderText) {
          //had to place this block outside radio, else timing error
          let radioValuesArray = questionData.placeholderText.split(";");
          if (radioValuesArray.length > 0) {
            customRadioNo = util.checkCustomRadioValue(radioValuesArray, "No");
            customRadioYes = util.checkCustomRadioValue(radioValuesArray, "Yes");
          }
        }
      }

      return (
        <FormInputs
          type={type}
          columnOffset={util.convertToOffsetClass(questionData.columnOffset)}
          customRadioNo={customRadioNo}
          customRadioYes={customRadioYes}
          defaultValue={defaultValue}
          disabled={disabled}
          displayOrder={questionData.displayOrder}
          expandingCard={expandingCard}
          formError={formError}
          formValues={formValues}
          group_css={questionData.cssGroup}
          helptext={questionData.helpText ? questionData.helpText : questionData.toolTipText} //TODO: Delete toolTipText col from db? There's no entries with both...
          //set id to lookupCode instead of questionID since "target" attribute + CSS3 query selector can't take numeric ids
          input_css={questionData.cssInput}
          id={questionData.lookupCode}
          isCoverage={questionData.isCoverage}
          isInlineRow={isInlineRow}
          isUl={isUl}
          label={questionData.questionText}
          label_css={questionData.cssLabel}
          label_location={label_location}
          label_prefix={questionData.questionTextPrefix}
          lookupClass={questionData.lookupClass}
          name={questionData.lookupCode}
          notEligibleIf={questionData.validEligible}
          notEligibleText={questionData.validEligibleText}
          onModal={onModal}
          options={items}
          ordinalPos={ordinalPos}
          parentCardLookupCode={questionData.cardLookupCode}
          parentCardSetType={parentCardSetType}
          parentLookupCode={questionData.parentLookupCode}
          placeholder={questionData.placeholderText}
          premiumIndication={questionData.validPremiumIndication}
          qrDisplayOrder={questionData.qrDisplayOrder}
          required={questionData.validRequired}
          requiredText={questionData.validRequiredText}
          requiresReviewIf={questionData.validPremiumIndication}
          requiresReviewText={questionData.validPremiumText}
          setFormValueCallback={setQuestionsFormValue}
          source={questionData.questionText}
          subpage_css={questionData.subpage}
          value={value}
          windowWidth={windowWidth}
        />
      );
    }
  };

  //#endregion render question

  return (
    <>
      {notEligibleIf && value && notEligibleIf === value && !questions.isInvalidForm && (
        <IndependentModal
          isOpen={true}
          modalHeader={"Not Eligible"}
          content={details.ErrorMessage}
          cancelBtn={false}
          acceptCallback={() => logValidation(submissionID, "NOT_ELIGIBLE", details)}
        />
      )}
      {requiresReviewIf && value && requiresReviewIf === value && (
        <IndependentModal
          isOpen={true}
          modalHeader={"Requires Review"}
          content={details.ErrorMessage}
          cancelBtn={false}
          acceptCallback={() => logValidation(submissionID, "NO_PREMIUM", details)}
        />
      )}
      {renderQuestion(questionData.questionType.toLowerCase())}
    </>
  );
};

export default Question;
