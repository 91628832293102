import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from "react-toastify";
import "../_styles/mixins/mixins.scss";
import { keyframes } from 'styled-components'

const Toastify__trackProgress = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`

// export const ToastWrapped = styled(ToastContainer).attrs({
// })`
//   .Toastify__toast-container {}
//   .Toastify__toast {}
//   .Toastify__toast--error {
//     color: #000000;
//     background-color: #ffffff;
//     border: 3px solid #00cf92;
//     height: 80px;
//   }
//   .Toastify__toast--warning {
//     background-color: none;
//     min-height: ${(props) => console.log(props) }
//   }
//   .Toastify__toast--success {
//     background-color: none;
//     min-height: 80px;
//     padding: 15px;
//   }
//   .Toastify__toast-body {}
//   .Toastify__progress-bar {}
// `;

// export const ToastWrapped = styled.div`
// .Toastify__toast-container {}
// .Toastify__toast {}
// .Toastify__toast--error {
//   color: #000000;
//   background-color: #ffffff;
//   border: 3px solid #00cf92;
//   height: 80px;
// }
// .Toastify__toast--warning {
//   background-color: none;
//   min-height: ${(props) => console.log(props) }
// }
// .Toastify__toast--success {
//   background-color: none;
//   min-height: 80px;
//   padding: 15px;
// }
// .Toastify__toast-body {}
// .Toastify__progress-bar {}
// `

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
.Toastify__toast-container {}
.Toastify__toast {}
.Toastify__toast--error {
  color: #000000;
  background-color: #ffffff;
  border: 3px solid #00cf92;
  height: 80px;
}
.Toastify__toast--warning {
  background-color: none;
  min-height: ${(props) => console.log(props)}
}
.Toastify__toast--success {
  background-color: none;
  min-height: 80px;
  padding: 15px;
}
.Toastify__toast-body {}
.Toastify__progress-bar {
  animation: ${Toastify__trackProgress} linear 1;
}
`;