import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../Errors/ErrorBoundary/ErrorBoundary";
import { Col, Row } from "react-bootstrap";
import { Form, withFormik } from "formik";
import { default as FormInputs } from "../../../../Utilities/Inputs/FormGroupsWrapper";
import enums from "../../../../Utilities/Enums/Enums";
import * as appActions from "../../../../App/AppActions";
import * as sideMenuActions from "../../../../SideMenu/SideMenuActions";

const BasicForm = (props) => {
  //form props
  const { values } = props;

  //dispatch actions
  const dispatch = useDispatch();

  //store
  const app = useSelector((state) => state.app);
  const sideMenu = useSelector((state) => state.sideMenu);

  //outbound
  const createSubmission = useCallback((programCode) => dispatch(appActions.createSubmission(programCode)), [dispatch]);
  const closeFAQModal = useCallback(() => dispatch(sideMenuActions.toggleFAQModal()), [dispatch]);

  const handleCreateSubmission = (field, programCode) => {
    if (programCode) {
      if (sideMenu.faqModalOpen) {
        closeFAQModal();
      }
      createSubmission(programCode);
    }
  };

  return (
    <Form id="product_type_form_1">
      <Row id="product_type_row_1">
        <Col id="product_type_col_1">
          <ErrorBoundary>
            <FormInputs
              type={enums.fieldTypes.combo}
              id="insuranceType"
              name="insuranceType"
              options={app.products}
              placeholder="Select RVNA Product"
              value={values["insuranceType"]}
              setFormValueCallback={handleCreateSubmission}
            />
          </ErrorBoundary>
        </Col>
      </Row>
    </Form>
  );
};

const ProductTypeFormHOC = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    //initial values
    insuranceType: "",
  }),
});

const ProductTypeForm = ProductTypeFormHOC(BasicForm);

export default ProductTypeForm;
