import { useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";

const AdditionalInsured = (props) => {
  const [open, toggleCollapse] = useState(false);

  if (props.openComponent && props.openComponent !== "additionalInsured" && open) {
    toggleCollapse(false);
  }

  let toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("additionalInsured");
  }

  return (
    <div id="additional_insured_main_div_1">
      <div
        id="additional_insured_div_2"
        aria-controls="addlDiv"
        aria-expanded={open}
        onClick={() => toggleCollapseBundle()}
      >
        How do I add an Additional Insured?
      </div>
      <Collapse in={open}>
        <div id="additional_insured_div_3">
          <Row id="additional_insured_row_1">
            <Col id="additional_insured_col_1">
              <hr />
              <div id="additional_insured_div_4">
                If you have purchased Liability Insurance, you can add and print unlimited Insurance Certificates using our online policy management system.
                To add and print an insurance certificate please log into your online portal and Click the View Quotes {"&"} Policies button and locate the policy
                you are needing to add an Additional Insured to. In the Manage column, click the Manage Policy button and then select “Add Additional Insured”.
                Follow the steps to add an Additional Insured and print an Insurance Certificate. There is no charge to add an Additional Insured to an existing policy.
                <br /><br />
                In the case that you have a large number of Additional Insureds on your policy, you are able to hide the Additional Insureds that have passed or that you are no longer needing to view.
                To do this, please select “Hide” on the Additional Insured card. If you would like to view those hidden Additional Insureds again, you can select Show Hidden Cards and you will be
                able to view and Unhide those cards. Please be aware that hiding an Additional Insured does not delete it, and once you add an Additional Insured, it cannot be deleted.
                <br /><br />
                If you need assistance with adding an Additional Insured, please email the information to our support department at <a href="mailto:support@rvnuccio.com">support@rvnuccio.com</a>.
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default AdditionalInsured;
