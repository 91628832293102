import { useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import ReactJson from 'react-json-view'


const ReportLogItem = (props) => {

  const { report } = props;

  //state
  const [openModal, toggleOpenModal] = useState(false);

  return (
    <>

      {/* Renders Additional Details in Modal */}
      {openModal && (
        <Modal show={true} className="ps_report_modal">
          <Modal.Header onClick={() => toggleOpenModal(!openModal)} closeButton></Modal.Header>
          <Modal.Body>
            <ReactJson
              src={report}
              name={"PageScript Report"}
              displayDataTypes={false}
              enableClipboard={false}
              collapsed={true}
              theme={"brewer"}
            />
          </Modal.Body>
        </Modal>
      )}

      {/* Renders in Side Menu */}
      <div onClick={() => toggleOpenModal(!openModal)}>
        <Alert variant={'light'} className="ps_report_item" >
          <div style={{ fontSize: "12px" }}>
            <b>PageScriptID:</b> {report.stagedField.id ? report.stagedField.id : "n/a"}
          </div>
          <div style={{ fontSize: "12px" }}>
            <b>Applies to:</b> {report.stagedField.appliesToLookupCodes ? report.stagedField.appliesToLookupCodes : "n/a"}
          </div>
          <div style={{ fontSize: "12px" }}>
            <b>Returned Value:</b> {report.result.returnedValue ? report.result.returnedValue : "n/a"}
          </div>
          <div style={{ fontSize: "12px" }} className={report?.result?.ruleResult ? "rule_success" : ""}>
            <b>Rule Result:</b> {(report.result.ruleResult || typeof report.result.ruleResult === "boolean") ? report.result.ruleResult.toString() : "n/a"}
          </div>
        </Alert>
        <hr></hr>
      </div>

    </>
  );
};

export default ReportLogItem;
