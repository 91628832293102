import { useContext } from "react";
import { SearchContext } from "../Store/SearchStore";
import Button from "react-bootstrap/Button";

const SearchButtons = (props) => {

  //context
  const {
    clearSearchStore: clearSearchStore,
    searchForm: [searchFormIsOpen, setSearchFormOpen],
  } = useContext(SearchContext);

  return (
    <>
      {props.showSearchBtn && (
        <Button
          id="search_btn_1"
          variant={"outline-primary"}
          style={{ cursor: "pointer" }}
          onClick={() => setSearchFormOpen(!searchFormIsOpen)}
        >
          Search Policies
        </Button>
      )}
      {props.showClearBtn && (
        <Button
          id="search_btn_2"
          variant="outline-primary"
          onClick={() => clearSearchStore()}
        >
          Clear Search Results
        </Button>
      )}
    </>
  );
};

export default SearchButtons;
