import { useState } from "react";
import { Collapse, Col, Row } from "react-bootstrap";
import ProductTypeForm from "./ProductTypeForm";

const Products = (props) => {
  const [open, toggleCollapse] = useState(true);

  if (props.openComponent && props.openComponent !== "products" && open) {
    toggleCollapse(false);
  }

  const toggleCollapseBundle = () => {
    toggleCollapse(!open);
    props.openComponentCallback("products");
  }

  return (
    <div id="products_main_div_1">
      <div
        id="products_div_2"
        onClick={() => toggleCollapseBundle()}
        aria-controls="productsRow"
        aria-expanded={open}
        className={"sectionHeader"}
      >
        Get a New Quote
      </div>
      <Collapse in={open} className="collapseComponent">
        <div id="products_div_3">
          <Row id="products_row_1">
            <Col id="products_col_1">
              <ProductTypeForm />
            </Col>
            <Col id="products_col_2" xs={"12"}>
              All RVNA Insurance Products are available to Quote {"&"} Buy Online 24/7
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default Products;
