import { Component } from "react";
import { PropTypes } from "prop-types";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";


export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      collapseItem: "",
      redirectToHome: false,
      customErrorMessage: props.customErrorMessage ? props.customErrorMessage : ""
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error?.toString(),
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container id="error_boundary_main_container_1" className="errorBoundary">
          <Row id="error_boundary_row_1">
            <Col id="error_boundary_col_1">
              <Alert id="error_boundary_alert_1" variant="danger">
                <div id="error_boundary_div_1"><i className="fa fa-exclamation-triangle"></i> Oops, something went wrong...</div>
                <div id="error_boundary_div_2" className="font-italic font-weight-light errorText">{this.state.customErrorMessage ? this.state.customErrorMessage : this.state.error}</div>
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }
    //No error = render children within wrapper
    return this.props.children;
  }
}
