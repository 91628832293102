import { useSelector } from "react-redux";
import { FieldWrapper } from '../../../../StylizedComponents/index';
import { Field } from "formik";
import { Form, Container, Row, Col } from "react-bootstrap";
import NestedFieldAlert from "../Alerts/NestedFieldAlert";
import RadiosLabel from "./RadiosLabel";
import enums from "../../../Enums/Enums";
import ps from "../../../Functions/PageScript";
import util from "../../../Functions/Functions";

const RadioButtonGroup = (props) => {
  const {
    clearForm,
    clearPropertyValueFromPSField,
    columnOffset,
    contingentPSBundle,
    customRadioNo,
    customRadioYes,
    defaultValue,
    field: { name },
    fieldsRef,
    form: { resetForm, setFieldValue, setFieldTouched, values },
    helptext,
    id,
    label,
    label_css,
    label_location,
    label_prefix,
    setLabelClassVal,
    setFormValueCallback,
    setValueParentOrLocal,
    type,
    validateQuestionSimple,
    validationOff,
    windowWidth
  } = props;

  //#region stored values
  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);
  let invalidFieldErrorMessage;
  let notEligibleErrorType = false;

  let hasError = isQuestionsContext ? util.checkFieldHasQuestionError(name, storedInvalidFields) : null;
  const value = defaultValue && !values[id] ? defaultValue : values[id];

  if (hasError && isQuestionsContext) {
    let invalidField = util.getInvalidFieldMessage(name, storedInvalidFields)
    invalidFieldErrorMessage = invalidField?.fieldMessage;
    if (invalidField && invalidField.validEligible) {
      notEligibleErrorType = true;
    }
  }

  //#endregion stored values

  //#region pageScript

  const normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let psPremount, psExecutePostmount, psPostmountExecutedFields;
  let psReady = {};

  if (isQuestionsContext) {
    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, values[name]);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      //we may have already applied updates to psReady during premount, so pass it back in for updating
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, values[name]);
    }

    if ((psReady.value || psReady.value !== undefined) && psReady.value !== values[name]) {
      if (psReady.value === "clearValue") {
        clearPropertyValueFromPSField(psReady.id, "value");
        setValueParentOrLocal(name, "FALSE", setFieldValue, setFormValueCallback);
      }
      else {
        setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
      }
    }

    if (ps.hasActiveAlert(psReady)) {
      //need this to mark up input and override valid
      hasError = true;
      // hasValid = false;
    }
  }

  //#endregion pageScript

  //#region functions & actions

  const clearFormOnClick = (clearForm, resetForm) => {
    resetForm();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const normalValue = !value ? "" : value;
    setFieldTouched(name, true);
    setValueParentOrLocal(name, normalValue, setFieldValue, setFormValueCallback);

    if (psExecutePostmount) {
      contingentPSBundle(psExecutePostmount, normalValue, name, enums.psExecute.postmount);
    }
    if (isQuestionsContext) {
      validateQuestionSimple(name, normalValue);
    }
  };

  //#endregion functions & actions

  //#region radioLabel

  let label_class_interpreted;
  if (!validationOff) {
    if (hasError && !notEligibleErrorType) {
      label_class_interpreted += " error-msg ";
      if (setLabelClassVal) {
        setLabelClassVal("error-msg ");
      }
      if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
        validateQuestionSimple(name, value);
      }
    }
    else {
      label_class_interpreted = "";
      if (setLabelClassVal) {
        setLabelClassVal("");
      }
    }
  }

  label_class_interpreted += label_css;

  //switch off inline label if we are in mobile view
  let desktop = true;
  if (windowWidth && windowWidth < enums.mobile.maxWidth) {
    desktop = false;
  }

  let label_text = label ? label : "";
  if (psReady && psReady.setLabelValue) {
    label_text = psReady.setLabelValue;
  }

  const labelProps = {
    id, label_class_interpreted, label_prefix,
    label_text, type, helptext
  }

  //#endregion radioLabel

  return (
    <Container
      key={id}
      className={type === "list" ? "form-check radios listButtons" : "form-check radios"}
      id={id + "Radio"}
    >
      <Field
        id="radios_field_wrapper_1"
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, "")}
        component={FieldWrapper}
        name={"radios-" + name}
      >
        {label_text && (label_location === enums.labelLocation.top && type !== "list" || !label_location) && (
          <Row id="radios_row_1">
            <Col id="radios_col_1" xs={"12"}>
              <RadiosLabel ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row id="radios_row_2" className={label_prefix ? "has_label_prefix" : ""}>
          {label_text && (label_location === enums.labelLocation.left && desktop) && (
            <Col id="radios_col_2">
              <RadiosLabel ref={fieldsRef} {...labelProps} />
            </Col>
          )}
          <Col id="radios_col_3" className={label_text ? "" : ""}>
            <div id="radios_div_1">
              <Form.Check
                checked={value === enums.bool.true}
                className={customRadioYes ? "fullRadioWidth" : ""}
                id={name + "Yes"}
                inline
                label={customRadioYes ? customRadioYes : "Yes"}
                name={name}
                onChange={(e) => handleChange(e)}
                onClick={
                  clearForm
                    ? () => {
                      clearFormOnClick(clearForm, resetForm);
                    }
                    : null
                }
                type={enums.fieldTypes.radio}
                value={enums.bool.true}
              />
              <Form.Check
                checked={value === enums.bool.false}
                className={customRadioNo ? "fullRadioWidth" : ""}
                id={name + "No"}
                inline
                label={customRadioNo ? customRadioNo : "No"}
                name={name}
                onChange={(e) => handleChange(e)}
                onClick={
                  clearForm
                    ? () => {
                      clearFormOnClick(clearForm, resetForm);
                    }
                    : null
                }
                type={enums.fieldTypes.radio}
                value={enums.bool.false}
              />
            </div>
          </Col>
        </Row>
        <Row id="radios_row_3">
          <div id="radios_div_2">
            <NestedFieldAlert psReady={psReady} invalidFieldErrorMessage={invalidFieldErrorMessage} />
          </div>
        </Row>
      </Field>
    </Container>
  );
};

export default RadioButtonGroup;
