import { clone, cloneDeep, find, findIndex, forEach, merge, remove, filter } from "lodash";
import util from "../../Utilities/Functions/Functions";
import enums from "../../Utilities/Enums/Enums";
import * as valueHelpers from "./ValuesHelper";
import * as validationHelpers from "./ValidationHelper";

function buildCardObjectResponse(ordinalPosition, ordinalSetID, questionsAndData, isHidden) {
  const card = {
    "@ordinalPosition": ordinalPosition,
    "@ordinalSetID": ordinalSetID ? ordinalSetID.toUpperCase() : 0, //retro data has no cardSet concept, so default to 0
    "@title": ordinalPosition,
    "@isHidden": isHidden || false,
    response: questionsAndData,
  };
  return card;
}

function buildExpandingCardResponse(cards, questionIDLookups, formValues, rootValsArray) {
  for (let i = 0; i < cards.length; i++) {
    for (let j = 0; j < cards[i].questionsAndData.length; j++) {
      const question = cards[i].questionsAndData[j];
      const key = question.lookupCode;
      let datavalue = setDataValue(formValues[key]);
      if (question.questionType === enums.fieldTypes.combo && datavalue && datavalue.includes("_")) {
        const regExp = /_/g;
        datavalue = datavalue.replace(regExp, ".");
      }

      if (questionIDLookups[key]) {
        const item = {
          "@QID": questionIDLookups[key],
          "@datavalue": datavalue,
          "@isInvalid": question.isInvalid,
          "@isInvalidText": question.isInvalidText,
          "@isEndorsed": question.isEndorsed,
        };

        rootValsArray.push(item);
      }
    }
  }

  return rootValsArray;
}

function buildRepeatingCardResponse(card, ordinalSetID, questions, formValues) {
  let values = [];

  for (let i = 0; i < card.questionsAndData.length; i++) {
    const question = clone(card.questionsAndData[i]);
    const nativeLookupCode = question.lookupCode.split("-SetID")[0];
    const ordinalPosition = question.lookupCode.split("-OP")[1];
    const key = nativeLookupCode + "-SetID-" + ordinalSetID.toUpperCase() + "-OP" + ordinalPosition;
    let datavalue = setDataValue(formValues[key]);
    if (question.questionType === enums.fieldTypes.combo && datavalue && datavalue.includes("_")) {
      const regExp = /_/g;
      datavalue = datavalue.replace(regExp, ".");
    }

    if (question.lookupCode && question.lookupCode.includes("ViewOnlySubmissionID") && !datavalue) {
      datavalue = questions.submissionID;
    }

    if (question.lookupCode && question.lookupCode.includes("WasPreviouslyValid")) {
      if (!datavalue) {
        datavalue = "FALSE";
      }
    }

    if (questions.questionIDLookups[key]) {
      const item = {
        "@QID": questions.questionIDLookups[key],
        "@datavalue": datavalue,
        "@isInvalid": question.isInvalid,
        "@isInvalidText": question.isInvalidText,
        "@isEndorsed": question.isEndorsed,
      };

      values.push(item);
    }
  }

  return new buildCardObjectResponse(card.ordinalPosition, ordinalSetID, values, card.isHidden);
}

function buildIsolatedQuestionsResponse(isolatedQuestions, questionIDLookups, formValues, rootValsArray) {
  for (let i = 0; i < isolatedQuestions.length; i++) {
    const question = isolatedQuestions[i];
    const key = question.lookupCode;
    let datavalue = setDataValue(formValues[key]);

    if (question.questionType === enums.fieldTypes.combo && datavalue && datavalue.includes("_")) {
      let regExp = /_/g;
      datavalue = datavalue.replace(regExp, ".");
    }

    if (questionIDLookups[key]) {
      const item = {
        "@QID": questionIDLookups[key],
        "@datavalue": datavalue,
        "@isInvalid": question.isInvalid,
        "@isInvalidText": question.isInvalidText,
        "@isEndorsed": question.isEndorsed,
      };

      rootValsArray.push(item);
    }
  }

  return rootValsArray;
}

export function buildQuestionVM(questions, formValues, navigateToHome) {
  let rootValsArray = [];
  let responses = {};
  responses.response = [];
  responses.parent = [];
  let JSONresponses = {};
  JSONresponses.responses = responses;

  const isolatedQuestions = getIsolatedQuestions(questions.questions);
  const inlineBlockQuestions = getInlineBlockQuestions(questions.questions);
  const expandingCards = getExpandingCards(questions.questions);
  const repeatingCardSets = getRepeatingCardSets(questions.questions);
  const questionIDLookups = questions.questionIDLookups;
  const existingResponseData = questions?.allData?.responseJSON ? JSON.parse(questions.allData.responseJSON) : null;
  const existingResponses = existingResponseData?.responses?.response;

  if (isolatedQuestions && isolatedQuestions.length > 0) {
    buildIsolatedQuestionsResponse(isolatedQuestions, questionIDLookups, formValues, rootValsArray);
  }
  if (inlineBlockQuestions && inlineBlockQuestions.length > 0) {
    buildIsolatedQuestionsResponse(inlineBlockQuestions, questionIDLookups, formValues, rootValsArray);
  }
  if (expandingCards && expandingCards.length > 0) {
    buildExpandingCardResponse(expandingCards, questionIDLookups, formValues, rootValsArray);
  }

  //find missing QIDs that weren't delivered due to RestrictedRoles and restore them to the res obj
  let missingResponses = [];

  if (existingResponses && existingResponses instanceof Array) {
    missingResponses = existingResponses.filter((existing) => {
      return (!rootValsArray.find((item) => item["@QID"] === existing["@QID"]));
    });
  }

  const completeRootResponses = rootValsArray.concat(missingResponses);

  JSONresponses.responses.response = completeRootResponses;

  if (repeatingCardSets && repeatingCardSets.length > 0) {

    //build out our existing json data 
    const hasExistingCardData = existingResponseData?.responses?.parent ? true : false;
    const isSingleParentObj = existingResponseData?.responses?.parent?.hasOwnProperty("@ordinalPosition") ? true : false;

    //final array to assign to JSON response parent array
    const allRepeatingParentNodes = [];

    for (let i = 0; i < repeatingCardSets.length; i++) {
      const cardSet = repeatingCardSets[i];
      const normalSetID = cardSet.questionID.toUpperCase();

      for (let j = 0; j < cardSet.cardCollection.length; j++) {

        const card = cardSet.cardCollection[j];
        let existingParentNodeObj;
        let existingParentNodeItem;

        //run base build card to get all QIDs for role
        let cardResponseNodes = buildRepeatingCardResponse(
          card,
          cardSet.questionID,
          questions,
          formValues
        );

        //identify existing data type
        if (
          hasExistingCardData && isSingleParentObj &&
          (!existingResponseData.responses.parent["@ordinalSetID"] || //old data might be missing ordinalSetID
            existingResponseData.responses.parent["@ordinalSetID"] === normalSetID) &&
          parseInt(existingResponseData.responses.parent["@ordinalPosition"]) === card.ordinalPosition
        ) {
          existingParentNodeObj = existingResponseData.responses.parent;
        }
        else {
          existingParentNodeObj = null;
        }

        if (
          hasExistingCardData &&
          existingResponseData.responses.parent[j] &&
          (!existingResponseData.responses.parent[j]["@ordinalSetID"] ||
            existingResponseData.responses.parent[j]["@ordinalSetID"] === normalSetID) &&
          parseInt(existingResponseData.responses.parent[j]["@ordinalPosition"]) === card.ordinalPosition
        ) {
          existingParentNodeItem = existingResponseData.responses.parent[j];
        }
        else {
          existingParentNodeItem = null;
        }

        //find missing QIDs in existing res obj data that weren't delivered due to RestrictedRoles and restore them 
        let missingResponses = [];

        if (existingParentNodeItem) {
          missingResponses = existingParentNodeItem.response.filter((existing) => {
            return (!cardResponseNodes.response.find((item) => item["@QID"] === existing["@QID"]));
          });
        }

        if (existingParentNodeObj) {
          missingResponses = existingParentNodeObj.response.filter((existing) => {
            return (!cardResponseNodes.response.find((item) => item["@QID"] === existing["@QID"]));
          });
        }

        cardResponseNodes.response = cardResponseNodes.response.concat(missingResponses);
        allRepeatingParentNodes.push(cardResponseNodes);
      }
    }

    JSONresponses.responses.parent = allRepeatingParentNodes;
  }

  const SubmissionDataVM = {
    SubmissionID: questions.submissionID,
    PageName: navigateToHome ? "home" : questions.pageUrl,
    ProgramCode: questions.programCode,
    ProgramName: questions.programName,
    DestinationPageName: navigateToHome ? "home" : questions.navigateTo.pageUrl,
    ResponseJSON: JSON.stringify(JSONresponses),
  };

  return SubmissionDataVM;
}

export const checkQuestionIsVisible = (question, formValues) => {
  let isVisible = false;

  if (question) {

    const isVisibleValues = question?.isVisibleValue?.replace(/\s/g, "").split(";");
    const isVisibleLookups = question?.isVisibleLookupCode?.replace(/\s/g, "").split(";");

    if (question?.isVisibleLookupCode && question.isVisibleValue && isVisibleLookups.length > 1) {
      forEach(isVisibleLookups, (isVisibleLookupCode, key) => {
        const parentValue = formValues[isVisibleLookupCode];

        if (parentValue && parentValue !== "" && question.isVisibleValue.toString().toUpperCase() === parentValue.toUpperCase()) {
          isVisible = true;
          return false;
        }
      });
    }
    else if (!question.isVisibleLookupCode) {
      return true;
    }
    else if (question.isVisibleLookupCode && isVisibleValues && isVisibleValues.length > 1) {
      forEach(isVisibleValues, (isVisibleValue) => {
        const parentValue = formValues[question.isVisibleLookupCode] ? formValues[question.isVisibleLookupCode].replace(/\s/g, "") : "";
        //convert to string in case we have a bool from the form
        if (
          isVisibleValue &&
          parentValue &&
          isVisibleValue.toString().toUpperCase() === parentValue.toUpperCase()
        ) {
          isVisible = true;
          return false;
        }
      });
    }
    else if (question?.isVisibleLookupCode && question.isVisibleValue && !question.isVisibleValue.includes(";")) {
      forEach(formValues, (value, key) => {
        if (question.isVisibleLookupCode === key && question.isVisibleValue === formValues[key]) {
          isVisible = true;
          return false;
        }
      });
    }
  }

  return isVisible;
};

export const clearQuestionValue = (isVisible, questionData, formValues) => {
  if (questionData && questionData.isVisibleLookupCode) {
    return !isVisible && formValues[questionData.lookupCode] !== "";
  }

  return false;
};

export function setNavPages(navigationPages, pageUrl) {
  let displayedNavPages = navigationPages.slice();
  displayedNavPages = remove(displayedNavPages, function (page) {
    return page.pageOrder > 0;
  });
  let nextPage = null;
  let previousPage = null;
  let currentPageIndex = findIndex(displayedNavPages, function (page) {
    return page.pageUrl === pageUrl;
  });
  if (currentPageIndex !== -1) {
    nextPage = displayedNavPages[currentPageIndex + 1]; //If null, we have no next page
    previousPage = displayedNavPages[currentPageIndex - 1]; //If null, we have no prev page
  }
  return { nextPage, previousPage };
}

/**************************************************************************************************************************
 * setQuestionValues
 * params: questionsData - our SubmissionDataVM class is passed in, containing Questions and ResponseJSON
 * returns: isolatedQuestions, newRCard, rCard, eCard, initialValues, questionIDLookups
 *
 *
 * We've split the questions into three possible types (and object arrays):
 *
 *  isolatedQuestions:
 *    Questions: these are rootlevel questions that meet the following criteria: they do not have questionType cardrepeating or
 *      cardexpanding, and they do not contain a cardLookupCode. These questions are unique and do not contain an ordinal position.
 *      They will be mapped and pushed directly to the isolatedQuestions array
 *
 *  rCards:
 *   Card: these are questions that contain questionType "cardrepeating", they are used for
 *    identification containers only and will not be reflected as a form question
 *   CardQuestions: these are questions that contain a cardLookupCode equal to the Parents LookupCode. When looping through, the
 *    ordinal position will be appended to the card's Lookupcode as -OPX to create unique identifiers for our form's initial
 *    values. These will be mapped to their corresponding values and added to questionsAndData in the cardObject, which is in
 *    turn pushed to the rCard array.
 *
 *  eCards:
 *   Card: these are questions that contain questionType "cardexpanding", they are used for
 *    identification containers only and will not be reflected as a form question
 *   CardQuestions: these are questions that contain a cardLookupCode equal to the Parents LookupCode. These questions are unique
 *    and will not have an ordinal position appended. They will be mapped to their corresponding values and added to questionsAndData
 *    in the cardObject, which is in turned pushed to the eCard array.
 *
 *    Each card will contain the following structure:
 *      cardObject = [
 *       bool isHidden
 *       int ordinalPosition //TODO this is a string right now from the JSON - convert to int here and remove conversion from CardsHelper so we don't have to handle more than once
 *       [Question] questionsAndData
 *    ]
 *
 * Also added during setup:
 *  initialValues: This is an object that contains key(LookupCode)/value(datavalue) pairs to represent each of our questions. This must be populated and
 *    passed into our Formik mapValuesToProps connection in order to populate the form on load, make sure each question is included
 *  questionIDLookups: This is an object that contains key(LookupCode)/value(questionID) pairs to avoid excess looping and for easy mapping on save.
 *    Formik does not have a way to pass the question ID back with the submitted form, since only the field name and values are tracked. Because our
 *    questions' LookupCode's have the potential to change when they are contained within repeating cards, we store each lookupCode after their ordinal
 *    position (-OPX) will have been appended.
 *  newRCard: This is an empty set of cardrepeating questions that will allow for the user to add new cardrepeating elements. It's the initial injection
 *    of rCards before data is appended. Ordinal positions will be added to the LookupCodes within CardsHelper.js when called upon.
 *
 **************************************************************************************************************************/

export function setQuestionValues(questionsData, programGroupCode, activeCardSet) {
  let data,
    rootData = [],
    parentData = [];
  if (questionsData.responseJSON !== "" && questionsData.responseJSON !== '{"responses":null}') {
    data = JSON.parse(questionsData.responseJSON);
    //Must add manually because JSONParse will not return type array for a single object. If length doesn't exist, then we were only given one
    if (data.responses.response && data.responses.response.length) {
      rootData = data.responses.response;
    }
    else if (data.responses.response) {
      rootData.push(data.responses.response);
    }
    if (data.responses.parent && data.responses.parent.length) {
      parentData = data.responses.parent;
    }
    else if (data.responses.parent) {
      parentData.push(data.responses.parent);
    }
  }

  let questionObject = {
    questions: [],
    initialValues: {},
    defaultValues: {},
    questionIDLookups: {},
    qrHardRequired: [], //toggles qr get quote button
    storedValidFields: [],
    storedInvalidFields: [],
    listeningValidationFields: [],
    logErrors: []
  };

  valueHelpers.buildRCards(questionsData, programGroupCode, questionObject, parentData, activeCardSet);
  valueHelpers.buildECards(questionsData, programGroupCode, questionObject, rootData);
  valueHelpers.buildInlineBlockRows(questionsData, programGroupCode, questionObject, rootData);
  valueHelpers.buildIsolatedQuestions(questionsData, programGroupCode, questionObject, rootData);
  validationHelpers.applySimpleValidationToStored(questionsData.pageName, questionsData.wasVisited, questionObject);

  return questionObject;
}

export function setValidationList(navigationData) {
  let invalidList = [];
  let validList = [];
  forEach(navigationData, (p) => {
    if (p.pageUrl === "PremInd") {
      return false;
    }
    if (p.isPageValid) {
      validList.push({
        pageDisplayName: p.pageDisplayName,
        pageUrl: p.pageUrl,
      });
    }
    else {
      invalidList.push({
        pageDisplayName: p.pageDisplayName,
        pageUrl: p.pageUrl,
      });
    }
  });
  return { validList, invalidList };
}

export function getPageState(validPages, invalidPages, pageName) {
  let isValidPage = find(validPages, { pageDisplayName: pageName }) !== undefined;
  let isInvalidPage = find(invalidPages, { pageDisplayName: pageName }) !== undefined;

  return { valid: isValidPage, invalid: isInvalidPage };
}

export function completeSubmissionCheck(validPages, navPagesLength) {
  if (validPages.length === navPagesLength) {
    return true;
  }
  if (validPages.length === navPagesLength - 1) {
    //Check if all pages other than the pay and view are valid
    return find(validPages, { pageUrl: "PremInd" }) === undefined;
  }
  return false;
}

export function setHiddenFlag(repeatingCards, ordinalPosition) {
  let cardsCopy = JSON.parse(JSON.stringify(repeatingCards));

  for (let i = 0; i < cardsCopy.length; i++) {
    if (cardsCopy[i].ordinalPosition === ordinalPosition) {
      cardsCopy[i].isHidden = !cardsCopy[i].isHidden;
      break;
    }
  }
  return cardsCopy;
}

const strapMessage = (message, i) => {

  const normalizeAnswer = (answer) => {
    return answer === enums.bool.true ? "True" : answer === enums.bool.false ? "False" : answer;
  }

  return (
    <table className="table table-striped table-sm" key={i}>
      <tbody>
        <tr className="bg-light">
          <td className="col-1 inline-block">Q:</td>
          <td className="col-11 inline-block" style={{ textAlign: "left" }}>
            {message.question !== "" ? message.question : message.placeholderText !== "" ? message.placeholderText : ""}
          </td>
        </tr>
        <tr>
          <td className="col-1 inline-block">A:</td>
          <td className="col-11 inline-block" style={{ textAlign: "left" }}>{message.answer !== "" ? normalizeAnswer(message.answer) : <i>No answer provided.</i>}</td>
        </tr>
        <tr className="bg-light">
          <td className="col-1 inline-block" style={{ height: "auto" }}></td>
          <td className="col-11 inline-block text-danger" style={{ textAlign: "left" }}>
            {message.errorMessage}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export function buildValidationModalMessage(validationMsgList) {
  let listItems;
  let i = 0;

  forEach(validationMsgList, (validationItem) => {
    listItems = validationItem.validationMsgs.reduce(function (result, message) {
      result.push(strapMessage(message, i));
      i++;
      return result;
    }, []);
  });

  return (
    <div>
      <div>
        <h4 className="w-100 text-center">The following questions require attention:</h4>
      </div>
      <div className={"mt-30"}>
        {listItems}
      </div>
    </div>
  );
}

export function removeHiddenECardValues(clonedValues, questions) {
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].questionType === "cardexpandingset") {
      let selectedQuestion = questions[i];
      for (let j = 0; j < selectedQuestion.cardCollection.length; j++) {
        let cardObject = selectedQuestion.cardCollection[j];
        //go into that card object's questionsAndData and delete each lookupCode from the formik form values object
        if (cardObject.isHidden) {
          let questionsSetToRemove = cardObject.questionsAndData;
          for (let k = 0; k < questionsSetToRemove.length; k++) {
            let lookupCode = questionsSetToRemove[k].lookupCode;
            if (clonedValues.hasOwnProperty(lookupCode)) {
              delete clonedValues[lookupCode];
            }
          }
        }
      };
    }
  };
  return clonedValues;
}

export function removeAllRCardValues(initialValues, exceptOrdinalPosition) {
  for (let prop in initialValues) {
    if (exceptOrdinalPosition) {
      if (prop.includes("-OP") && parseInt(prop.split("-OP")[1]) !== exceptOrdinalPosition) {
        delete initialValues[prop];
      }
    }
    else {
      if (prop.includes("-OP")) {
        delete initialValues[prop];
      }
    }
  }

  return initialValues;
}

export function removeSingleRCardValues(initialValues, removeOrdinalPosition) {
  for (let prop in initialValues) {
    if (prop.includes("-OP") && parseInt(prop.split("-OP")[1]) === removeOrdinalPosition) {
      delete initialValues[prop];
    }
  }

  return initialValues;
}

export function addNewActiveRCardValues(initialValues, rCardsAll, repeatingCardsActiveRangeEnd, showHiddenCards) {
  let start = repeatingCardsActiveRangeEnd - 6; //cards are grouped in bundles of 6

  for (let i = start; i < repeatingCardsActiveRangeEnd; i++) {
    if (rCardsAll[i]) {
      let cardQuestions = rCardsAll[i].questionsAndData;

      if (showHiddenCards) {
        if (rCardsAll[i].isHidden) {
          for (let j = 0; j < cardQuestions.length; j++) {
            initialValues[cardQuestions[j].lookupCode] = cardQuestions[j].datavalue;
          }
        }
      }
      else {
        if (!rCardsAll[i].isHidden) {
          for (let j = 0; j < cardQuestions.length; j++) {
            initialValues[cardQuestions[j].lookupCode] = cardQuestions[j].datavalue;
          }
        }
      }
    }
  }

  return initialValues;
}

export function restoreActiveRCardValues(activeValues, allStoredRCardValues) {
  let activeValuesCopy = clone(activeValues);

  for (let field in allStoredRCardValues) {
    if (!activeValuesCopy.hasOwnProperty(field)) {
      activeValuesCopy[field] = allStoredRCardValues[field];
    }
  }

  return activeValuesCopy;
}

export function updateAllButActiveRCardValues(storedInitialValues, activeFormValues, newCardOP) {
  //first clean our activeFormValues and storedInitialValues of newCardOP values
  if (newCardOP) {
    for (let storedProp in storedInitialValues) {
      if (storedProp.includes("-OP") && parseInt(storedProp.split("-OP")[1]) === newCardOP) {
        delete storedInitialValues[storedProp];
      }
    }

    for (let activeProp in activeFormValues) {
      if (activeProp.includes("-OP") && parseInt(activeProp.split("-OP")[1]) === newCardOP) {
        delete activeFormValues[activeProp];
      }
    }
  }

  //second add cleaned values back to our storedInitialValues
  for (let prop in storedInitialValues) {
    for (let matchProp in activeFormValues) {
      if (!prop.includes("-OP") && prop === matchProp) {
        //assign the circulating form value to our saved state value
        storedInitialValues[prop] = activeFormValues[matchProp];
      }
    }
  }

  return storedInitialValues;
}

export function extractActiveRCards(rCardsAll, repeatingCardsActiveRangeEnd, showHiddenCards = false) {
  let extractedRCards = [];
  let start = repeatingCardsActiveRangeEnd - 6; //cards are grouped in bundles of 6
  let filteredShowOrHideCards;

  if (showHiddenCards !== undefined) {
    filteredShowOrHideCards = filter(rCardsAll, function (card) {
      return card.isHidden === showHiddenCards;
    });
  }
  else {
    filteredShowOrHideCards = rCardsAll;
  }

  for (let i = start; i < repeatingCardsActiveRangeEnd; i++) {
    if (filteredShowOrHideCards[i]) {
      if (showHiddenCards) {
        if (filteredShowOrHideCards[i].isHidden) {
          extractedRCards.push(filteredShowOrHideCards[i]);
        }
      }
      else {
        if (!filteredShowOrHideCards[i].isHidden) {
          extractedRCards.push(filteredShowOrHideCards[i]);
        }
      }
    }
  }

  return extractedRCards;
}

export function clearHiddenChildFieldValues(questions, formValues) {
  if (questions && formValues) {
    let formValuesClone = clone(formValues);

    const testAndClearValue = (question) => {
      forEach(formValuesClone, (value, key) => {
        if (
          question.isVisibleLookupCode === key &&
          (!value || value === enums.bool.false) &&
          question.isVisibleValue !== enums.bool.false &&
          formValuesClone[question.lookupCode]
        ) {
          formValuesClone[question.lookupCode] = "";
        }
      });
    };

    //check if this is a card set obj
    forEach(questions, (question) => {
      if (question.cardCollection) {
        forEach(question.cardCollection, (cardObject) => {
          forEach(cardObject.questionsAndData, (question) => {
            if (question.isVisibleLookupCode) {
              testAndClearValue(question);
            }
          });
        });
      }
      else if (question.isVisibleLookupCode) {
        testAndClearValue(question);
      }
    });

    return formValuesClone;
  }

  return formValues;
}

export function checkShowHiddenCardValidity(showingCardCount, hiddenCardCount, showHiddenCards) {
  if (hiddenCardCount === 0 && showHiddenCards === true) {
    return false;
  }
  else if (showingCardCount === 0 && showHiddenCards === false) {
    return true;
  }
  else {
    return showHiddenCards;
  }
}

export function getShowHideCountsForAllRCards(rCardsAll, showHiddenCards) {
  let showHideCardCounts = {
    hideCardsCount: 0,
    showCardsCount: 0,
  };

  if (rCardsAll && rCardsAll.length > 0) {
    for (let i = 0; i < rCardsAll.length; i++) {
      if (showHiddenCards) {
        if (rCardsAll[i].isHidden) {
          showHideCardCounts.hideCardsCount++;
        }
      }
      else {
        if (!rCardsAll[i].isHidden) {
          showHideCardCounts.showCardsCount++;
        }
      }
    }
  }

  return showHideCardCounts;
}

export function buildQuoteVM(submissionID, pageUrl, navigateToPageUrl) {
  let QuoteVM = {
    SubmissionID: submissionID,
    PageName: pageUrl,
    DestinationPageName: navigateToPageUrl,
    FinalQuote: 0.0,
    PaymentType: "",
    FEIN: "",
    BankName: "",
    BankRouting: "",
    BankAccount: "",
    CCType: "",
    CCNumber: "",
    CCMonth: "",
    CCYear: "",
    CCCVC: "",
    CCFirst: "",
    CCLast: "",
    CCCompany: "",
    CCUseAddress: false,
    CCAddress: "",
    CCCity: "",
    CCState: "",
    CCZip: "",
    Reviewed: false,
    AcceptedSignature: "",
    AcceptedDate: "",
    Consent: false,
    Downpayment: 0.0,
    DebitAuth: false,
  };
  return QuoteVM;
}

const setDataValue = (value) => {
  if (value && typeof value === "boolean") {
    // we only deal with bool strings on back, use this to self-heal
    if (value) {
      return "TRUE";
    }
    else {
      return "FALSE";
    }
  }
  else if (value) {
    return value;
  }
  else {
    return "";
  }
};

export function suspendedFormValues(invalidForm, suspendedFormValues, pageUrl) {
  let matchPageURL = 0;
  let mismatchPageURL = 0;

  if (invalidForm && !util.isObjEmpty(suspendedFormValues)) {
    for (let field in suspendedFormValues) {
      //can't rely on a field always having page name since we add prev page fields to formik form values for PS
      //so, we can check that the number of pageURL matches is greater than mismatches to know
      //if suspendedFormValues applies to this page
      if (field.split("_")[0] === pageUrl) {
        matchPageURL++;
      }
      else {
        mismatchPageURL++;
      }
    }
    if (matchPageURL > mismatchPageURL) {
      return suspendedFormValues;
    }
    else {
      return {};
    }
  }
  else {
    return {};
  }
}

export function getIsolatedQuestions(questions) {
  return filter(questions, function (q) {
    let inlineBlockSetChild =
      q.parentLookupCode && q.parentLookupCode.toLowerCase().includes(enums.setTypes.inlineBlockSet);

    return (
      (q.cardLookupCode === null || (q.cardLookupCode && q.cardLookupCode.toLowerCase().includes("modal"))) &&
      q.questionType !== enums.setTypes.cardRepeating &&
      q.questionType !== enums.setTypes.cardRepeatingSet &&
      q.questionType !== enums.setTypes.cardExpanding &&
      q.questionType !== enums.setTypes.cardExpandingSet &&
      q.questionType !== enums.setTypes.inlineBlockSet &&
      !inlineBlockSetChild
    );
  });
}

export function getInlineBlockQuestions(questions) {
  let inlineQuestions = [];

  forEach(questions, (question) => {
    forEach(question.inlineBlockQuestions, (iq) => {
      inlineQuestions.push(iq);
    });
  });

  return inlineQuestions;
}

export function getExpandingCardsFromRaw(questions) {
  return filter(questions, function (q) {
    return q.questionType === enums.setTypes.cardExpanding;
  });
}

export function getExpandingCards(questions) {
  let extractedECards = [];

  for (let i = 0; i < questions.length; i++) {
    let question = questions[i];
    if (question.questionType === enums.setTypes.cardExpandingSet) {
      for (let k = 0; k < question.cardCollection.length; k++) {
        extractedECards.push(question.cardCollection[k]);
      }
    }
    else if (question.questionType === enums.setTypes.cardExpanding) {
      extractedECards.push(question);
    }
  }

  return extractedECards;
}

export function getExpandingCardSets(questions) {
  return filter(questions, function (q) {
    return q.questionType === enums.setTypes.cardExpandingSet;
  });
}

export function getInlineBlockSets(questions) {
  return filter(questions, function (q) {
    return q.questionType === enums.setTypes.inlineBlockSet;
  });
}

export function getRepeatingCardsFromRaw(questions) {
  return filter(questions, function (q) {
    return q.questionType === enums.setTypes.cardRepeating;
  });
}

export function getRepeatingCards(questions) {
  let extractedRCards = [];

  for (let i = 0; i < questions.length; i++) {
    let question = questions[i];
    if (question.questionType === enums.setTypes.cardRepeatingSet) {
      for (let k = 0; k < question.cardCollection.length; k++) {
        extractedRCards.push(question.cardCollection[k]);
      }
    }
    else if (question.questionType === enums.setTypes.cardRepeating) {
      extractedRCards.push(question);
    }
  }

  return extractedRCards;
}

export function getRepeatingCardSets(questions) {
  return filter(questions, function (q) {
    return q.questionType === enums.setTypes.cardRepeatingSet;
  });
}

export function removeRepeatingCards(questions) {
  return filter(questions, function (q) {
    return q.questionType !== enums.setTypes.cardRepeating || q.questionType !== enums.setTypes.cardRepeatingSet;
  });
}

export function getInvalidResponses(questionsAndData, formValues) {
  const invalidResponses = [];

  forEach(questionsAndData, (question) => {
    if (checkQuestionIsVisible(question, formValues)) {
      const fieldValue = formValues[question.lookupCode];
      if (validationHelpers.checkHasInvalidResponse(question, fieldValue)) {
        invalidResponses.push(question);
      }
    }
  });

  return invalidResponses;
}

export function toggleCardHiddenValue(show, card, questionsClone) {
  forEach(questionsClone.questions, (item) => {
    if (item?.cardCollection) {
      forEach(item.cardCollection, (cardObject) => {
        if (cardObject.cardLookupCode === card.cardLookupCode) {
          cardObject.isHidden = show;
          return false;
        }
      });
    }
  });

  return questionsClone;
}

export function toggleCardPristineValue(formValues, parentCardLookupCode, questions) {

  const questionsClone = cloneDeep(questions);

  const isPristine = (value, question) => {
    if (value === "" || value === undefined || (question.questionType === enums.fieldTypes.checkbox && value === "FALSE")) {
      return true;
    }

    return false;
  }

  forEach(questionsClone.questions, (item) => {
    if (item?.cardCollection) {
      forEach(item.cardCollection, (cardObject) => {
        forEach(cardObject.questionsAndData, (question) => {
          let value = formValues[question.lookupCode];

          if (
            question.questionType !== "hidden" &&
            question.cardLookupCode === parentCardLookupCode &&
            question.defaultValue !== value
          ) {
            if (!isPristine(value, question)) {
              cardObject.isPristine = false;
              return false; //breakout
            }
            else {
              cardObject.isPristine = true;
            }
          }
        });
      });
    }
  });

  return questionsClone;
}

export function setCardToPristineAndHide(parentCardLookupCode, questionsClone) {

  forEach(questionsClone.questions, (item) => {
    if (item?.cardCollection) {
      forEach(item.cardCollection, (cardObject) => {
        forEach(cardObject.questionsAndData, (question) => {
          if (question.cardLookupCode === parentCardLookupCode) {
            cardObject.isPristine = true;
            cardObject.isSelected = false;
            return false;
          }
        });
      });
    }
  });

  return questionsClone;
}