import * as appActions from "./AppActionTypes";
import * as appHelper from "./AppHelper";
import * as axiosApi from "../axiosApi";
import * as headerActions from "../Header/HeaderActions";
import * as questionsActions from "../Questions/QuestionsActions";
import enums from "../Utilities/Enums/Enums";
import util from "../Utilities/Functions/Functions";
import historyObj from "../Routing/browserHistory";

export function changeUsername(values) {
  return async (dispatch) => {
    const vm = {
      NewEmail: values.newUsername,
      CurrentEmail: values.currentUsername,
      EntityId: values.entityId,
    };

    try {
      const result = await axiosApi.post("api/token/changeemail", vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(logError("AppActions - changeUsername", result, false));
      }
      else {
        //new token is returned, set it
        sessionStorage.setItem("token", result.data.data.token);
        sessionStorage.setItem("refreshToken", result.data.data.refreshToken);
        //get updated user to update state
        await dispatch(getUser());
        dispatch(reportToast(result.data.message, enums.toastTypes.success));
      }
    }
    catch (error) {
      dispatch(reportToast("Unable to change username. Please try again later.", enums.toastTypes.error));
      dispatch(logError("AppActions - changeUsername", error, false));
    }
  };
}

export function clearCurrentSubmission() {
  return { type: appActions.clearCurrentSubmission };
}

export function clearFacilityRefCode() {
  return { type: appActions.clearFacilityRefCode };
}

export function clearResetPwAndNav() {
  return (dispatch) => {
    dispatch(clearResetPwSuccess());
    historyObj.push("/login");
  };
}

export function clearResetPwSuccess() {
  return { type: appActions.clearResetPwSuccess };
}

export function clearToast() {
  return { type: appActions.clearToast };
}

export function createSubmission(programCode, redirectFromPolicyLink) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const refCode = getState().app.facilityRefCode;
      const facilityRefCode = refCode ? refCode : 0;
      const result = await axiosApi.get("api/Submission/Create/" + programCode + "/" + facilityRefCode);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(reportToast(result.data.message, enums.toastTypes.error));
        // dispatch(logError("AppActions - server error - createSubmission", result, false));
      }
      else {
        if (state.header.sideMenuOpen) {
          dispatch(toggleBlur());
          dispatch(headerActions.toggleSideMenu());
        }
        dispatch(setDirectToQR(result.data.data.isDirectToQR));
        dispatch(updateSubmissionAndNav(result.data.data, redirectFromPolicyLink));
      }
    }
    catch (error) {
      dispatch(
        reportToast("Unable to create a submission at this time. Please try again later.", enums.toastTypes.error)
      );
      dispatch(logError("AppActions - createSubmission", error, false));
      dispatch(toggleLoading(false));
    }
  };
}

export function forgotPassword(userEmail, submissionId) {
  return async (dispatch) => {
    try {
      const vm = { Email: userEmail.Email };
      const result = await axiosApi.post("api/token/getResetCode", vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(reportToast(result.data.message, enums.toastTypes.error));
        dispatch(logError("AppActions - getResetCode", result, false));
      }
      else {
        dispatch(reportToast(result.data.message, enums.toastTypes.success));
        dispatch(setResetPasswordEmail(userEmail.Email));
        if (!submissionId) {
          historyObj.push("/verification");
        }
      }
    }
    catch (error) {
      //don't report invalid email to user
      dispatch(reportToast("Please check your account email for additional details.", enums.toastTypes.success));
      dispatch(logError("AppActions - getResetCode", error, false));
    }
  };
}

export function openResetPwModal() {
  return { type: appActions.openResetPwModal };
}

export function setResetPasswordEmail(email) {
  return { type: appActions.resetPwEmail, email };
}

export function verifyCode(emailAddress, code) {
  const errorMsg = "Invalid verification code. Please try again.";

  return async (dispatch, getState) => {
    const vm = {
      UserName: emailAddress,
      Code: code,
    };
    try {
      const app = getState().app;
      const tokenResult = await axiosApi.post("api/token/loginwithcode", vm);
      if (tokenResult.data.response === enums.serverResponse.error) {
        throw tokenResult;
      }
      else {
        sessionStorage.setItem("token", tokenResult.data.data.token);
        sessionStorage.setItem("refreshToken", tokenResult.data.data.refreshToken);
        dispatch(openResetPwModal());

        dispatch(GetLoginData());

        dispatch(updateUser(tokenResult.data.data.user, emailAddress));
        dispatch(loginSuccess());
        let entityTypeCode = tokenResult.data.data.user.entityTypeCode;

        if (entityTypeCode === enums.role.direct) {
          historyObj.push("/policylist");
        }
        else {
          historyObj.push("/");
        }
      }
    }
    catch (error) {
      dispatch(reportToast(errorMsg, enums.toastTypes.error));
    }
  };
}

export function getApprovedGroupStatus(submission) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("/api/Submission/getApprovedGroupStatus/" + submission.internalStatus);
      return Promise.resolve(result);
    }
    catch (error) {
      dispatch(reportToast("Not authorized to access this feature", enums.toastTypes.error));
      return Promise.reject(error);
    }
  };
}

export function getFacilityConfigSuccess(facilityRefCode, facilityConfigData) {
  return { type: appActions.getFacilityConfigSuccess, facilityRefCode, facilityConfigData };
}

export function GetLoginData() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("/api/User/GetLoginData/States");
      if (result.data.response !== enums.serverResponse.error) {
        dispatch(getLoginDataSuccess(result.data.data));
      }
      return Promise.resolve(true);
    }
    catch (error) {
      return Promise.reject(error);
    }
  };
}

export function getLoginDataSuccess(loginData) {
  return { type: appActions.getLoginDataSuccess, loginData };
}

export function getEntityConfig(entityId) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("/api/User/getEntityConfig/" + entityId);

      if (result.data.response !== enums.serverResponse.error) {
        if (result.data.data && result.data.data.length > 0) {
          dispatch(getEntityConfigSuccess(result.data.data));
        }
      }
      return Promise.resolve(true);
    }
    catch (error) {
      return Promise.reject(error);
    }
  };
}

export function getEntityConfigSuccess(entityConfigData) {
  //returns an array of config items, need to form into single config object
  const entityConfig = appHelper.buildConfigEntity(entityConfigData);
  return { type: appActions.getEntityConfigSuccess, entityConfig };
}

export function initialize() {
  return (dispatch) => {
    try {
      dispatch(toggleLoading(false));
    }
    catch (error) {
      dispatch(
        logError(
          "AppActions - initialize",
          error,
          true,
          "Unable to load account configuration. Please contact the help desk."
        )
      );
    }
  };
}

export function getUserProgramList() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/Submission/getUserProgramList");
      dispatch(getUserProgramListSuccess(result.data.data));
    }
    catch (error) {
      dispatch(
        logError(
          "AppActions - getUserProgramList",
          error,
          true,
          "Unable to load programs list. Please contact the help desk."
        )
      );
    }
  };
}

export function getProgramGroupList() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/Submission/getAllPrograms");
      dispatch(getProgramGroupListSuccess(result.data.data));
    }
    catch (error) {
      dispatch(
        logError(
          "AppActions - getProgramGroupList",
          error,
          true,
          "Unable to load program groups list. Please contact the help desk."
        )
      );
    }
  };
}

export function getUserProgramListSuccess(programs) {
  return { type: appActions.getUserProgramListSuccess, programs };
}

export function getProgramGroupListSuccess(programGroups) {
  return { type: appActions.getProgramGroupListSuccess, programGroups };
}

export function getPolicyCount() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/submission/count");
      if (result.data.response === enums.serverResponse.error) {
        dispatch(setPolicyCountError(result.data.message));
      }
      else {
        dispatch(setPolicyCountSuccess(result.data.data));
      }
    }
    catch (error) {
      dispatch(logError("AppActions - getPolicyCount", error, false));
    }
  };
}

export function getSelectedPDFsSuccess(pdfPath) {
  return { type: appActions.getSelectedPDFsSuccess, pdfPath };
}

export function getSubmissionStatusTypes() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/submission/statusTypes");
      if (result.data.response !== enums.serverResponse.error) {
        dispatch(getSubmissionStatusTypesSuccess(result.data.data));
      }
    }
    catch (error) {
      dispatch(logError("AppActions - getSubmissionStatusTypes", error, false));
    }
  };
}

export function getSubmissionStatusTypesSuccess(statuses) {
  return { type: appActions.getSubmissionStatusTypesSuccess, statuses };
}

export function getStatusTypesInternal() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/submission/statuses-internal");

      if (result.data.response !== enums.serverResponse.error) {
        dispatch(getStatusTypesInternalSuccess(result.data.data));
      }
    }
    catch (error) {
      dispatch(logError("AppActions - getStatusTypesInternal", error, false));
    }
  };
}

export function getStatusTypesInternalSuccess(statuses) {
  return { type: appActions.getStatusTypesInternalSuccess, statuses };
}

export function getUser() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("api/user/getUser/");
      if (result.data.response === enums.serverResponse.error) {
        throw result;
      }
      else {
        dispatch(updateUser(result.data.data, result.data.data.userName));
        return Promise.resolve(true);
      }
    }
    catch (error) {
      //allow parent to interpret error and log it
      return Promise.reject(error);
    }
  };
}

export function getUSStates() {
  return async (dispatch) => {
    try {
      const result = await axiosApi.get("/api/submission/lookups/States");
      dispatch(getUSStatesSuccess(result.data.data));
    }
    catch (error) {
      dispatch(logError("AppActions - getUSStates", error, false));
    }
  };
}

export function getUSStatesSuccess(states) {
  return { type: appActions.setUSStates, states };
}

//network/client/server errors
export function logError(calleeName, error, redirect, customMessage) {
  return async (dispatch, getState) => {
    try {
      //401 auto log out
      if (error?.response?.status === 401) {
        dispatch(logout());
        dispatch(reportToast("401: Unauthorized", enums.toastTypes.error));
        return;
      }

      //else other error, report it
      let data = "",
        message = "";
      let submissionNumber = getState().app.currentSubmission.submissionNumber;

      if ((error?.config?.url.includes("create") || error?.config?.url.includes("resetpassword")) && error?.config?.data) {
        let returnedDataObj = JSON.parse(error.config.data);
        if (!util.isObjEmpty(returnedDataObj) && (returnedDataObj.Password || returnedDataObj.ConfirmPassword)) {
          returnedDataObj.Password = "######";
          returnedDataObj.ConfirmPassword ?? "######";
          data = JSON.stringify(returnedDataObj);
        }
        else {
          data = JSON.stringify(error.config.data);
        }
      }
      else if (error?.data?.data) {
        //reporting a server handled error
        data = JSON.stringify(error.data.data);
        message = error?.data?.message ?? "";
      }
      else if (error?.config?.data) {
        //reporting an unhandled error/network error
        data = JSON.stringify(error.config.data);
      }
      else if (error?.message) {
        message = error.message;
      }
      else {
        //else something unexpected is being passed in
        if (window.location.hostname === "localhost") {
          alert("Unhandled error obj in AppActions.js: logError. Check console for object report!");
          console.log(error);
        }
        else {
          console.log("Unhandled error obj in AppActions.js: logError!");
          console.log(error);
        }
      }

      const vm = {
        //don't flood the log with huge amounts of data - 200 "should" be enough, if not widen it a bit
        data: data ? data.substring(0, 200) : "",
        endpoint: error?.config?.url ?? "",
        message: customMessage ? calleeName + ": " + customMessage : calleeName + ": " + message,
        submissionNumber: submissionNumber ? String(submissionNumber) : error?.submissionID ? error?.submissionID : "",
      };

      if (
        !vm.message ||
        vm.message === undefined ||
        vm.message.includes("undefined") ||
        vm.message === null ||
        vm.message === "QuestionsActions - abortSubmission: " //just the title, no actual message so it's not helpful
      ) {
        return;
      }

      const result = await axiosApi.post("api/token/logFrontEndError", vm);
      if (redirect) {
        historyObj.push("/error", {
          customHeaderMsg: "",
          customSubheaderMsg: result?.data?.message ?? "",
          customBodyMsg: "",
          hideContactForm: "",
        });
      }
      else {
        return;
      }
    }
    catch (error) {
      if (redirect) {
        //even if it doesn't log (i.e. network error), push the error message
        historyObj.push("/error", {
          customHeaderMsg: "",
          customSubheaderMsg: customMessage ? customMessage : error?.message ? error?.message : "",
          customBodyMsg: "",
          hideContactForm: "",
        });
      }
      else {
        return;
      }
    }
  };
}

export function logUIError(error, redirect) {
  return async () => {
    try {
      const vm = {
        data: error?.data ? error.data : "",
        endpoint: error?.endpoint ? error.endpoint : "",
        message: error?.message ? error.message : "",
        submissionNumber: error?.submission ? error.submission : "",
      };

      const result = await axiosApi.post("api/token/logFrontEndError", vm);

      if (redirect) {
        historyObj.push("/error", {
          customHeaderMsg: "",
          customSubheaderMsg: result.data.message,
          customBodyMsg: "",
          hideContactForm: "",
        });
      }
    }
    catch (error) {
      return;
    }
  };
}

export function logValidation(submissionID, validationType, details) {
  return async () => {
    try {
      const vm = {
        submissionID: submissionID,
        validationType: validationType,
        reports: [
          {
            LookupCode: details.LookupCode,
            Question: details.Question,
            PlaceholderText: details.PlaceholderText,
            Answer: details.Answer,
            ErrorMessage: details.ErrorMessage,
          },
        ],
      };

      //just a fire-and-forget, don't care or need any return values
      await axiosApi.post("api/submission/logValidation", vm);
    }
    catch (error) {
      return;
    }
  };
}

export function login(vm, facilityRefCode, directLinkProgramCode, promoCode) {
  const errorMsg = "Invalid login. If you forgot your credentials, please use the Forgot Password link.";

  return async (dispatch, getState) => {
    try {
      dispatch(loggingIn());

      const app = getState().app;
      const tokenResult = await axiosApi.post("api/token/create", vm);
      const username = directLinkProgramCode ? null : tokenResult.data.data.user.entityEmail;

      if (tokenResult.data.response === enums.serverResponse.error) {
        throw tokenResult;
      }
      else {
        sessionStorage.setItem("token", tokenResult.data.data.token);
        sessionStorage.setItem("refreshToken", tokenResult.data.data.refreshToken);

        if (!facilityRefCode) {
          facilityRefCode = app.facilityRefCode;
        }
        dispatch(GetLoginData());
        // dispatch(getEntityConfig(tokenResult.data.data.user.entityId)); //not using this so comment out, but in case we need it again...
        dispatch(getFacilityConfigSuccess(facilityRefCode));

        if (promoCode) {
          dispatch(questionsActions.setPromoCode(promoCode));
        }

        dispatch(updateUser(tokenResult.data.data.user, username));
        dispatch(loginSuccess());

        //unknown user is coming in from parent site
        if (directLinkProgramCode) {
          dispatch(createSubmission(directLinkProgramCode));
        }
        else {
          return tokenResult.data.data.user.entityTypeCode;
        }
      }
    }
    catch (error) {
      dispatch(logout());
      dispatch(reportToast(error?.data?.message ? error.data.message : errorMsg, enums.toastTypes.error));
    }
  };
}

export function loggingIn() {
  return { type: appActions.loggingIn };
}

export function loginSuccess() {
  return { type: appActions.loginSuccess };
}

export function logout() {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("refreshToken");

  return (dispatch) => {
    dispatch(logoutSuccess());
  };
}

export function logoutSuccess() {
  return { type: appActions.logoutSuccess };
}

export function navigateToSubmission(submission, editing) {
  //note: called from policy action modal - always refresh current submission
  return async (dispatch, getState) => {
    const entityID = getState().app.user.entityId;

    try {
      // await dispatch(getEntityConfig(entityID)); //not using this so comment out, but in case we need it again...
      //check if user is editing (e.g. user hits Edit in PolicyList)
      if (editing) {
        //get approved group status
        const approvedGroupResult = await dispatch(getApprovedGroupStatus(submission));
        const isApprovedSubmission = approvedGroupResult.data.data;

        //check if endorsing
        if (isApprovedSubmission) {
          const editOrEndorseResult = await dispatch(setEditOrEndorse(submission.submissionID, true));
          const submissionEndorsed = editOrEndorseResult.data.data;
          //endorsement successful, now we are editing
          await dispatch(setEditOrEndorse(submissionEndorsed.submissionID));
          dispatch(updateSubmissionAndNav(submissionEndorsed));
        }
        else {
          //editing submission
          await dispatch(setEditOrEndorse(submission.submissionID));
          dispatch(updateSubmissionAndNav(submission));
        }
      }
      else {
        //else user clicked other nav (e.g. Complete Purchase)
        dispatch(updateSubmissionAndNav(submission));
      }
    }
    catch (error) {
      dispatch(
        reportToast(
          error?.data?.message ? error.data.message : "Unable to load submission. Please try again later.",
          enums.toastTypes.error
        )
      );
      dispatch(logError("AppActions - navigateToSubmission", error, false));
      dispatch(toggleLoading(false));
    }
  };
}

export function register(vm, isBroker) {
  return async (dispatch) => {
    dispatch(reportToast("Registering user...", enums.toastTypes.success));

    try {
      //TODO: Should we store rememberMe?
      const registerURL = isBroker === enums.bool.true ? "api/token/registerbroker" : "api/token/register";
      const result = await axiosApi.post(registerURL, vm);

      if (result.data.response === enums.serverResponse.error) {
        dispatch(reportToast(result.data.message, enums.toastTypes.error));
      }
      else {
        dispatch(reportToast("Account successfully created. Please log in.", enums.toastTypes.success));
        historyObj.push("/login");
      }
    }
    catch (error) {
      dispatch(
        logError("AppActions - register", error, true, "Unable to register at this time. Please try again later.")
      );
    }
  };
}

export function reportToast(toastMsg, toastType) {
  return { type: appActions.reportToast, toastMsg, toastType };
}

export function resetPassword(vm) {
  return async (dispatch) => {
    try {
      const result = await axiosApi.post("api/token/resetpassword", vm);
      //always returns success for security/confidentiality
      dispatch(resetPwSuccess(result.data.message));
    }
    catch (error) {
      dispatch(logError("AppActions - resetPassword", error, false));
    }
    finally {
      dispatch(clearPwModal());
    }
  };
}

export function clearPwModal() {
  return { type: appActions.clearPwModal };
}

export function resetPwSuccess(successResponse) {
  return { type: appActions.resetPwSuccess, successResponse };
}

export function setProgramGroupCode(programGroupCode) {
  return { type: appActions.setProgramGroupCode, programGroupCode };
}

export function saveUser(vm) {
  return async (dispatch) => {
    try {
      const invalidProperties = appHelper.checkStringInputLengths(vm);

      if (invalidProperties.length === 0) {
        const result = await axiosApi.post("api/User/saveUser", vm);
        if (result.data.response === enums.serverResponse.error) {
          dispatch(reportToast(result.data.message, enums.toastTypes.error));
        }
        else {
          dispatch(updateUser(result.data.data, result.data.data.userName));
          dispatch(reportToast(result.data.message, enums.toastTypes.success));
        }
      }
      else {
        let propertySingPlural = " property ";
        let isSingPlural = " is ";
        let fieldSingPlural = " this field ";
        if (invalidProperties.length > 1) {
          propertySingPlural = " properties ";
          isSingPlural = " are ";
          fieldSingPlural = " these fields ";
        }
        const propertiesString = invalidProperties.join(", ");
        dispatch(
          reportToast(
            "The length of the" +
            propertySingPlural +
            propertiesString +
            isSingPlural +
            "too long. Please shorten" +
            fieldSingPlural +
            "and try again.",
            enums.toastTypes.error
          )
        );
      }
    }
    catch (error) {
      dispatch(reportToast("Unable to save user. Please try again.", enums.toastTypes.error));
      dispatch(logError("AppActions - saveUser", error, false));
    }
  };
}

export function setDirectToQR(directToQR) {
  return { type: appActions.setDirectToQR, directToQR };
}

export function setEditOrEndorse(submissionID, endorse) {
  return async () => {
    try {
      const url = endorse ? "api/Submission/endorse" : "api/Submission/edit";
      const vm = { SubmissionID: submissionID };

      const result = await axiosApi.post(url, vm);

      if (result.data.response === enums.serverResponse.error) {
        throw result;
      }
      else {
        return Promise.resolve(result);
      }
    }
    catch (error) {
      return Promise.reject(error);
    }
  };
}

export function setCurrentSubmission(submission) {
  return { type: appActions.setCurrentSubmission, submission };
}

export function setPolicyCountError(errorResponse) {
  return { type: appActions.setPolicyCountError, errorResponse };
}

export function setPolicyCountSuccess(count) {
  return { type: appActions.setPolicyCount, count };
}

export function toggleBlur() {
  return { type: appActions.toggleBlur };
}

export function toggleLoading(bool) {
  return { type: appActions.toggleLoading, bool };
}

export function updateSubmissionAndNav(submission, redirectFromPolicyLink) {
  return async (dispatch) => {
    try {
      const programCodeToUse = submission.programGroupCode ? submission.programGroupCode : submission.programCode;

      dispatch(setProgramGroupCode(programCodeToUse));
      dispatch(setCurrentSubmission(submission));

      setTimeout(function () {
        dispatch(toggleLoading(false));
        historyObj.push("/questions");

        if (redirectFromPolicyLink) {
          //required to trigger Questions.js listener for setupQuestions
          //in scenario where in-policy link redirects to another
          window.location.reload();
        }
      });
    }
    catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateUser(user, username) {
  return { type: appActions.updateUser, user, username };
}

export function useDefaultConfig() {
  return { type: appActions.useDefaultConfig };
}
