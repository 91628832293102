import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Image, Row, Col } from "react-bootstrap";
import * as appActions from "../../../App/AppActions";
import * as questionsActions from "../../../Questions/QuestionsActions";
import enums from "../../Enums/Enums";
import util from "../../Functions/Functions";

const Images = (props) => {
  const {
    color,
    fontSize,
    helptext,
    id,
    input_css,
    name,
    source
  } = props;

  //store
  let questions = useSelector((state) => state.questions);

  //dispatch actions
  const dispatch = useDispatch();

  //outbound
  const logUIError = useCallback((error) => dispatch(appActions.logUIError(error)), [dispatch]);

  const togglePageHasBanner = useCallback(() => dispatch(questionsActions.togglePageHasBanner()), [dispatch]);

  const isFontIconImg = (fileSource) => {
    if (fileSource) {
      return fileSource.includes("icon-");
    }
    else {
      return false;
    }
  };

  let buildErrorMsg = (error) => {
    if (window.location.href.includes("/questions") && !util.isObjEmpty(questions)) {
      error.message = "Image source not found at " + questions.pageUrl;
    }
    else {
      error.message = "Image source not found at " + window.location.href;
    }
  };

  const isQuestionsContext = (questions) => {
    if (questions && questions.submissionID) {
      return true;
    }

    return false;
  };

  let processImageSafely = () => {
    let error = {};

    //no data source, report and issue the image placeholder
    if (!source) {
      buildErrorMsg(error);
    }

    //check if it's a font icon img and return it if true
    else if (isFontIconImg(source)) {
      return <div className={"icon " + source} id={id}></div>;
    }

    //standard image, test the url first and display if it's valid
    else {
      let verifiedSource;

      //test the require statement
      try {
        verifiedSource = require("../../../images/" + source);
      }
      catch (error) {
        buildErrorMsg(error);
      }

      if (verifiedSource) {
        if (id === "productBannerImg" && questions && isQuestionsContext(questions) && !questions.pageHasBanner) {
          togglePageHasBanner();
        }

        return (
          <Container id="images_container_1" key={id} className={input_css}>
            <Row id="images_row_1">
              <Col id="images_col_1">
                <Image
                  alt={helptext ? helptext : ""}
                  name={name}
                  id={id}
                  src={verifiedSource}
                />
              </Col>
            </Row>
          </Container>
        );
      }
      else {
        buildErrorMsg(error);
      }
    }

    if (!util.isObjEmpty(error)) {
      //log it to our BE error logger
      //no image is a perfectly valid state, don't report this via the logger.
      //logUIError(error, false);

      //show program name flag set here
      if (id === "productBannerImg" && questions && isQuestionsContext(questions) && questions.pageHasBanner) {
        togglePageHasBanner();
      }

      //display our default missing image icon
      // return (
      //   <i
      //     className="fa fa-picture-o"
      //     aria-hidden="true"
      //     alt={helptext ? helptext : "Missing image..."}
      //     style={{
      //       fontSize: fontSize ? fontSize : "30px",
      //       color: color ? color : "#c5c5c5",
      //     }}
      //   ></i>
      // );
    }
  };

  return (
    <div id="images_main_div_1">
      {processImageSafely()}
    </div>
  );
};

export default Images;
