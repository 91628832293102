import { Component } from "react";
import { PropTypes } from "prop-types";
import ErrorPage from "../ErrorPage/ErrorPage.js";

export default class ErrorBoundaryAll extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error?.toString(),
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      document.body.style = 'background-color: #f3f3f3 !important;';

      return (
        <ErrorPage {...this.props} isErrorBoundaryAll={true} />
      );
    }

    //No error = render children within wrapper
    return this.props.children;
  }
}
