import * as Yup from "yup";
import * as Errors from "../../../../Errors/ErrorSchemaMessages/ErrorSchemaMessages";

const CallbackSchema = Yup.object().shape({
  email: Yup.string()
    .email(Errors.default.invalidEmailAddr)
    .max(99, Errors.default.maxChars)
    .min(6, Errors.default.minChars)
    .required(Errors.default.required),
});

export default CallbackSchema;
