import { useSelector } from "react-redux";
import { default as FormInputs } from "../../Utilities/Inputs/FormGroupsWrapper";
import enums from "../../Utilities/Enums/Enums";
import { Formik } from "formik";

const ProductBanner = (props) => {
  //store
  let app = useSelector((state) => state.app);
  let header = useSelector((state) => state.header);

  return (
    <div id="product_banner_main_div_1" className={props.windowWidth >= 768 ? "productBanner" : "d-none"}>
      <Formik>
        {header.productBanner && (
          <FormInputs
            id={"productBannerImg"}
            name={"productBannerImg"}
            helptext={app.productName}
            source={"Banners/" + header.productBanner}
            type={enums.fieldTypes.image}
          />
        )}
      </Formik>
    </div>
  );
};

export default ProductBanner;
