import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from 'react-bootstrap';
import QuoteAndBuy from "./FAQViews/QuoteAndBuy";
import ProofOfInsurance from "./FAQViews/ProofOfInsurance";
import PolicyDocuments from "./FAQViews/PolicyDocuments";
import AdditionalInsured from "./FAQViews/AdditionalInsured";
import PolicyChanges from "./FAQViews/PolicyChanges";
import ChangePassword from "./FAQViews/ChangePassword";
import SamplePolicy from "./FAQViews/SamplePolicy";
import * as sideMenuActions from "../../../SideMenu/SideMenuActions";

const FAQModal = () => {
  //store
  const questions = useSelector((state) => state.questions);
  const sideMenu = useSelector((state) => state.sideMenu);

  //dispatch actions
  const dispatch = useDispatch();

  //local state
  const [openComponentName, setOpenComponent] = useState("");

  let setOpenComponentCallback = (componentName) => {
    setOpenComponent(componentName);
  }

  //outbound
  const toggleFAQModal = useCallback(() => dispatch(sideMenuActions.toggleFAQModal()), [dispatch]);

  //local
  const close = () => {
    toggleFAQModal();
  };

  return (
    <Modal id="faq_modal_1" show={sideMenu.faqModalOpen} onHide={close}>
      <Modal.Header id="faq_modal_header_1" className={"d-flex align-items-center modalHeader border-0"}>
        <div id="faq_modal_div_1">Frequently Asked Questions</div>
        <button id="faq_modal_close_btn_1" onClick={() => close()}>X</button>
      </Modal.Header>
      <Modal.Body id="faq_modal_body_1">
        <div id="faq_modal_div_2">
          <div id="faq_modal_div_3">
            {/* standard view */}
            <span id="faq_modal_span_1" className={questions && questions.pageScriptReporting ? "d-none" : ""}>
              <Row id="faq_modal_row_1">
                <Col id="faq_modal_col_1" xs={"12"}>
                  <QuoteAndBuy openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                </Col>
                <Col id="faq_modal_col_2" xs={"12"}>
                  <div id="faq_modal_div_4">
                    <hr />
                  </div>
                </Col>
              </Row>
              <Row id="faq_modal_row_2">
                <Col id="faq_modal_col_3" xs={"12 mt-15"}>
                  <ProofOfInsurance openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                </Col>
                <Col id="faq_modal_col_4" xs={"12"}>
                  <div id="faq_modal_div_5">
                    <hr />
                  </div>
                </Col>
              </Row>
              <Row id="faq_modal_row_3">
                <Col id="faq_modal_col_7" xs={"12 mt-15"}>
                  <PolicyDocuments openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                </Col>
                <Col id="faq_modal_col_8" xs={"12"}>
                  <div id="faq_modal_div_7">
                    <hr />
                  </div>
                </Col>
              </Row>
              <Row id="faq_modal_row_4">
                <Col id="faq_modal_col_9" xs={"12 mt-15"}>
                  <AdditionalInsured openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                </Col>
                <Col id="faq_modal_col_10" xs={"12"}>
                  <div id="faq_modal_div_8">
                    <hr />
                  </div>
                </Col>
              </Row>
              <Row id="faq_modal_row_5">
                <Col id="faq_modal_col_11" xs={"12 mt-15"}>
                  <PolicyChanges openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                </Col>
                <Col id="faq_modal_col_12">
                  <div id="faq_modal_div_9">
                    <hr />
                  </div>
                </Col>
              </Row>
              <Row id="faq_modal_row_6">
                <Col id="faq_modal_col_13" xs={"12 mt-15"}>
                  <ChangePassword openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                </Col>
                <Col id="faq_modal_col_14">
                  <div id="faq_modal_div_10">
                    <hr />
                  </div>
                </Col>
              </Row>
              <Row id="faq_modal_row_7">
                <Col id="faq_modal_col_15" xs={"12 mt-15"}>
                  <SamplePolicy openComponent={openComponentName} openComponentCallback={setOpenComponentCallback} />
                </Col>
                <Col id="faq_modal_col_16">
                  <div id="faq_modal_div_11">
                    <hr />
                  </div>
                </Col>
              </Row>
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer id="faq_modal_footer_1" />
    </Modal>
  );
};

export default FAQModal;
