import * as reportsActions from "./ReportsActionTypes";

export const INITIAL_STATE = {
  reportData: {},
  reportList: [],
  reportPath: "",
  displayReport: false,
  reportIsLoading: false,
};

export default function reportsReducer(state = INITIAL_STATE, action) {
  const update = (state, mutations) => Object.assign({}, state, mutations);

  switch (action.type) {
    case reportsActions.loadReportListSuccess:
      state = update(state, {
        reportList: action.reportList,
        reportIsLoading: false
      });
      break;
    case reportsActions.runReportSuccess:
      state = update(state, {
        reportPath: action.reportPath,
        displayReport: true,
        reportIsLoading: false,
      });
      break;
    case reportsActions.downloadReportSuccess:
      state = update(state, {
        reportIsLoading: false
      });
      break;
    case reportsActions.setLoadingReportsFlag:
      state = update(state, {
        reportIsLoading: action.isLoading
      });
      break;
    case reportsActions.goBack:
      state = update(state, {
        reportData: {},
        reportPath: "",
        displayReport: false,
      });
      break;
    default:
      /* do nothing */
      break;
  }
  return state;
}
