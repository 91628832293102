import { default as FormInputs } from "../Utilities/Inputs/FormGroupsWrapper";
import { find, forEach } from "lodash";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import enums from "../Utilities/Enums/Enums";
import util from "../Utilities/Functions/Functions";

export const isAdmin = (user) => {
  if (!util.isObjEmpty(user)) {
    return user.entityTypeCode === enums.role.admin;
  }
  return false;
};

export const isCustomer = (user) => {
  if (!util.isObjEmpty(user)) {
    let entityTypeCode = user.entityTypeCode;
    return entityTypeCode === enums.role.direct || entityTypeCode === enums.role.broker;
  }
  return false;
};

export const isMGA = (user) => {
  if (!util.isObjEmpty(user)) {
    return user.entityTypeCode === enums.role.mga;
  }
  return false;
};

export function setProgramsValues(programs, isAllPrograms) {
  let codeLabels = {};
  let codeValues = {};
  let strappedPrograms = {};

  if (programs) {
    for (let i = 0; i < programs.length; i++) {
      if (programs[i].programCode) {
        let value;
        if (isAllPrograms) {
          value = programs[i].showInMenu && programs[i].showInMenu.toLowerCase() === "true" ? "TRUE" : "FALSE";
        }
        else {
          value =
            programs[i].showInMenuForSpecificUser && programs[i].showInMenuForSpecificUser.toLowerCase() === "true"
              ? "TRUE"
              : "FALSE";
        }
        let label = isAllPrograms
          ? programs[i].programCode
          : programs[i].programName + " (" + programs[i].programCode + ")";
        let programCode = programs[i].programCode;
        codeLabels[programCode] = label;
        codeValues[programCode] = value;
      }
    }
  }

  strappedPrograms.codeLabels = util.sortByKey(codeLabels);
  strappedPrograms.codeValues = util.sortByKey(codeValues);
  return strappedPrograms;
}

function menuItem(value, label) {
  this.value = value;
  this.label = label;
}

export function setEntityStatusValues(statuses) {
  let options = [];

  if (statuses) {
    for (let i = 0; i < statuses.length; i++) {
      let item = new menuItem(statuses[i].toUpperCase(), statuses[i]);
      options.push(item);
    }
  }
  return options;
}

export function setBlacklistedUserValues(blacklistedUsers) {
  let strappedBlacklistedUsers = {};

  if (blacklistedUsers) {
    for (let i = 0; i < blacklistedUsers.length; i++) {
      let isBlockedValue = blacklistedUsers[i].isBlocked === true ? "TRUE" : "FALSE";
      let contextValue = blacklistedUsers[i].context;

      strappedBlacklistedUsers[blacklistedUsers[i].ipAddress] = isBlockedValue;
      strappedBlacklistedUsers[blacklistedUsers[i].accessBlacklistId] = contextValue;
    }
  }

  return strappedBlacklistedUsers;
}

const strapProgram = (code, label, value) => {
  return (
    <tr key={code}>
      <td>{label}</td>
      <td>
        <FormInputs name={code} defaultChecked={value} id={code} type={enums.fieldTypes.checkbox} label=" " label_location="right" />
      </td>
    </tr>
  );
};

export function setProgramsModalBody(programs, userName) {
  let inputFields = [];

  forEach(programs.codeValues, (value, code) => {
    let label = find(programs.codeLabels, function (label, programCode) {
      if (programCode === code) {
        return label;
      }
    });

    inputFields.push(strapProgram(code, label, value));
  });

  let title = userName ? "User: " : "Global Programs List";
  let name = userName ? userName : "";

  return (
    <div className="p-4">
      <Row className="mb-3">
        <Col>
          <strong>{title}</strong>
          {name}
        </Col>
      </Row>
      <Table responsive>
        <tbody>{inputFields}</tbody>
      </Table>
    </div>
  );
}

export function setAllProgramsVM(codeValues) {
  let programsAndData = [];
  forEach(codeValues, (value, programCode) => {
    let item = {
      ProgramCode: programCode,
      ProgramName: "",
      ShowInMenu: (value === true || value === enums.bool.true) ? enums.bool.true : enums.bool.false,
    };
    programsAndData.push(item);
  });

  let UserProgramsVM = {
    EntityID: "",
    UserPrograms: programsAndData,
  };
  return UserProgramsVM;
}

export function setUserProgramsVM(userPrograms, userProgramsPristine, entityID, setGlobally) {
  let programsAndData = [];
  let changedCodeValues = {};

  //check if the new codeValue is different than pristine
  forEach(userProgramsPristine.codeValues, (pristineValue, programCode) => {
    let newValue = find(userPrograms.codeValues, (value, code) => {
      if (code === programCode && value !== pristineValue) {
        return value;
      }
    });
    if (newValue) {
      changedCodeValues[programCode] = newValue;
    }
  });

  //if it's different build the new obj to send to back
  forEach(changedCodeValues, (value, programCode) => {
    let programName = find(userPrograms.codeLabels, (label, code) => {
      if (code === programCode) {
        return label;
      }
    });

    let item = {
      ProgramCode: programCode,
      ProgramName: programName ? programName : "",
      showInMenuForSpecificUser: null,
      showInMenu: null,
    };

    item.showInMenuForSpecificUser = (value === true || value === enums.bool.true) ? enums.bool.true : enums.bool.false;

    programsAndData.push(item);
  });

  let UserProgramsVM = {
    EntityID: entityID,
    UserPrograms: programsAndData,
  };

  return UserProgramsVM;
}

export function updateUsers(users, user) {
  let usersCopy = [...users];

  for (let i = 0; i < usersCopy.length; i++) {
    if (usersCopy[i].entityId === user.entityId) {
      usersCopy[i] = user;
      break;
    }
  }
  return usersCopy;
}
