import moment from "moment";
import enums from "../../Utilities/Enums/Enums";
import { clone, forEach } from "lodash";

const acceptedCreditCards = {
  Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  Mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  Amex: /^3[47][0-9]{13}$/,
  Discover:
    /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  Diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
};

export function hasSelectedPaymentType(values) {
  for (let lookupCode in values) {
    if (
      (lookupCode === "PremInd_BuyNow" ||
        lookupCode === "PremInd_Credit" ||
        lookupCode === "PremInd_ECheck" ||
        lookupCode === "PremInd_MailIn" ||
        lookupCode === "PremInd_PremFinance") &&
      values[lookupCode] === "TRUE"
    ) {
      return true;
    }
  }
  return false;
}

export function mapPaymentVM(values, requiresReview) {
  let PurchaseVM = {
    SubmissionID: values.submissionID,
    PageName: values.pageName,
    FinalQuote: values.finalQuote ? parseFloat(values.finalQuote) : "",
    PaymentType:
      values["PremInd_Credit"] === "TRUE"
        ? "CARD"
        : values["PremInd_ECheck"] === "TRUE"
          ? "ACH1"
          : values["PremInd_PremFinance"] === "TRUE"
            ? "IPFS"
            : "MAIL",
    FEIN: values["PremInd_FEIN"],
    BankName: "", //not in use
    BankRouting: values["PremInd_BankRouting"],
    BankAccount: values["PremInd_BankAccount"],
    CCType: values["PremInd_CCType"],
    CCNumber: values["PremInd_CCNumber"],
    CCMonth: values["PremInd_CCExpMonth"],
    CCYear: values["PremInd_CCExpYear"],
    CCCVC: values["PremInd_CCCVC"],
    CCFirst: values["PremInd_CCFirst"],
    CCLast: values["PremInd_CCLast"],
    CCCompany: values["PremInd_CCCompany"],
    CCUseAddress: values["PremInd_CCUseAddress"] === enums.bool.true ? true : false,
    CCAddress: values["PremInd_CCAddress"],
    CCCity: values["PremInd_CCCity"],
    CCState: values["PremInd_CCState"],
    CCZip: values["PremInd_CCZip"],
    Reviewed: false, //not in use
    AcceptedSignature: values["PremInd_AcceptedSignature"],
    AcceptedDate: values["PremInd_AcceptedDate"],
    Consent: false, //not in use
    Downpayment: 0, //not in use
    DebitAuth: false, //not in use, 
    RequiresReview: requiresReview
  };
  return PurchaseVM;
}

export function validateRoutingNumber(value) {
  if (value !== undefined && !isNaN(value)) {
    return /^\d{9}$/.test(value);
  }
  return false;
}

export function confirmAccountNumber(value1, value2) {
  if (value1 !== undefined && value2 !== undefined && value1 === value2) {
    return true;
  }
  return false;
}

export function validateCardCVC(testItemIndexes, testItems, hasFields, enteredCC, enteredCVC) {

  if ((testItems && testItems.length > 0 && testItemIndexes && testItemIndexes.length > 0) || hasFields) {
    let cvc;
    let creditCard;

    if (hasFields) {
      cvc = enteredCVC;
      creditCard = enteredCC;
    }
    else {
      forEach(testItems, (result, index) => {
        if (testItems[testItemIndexes[index]] !== undefined) {
          if (cvc) {
            creditCard = testItems[testItemIndexes[index]].RESULT;
          }
          else {
            cvc = testItems[testItemIndexes[index]].RESULT;
          }
        }
      });
    }

    if (cvc && creditCard) {
      // american express and cvc is 4 digits
      if (acceptedCreditCards.Amex.test(creditCard)) {
        return /^\d{4}$/.test(cvc);
      }
      else if (/^\d{3}$/.test(cvc)) {
        // other card & cvc is 3 digits
        return true;
      }
      else {
        return false;
      }
    }
  }

  return false;
}

export function validateCardMonth(testItemIndexes, testItems, hasFields, enteredMonth) {

  if ((testItems && testItems.length > 0 && testItemIndexes && testItemIndexes.length > 0) || hasFields) {
    let month;

    if (hasFields) {
      month = enteredMonth;
    }
    else {
      forEach(testItems, (result, index) => {
        if (testItems[testItemIndexes[index]] !== undefined) {
          month = testItems[testItemIndexes[index]].RESULT;
        }
      });
    }

    if (month) {
      // remove all non digit characters
      var monthNormal = month.replace(/\D/g, "");
      if (/^(?:1[0-2]|0[1-9])$/.test(monthNormal)) {
        return true;
      }
    }
  }

  return false;
}

export function validateCardNumber(testItemIndexes, testItems, hasFields, enteredCC, enteredCCType) {

  if ((testItems && testItems.length > 0 && testItemIndexes && testItemIndexes.length > 0) || hasFields) {
    let numbers;
    let selectedType;

    if (hasFields) {
      selectedType = enteredCCType;
      const rawNumberEntry = enteredCC ? String(clone(enteredCC)) : "0";
      const normalizedNumbers = rawNumberEntry.replace(/-|\s/g, "");
      numbers = normalizedNumbers;
    }
    else {
      forEach(testItems, (result, index) => {
        if (testItems[testItemIndexes[index]] !== undefined) {
          if (numbers) {
            selectedType = testItems[testItemIndexes[index]].RESULT;
          }
          else {
            const rawNumberEntry = testItems[testItemIndexes[index]].RESULT ? String(clone(testItems[testItemIndexes[index]].RESULT)) : "0";
            const normalizedNumbers = rawNumberEntry.replace(/-|\s/g, "");
            numbers = normalizedNumbers;
          }
        }
      });
    }

    if (numbers && selectedType) {
      //reference: https://medium.com/hootsuite-engineering/a-comprehensive-guide-to-validating-and-formatting-credit-cards-b9fa63ec7863
      // remove all non digit characters
      var sum = 0;
      var shouldDouble = false;
      // loop through numberss starting at the rightmost side
      for (var i = numbers.length - 1; i >= 0; i--) {
        var digit = parseInt(numbers.charAt(i));

        if (shouldDouble) {
          if ((digit *= 2) > 9) digit -= 9;
        }
        sum += digit;
        shouldDouble = !shouldDouble;
      }

      var valid = sum % 10 === 0;
      var accepted = false;
      var cardType;

      // loop through the keys (visa, mastercard, amex, etc.)
      Object.keys(acceptedCreditCards).forEach((key) => {
        let regex = acceptedCreditCards[key];
        if (regex.test(numbers)) {
          cardType = key;
          accepted = true;
        }
      });
      if (valid && accepted) {
        if (selectedType.includes(cardType)) {
          return true;
        }
        else {
          //card number does not match card type selected above
          return false;
        }
      }
      else {
        return false;
      }
    }
  }

  return false;
}

export function validateCardYear(testItemIndexes, testItems, hasFields, enteredYear) {

  if ((testItems && testItems.length > 0 && testItemIndexes && testItemIndexes.length > 0) || hasFields) {
    let year;

    if (hasFields) {
      year = enteredYear;
    }
    else {
      forEach(testItems, (result, index) => {
        if (testItems[testItemIndexes[index]] !== undefined) {
          year = testItems[testItemIndexes[index]].RESULT;
        }
      });
    }

    if (year) {
      var yearNow = moment().year();
      if (/^\d{4}$/.test(year)) {
        if (year - yearNow <= 19 && year - yearNow >= 0) {
          return true;
        }
        else {
          return false;
        }
      }
    }

  }

  return false;
}

export function isValidCreditCard(formValues) {
  let count = 0;
  let validCount = 11;

  forEach(formValues, (value, key) => {
    if (
      key === "PremInd_CCTypeValid" ||
      key === "PremInd_CCNumberValid" ||
      key === "PremInd_CCExpMonthValid" ||
      key === "PremInd_CCExpYearValid" ||
      key === "PremInd_CCCVCValid" ||
      key === "PremInd_CCFirstValid" ||
      key === "PremInd_CCLastValid"
    ) {
      if (value === "TRUE") {
        count++;
      }
      else {
        return false;
      }
    }
    if (formValues["PremInd_CCUseAddress"] === "FALSE") {
      if (
        key === "PremInd_CCAddressValid" ||
        key === "PremInd_CCCityValid" ||
        key === "PremInd_CCStateValid" ||
        key === "PremInd_CCZipValid"
      ) {
        if (
          value === "TRUE" &&
          (
            formValues["PremInd_CCAddress"] ||
            formValues["PremInd_CCCity"] ||
            formValues["PremInd_CCState"] ||
            formValues["PremInd_CCZip"]
          )
        ) {
          count++;
        }
        else {
          return false;
        }
      }
    }
  });

  if (formValues["PremInd_CCUseAddress"] === "TRUE" || formValues["PremInd_CCUseAddressValid"] === "TRUE") {
    count += 4;
  }

  return count === validCount;
}

export function isValidECheck(formValues) {
  let count = 0;
  let validCount = 2;

  forEach(formValues, (value, key) => {
    switch (key) {
      case "PremInd_BankAccount":
        if (value !== "") {
          count++;
          break;
        }
        else {
          return false;
        }
      case "PremInd_BankRouting":
        if (validateRoutingNumber(value)) {
          count++;
          break;
        }
        else {
          return false;
        }
      default:
        break;
    }
  });

  return count === validCount;
}

export function isValidPremFinance(formValues) {
  let count = 0;
  let validCount = 6;

  forEach(formValues, (value, key) => {
    switch (key) {
      case "PremInd_AcceptedSignatureValid":
      case "PremInd_PremFinanceConsentValid":
      case "PremInd_PremFinanceIPFSAuthValid":
      case "PremInd_PremFinanceReviewedTermsValid":
        if (value === "TRUE") {
          count++;
          break;
        }
        else {
          //exit for loop
          return false;
        }
      case "PremInd_BankAccount":
        if (value !== "") {
          count++;
          break;
        }
        else {
          return false;
        }
      case "PremInd_BankRouting":
        if (validateRoutingNumber(value)) {
          count++;
          break;
        }
        else {
          return false;
        }
      default:
        break;
    }
  });

  return count === validCount;
}
